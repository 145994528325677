import React from 'react'
import styled from 'styled-components'
import { text } from 'utils/colors'

interface SignatureProps {
  name: string
}

const SignatureContainer = styled.div`
  font-family: 'Nothing You Could Do', cursive;
  font-size: 1.2em;
  color: ${text.main};
`

const Signature: React.FC<SignatureProps> = ({ name }: SignatureProps) => {
  return <SignatureContainer>{name}</SignatureContainer>
}

export default Signature
