import {
  FIND_CHANGE_PASSWORD_URL_LOADING,
  FIND_CHANGE_PASSWORD_URL_SUCCESS,
  FIND_CHANGE_PASSWORD_URL_FAILURE,
  UPDATE_USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLOSE_USER_UPDATE_MODAL,
} from 'actions/userActions'

interface UserState {
  changePasswordUrl: {
    isFetching: boolean
    item: { url: string }

    error?: string
    lastUpdated?: Date
  }

  user: {
    isFetching: boolean
    isUpdateSuccessful: boolean
    item: {} | { id: string; name: string; email: string }
    error?: string
  }
}

const initState = {
  changePasswordUrl: {
    isFetching: false,
    item: { url: '' },
  },

  user: {
    isFetching: false,
    isUpdateSuccessful: false,
    item: {},
  },
}

export const userReducer = (state = initState, action: any): UserState => {
  switch (action.type) {
    case FIND_CHANGE_PASSWORD_URL_LOADING:
      return {
        ...state,

        changePasswordUrl: {
          ...state.changePasswordUrl,

          isFetching: true,
          error: undefined,
        },
      }

    case FIND_CHANGE_PASSWORD_URL_SUCCESS:
      return {
        ...state,

        changePasswordUrl: {
          ...state.changePasswordUrl,

          item: {
            url: action.payload.url,
          },

          isFetching: false,
          error: undefined,
        },
      }

    case FIND_CHANGE_PASSWORD_URL_FAILURE:
      return {
        ...state,

        changePasswordUrl: {
          ...state.changePasswordUrl,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case UPDATE_USER_LOADING:
      return {
        ...state,

        user: {
          ...state.user,

          isFetching: true,
          error: undefined,
        },
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,

        user: {
          ...state.user,

          isFetching: false,
          isUpdateSuccessful: true,
          item: {
            id: action.payload.user.id,
            name: action.payload.user.name,
            email: action.payload.user.email,
          },
        },
      }

    case CLOSE_USER_UPDATE_MODAL:
      return {
        ...state,
        user: {
          ...state.user,
          isUpdateSuccessful: false,
        },
      }

    case UPDATE_USER_FAILURE:
      return {
        ...state,

        user: {
          ...state.user,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    default:
      return state
  }
}
