/*
 *
 * PoweredBy Component
 *
 */

import React from 'react'
import styled from 'styled-components'
import { text } from 'utils/colors'
import { useTranslation } from 'react-i18next'
import brandConfig, { BrandConfigProps } from 'utils/brandConfig'

import ComplyifyLogo from 'components/ComplyifyLogo'
import PoweredByLogo from 'components/PoweredByLogo'
import EALogo from 'components/EALogo'

const PoweredByContainer = styled.div`
  align-self: end;
  padding: 1rem 1.5rem;
  opacity: 0.7;
`
const ComplyifyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  color: ${text.nav};
  width: 85%;
  margin: 0 auto;
  height: 1.5em;

  p {
    font-weight: 300;
    margin: 0;
    text-align: right;
    padding-right: 0.5em;
    font-size: 0.8em;
  }

  svg {
    width: 9rem;
  }
`

const PoweredBy: React.FC<{}> = () => {
  const { t } = useTranslation()

  const renderPoweredIcon = (brandConfig: BrandConfigProps): JSX.Element => {
    switch (brandConfig?.brandId) {
      case 'TRIVE':
        return (
          <ComplyifyWrapper>
            <p>{t('poweredBy')}</p>
            <ComplyifyLogo />
          </ComplyifyWrapper>
        )

      case 'MOCK':
        return <PoweredByLogo />

      case 'EA':
        return <EALogo />

      default:
        return (
          <ComplyifyWrapper>
            <p>{t('poweredBy')}</p>
            <ComplyifyLogo />
          </ComplyifyWrapper>
        )
    }
  }

  return <PoweredByContainer>{renderPoweredIcon(brandConfig)}</PoweredByContainer>
}

export default PoweredBy
