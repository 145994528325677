import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { setNestedObjectValues } from 'utils/objectMethods'
import { map, isEmpty } from 'lodash'

import InputText from 'components/Form/InputText'
import Button from 'components/Button'
import { ActionBtns } from 'components/styles'
// import Debug from 'components/Form/Debug'

interface FormProps {
  fields: Record<string, any>
  submitLabel?: string | React.ReactElement<any>
  isFlex?: boolean
  actionBtns?: React.ReactElement<any>
  validate: (values: Record<string, any>) => Record<string, any>
  onSubmit: (values: any) => void
}

interface FormType {
  initValues: Record<string, any>
  validate: (values: Record<string, any>) => Record<string, any>
  onSubmit: (values: any) => void
}

const FormContainer = styled.form<{ isFlex?: boolean }>`
  display: ${p => (p.isFlex ? 'flex' : 'block')};
  align-items: center;
  * {
    margin-right: ${p => (p.isFlex ? '0.5rem' : '')};
  }
`

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_FIELD_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      }

    case 'SET_FIELD_TOUCHED':
      return {
        ...state,
        touched: {
          ...state.touched,
          ...action.payload,
        },
      }

    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      }

    case 'SUBMIT_ATTEMPT':
      return {
        ...state,
        isSubmitting: true,
        touched: setNestedObjectValues(state.values, true),
      }

    case 'SUBMIT_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
      }

    case 'SUBMIT_FAILURE':
      return {
        ...state,
        isSubmitting: false,
        submitError: action.payload,
      }

    default:
      return state
  }
}

const useForm = ({ initValues, validate, onSubmit }: FormType) => {
  const [state, dispatch] = React.useReducer(reducer, {
    values: initValues,
    errors: {},
    touched: {},
  })

  React.useEffect(() => {
    if (validate) {
      const errors = validate(state.values)
      dispatch({ type: 'SET_ERRORS', payload: errors })
    }
  }, [state.values, validate])

  const handleChange = (event: any) => {
    console.log('handleChange()', event)
    event.persist()
    dispatch({
      type: 'SET_FIELD_VALUE',
      payload: {
        [event.target.name]: event.target.value,
      },
    })
  }

  const handleBlur = (event: any) => {
    console.log('handleBlur()', event)
    event.persist()
    dispatch({
      type: 'SET_FIELD_TOUCHED',
      payload: {
        [event.target.name]: true,
      },
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    dispatch({ type: 'SUBMIT_ATTEMPT' })
    const errors = validate(state.values)
    if (isEmpty(errors)) {
      try {
        await onSubmit(state.values)
        dispatch({ type: 'SUBMIT_SUCCESS' })
      } catch (submitError) {
        dispatch({ type: 'SUBMIT_FAILURE', payload: submitError })
      }
    } else {
      dispatch({ type: 'SET_ERRORS', payload: errors })
      dispatch({ type: 'SUBMIT_FAILURE' })
    }
  }

  return {
    handleChange,
    handleBlur,
    handleSubmit,
    ...state,
  }
}

const Form: React.FC<FormProps> = ({ fields, validate, isFlex, onSubmit, submitLabel, actionBtns }: FormProps) => {
  const { t } = useTranslation()
  const formProps = useForm({ initValues: fields, validate, onSubmit })
  const { handleChange, handleBlur, handleSubmit, values, touched, errors } = formProps

  return (
    <FormContainer onSubmit={handleSubmit} isFlex={isFlex}>
      {map(fields, (value, key) => (
        <InputText
          key={`input-${key}`}
          name={key}
          value={values[key]}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched[key] && errors[key]}
        />
      ))}
      <ActionBtns>
        {actionBtns || (
          <Button onClick={handleSubmit} type="submit" size="small">
            {submitLabel || t('submitBtn')}
          </Button>
        )}
        {/* <Debug form={formProps} /> */}
      </ActionBtns>
    </FormContainer>
  )
}

export default Form
