import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import { text } from 'utils/colors'

import Button from 'components/Button'
import Modal from 'components/Modal'
import ErrorView from 'components/ErrorView'
import { SubTitle, MiniTitle, ActionBtns } from 'components/styles'

interface InstructionsProps {
  initialVal?: string
  templateId?: string
  isOpen: boolean
  isViewOnly?: boolean
  onCancel: () => void
  onSave: (instructions: string) => void
}

const InstructContainer = styled.div`
  width: 40rem;
  margin: 0 0 1rem;
`
const InstructTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem;
`
const UnsavedChangesWarning = styled(MiniTitle)<{ isWarning: boolean }>`
  padding: ${p => (p.isWarning ? '1rem 0 0' : '0')};
  overflow: hidden;
  height: auto;
  max-height: ${p => (p.isWarning ? '10rem' : '0')};
  transition: all 0.3s ease-in-out;
  color: ${text.error};
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
`
const defaultValues = {
  TextField: '',
}

const Instructions: React.FC<InstructionsProps> = ({
  initialVal,
  isOpen,
  isViewOnly,
  onCancel,
  onSave,
}: InstructionsProps) => {
  const { t } = useTranslation()
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const [isWarning, setWarning] = React.useState<boolean>(false)

  const { control, watch } = useForm({ defaultValues })

  const { templateInstructions } = useSelector((state: any) => state.templates)
  const currentVal = watch('instructions', initialVal) as string

  const handleSave = () => {
    setHasChanges(false)
    setWarning(false)
    onSave(currentVal)
  }

  const handleCancelConfirm = () => {
    setHasChanges(false)
    setWarning(false)
    onCancel()
  }

  const handleCancel = () => {
    if (hasChanges) {
      setWarning(true)
    } else {
      handleCancelConfirm()
    }
  }

  if (!!currentVal && currentVal !== initialVal && !hasChanges) {
    setHasChanges(true)
  }

  const ModalContent = (
    <InstructContainer>
      <InstructTitle>
        <SubTitle>{t('editInstructionsTitle')}</SubTitle>

        <ActionBtns>
          <>
            {!isViewOnly && (
              <Button onClick={handleSave} color="success" size="small">
                {t('saveBtn')}
              </Button>
            )}

            <Button onClick={handleCancelConfirm} color="secondary" size="small">
              {isViewOnly ? t('closeBtn') : t('cancelBtn')}
            </Button>
          </>
        </ActionBtns>
      </InstructTitle>
      <Controller
        as={TextField}
        name="instructions"
        control={control}
        variant="outlined"
        multiline
        defaultValue={initialVal || ''}
        fullWidth
        disabled={isViewOnly}
        rows={12}
      />

      {templateInstructions?.error ? (
        <ErrorView errorMsg={t(templateInstructions?.error)} />
      ) : isWarning ? (
        <UnsavedChangesWarning isWarning={isWarning}>{t('unsavedMsg')}</UnsavedChangesWarning>
      ) : null}
    </InstructContainer>
  )

  return <Modal isOpen={isOpen} content={ModalContent} onClose={handleCancel} width="lg" />
}

export default Instructions
