import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { isEmpty, map, filter, includes, find, orderBy, concat, isNil } from 'lodash'
import { AdminReport, ReportUser } from 'models/report'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { listReports, removeReport, updateEffectiveDate, clearErrors } from 'actions/reportActions'
import { isMockData } from 'utils/brandConfig'
import { mockCompanies } from 'mockData/mockCompanies'
import { mockReportsResponse } from 'mockData/mockReports'
import { mockTemplates } from 'mockData/mockTemplates'
// import { Label } from 'components/Form/GoodForm'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import ErrorView from 'components/ErrorView'
import EmptyView from 'components/EmptyView'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import LatestEvent from 'components/LatestEvent'
import StatusBadge from 'components/StatusBadge'
import Avatar from 'components/Avatar'
import ConfirmDialog from 'components/ConfirmDialog'

import { ContentBox, ColumnHeading, ToggleIcon, ActionBtns } from 'components/styles'
import {
  ReportsTable,
  ReportRow,
  ReportRowHeader,
  ReportRowMain,
  ReportName,
  ReportTemplate,
  ReportStatus,
  UsersWrapper,
  ReportResponse,
  HiddenReportDetail,
  ReportUserRow,
  UserDetailStatus,
  UserDetailNotSubmitted,
  UserDetailRemoveReport,
  UserDetailTimestamp,
  UserDetailResponse,
  UserDetailRemovedReport,
  UserDetailError,
  EffectiveDateCell,
  DateWrapper,
  DateInputWrapper,
  RemoveEffectiveDate,
  DateInput,
  DateRow,
  EffectiveDateForm,
  EffectiveDate,
  EffectiveFlag,
  EditEffectiveDate,
  ErrorModal,
} from './styles'
import Modal from 'components/Modal'

interface ReportListProps {
  companyId: string
}

dayjs.extend(relativeTime)

const ReportsList: React.FC<ReportListProps> = ({ companyId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const [openReport, setOpenReport] = React.useState<number | undefined>(0)
  const [reportIdToEditDate, setReportIdToEditDate] = React.useState<string | undefined>(undefined)
  const [selectedEffectiveDate, setSelectedEffectiveDate] = React.useState<Date | undefined>()
  const [removeConfirmation, setRemoveConfirmation] = React.useState<
    { reportId: string; userId: string; userName: string } | undefined
  >()
  const mockCompany = find(mockCompanies, co => co.id === companyId)

  const mockReports = map(mockReportsResponse, report => {
    return {
      ...report,
      users: map(mockCompany?.users, (user, i) => {
        return {
          ...report?.users[i],
          name: user.name,
          email: user.email,
          id: user.id,
        }
      }),
      template: {
        id: mockTemplates[0]?.id,
        name: mockTemplates[0]?.name,
      },
    }
  })

  const { reportList } = useSelector((state: any) => state.reports)

  React.useEffect(() => {
    dispatch(listReports(auth0Context, companyId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggleReport = (reportIndex: number) => {
    if (reportIndex === openReport) {
      setOpenReport(undefined)
    } else {
      setOpenReport(reportIndex)
    }
  }

  const handleSubmitEffectiveDate = (sendDate: string) => {
    if (!isNil(reportIdToEditDate)) {
      const effectiveDate = isNil(selectedEffectiveDate) ? sendDate : dayjs(selectedEffectiveDate).toString()
      dispatch(updateEffectiveDate(auth0Context, { reportId: reportIdToEditDate, effectiveDate }))
      setReportIdToEditDate(undefined)
      setSelectedEffectiveDate(undefined)
    }
  }

  const getIsReportRemoved = (report: AdminReport, user?: ReportUser) => {
    if (user) {
      if (user.reportStatus === 'REMOVED') return true
      const pendingItem = reportList.pendingItems[`${report.id}-${user.id}`]
      return pendingItem?.status === 'REMOVED'
    }
    if (report.status === 'REMOVED') return true
    const activeUsers: ReportUser[] = filter(report.users, (u: ReportUser) => !getIsReportRemoved(report, u))
    return isEmpty(activeUsers)
  }

  const handleGoToReport = (reportId: string) => {
    console.log('handleGoToReport', reportId)
  }

  const handleRemoveReport = (): void => {
    if (removeConfirmation) {
      const { reportId, userId } = removeConfirmation
      console.log('handleRemoveReport', reportId, userId)
      dispatch(removeReport(auth0Context, reportId, userId))
      setRemoveConfirmation(undefined)
    }
  }

  const renderEffectiveDateModal = (reportId?: string) => {
    const reportDetail: AdminReport = find(sortedReports, r => r.id === reportId)
    // const initialDate = isNil(reportDetail?.effectiveDate) ? undefined : dayjs(reportDetail?.effectiveDate).toDate()

    return (
      <EffectiveDateForm>
        <p>
          Effective Date is the date which your report is considered sent on. Changing the Effective Date can
          incorporate a report's responses into a different quarter/annual grouping on a Metrics historical graph than
          what the original Report Sent date considers it to be in.
        </p>
        <DateRow>
          <label>Report Sent:</label>
          <DateWrapper>
            <div>{dayjs(reportDetail?.sentDate).format('D MMM YYYY')}</div>
          </DateWrapper>
        </DateRow>
        <DateRow>
          <label>Effective Date:</label>
          <DateInputWrapper>
            <DateInput>
              <DatePicker
                id="report_schedule_start"
                name="report_schedule_start"
                dateFormat="d MMM yyyy"
                popperPlacement="top"
                selected={selectedEffectiveDate}
                onChange={(date: Date) => setSelectedEffectiveDate(date)}
                onSelect={(date: Date) => setSelectedEffectiveDate(date)}
                maxDate={new Date()}
              />
            </DateInput>
            <RemoveEffectiveDate onClick={() => setSelectedEffectiveDate(undefined)}>
              <FontAwesomeIcon icon="times-circle" />
            </RemoveEffectiveDate>
          </DateInputWrapper>
        </DateRow>
        <ActionBtns>
          <Button onClick={() => handleSubmitEffectiveDate(reportDetail?.sentDate)} size="small" color="success">
            {t('submitBtn')}
          </Button>
          <Button
            onClick={() => {
              setReportIdToEditDate(undefined)
              setSelectedEffectiveDate(undefined)
            }}
            size="small"
            color="secondary"
          >
            {t('cancelBtn')}
          </Button>
        </ActionBtns>
      </EffectiveDateForm>
    )
  }

  const renderReport = (report: AdminReport, index: number) => {
    const completeUsers = filter(report.users, (u: ReportUser) =>
      includes(['COMPLIANT', 'NON_COMPLIANT', 'COMPLETE'], u.reportStatus),
    )
    const pendingUsers = filter(report.users, (u: ReportUser) => includes(['PENDING', 'OVERDUE'], u.reportStatus))
    const isAllReportsRemoved = getIsReportRemoved(report)
    const isEffectiveDateDefined =
      !isNil(report.effectiveDate) &&
      dayjs(report.sentDate).format('YYYYMMDD') !== dayjs(report.effectiveDate).format('YYYYMMDD')

    return (
      <ReportRow key={report.id} isActive={openReport === index}>
        <ReportRowMain onClick={() => handleToggleReport(index)} isRemoved={isAllReportsRemoved}>
          {isEmpty(report.users) || isAllReportsRemoved ? (
            <div />
          ) : (
            <ToggleIcon isOpen={openReport === index}>
              <FontAwesomeIcon icon="angle-right" />
            </ToggleIcon>
          )}
          <ReportName title={report.name}>{report.name}</ReportName>
          <ReportTemplate>{report.template.name}</ReportTemplate>
          <ReportStatus>
            {report.status === 'NON_COMPLIANT' ? (
              <StatusBadge
                status={isAllReportsRemoved ? 'REMOVED' : report.status}
                count={report.questionStatistics.NON_COMPLIANT}
              />
            ) : (
              <StatusBadge status={isAllReportsRemoved ? 'REMOVED' : report.status} />
            )}
          </ReportStatus>
          <UsersWrapper>
            {map(completeUsers, (u: ReportUser) => (
              <Avatar
                key={`completedUser-${companyId}-${u.id}`}
                user={u}
                status={u?.reportStatus}
                isImageOnly
                isCircleSmall
              />
            ))}
          </UsersWrapper>
          <UsersWrapper>
            {map(pendingUsers, (u: ReportUser) => {
              if (getIsReportRemoved(report, u)) return null
              return (
                <Avatar
                  key={`pendingUser-${companyId}-${u.id}`}
                  user={u}
                  status={u?.reportStatus}
                  isImageOnly
                  isCircleSmall
                />
              )
            })}
          </UsersWrapper>
          {isAllReportsRemoved || isNil(report?.sentDate) ? (
            <div />
          ) : (
            <EffectiveDateCell>
              <EffectiveDate>
                {isNil(report.effectiveDate)
                  ? dayjs(report.sentDate).format('D MMM YYYY')
                  : dayjs(report.effectiveDate).format('D MMM YYYY')}
                {isEffectiveDateDefined && <EffectiveFlag>Effective</EffectiveFlag>}
              </EffectiveDate>
              <EditEffectiveDate
                onClick={e => {
                  e.stopPropagation()
                  setReportIdToEditDate(report.id)
                  setSelectedEffectiveDate(isEffectiveDateDefined ? dayjs(report.effectiveDate).toDate() : undefined)
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </EditEffectiveDate>
            </EffectiveDateCell>
          )}

          <LatestEvent events={report.events} />
          {isAllReportsRemoved ? (
            <div />
          ) : (
            <Button to={`/report/${report.id}/${companyId}`} color="active" size="small">
              {t('findReportBtn')}
            </Button>
          )}
        </ReportRowMain>

        <HiddenReportDetail isOpen={openReport === index}>
          {map(report.users, u => {
            if (u.reportStatus === 'REMOVED') {
              return null
            }
            const isUserReportComplete = includes(['COMPLIANT', 'NON_COMPLIANT', 'COMPLETE'], u.reportStatus)
            const isUserReportRemoved = getIsReportRemoved(report, u)
            const userReportStatus = isUserReportComplete
              ? 'COMPLETE'
              : isUserReportRemoved
              ? 'REMOVED'
              : u.reportStatus

            const pendingItem = reportList.pendingItems[`${report.id}-${u.id}`]
            let removeIcon = (
              <Button
                onClick={() =>
                  setRemoveConfirmation({
                    reportId: report.id,
                    userId: u.id,
                    userName: u.name,
                  })
                }
                isSimple
                color="error"
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            )
            if (pendingItem?.status === 'PENDING') {
              removeIcon = <Spinner isMini />
            }
            if (pendingItem?.status === 'REMOVED') {
              removeIcon = <UserDetailRemovedReport>{t('removedMsg')}</UserDetailRemovedReport>
            }
            if (pendingItem?.status === 'ERROR') {
              removeIcon = (
                <UserDetailError>
                  {t('somethingWentWrong')}
                  <br />
                  {pendingItem?.error}
                </UserDetailError>
              )
            }

            const userResponses = isUserReportComplete ? (
              <>
                <ReportResponse status="NON_COMPLIANT">
                  {t('nonCompliantCount', {
                    count: u.questionStatistics.NON_COMPLIANT,
                  })}
                </ReportResponse>
                <ReportResponse status="NOT_APPLICABLE">
                  {t('notApplicableCount', {
                    count: u.questionStatistics.NOT_APPLICABLE,
                  })}
                </ReportResponse>
              </>
            ) : (
              <>
                <UserDetailNotSubmitted>{t('notSubmittedReportMsg')}</UserDetailNotSubmitted>
                <UserDetailRemoveReport>{removeIcon}</UserDetailRemoveReport>
              </>
            )
            const completedWhen = dayjs(report.dueBy).fromNow() // TODO not the correct value - need completed date
            const timeMsg = isUserReportComplete
              ? t('completedReportMsg', { completedWhen })
              : u.reportStatus === 'OVERDUE'
              ? t('overdueReportMsg', {
                  fromNow: dayjs(report.dueBy).fromNow(),
                })
              : t('pendingReportMsg', {
                  toNow: dayjs(report.dueBy).toNow(true),
                })

            return (
              <ReportUserRow
                key={`userDetail-${companyId}-${u.id}`}
                onClick={() => handleGoToReport(report.id)}
                isRemoved={isUserReportRemoved}
              >
                <Avatar user={u} isCircleSmall />
                <UserDetailStatus status={userReportStatus}>{t(userReportStatus)}</UserDetailStatus>
                {isUserReportRemoved ? (
                  <div />
                ) : (
                  <UserDetailTimestamp isComplete={isUserReportComplete} status={u.reportStatus}>
                    {timeMsg}
                  </UserDetailTimestamp>
                )}
                <UserDetailResponse>{userResponses}</UserDetailResponse>
              </ReportUserRow>
            )
          })}
        </HiddenReportDetail>
      </ReportRow>
    )
  }

  const reports = !isMockData ? reportList?.items : mockReports
  const activeReports = orderBy(
    filter(reports, r => !getIsReportRemoved(r)),
    'dueBy',
    'desc',
  )
  const removedReports = orderBy(
    filter(reports, r => getIsReportRemoved(r)),
    'dueBy',
    'desc',
  )
  const sortedReports = concat(activeReports, removedReports)

  let content
  if (reportList.error && !isMockData) {
    content = <ErrorView errorMsg={t('noReportsToListMsg')} serverResponse={t(reportList.error)} />
  } else if (reportList.isFetching) {
    content = <Spinner />
  } else if (isEmpty(reports)) {
    content = <EmptyView emptyMsg={t('noReportsToListMsg')} />
  } else {
    content = (
      <ReportsTable>
        <ReportRowHeader>
          <ColumnHeading isLeftAligned size="lg" isDoubleCol>
            {t('reportsTableTitle')}
          </ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportTemplateCol')}</ColumnHeading>
          <ColumnHeading>{t('reportStatusCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportCompletedCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportPendingCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportSentCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned isDoubleCol>
            {t('reportLatestCol')}
          </ColumnHeading>
        </ReportRowHeader>
        {map(sortedReports, (r: AdminReport, index: number) => renderReport(r, index))}
      </ReportsTable>
    )
  }

  const removingReport = find(reportList?.items, r => r.id === removeConfirmation?.reportId)

  return (
    <>
      <ContentBox>{content}</ContentBox>
      <ConfirmDialog
        isOpen={!!removeConfirmation}
        confirmText={t('removeReportConfirmationText', {
          reportName: removingReport?.name || 'this report',
          userName: removeConfirmation?.userName || 'this user',
        })}
        onConfirm={handleRemoveReport}
        onCancel={() => setRemoveConfirmation(undefined)}
      />
      <Modal
        isOpen={!isNil(reportIdToEditDate)}
        onClose={() => {
          setReportIdToEditDate(undefined)
          setSelectedEffectiveDate(undefined)
        }}
        title="Choose Effective Send Date"
        content={renderEffectiveDateModal(reportIdToEditDate)}
      />
      <Modal
        isOpen={!isEmpty(reportList.error)}
        width="md"
        content={
          <ErrorModal>
            <h2>Something went wrong</h2>
            <p>An error occurred. If this issue persists, please contact support.</p>
            <pre>{reportList.error}</pre>
            <ActionBtns>
              <Button color="error" size="small" onClick={() => dispatch(clearErrors())}>
                {t('okayBtn')}
              </Button>
            </ActionBtns>
          </ErrorModal>
        }
      />
    </>
  )
}

export default ReportsList
