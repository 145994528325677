import brandConfig from 'utils/brandConfig'
import hexToRgb from 'utils/hexToRgb'
import isColorLight from 'utils/isColorLight'

export const bgOpacity = 0.08
export const hintOpacity = 0.05
export const activeMultiplier = 0.8
export const fadedOpacity = 0.75
export const invertedOpacity = 0.6

export const logo = {
  hex: brandConfig.colorPrimary,
  rgb: hexToRgb(brandConfig.colorPrimary),
}
export const dark = {
  hex: brandConfig.colorDark,
  rgb: hexToRgb(brandConfig.colorDark),
}
export const crayola = {
  blue: {
    hex: '#4D8CBD',
    rgb: '77,140,189',
  },
  green: {
    hex: '#28BD59',
    rgb: '40,189,89',
  },
  red: {
    hex: '#F0433E',
    rgb: '240,67,62',
  },
  yellow: {
    hex: '#F8AF41',
    rgb: '248,175,65',
  },
}

export const pastel = {
  blue: {
    hex: '#56BDDE',
    rgb: '86, 189, 222',
  },
  green: {
    hex: '#6CC7BF',
    rgb: '108, 199, 191',
  },
  red: {
    hex: '#FF8764',
    rgb: '255, 135, 100',
  },
  yellow: {
    hex: '#FFC301',
    rgb: '255, 195, 1',
  },
}

export const smooth = {
  blue: {
    hex: '#68B3C8',
    rgb: '104,179,200',
  },
  green: {
    hex: '#52b86d',
    rgb: '82, 184, 109',
  },
  red: {
    hex: '#EB5E28',
    rgb: '235,94,40',
  },
  yellow: {
    hex: '#F3BA45',
    rgb: '243, 186, 69',
  },
}

export const blue = smooth.blue
export const green = smooth.green
export const red = smooth.red
export const yellow = smooth.yellow

export const white = {
  hex: '#FFF',
  rgb: '255,255,255',
}
export const black = {
  hex: '#333',
  rgb: '33,33,33',
}
export const darkGrey = {
  hex: '#555',
  rgb: '55,55,55',
}
export const mediumGrey = {
  hex: '#999',
  rgb: '99,99,99',
}
export const grey = {
  hex: '#aaa',
  rgb: '170,170,170',
}
export const lightGrey = {
  hex: '#ddd',
  rgb: '221,221,221',
}
export const offWhite = {
  hex: '#f9f9fc',
  rgb: '249,249,252',
}

export const lighten = `rgba(${white.rgb},0.1)`
export const shaded = `rgba(${black.rgb},0.3)`
export const darken = `rgba(${black.rgb},0.5)`

export const text = {
  logo: logo.hex,
  main: black.hex,
  contentBox: black.hex,
  contrast: white.hex,
  nav: white.hex,
  titlebar: isColorLight(logo.hex) ? black.hex : white.hex,
  reportUser: white.hex,
  active: blue.hex,
  success: green.hex,
  error: red.hex,
  warning: yellow.hex,
  secondary: darkGrey.hex,
  COMPLETE: green.hex,
  COMPLIANT: green.hex,
  NON_COMPLIANT: red.hex,
  NOT_APPLICABLE: mediumGrey.hex,
  OVERDUE: yellow.hex,
  PENDING: blue.hex,
  INACTIVE: grey.hex,
  REMOVED: grey.hex,
  COMPLETED: green.hex,
  CONFLICTING_ANSWERS: red.hex,
  inactive: grey.hex,
  deemphasis: mediumGrey.hex,
  light: grey.hex,
  disabled: white.rgb,
}

export const statusColors = {
  COMPLIANT: green.hex,
  NON_COMPLIANT: red.hex,
  NOT_APPLICABLE: mediumGrey.hex,
  OVERDUE: yellow.hex,
  PENDING: blue.hex,
  INACTIVE: grey.hex,
}

export const rgb = {
  logo: logo.rgb,
  main: black.rgb,
  contentBox: black.rgb,
  contrast: white.rgb,
  nav: white.rgb,
  titlebar: isColorLight(logo.hex) ? black.rgb : white.rgb,
  reportUser: white.rgb,
  active: blue.rgb,
  success: green.rgb,
  error: red.rgb,
  warning: yellow.rgb,
  secondary: darkGrey.rgb,
  COMPLETE: green.rgb,
  COMPLIANT: green.rgb,
  NON_COMPLIANT: red.rgb,
  NOT_APPLICABLE: mediumGrey.rgb,
  OVERDUE: yellow.rgb,
  PENDING: blue.rgb,
  INACTIVE: grey.rgb,
  REMOVED: black.rgb,
  COMPLETED: green.rgb,
  CONFLICTING_ANSWERS: red.rgb,
  inactive: grey.rgb,
  light: grey.rgb,
}

export const bg = {
  logo: `rgba(${logo.rgb},${bgOpacity})`,
  main: offWhite.hex,
  contentBox: white.hex,
  contrast: dark.hex,
  nav: dark.hex,
  titlebar: logo.hex,
  reportUser: logo.hex,
  active: `rgba(${blue.rgb},${bgOpacity})`,
  success: `rgba(${green.rgb},${bgOpacity})`,
  error: `rgba(${red.rgb},${bgOpacity})`,
  warning: `rgba(${yellow.rgb},${bgOpacity})`,
  secondary: `rgba(${darkGrey.rgb},${bgOpacity})`,
  COMPLETE: `rgba(${green.rgb},${bgOpacity})`,
  COMPLIANT: `rgba(${green.rgb},${bgOpacity})`,
  NON_COMPLIANT: `rgba(${red.rgb},${bgOpacity})`,
  NOT_APPLICABLE: `rgba(${mediumGrey.rgb},${bgOpacity})`,
  OVERDUE: `rgba(${yellow.rgb},${bgOpacity})`,
  PENDING: `rgba(${blue.rgb},${bgOpacity})`,
  INACTIVE: `rgba(${grey.rgb},${bgOpacity})`,
  inactive: `rgba(${grey.rgb}, 0.12)`,
  light: white.hex,
  logoHint: `rgba(${logo.rgb},${hintOpacity})`,
  hint: `rgba(${blue.rgb},${hintOpacity})`,
  spinner: `rgba(${grey.rgb}, 0.3)`,

  activeLight: `rgba(${blue.rgb}, 0.06)`,
  errorLight: `rgba(${red.rgb}, 0.05)`,
}

export const bgInverted = {
  logo: `rgba(${logo.rgb},${invertedOpacity})`,
  active: `rgba(${blue.rgb},${invertedOpacity})`,
  success: `rgba(${green.rgb},${invertedOpacity})`,
  error: `rgba(${red.rgb},${invertedOpacity})`,
  warning: `rgba(${yellow.rgb},${invertedOpacity})`,
  secondary: `rgba(${darkGrey.rgb},${invertedOpacity})`,
  disabled: `rgba(${mediumGrey.rgb},${invertedOpacity})`,
  COMPLETE: `rgba(${green.rgb},${invertedOpacity})`,
  COMPLIANT: `rgba(${green.rgb},${invertedOpacity})`,
  NON_COMPLIANT: `rgba(${red.rgb},${invertedOpacity})`,
  NOT_APPLICABLE: `rgba(${mediumGrey.rgb},${invertedOpacity})`,
  OVERDUE: `rgba(${yellow.rgb},${invertedOpacity})`,
  PENDING: `rgba(${blue.rgb},${invertedOpacity})`,
  INACTIVE: `rgba(${grey.rgb},${invertedOpacity})`,
  inactive: `rgba(${grey.rgb},${invertedOpacity})`,
}

export const bgOpaque = {
  logo: '#E6E9ED',
  active: '#EEF4F9',
  main: offWhite.hex,
  contentBox: white.hex,
  contrast: dark.hex,
  nav: dark.hex,
  titlebar: logo.hex,
  light: white.hex,
}

export const random = [
  'rgb(25, 99, 227)', // blue
  'rgb(227, 155, 2)', // yellow
  'rgb(6, 167, 125)', // green
  'rgb(126, 178, 221)', // periwinkle
  'rgb(71, 0, 36)', // maroon
  'rgb(250, 121, 33)', // orange
  'rgb(12, 71, 103)', // dark blue
  'rgb(127, 194, 155)', // sea green
  'rgb(227, 74, 111)', // pink
]

export const randomBg = [
  'rgba(25, 99, 227, 0.2)', // blue
  'rgba(227, 155, 2, 0.2)', // yellow
  'rgba(6, 167, 125, 0.2)', // green
  'rgba(126, 178, 221, 0.2)', // periwinkle
  'rgba(71, 0, 36, 0.2)', // maroon
  'rgba(250, 121, 33, 0.2)', // orange
  'rgba(12, 71, 103, 0.2)', // dark blue
  'rgba(127, 194, 155, 0.2)', // sea green
  'rgba(227, 74, 111, 0.2)', // pink
]

export const colors = {
  logo: logo.hex,
  lighten,
  darken,
  text,
  bg,
  dark: dark.hex,
  blue: blue.hex,
  green: green.hex,
  red: red.hex,
  yellow: yellow.hex,
  white: white.hex,
  black: black.hex,
  darkGrey: darkGrey.hex,
  mediumGrey: mediumGrey.hex,
  grey: grey.hex,
  lightGrey: lightGrey.hex,
  offWhite: offWhite.hex,

  shadow: lightGrey.hex,
  border: lightGrey.hex,
}

export default colors
