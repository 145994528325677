import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { isEmpty, map, size, isNil, find, includes, filter, concat, toSafeInteger } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { CompanyReportUser } from 'models/client'
import { Company } from 'models/company'
import { ReportScheduleFormData, UuidIdObject } from 'models/schedule'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { calcRecurringCadenceData, calcNthDay, calcNextReportDate } from 'utils/calcRecurringReportCadence'

import Button from 'components/Button'
import ErrorView from 'components/ErrorView'
import Modal from 'components/Modal'
import { SubTitle } from 'components/styles'
import { Label, SelectInput, SelectOption, InputField, ErrorMsg } from 'components/Form/GoodForm'

import {
  FormInfoWrapper,
  FormDateWrapper,
  ScheduleReportFormContainer,
  FormRecurrenceWrapper,
  FormRecipientsWrapper,
  FormBtns,
  DateInputWrapper,
  HourOfDayWrapper,
  AddBtnWrapper,
  RecipientContactsRowWrapper,
  RecipientCompaniesRowWrapper,
  RecipientName,
  RecipientSecondaryDetail,
  RecipientsWrapper,
  EmptyRecipientsWrapper,
  AddRecipientsModalWrapper,
  RecipientsModalTitle,
  SelectedCountMsg,
  RecipientOptionsWrapper,
  RecipientOptionRow,
  FormErrorMsg,
  FormErrorWrapper,
} from './styles'

interface ScheduleReportFormProps {
  onSubmit: (args: any) => void
  onCancel: () => void
  templatesListOptions: { id: string; name: string }[]
  allUsersList: CompanyReportUser[]
  allCompaniesList: Company[]
  existingData?: ReportScheduleFormData
  nextReportDate?: Dayjs
  errorMsg?: string
  isResetNeeded?: boolean
  isDisabled?: boolean
}

const DEFAULT_DAYS_TO_COMPLETE_REPORT = '90'

const ScheduleReportForm: React.FC<ScheduleReportFormProps> = ({
  onSubmit,
  onCancel,
  templatesListOptions,
  allUsersList,
  allCompaniesList,
  existingData,
  nextReportDate,
  errorMsg,
  isResetNeeded,
  isDisabled,
}) => {
  // console.log('ScheduleReportForm', {
  //   allUsersList,
  //   allCompaniesList,
  // })
  const { t } = useTranslation()
  const [existingScheduleId, setExistingScheduleId] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [hasStartDateError, setHasStartDateError] = useState<boolean>(false)
  const [recipientContacts, setRecipientContacts] = useState<string[]>([])
  const [recipientCompanies, setRecipientCompanies] = useState<string[]>([])
  const [isRecipientContactsOpen, setIsRecipientContactsOpen] = useState<boolean>(false)
  const [isRecipientCompaniesOpen, setIsRecipientCompaniesOpen] = useState<boolean>(false)
  const [recipientContactsHasError, setRecipientContactsHasError] = useState<boolean>(false)
  const [recipientCompaniesHasError, setRecipientCompaniesHasError] = useState<boolean>(false)

  const { errors, register, triggerValidation, watch, reset } = useForm({})

  useEffect(() => {
    if (isResetNeeded) {
      initializeForm(existingData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetNeeded])

  useEffect(() => {
    if (!existingData || existingData?.id !== existingScheduleId) {
      initializeForm(existingData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingData, existingScheduleId])

  const initializeForm = (data?: ReportScheduleFormData) => {
    setExistingScheduleId(data?.id)
    const formData = data
      ? {
          report_schedule_name: data.name,
          report_template_id: data.report_template_id,
          days_to_complete_report: data.days_to_complete,
          report_schedule_start: dayjs(data.start).toDate(),
          recurrence_cadence: data.recurrence_cadence,
          recipients_type: data.recipients_type,
        }
      : {
          days_to_complete_report: DEFAULT_DAYS_TO_COMPLETE_REPORT,
        }

    reset(formData)

    setStartDate(data ? dayjs(data.start).toDate() : undefined)

    if (data?.recipients_list) {
      if (data.recipients_type === 'specified_contacts') {
        setRecipientContacts(data.recipients_list)
        setRecipientCompanies([])
      }
      if (data.recipients_type === 'specified_companies') {
        setRecipientCompanies(data.recipients_list)
        setRecipientContacts([])
      }
    } else {
      setRecipientContacts([])
      setRecipientCompanies([])
    }

    setHasStartDateError(false)
    setIsRecipientContactsOpen(false)
    setIsRecipientCompaniesOpen(false)
    setRecipientContactsHasError(false)
    setRecipientCompaniesHasError(false)
  }

  const handleCancel = () => {
    initializeForm(existingData)
    onCancel()
  }

  const watchCadence = watch('recurrence_cadence', 'one_off')
  const watchRecipients = watch('recipients_type', 'all_contacts')

  const validateAltFields = (
    report_schedule_start?: Date,
    recipients_type?: 'all_contacts' | 'specified_companies' | 'specified_contacts',
  ) => {
    let isValid = true
    if (
      isEmpty(report_schedule_start) ||
      (dayjs(report_schedule_start).isBefore(dayjs(), 'day') && isNil(existingData)) ||
      dayjs(report_schedule_start).isAfter(dayjs().add(365, 'day'), 'day')
    ) {
      setHasStartDateError(true)
      isValid = false
    }
    if (recipients_type === 'specified_contacts' && isEmpty(recipientContacts)) {
      setRecipientContactsHasError(true)
      isValid = false
    }
    if (recipients_type === 'specified_companies' && isEmpty(recipientCompanies)) {
      setRecipientCompaniesHasError(true)
      isValid = false
    }
    return isValid
  }

  const makeUuidObj = (id: string): UuidIdObject => ({
    type: 'uuid',
    value: id,
  })

  const handleSubmitBtn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const target = event.target as typeof event.target & {
      report_schedule_name: { value?: string }
      report_template_id: { value?: string }
      days_to_complete_report: { value?: string }
      report_schedule_start: { value?: Date }
      recurrence_cadence: { value?: 'one_off' | 'weekly' | 'monthly' | 'quarterly' | 'annually' }
      recipients_type: { value?: 'all_contacts' | 'specified_companies' | 'specified_contacts' }
    }

    const isAltValid = validateAltFields(target.report_schedule_start.value, target.recipients_type.value)
    const isFormValid = await triggerValidation()

    if (isAltValid && isFormValid) {
      const { nth, weekday, optionalMonth } = calcRecurringCadenceData(startDate, watchCadence)

      let recurrenceObj = {} // target.recurrence_cadence.value === 'one_off'
      const weekdayString = isNil(weekday)
        ? ''
        : dayjs()
            .day(weekday)
            .format('ddd')

      if (target.recurrence_cadence.value === 'weekly') {
        recurrenceObj = {
          recurrence: {
            cadence: target.recurrence_cadence.value,
            day: weekdayString,
          },
        }
      }

      if (target.recurrence_cadence.value === 'monthly' || target.recurrence_cadence.value === 'quarterly') {
        recurrenceObj = {
          recurrence: {
            cadence: target.recurrence_cadence.value,
            day: {
              nth,
              weekday: weekdayString,
            },
          },
        }
      }

      if (target.recurrence_cadence.value === 'annually') {
        recurrenceObj = {
          recurrence: {
            cadence: target.recurrence_cadence.value,
            month: isNil(optionalMonth)
              ? ''
              : dayjs()
                  .day(optionalMonth)
                  .format('MMMM'),
            day: {
              nth,
              weekday: weekdayString,
            },
          },
        }
      }

      const payload = {
        report_schedule_name: target.report_schedule_name.value,
        report_template_id: {
          type: 'uuid',
          value: target.report_template_id.value,
        },
        days_to_complete_report: toSafeInteger(target.days_to_complete_report.value),
        report_schedule_start: dayjs(target.report_schedule_start.value).format('YYYY-MM-DD'),
        ...recurrenceObj,
      }

      console.log('*** SUBMIT ***', payload)
      if (target.recipients_type.value === 'specified_contacts') {
        return onSubmit({
          ...payload,
          recipients: {
            type: target.recipients_type.value,
            list: map(recipientContacts, makeUuidObj),
          },
        })
      }
      if (target.recipients_type.value === 'specified_companies') {
        return onSubmit({
          ...payload,
          recipients: {
            type: target.recipients_type.value,
            list: map(recipientCompanies, makeUuidObj),
          },
        })
      }
      onSubmit({
        ...payload,
        recipients: {
          type: target.recipients_type.value,
        },
      })
    }
  }

  const handleStartDateChange = (newStartDate: Date) => {
    setHasStartDateError(false)
    setStartDate(newStartDate)
  }

  const handleContactOptionClick = (contactId: string) => {
    if (includes(recipientContacts, contactId)) {
      setRecipientContacts(filter(recipientContacts, (id: string) => id !== contactId))
    } else {
      setRecipientContacts(concat(recipientContacts, contactId))
    }
  }

  const handleCompanyOptionClick = (companyId: string) => {
    if (includes(recipientCompanies, companyId)) {
      setRecipientCompanies(filter(recipientCompanies, (id: string) => id !== companyId))
    } else {
      setRecipientCompanies(concat(recipientCompanies, companyId))
    }
  }

  const handleResetAddRecipients = () => {
    setIsRecipientContactsOpen(false)
    setIsRecipientCompaniesOpen(false)
    setRecipientContacts([])
    setRecipientCompanies([])
    setRecipientContactsHasError(false)
    setRecipientCompaniesHasError(false)
  }

  const renderOrdinal = (num: number | undefined): string => {
    if (!num) return ''
    const ordinalRules = new Intl.PluralRules('en-US', {
      type: 'ordinal',
    })
    const suffixes: { [key: string]: string } = {
      zero: '',
      one: 'st',
      two: 'nd',
      few: 'rd',
      many: 'th',
      other: 'th',
    }
    const suffix = suffixes[ordinalRules.select(num)]
    return num + suffix
  }

  const renderCadencePhrase = (): string | null => {
    const { nth, weekday, optionalMonth, cadence } = calcRecurringCadenceData(startDate, watchCadence)
    const weekdayString = dayjs()
      .day(weekday)
      .format('dddd')
    const ordinalNth = renderOrdinal(nth)
    const monthString = !isNil(optionalMonth)
      ? dayjs()
          .month(optionalMonth)
          .format('MMMM')
      : ''

    if (watchCadence === 'weekly') {
      return weekdayString
    }

    if (watchCadence === 'annually') {
      if (isNil(optionalMonth)) return null
      return `${ordinalNth} ${weekdayString} every ${monthString}`
    }

    return `${ordinalNth} ${weekdayString} of the ${cadence}`
  }

  const renderSecondReportPhrase = (): string | null => {
    const secondReportDate = calcNextReportDate(startDate, watchCadence)
    if (isNil(secondReportDate)) return null
    return secondReportDate.format('dddd, MMMM D, YYYY')
  }

  const renderReportCadenceMsgs = () => {
    if (!isNil(nextReportDate)) {
      return (
        <>
          <div>
            <h3>{t('scheduleReportCadenceMsgLabel')}</h3>
            <p>{renderCadencePhrase()}</p>
          </div>
          <div>
            <h3>{t('scheduleReportNextReportMsgLabel')}</h3>
            <p>{nextReportDate.format('dddd, MMMM D, YYYY')}</p>
          </div>
        </>
      )
    }
    return (
      <>
        <div>
          <h3>{t('scheduleReportCadenceMsgLabel')}</h3>
          <p>{renderCadencePhrase()}</p>
        </div>
        <div>
          <h3>{t('scheduleReportSecondReportMsgLabel')}</h3>
          <p>{renderSecondReportPhrase()}</p>
        </div>
      </>
    )
  }

  const renderAllContactsMsg = (): string | null => {
    const userCountMsg =
      size(allUsersList) > 1 ? t('contactPluralMsg', { count: size(allUsersList) }) : t('contactSingularMsg')
    const portCoCountMsg =
      size(allCompaniesList) > 1 ? t('portCoPluralMsg', { count: size(allCompaniesList) }) : t('portCoSingularMsg')
    return t('allContactsMsg', { userCountMsg, portCoCountMsg })
  }

  const renderRecipientContactsRow = (contactId: string) => {
    const contact = find(allUsersList, u => u.id === contactId)
    if (!contact) return null
    const companyName = find(allCompaniesList, c => c.id === contact.companyId)
    return (
      <RecipientContactsRowWrapper key={`recipient-${contactId}`} onClick={() => setIsRecipientContactsOpen(true)}>
        <FontAwesomeIcon icon="check" />
        <RecipientName>{contact.name}</RecipientName>
        <RecipientSecondaryDetail>{companyName?.name || contact.companyId}</RecipientSecondaryDetail>
      </RecipientContactsRowWrapper>
    )
  }

  const renderRecipientCompaniesRow = (companyId: string) => {
    const company = find(allCompaniesList, u => u.id === companyId)
    if (!company) return null
    return (
      <RecipientCompaniesRowWrapper key={`recipient-${companyId}`} onClick={() => setIsRecipientCompaniesOpen(true)}>
        <FontAwesomeIcon icon="check" />
        <RecipientName>{company.name}</RecipientName>
        <RecipientSecondaryDetail>{t('companyUserCountMsg', { count: size(company.users) })}</RecipientSecondaryDetail>
      </RecipientCompaniesRowWrapper>
    )
  }

  const renderRecipientContactsOptionRow = ({ id, name, companyId }: CompanyReportUser) => {
    const companyName = find(allCompaniesList, c => c.id === companyId)
    const isSelected = includes(recipientContacts, id)
    return (
      <RecipientOptionRow
        key={`recipient-option-${id}`}
        isSelected={isSelected}
        onClick={() => handleContactOptionClick(id)}
      >
        {isSelected ? <FontAwesomeIcon icon="check" /> : <div />}
        <RecipientName>{name}</RecipientName>
        <RecipientSecondaryDetail>{companyName?.name || companyId}</RecipientSecondaryDetail>
      </RecipientOptionRow>
    )
  }

  const renderRecipientCompaniesOptionRow = ({ id, name, users }: Company) => {
    const isSelected = includes(recipientCompanies, id)
    return (
      <RecipientOptionRow
        key={`recipient-option-${id}`}
        isSelected={isSelected}
        onClick={() => handleCompanyOptionClick(id)}
      >
        {isSelected ? <FontAwesomeIcon icon="check" /> : <div />}
        <RecipientName>{name}</RecipientName>
        <RecipientSecondaryDetail>{t('companyUserCountMsg', { count: size(users) })}</RecipientSecondaryDetail>
      </RecipientOptionRow>
    )
  }

  const renderRecipients = () => {
    if (watchRecipients === 'all_contacts' || !watchRecipients) return null

    const isContacts = watchRecipients === 'specified_contacts'
    const recipientsList = isContacts ? recipientContacts : recipientCompanies

    const hasError = isContacts ? recipientContactsHasError : recipientCompaniesHasError
    const renderRecipientRow = isContacts ? renderRecipientContactsRow : renderRecipientCompaniesRow
    const recipientsTable = !isEmpty(recipientsList) ? (
      <RecipientsWrapper>
        { map(recipientsList, renderRecipientRow) }
      </RecipientsWrapper>
    ) : (
      <EmptyRecipientsWrapper hasError={hasError}>
        {t(isContacts ? 'emptyContactsMsg' : 'emptyCompaniesMsg')}
      </EmptyRecipientsWrapper>
    )

    const openRecipientModal = isContacts ? setIsRecipientContactsOpen : setIsRecipientCompaniesOpen

    const addBtnMsg = isContacts ? 'addRecipientContacts' : 'addRecipientCompanies'
    const editBtnMsg = isContacts ? 'editRecipientContacts' : 'editRecipientCompanies'
    const btnMsg = isEmpty(recipientsList) ? addBtnMsg : editBtnMsg

    const btnIcon = isEmpty(recipientsList) ? 'plus' : 'pencil-alt'
    const buttonComponent = isDisabled ? null : (
      <AddBtnWrapper>
        <Button onClick={() => openRecipientModal(true)} size="small">
          <>
            <FontAwesomeIcon icon={btnIcon} />
            {t(btnMsg)}
          </>
        </Button>
      </AddBtnWrapper>
    )

    return (
      <>
        {recipientsTable}
        {buttonComponent}
      </>
    )
  }

  return (
    <ScheduleReportFormContainer onSubmit={handleSubmitBtn} maxGridColumns={3}>
      <FormInfoWrapper>
        <Label key="report_schedule_name" htmlFor="report_schedule_name">
          {t('schedule_report_name_label')}
          <InputField
            name="report_schedule_name"
            type="text"
            ref={register({ required: true, minLength: 1 })}
            onBlur={() => triggerValidation('report_schedule_name')}
            disabled={isDisabled}
          />
          {errors['report_schedule_name'] && <ErrorMsg>{t('scheduledReportNameRequired')}</ErrorMsg>}
        </Label>
        <Label key="report_template_id" htmlFor="report_template_id">
          {t('schedule_report_template_label')}
          <SelectInput
            name="report_template_id"
            ref={register({ required: true })}
            onBlur={() => triggerValidation('report_template_id')}
            disabled={isDisabled}
          >
            {map(templatesListOptions, ({ id, name }) => (
              <SelectOption key={`option-${id}`} value={id}>
                {name}
              </SelectOption>
            ))}
          </SelectInput>
          {errors['report_template_id'] && <ErrorMsg>{t('templateFieldRequired')}</ErrorMsg>}
        </Label>

        <Label key="days_to_complete_report" htmlFor="days_to_complete_report">
          {t('days_to_complete_label')}
          <InputField
            name="days_to_complete_report"
            type="number"
            ref={register({ required: true, min: 1, max: 365 })}
            onBlur={() => triggerValidation('days_to_complete_report')}
            disabled={isDisabled}
          />
          {errors['days_to_complete_report'] && <ErrorMsg>{t('scheduledReportDaysToCompleteRequired')}</ErrorMsg>}
        </Label>
      </FormInfoWrapper>

      <FormDateWrapper>
        <Label key="report_schedule_start" htmlFor="report_schedule_start">
          {t('schedule_report_start_label')}
          <DateInputWrapper hasError={!!errors['report_schedule_start']}>
            <DatePicker
              id="report_schedule_start"
              name="report_schedule_start"
              selected={startDate}
              onChange={(date: Date) => handleStartDateChange(date)}
              onSelect={(date: Date) => handleStartDateChange(date)}
              minDate={new Date()}
              disabled={isDisabled}
            />
          </DateInputWrapper>
          {hasStartDateError && <ErrorMsg>{t('scheduledReportStartDateRequired')}</ErrorMsg>}
        </Label>
        <HourOfDayWrapper>{t('hourOfDayMsg')}</HourOfDayWrapper>
        <Label key="recurrence_cadence" htmlFor="recurrence_cadence">
          {t('schedule_report_recurrence_cadence_label')}
          <SelectInput
            name="recurrence_cadence"
            ref={register({ required: true })}
            onBlur={() => triggerValidation('recurrence_cadence')}
            disabled={isDisabled}
          >
            <SelectOption value="one_off">{t('schedule_report_recurrence_cadence_option_one_off')}</SelectOption>
            <SelectOption value="weekly">{t('schedule_report_recurrence_cadence_option_weekly')}</SelectOption>
            <SelectOption value="monthly">{t('schedule_report_recurrence_cadence_option_monthly')}</SelectOption>
            <SelectOption value="quarterly">{t('schedule_report_recurrence_cadence_option_quarterly')}</SelectOption>
            <SelectOption value="annually">{t('schedule_report_recurrence_cadence_option_annually')}</SelectOption>
          </SelectInput>
          {errors['recurrence_cadence'] && <ErrorMsg>{t('scheduledReportRecurrenceCadenceFieldRequired')}</ErrorMsg>}
        </Label>
      </FormDateWrapper>

      {watchCadence !== 'one_off' && !isNil(startDate) && (
        <FormRecurrenceWrapper>
          {calcNthDay(startDate, watchCadence) === 5 && (watchCadence === 'monthly' || watchCadence === 'annually') ? (
            <FormErrorMsg>{t('invalidCadence5thOfMonth')}</FormErrorMsg>
          ) : (
            renderReportCadenceMsgs()
          )}
        </FormRecurrenceWrapper>
      )}

      <FormRecipientsWrapper>
        <Label key="recipients_type" htmlFor="recipients_type">
          {t('schedule_report_recipients_type_label')}
          <SelectInput
            name="recipients_type"
            ref={register({ required: true })}
            onBlur={() => triggerValidation('recipients_type')}
            disabled={isDisabled}
          >
            <SelectOption value="all_contacts">{t('schedule_report_recipients_type_option_all_contacts')}</SelectOption>
            <SelectOption value="specified_companies">
              {t('schedule_report_recipients_type_option_specified_companies')}
            </SelectOption>
            <SelectOption value="specified_contacts">
              {t('schedule_report_recipients_type_option_specified_contacts')}
            </SelectOption>
          </SelectInput>
          {errors['recipients_type'] && <ErrorMsg>{t('secheduledReportRecurrenceCadenceFieldRequired')}</ErrorMsg>}
        </Label>
        {watchRecipients === 'all_contacts' && <h4>{renderAllContactsMsg()}</h4>}
        {renderRecipients()}
      </FormRecipientsWrapper>
      {!isDisabled && (
        <FormBtns>
          <Button type="submit" size="small" color="success">
            {t('submitBtn')}
          </Button>
          <Button onClick={handleCancel} size="small" color="secondary">
            {t('cancelBtn')}
          </Button>
          {errorMsg && (
            <FormErrorWrapper>
              <ErrorView serverResponse={errorMsg} hideContactMsg isSingleLine />
            </FormErrorWrapper>
          )}
        </FormBtns>
      )}
      <Modal
        isOpen={!isDisabled && isRecipientContactsOpen}
        hasOverlay
        content={
          <AddRecipientsModalWrapper>
            <RecipientsModalTitle>
              <SubTitle>{t('addRecipientContactsTitle')}</SubTitle>
              {!isEmpty(recipientContacts) && (
                <SelectedCountMsg>{`${size(recipientContacts)} Selected`}</SelectedCountMsg>
              )}
            </RecipientsModalTitle>
            <RecipientOptionsWrapper>{map(allUsersList, renderRecipientContactsOptionRow)}</RecipientOptionsWrapper>
            <FormBtns>
              <Button onClick={() => setIsRecipientContactsOpen(false)} size="small" color="success">
                {t('saveBtn')}
              </Button>
              <Button onClick={handleResetAddRecipients} size="small" color="secondary">
                {t('resetBtn')}
              </Button>
            </FormBtns>
          </AddRecipientsModalWrapper>
        }
      />
      <Modal
        isOpen={!isDisabled && isRecipientCompaniesOpen}
        hasOverlay
        content={
          <AddRecipientsModalWrapper>
            <RecipientsModalTitle>
              <SubTitle>{t('addRecipientCompaniesTitle')}</SubTitle>
              {!isEmpty(recipientCompanies) && (
                <SelectedCountMsg>{`${size(recipientCompanies)} Selected`}</SelectedCountMsg>
              )}
            </RecipientsModalTitle>
            <RecipientOptionsWrapper>
              {map(allCompaniesList, renderRecipientCompaniesOptionRow)}
            </RecipientOptionsWrapper>
            <FormBtns>
              <Button onClick={() => setIsRecipientCompaniesOpen(false)} size="small" color="success">
                {t('saveBtn')}
              </Button>
              <Button onClick={handleResetAddRecipients} size="small" color="secondary">
                {t('resetBtn')}
              </Button>
            </FormBtns>
          </AddRecipientsModalWrapper>
        }
      />
    </ScheduleReportFormContainer>
  )
}

export default ScheduleReportForm
