import { reject, map } from 'lodash'

import { Template } from '../models/template'

import {
  LIST_TEMPLATE_OPTIONS_LOADING,
  LIST_TEMPLATE_OPTIONS_SUCCESS,
  LIST_TEMPLATE_OPTIONS_FAILURE,
  LIST_TEMPLATES_LOADING,
  LIST_TEMPLATES_SUCCESS,
  LIST_TEMPLATES_FAILURE,
  FIND_TEMPLATE_LOADING,
  FIND_TEMPLATE_SUCCESS,
  FIND_TEMPLATE_FAILURE,
  DUPLICATE_TEMPLATE_LOADING,
  DUPLICATE_TEMPLATE_SUCCESS,
  DUPLICATE_TEMPLATE_FAILURE,
  ADD_TEMPLATE_LOADING,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_LOADING,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  REMOVE_TEMPLATE_LOADING,
  REMOVE_TEMPLATE_SUCCESS,
  REMOVE_TEMPLATE_FAILURE,
  CLOSE_TEMPLATE_DETAIL,
  SET_TEMPLATE_HAS_UNSAVED_CHANGES,
} from 'actions/templateActions'

interface TemplateState {
  hasUnsavedChanges: boolean

  templateDetail: {
    isFetching: boolean
    error?: string

    item: { id: string } | Template
    lastUpdated?: Date

    isSaving: boolean
    saveError?: string
  }

  templateOptions: {
    items: { value: string; label: string }[]
    isFetching: boolean

    error?: string
    lastUpdated?: Date
  }

  templateList: {
    items: Template[]
    isFetching: boolean

    page?: number
    perPage?: number
    totalItems?: number

    error?: string
    removeError?: string
    lastUpdated?: Date
  }
}

const initState: TemplateState = {
  hasUnsavedChanges: false,
  templateDetail: {
    isFetching: false,
    isSaving: false,
    item: { id: '' },
  },

  templateOptions: {
    isFetching: false,
    items: [],
  },

  templateList: {
    isFetching: false,
    items: [],
  },
}

export const templateReducer = (state = initState, action: any): TemplateState => {
  switch (action.type) {
    case LIST_TEMPLATE_OPTIONS_LOADING:
      return {
        ...state,

        templateOptions: {
          ...state.templateOptions,

          error: undefined,
          isFetching: true,
        },
      }

    case LIST_TEMPLATE_OPTIONS_SUCCESS:
      return {
        ...state,

        templateOptions: {
          items: map(action.payload.items, (template: Template) => ({
            value: template.id,
            label: template.name,
          })) as { value: string; label: string }[],

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case LIST_TEMPLATE_OPTIONS_FAILURE:
      return {
        ...state,

        templateOptions: {
          ...state.templateOptions,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case LIST_TEMPLATES_LOADING:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          error: undefined,
          isFetching: true,
        },
      }

    case LIST_TEMPLATES_SUCCESS:
      return {
        ...state,

        templateList: {
          items: action.payload.items,
          page: action.payload.page,
          perPage: action.payload.perPage,
          totalItems: action.payload.totalItems,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case LIST_TEMPLATES_FAILURE:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case FIND_TEMPLATE_LOADING:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          isFetching: true,
          error: undefined,
          saveError: undefined,
        },
      }

    case FIND_TEMPLATE_SUCCESS:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          isFetching: false,
          lastUpdated: new Date(),
          item: action.payload.template,
        },
      }

    case FIND_TEMPLATE_FAILURE:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case DUPLICATE_TEMPLATE_LOADING:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          error: undefined,
          isFetching: true,
        },
      }

    case DUPLICATE_TEMPLATE_SUCCESS:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          isFetching: false,
          items: state.templateList.items.concat(action.payload.template),
        },

        templateOptions: {
          ...state.templateOptions,

          items: state.templateOptions.items.concat({
            value: action.payload.template.id,
            label: action.payload.template.name,
          }),
        },
      }

    case DUPLICATE_TEMPLATE_FAILURE:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case ADD_TEMPLATE_LOADING:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          isSaving: true,
          saveError: undefined,
        },
      }

    case ADD_TEMPLATE_SUCCESS:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,
          isSaving: false,
          item: action.payload.template,
        },

        templateOptions: {
          ...state.templateOptions,

          items: state.templateOptions.items.concat({
            value: action.payload.template.id,
            label: action.payload.template.name,
          }),
        },

        templateList: {
          ...state.templateList,
          items: state.templateList.items.concat(action.payload.template),
        },
      }

    case ADD_TEMPLATE_FAILURE:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          isSaving: false,
          saveError: action.payload.errorMsg,
        },
      }

    case UPDATE_TEMPLATE_LOADING:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          isSaving: true,
          saveError: undefined,
        },
      }

    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        hasUnsavedChanges: false,
        templateDetail: {
          ...state.templateDetail,

          isSaving: false,
          item: action.payload.template,
        },

        templateOptions: {
          ...state.templateOptions,

          items: map(state.templateOptions.items, item =>
            item.value === action.payload.template.id
              ? {
                  value: action.payload.template.id,
                  label: action.payload.template.name,
                }
              : item,
          ),
        },

        templateList: {
          ...state.templateList,

          items: map(state.templateList.items, (template: Template) =>
            template.id === action.payload.template.id
              ? {
                  ...template,

                  ...action.payload.template,
                }
              : template,
          ),
        },
      }

    case UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,

        templateDetail: {
          ...state.templateDetail,

          isSaving: false,
          saveError: action.payload.errorMsg,
        },
      }

    case REMOVE_TEMPLATE_LOADING:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          error: undefined,
          removeError: undefined,
          isFetching: true,
        },
      }

    case REMOVE_TEMPLATE_SUCCESS:
      return {
        ...state,

        templateDetail:
          state.templateDetail.item.id === action.payload.templateId
            ? {
                ...state.templateDetail,

                item: { id: '' },
              }
            : state.templateDetail,

        templateOptions: {
          ...state.templateOptions,

          items: reject(state.templateOptions.items, {
            value: action.payload.templateId,
          }),
        },

        templateList: {
          ...state.templateList,

          isFetching: false,
          items: reject(state.templateList.items, {
            id: action.payload.templateId,
          }),
        },
      }

    case REMOVE_TEMPLATE_FAILURE:
      return {
        ...state,

        templateList: {
          ...state.templateList,

          removeError: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case CLOSE_TEMPLATE_DETAIL:
      return {
        ...state,
        hasUnsavedChanges: false,
        templateDetail: {
          isFetching: false,
          isSaving: false,
          item: { id: '' },
        },
      }

    case SET_TEMPLATE_HAS_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.payload.hasUnsavedChanges,
      }

    default:
      return state
  }
}
