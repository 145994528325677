import React, { useState, useEffect, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from 'utils/auth0'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  isString,
  isEmpty,
  size,
  map,
  reject,
  every,
  isNil,
  concat,
  omit,
  trim,
  upperCase,
  uniqBy,
  pullAt,
  head,
} from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { isMockData } from 'utils/brandConfig'
import { getMockTemplateDetail } from 'mockData/mockTemplates'

import {
  findTemplate,
  addTemplate,
  updateTemplate,
  setTemplateHasUnsavedChanges,
  closeTemplateDetail,
} from 'actions/templateActions'

import Spinner from 'components/Spinner'
import Button from 'components/Button'
import ErrorView from 'components/ErrorView'
import ConfirmDialog from 'components/ConfirmDialog'
import Modal from 'components/Modal'
import Instructions from './Instructions'
import AuthMatrixSection from './AuthMatrixSection'
import CustomSection from './CustomSection'

import { BannerTitle } from 'components/styles'

import { Template, Section, Question, CustomQuestion, AuthMatrixQuestion } from 'models/template'

import {
  TemplateViewWrapper,
  TemplateNormalTitle,
  TemplateViewOnlyTitle,
  TemplateEditTitle,
  TemplatePageTitle,
  TemplateName,
  ToggleEditIcon,
  CountLength,
  ViewOnlyMode,
  SectionsTableForm,
  NewSectionBox,
  NewBoxTitleRow,
  NewBoxTitle,
  NewSectionContent,
  NameFieldWrapper,
  TypeFieldWrapper,
  MoreInfoIcon,
  NewElementSelectBtn,
  NewElementSelectTitle,
  MoreInfoModal,
  ModalTitle,
  CloseIcon,
  InputValidationError,
} from './styles'

interface TemplateViewProps {
  match: {
    params: {
      templateId?: string
    }
  }
}

const TemplateView: React.FC<TemplateViewProps> = ({
  match: {
    params: { templateId },
  },
}: TemplateViewProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState<boolean>(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false)
  const [openSectionTitleIndex, setOpenSectionTitleIndex] = useState<number | undefined>(undefined)
  const [openSectionIndex, setOpenSectionIndex] = useState<number | undefined>(0)
  const [isInstructionsOpen, toggleInstructionsOpen] = useState<boolean>(false)
  const [isSavingThenClosing, setIsSavingThenClosing] = useState<boolean>(false)
  const [showViewOnlyModal, setShowViewOnlyModal] = useState<boolean>(false)
  const [submitErrorMsg, setSubmitErrorMsg] = useState<string | undefined>(undefined)
  const [isNewSectionOpen, setIsNewSectionOpen] = useState<boolean>(false)
  const [tempSectionName, setTempSectionName] = useState<string>('')
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState<boolean>(false)
  const [sectionNameError, setSectionNameError] = useState<string | undefined>(undefined)
  const [invalidQuestionQuery, setInvalidQuestionQuery] = useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { control, watch, handleSubmit } = useForm({})

  const { templateDetail } = useSelector((state: any) => state.templates)

  const existingTemplateId = templateId || templateDetail.item.id

  const template = isMockData ? getMockTemplateDetail(existingTemplateId) : templateDetail?.item

  const placeholderTemplate = isMockData
    ? {
        id: '',
        name: template.name,
        instructions: template.instructions,
        sections: template.sections,
        isLocked: template.isLocked,
      }
    : {
        id: '',
        name: 'New Template',
        instructions: '',
        sections: [],
        isLocked: false,
      }

  const [templateInPlace, setTemplateInPlace] = useState<Template>(placeholderTemplate)

  useEffect(() => {
    if (isString(templateId)) dispatch(findTemplate(auth0Context, templateId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, auth0Context])

  useEffect(() => {
    if (templateId && template && !hasUnsavedChanges) {
      setTemplateInPlace(template)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, templateDetail, history, templateInPlace.name])

  useEffect(() => {
    if (isSavingThenClosing && !templateDetail?.isSaving) {
      const hasError = templateDetail?.saveError
      if (hasError) return setIsSavingThenClosing(false)
      dispatch(closeTemplateDetail())
      return history.push('/settings')
    }
  }, [history, isSavingThenClosing, templateDetail, dispatch])

  const isViewOnly = !isEmpty(existingTemplateId) && templateInPlace?.isLocked

  const handleUpdateTemplateName = (e: SyntheticEvent): void => {
    const { value: name } = e.target as HTMLTextAreaElement
    console.log('handleUpdateTemplateName', name)

    setTemplateInPlace({
      ...templateInPlace,
      name,
    })

    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
  }

  const handleSectionOpen = (sectionIndex: number) => {
    if (openSectionIndex === sectionIndex) {
      setOpenSectionIndex(undefined)
    } else {
      setOpenSectionIndex(sectionIndex)
    }
  }

  const handleUpdateSectionName = (e: SyntheticEvent): void => {
    const { value: name } = e.target as HTMLTextAreaElement
    console.log('handleUpdateSectionName', name)
    setTemplateInPlace({
      ...(templateInPlace as Template),
      sections: map(templateInPlace?.sections, (section: Section, index: number) =>
        index === openSectionTitleIndex
          ? {
              ...section,
              name,
            }
          : section,
      ),
    })
    setOpenSectionTitleIndex(undefined)
    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
  }

  const validateSectionName = (name?: string) => {
    const existingSectionNames = map(templateInPlace?.sections, sec => sec.name)
    const isValid = !isEmpty(trim(name)) && every(existingSectionNames, n => upperCase(n) !== upperCase(name))
    setSectionNameError(isValid ? undefined : 'A unique section name is required')
    return isValid
  }

  const handleUpdateTempSectionName = (e: SyntheticEvent): void => {
    const { value: name } = e.target as HTMLTextAreaElement
    setTempSectionName(name)
    setSectionNameError(undefined)
  }

  const handleAddMatrixSection = () => {
    if (validateSectionName(tempSectionName)) {
      setIsNewSectionOpen(false)
      const newTempId = uuid()
      const newIndex = size(templateInPlace.sections)
      setTemplateInPlace({
        ...(templateInPlace as Template),
        sections: templateInPlace.sections.concat({
          id: newTempId,
          type: 'AUTHORITY_MATRIX',
          name: tempSectionName,
          columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
          questions: [
            {
              id: uuid(),
              columns: ['', '', '', ''],
              allowNA: false,
              allowDocuments: false,
            },
          ],
        }) as Section[],
      })

      setTempSectionName('')
      dispatch(setTemplateHasUnsavedChanges(true))
      setHasUnsavedChanges(true)
      setOpenSectionIndex(newIndex)
      const scrollingEl = document.getElementById('scroll-target')
      if (scrollingEl) scrollingEl.scrollTo({ top: scrollingEl?.scrollHeight, behavior: 'smooth' })
    }
  }

  const handleAddCustomSection = () => {
    if (validateSectionName(tempSectionName)) {
      setSectionNameError(undefined)
      setIsNewSectionOpen(false)
      const newTempId = uuid()
      const newIndex = size(templateInPlace.sections)
      setTemplateInPlace({
        ...(templateInPlace as Template),
        sections: templateInPlace.sections.concat({
          id: newTempId,
          type: 'CUSTOM',
          name: tempSectionName,
          questions: [],
        }) as Section[],
      })
      setTempSectionName('')
      dispatch(setTemplateHasUnsavedChanges(true))
      setHasUnsavedChanges(true)
      setOpenSectionIndex(newIndex)
      const scrollingEl = document.getElementById('scroll-target')
      console.log('scrollingEl', {
        scrollingEl,
        H: scrollingEl?.scrollHeight,
      })
      if (scrollingEl) scrollingEl.scrollTo({ top: scrollingEl?.scrollHeight, behavior: 'smooth' })
    }
  }

  const handleCancelNewSection = () => {
    setIsNewSectionOpen(false)
    setTempSectionName('')
  }

  const handleAddSection = () => {
    setTempSectionName('New Section')
    setIsNewSectionOpen(true)
    const scrollingEl = document.getElementById('scroll-target')
    if (scrollingEl) scrollingEl.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleRemoveSection = (sectionToRemove: Section): void => {
    console.log('handleRemoveSection()', sectionToRemove)

    setTemplateInPlace({
      ...(templateInPlace as Template),

      sections: reject(templateInPlace?.sections, { id: sectionToRemove.id }),
    })

    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
  }

  const onSaveChanges = (data: {}, isRedirectedAfterSave: boolean) => {
    console.log('SUBMIT templateInPlace.sections', templateInPlace.sections)
    const scrollingEl = document.getElementById('scroll-target')
    if (scrollingEl) scrollingEl.scrollTo({ top: 0, behavior: 'smooth' })

    if (!templateInPlace.instructions) {
      return setSubmitErrorMsg('MISSING_INSTRUCTIONS')
    }

    // User can save changes to instructions even in View Only mode. All other fields should be disabled
    if (isViewOnly && !isEmpty(existingTemplateId)) {
      dispatch(
        updateTemplate(auth0Context, existingTemplateId, {
          instructions: templateInPlace.instructions,
        }),
      )
      setIsSavingThenClosing(true)
      return
    }

    const sections = map(templateInPlace.sections, sec => {
      const updatedSection = omit(sec, 'id')
      return {
        ...updatedSection,
        questions: map(sec.questions, ques => {
          const updatedQuestion = omit(ques, 'id')
          return {
            ...updatedQuestion,
            allowNA: !!updatedQuestion.allowNA,
          }
        }),
      }
    })

    console.log('onSaveChanges()', { data, sections, templateInPlace })
    if (isEmpty(sections)) {
      setInvalidQuestionQuery(undefined)
      return setSubmitErrorMsg('EMPTY_SECTIONS')
    }

    // const allColumnsMatch = every(sections, (section: Section) => (
    //   section.type === 'CUSTOM' || every(
    //     section.questions,
    //     (question: Question) => {
    //       if (question.hasOwnProperty('columns') && question.columns) {
    //         return isEqual(size(section.columnNames), size(question.columns)) &&
    //           every(question.columns, column => !isEmpty(column))
    //       }
    //       return true
    //     }
    //   )
    // ))

    // if (!allColumnsMatch) return setSubmitErrorMsg('INVALID_COLUMNS')

    const allQueriesValid = every(sections, (sec: Section) => {
      if (sec.type === 'AUTHORITY_MATRIX') {
        return every(sec.questions, (q: Question, i: number) => {
          if (isEmpty(head(q.columns))) {
            setInvalidQuestionQuery(`${sec.name}: Question #${i + 1}`)
            return false
          }

          if (q.allowDocuments && isEmpty(q.documentDescription)) {
            setInvalidQuestionQuery(`${sec.name}: Question #${i + 1}: "Documentation Required"`)
            return false
          }

          return true
        })
      }
      if (sec.type === 'CUSTOM') {
        return every(sec.questions, (q: Question, i: number) => {
          if (isEmpty(q.query)) {
            setInvalidQuestionQuery(`${sec.name}: Question #${i + 1}`)
          }

          return !isEmpty(q.query)
        })
      }
    })

    if (!allQueriesValid) return setSubmitErrorMsg('MISSING_FIELDS')

    const allOptionsValid = every(
      sections,
      (section: Section) =>
        section.type === 'AUTHORITY_MATRIX' ||
        every(section.questions, (question: Question) => {
          const uniqOptions = uniqBy(question.options, upperCase)
          const allOptionsUnique = size(uniqOptions) === size(question.options)
          const isOptionsPopulated = every(question.options, option => !isEmpty(trim(option)))

          if (!allOptionsUnique) {
            setSubmitErrorMsg(`All Answer ${question.type === 'BREAKDOWN' ? 'Categories' : 'Options'} must be unique`)
          }
          if (!isOptionsPopulated) {
            setSubmitErrorMsg(`Answer ${question.type === 'BREAKDOWN' ? 'Categories' : 'Options'} cannot be left blank`)
          }
          if (!isOptionsPopulated || !allOptionsUnique) {
            setInvalidQuestionQuery(question.query)
          }
          return isOptionsPopulated && allOptionsUnique
        }),
    )

    if (!allOptionsValid) return

    // console.log('allOptionsValid', {existingTemplateId, sections, tip: templateInPlace.sections})
    if (!isEmpty(templateDetail.item.id)) {
      dispatch(
        updateTemplate(auth0Context, existingTemplateId, {
          name: templateInPlace.name,
          instructions: templateInPlace.instructions,
          sections,
        }),
      )
    } else {
      dispatch(
        addTemplate(auth0Context, {
          name: templateInPlace.name,
          instructions: templateInPlace.instructions,
          sections,
        }),
      )
    }

    if (isRedirectedAfterSave) {
      setIsSavingThenClosing(true)
    }
  }

  const handleInstructionsCancel = (): void => {
    toggleInstructionsOpen(false)
  }

  const handleInstructionsSave = (instructions: string): void => {
    toggleInstructionsOpen(false)
    const hasUnsavedChanges = instructions !== templateInPlace?.instructions
    if (hasUnsavedChanges && instructions != null) {
      setTemplateInPlace({
        ...(templateInPlace as Template),
        instructions,
      })

      setSubmitErrorMsg(undefined)
      setInvalidQuestionQuery(undefined)
      dispatch(setTemplateHasUnsavedChanges(true))
      setHasUnsavedChanges(true)
    }
  }

  const handleAddMatrixQuestion = (sIndex: number, question: AuthMatrixQuestion | CustomQuestion): void => {
    console.log('handleAddQuestion()', sIndex, question)

    setTemplateInPlace({
      ...(templateInPlace as Template),
      sections: map(templateInPlace?.sections, (section: Section, index: number) =>
        index === sIndex && !isNil(section.questions)
          ? {
              ...section,
              questions: concat(section.questions, question),
            }
          : section,
      ),
    })

    setOpenSectionIndex(sIndex)
    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
  }

  const handleAddCustomQuestion = (sIndex: number, question: CustomQuestion | AuthMatrixQuestion): void => {
    console.log('handleAddQuestion()', sIndex, question)

    setTemplateInPlace({
      ...(templateInPlace as Template),
      sections: map(templateInPlace?.sections, (section: Section, index: number) =>
        index === sIndex && !isNil(section.questions)
          ? {
              ...section,
              questions: concat(section.questions, question),
            }
          : section,
      ),
    })

    setOpenSectionIndex(sIndex)
    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
  }

  const handleRemoveQuestion = (questionToRemoveId: string): void => {
    console.log('handleRemoveQuestion()', questionToRemoveId, templateInPlace)

    setTemplateInPlace({
      ...(templateInPlace as Template),
      sections: map(templateInPlace?.sections, section => ({
        ...section,
        questions: reject(section.questions, { id: questionToRemoveId }),
      })),
    })

    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
  }

  const handleUpdateQuestion = (updatedTemplateInPlace: Template): void => {
    console.log('handleUpdateQuestion()', updatedTemplateInPlace)
    setTemplateInPlace(updatedTemplateInPlace)
    dispatch(setTemplateHasUnsavedChanges(true))
    setHasUnsavedChanges(true)
    setSubmitErrorMsg(undefined)
    setInvalidQuestionQuery(undefined)
  }

  const handleChangeQuestionOrder = (initialIndex: number, incrementBy: number, sectionIndex: number): void => {
    console.log('handleChangeQuestionOrder()', { initialIndex, incrementBy, sectionIndex })
    if (!isViewOnly) {
      setTemplateInPlace({
        ...(templateInPlace as Template),
        sections: map(templateInPlace?.sections, (section, index) => {
          if (index !== sectionIndex) {
            return section
          }
          const questionToMove = head(pullAt(section.questions, initialIndex))
          if (questionToMove) {
            section.questions.splice(initialIndex + incrementBy, 0, questionToMove)
          }
          // console.log('MOVING', {newIndex, item: head(questionToMove), secQues: section.questions})
          return {
            ...section,
            questions: section.questions,
          }
        }),
      })
      dispatch(setTemplateHasUnsavedChanges(true))
      setHasUnsavedChanges(true)
    }
  }

  const handleChangeSectionOrder = (initialIndex: number, incrementBy: number): void => {
    console.log('handleChangeSectionOrder()', { initialIndex, incrementBy })
    if (!isViewOnly) {
      const sectionsArray = templateInPlace?.sections
      const sectionToMove = head(pullAt(sectionsArray, initialIndex))
      if (sectionToMove) {
        sectionsArray.splice(initialIndex + incrementBy, 0, sectionToMove)
      }
      setTemplateInPlace({
        ...(templateInPlace as Template),
        sections: sectionsArray,
      })
      setOpenSectionIndex(undefined)
      dispatch(setTemplateHasUnsavedChanges(true))
      setHasUnsavedChanges(true)
    }
  }

  const handleCloseWithNoChanges = () => {
    dispatch(closeTemplateDetail())
    history.push('/settings')
  }

  let availableActions = (
    <TemplateNormalTitle>
      <TemplatePageTitle>
        {t('reportTemplateTitle')}
        <TemplateName>
          <h1 title={templateInPlace?.name}>{templateInPlace?.name}</h1>
          {isViewOnly ? null : (
            <ToggleEditIcon onClick={() => setIsTitleEditing(true)}>
              <FontAwesomeIcon icon="pencil-alt" />
            </ToggleEditIcon>
          )}
        </TemplateName>
      </TemplatePageTitle>
      <Button onClick={handleAddSection} isInverted color="active">
        <>
          <FontAwesomeIcon icon="plus" />
          {t('addSectionBtn')}
        </>
      </Button>
      <Button onClick={() => toggleInstructionsOpen(true)} isInverted color="secondary">
        <>
          <FontAwesomeIcon icon="list-ol" />
          {t('editInstructionsBtn')}
        </>
      </Button>
      <Button isInverted onClick={handleSubmit(data => onSaveChanges(data, false))} color="success">
        {templateDetail?.isSaving && !isSavingThenClosing ? (
          <Spinner isTextSize />
        ) : (
          <>
            <FontAwesomeIcon icon="save" />
            {t('saveProgressBtn')}
          </>
        )}
      </Button>
      <Button isInverted onClick={handleSubmit(data => onSaveChanges(data, true))} color="success">
        <>
          <FontAwesomeIcon icon="save" />
          {t('saveAndCloseBtn')}
        </>
      </Button>
      {hasUnsavedChanges ? (
        <Button onClick={() => setShowConfirmCancelDialog(true)} color="secondary" size="large" isSimple>
          <FontAwesomeIcon icon="times" />
        </Button>
      ) : (
        <Button onClick={handleCloseWithNoChanges} color="secondary" size="large" isSimple>
          <FontAwesomeIcon icon="times" />
        </Button>
      )}
    </TemplateNormalTitle>
  )
  if (isViewOnly) {
    availableActions = (
      <TemplateViewOnlyTitle hasUnsavedChanges={hasUnsavedChanges}>
        <TemplatePageTitle>
          {t('reportTemplateTitle')}
          <TemplateName>{templateInPlace?.name}</TemplateName>
        </TemplatePageTitle>
        <CountLength>
          {t('templateSectionLength', {
            count: size(templateInPlace?.sections),
          })}
        </CountLength>
        <ViewOnlyMode onClick={() => setShowViewOnlyModal(true)}>
          {t('viewOnlyModeMsg')}
          <FontAwesomeIcon icon="question-circle" />
        </ViewOnlyMode>

        <Button onClick={() => toggleInstructionsOpen(true)} isInverted color="secondary">
          <>
            <FontAwesomeIcon icon="list-ol" />
            {t('editInstructionsBtn')}
          </>
        </Button>

        {hasUnsavedChanges ? (
          <Button isInverted onClick={handleSubmit(formData => onSaveChanges(formData, true))} color="success">
            <>
              <FontAwesomeIcon icon="save" />
              {t('saveAndCloseBtn')}
            </>
          </Button>
        ) : null}

        {hasUnsavedChanges ? (
          <Button onClick={() => setShowConfirmCancelDialog(true)} color="secondary" size="large" isSimple>
            <FontAwesomeIcon icon="times" />
          </Button>
        ) : (
          <Button onClick={handleCloseWithNoChanges} color="secondary" size="large" isSimple>
            <FontAwesomeIcon icon="times" />
          </Button>
        )}
      </TemplateViewOnlyTitle>
    )
  }
  if (isTitleEditing) {
    availableActions = (
      <TemplateEditTitle>
        <input name="templateName" type="text" defaultValue={templateInPlace?.name} onBlur={handleUpdateTemplateName} />
        <Button onClick={() => setIsTitleEditing(false)} size="small" color="success">
          {t('submitBtn')}
        </Button>
      </TemplateEditTitle>
    )
  }

  const renderNewSectionBox = () => {
    if (!isNewSectionOpen) return null

    return (
      <NewSectionBox>
        <NewBoxTitleRow>
          <NewBoxTitle>{t('addSectionTitle')}</NewBoxTitle>
          <Button onClick={handleCancelNewSection} color="secondary" size="large" isSimple>
            <FontAwesomeIcon icon="times" />
          </Button>
        </NewBoxTitleRow>
        <NewSectionContent>
          <NameFieldWrapper>
            <label htmlFor="sectionName">{t('sectionNameFieldLabel')}</label>
            <input
              id="sectionName"
              name="sectionName"
              type="text"
              defaultValue={tempSectionName}
              onBlur={handleUpdateTempSectionName}
            />
            {!isNil(sectionNameError) && <InputValidationError>{sectionNameError}</InputValidationError>}
          </NameFieldWrapper>
          <TypeFieldWrapper>
            <label>{t('sectionTypeFieldLabel')}</label>
            <NewElementSelectBtn onClick={handleAddCustomSection}>
              <NewElementSelectTitle>
                <FontAwesomeIcon icon="tasks" />
                {t('customSectionBtn')}
              </NewElementSelectTitle>
            </NewElementSelectBtn>
            <NewElementSelectBtn onClick={handleAddMatrixSection}>
              <NewElementSelectTitle>
                <FontAwesomeIcon icon="th-list" />
                {t('authMatrixBtn')}
              </NewElementSelectTitle>
            </NewElementSelectBtn>
            <MoreInfoIcon onClick={() => setIsMoreInfoOpen(true)}>
              <FontAwesomeIcon icon="question-circle" />
            </MoreInfoIcon>
          </TypeFieldWrapper>
        </NewSectionContent>
      </NewSectionBox>
    )
  }

  // console.log('templateInPlace?.sections', templateInPlace?.sections)
  let content
  if (templateDetail?.isFetching) {
    content = <Spinner />
  } else if (templateDetail?.error && !isMockData) {
    content = <ErrorView errorMsg={t(templateDetail.error)} />
  } else {
    content = (
      <SectionsTableForm onSubmit={e => e.preventDefault()}>
        <TemplateViewWrapper>
          <BannerTitle>{availableActions}</BannerTitle>

          {submitErrorMsg ? (
            <ErrorView
              errorMsg={t(submitErrorMsg)}
              serverResponse={invalidQuestionQuery ? `See Question: "${invalidQuestionQuery}"` : undefined}
              hideContactMsg
            />
          ) : null}
          {templateDetail?.saveError ? <ErrorView errorMsg={t(templateDetail.saveError)} hideContactMsg /> : null}
          {renderNewSectionBox()}
          {map(templateInPlace?.sections, (section: Section, sIndex: number) => {
            if (section.type === 'AUTHORITY_MATRIX') {
              const sectionProps = {
                ...section,
                key: section.id,
                sIndex,
                isOpen: openSectionIndex === sIndex,
                handleOpen: () => handleSectionOpen(sIndex),
                isTitleEditing: openSectionTitleIndex === sIndex,
                handleSetTitleEditing: setOpenSectionTitleIndex,
                handleUpdateSectionName,
                isViewOnly,
                isMockData,
                control,
                watch,
                templateInPlace,
                onAddQuestion: handleAddMatrixQuestion,
                onRemoveQuestion: handleRemoveQuestion,
                onUpdateQuestion: handleUpdateQuestion,
                clearSubmitErrors: () => {
                  setSubmitErrorMsg(undefined)
                  setInvalidQuestionQuery(undefined)
                },
                onRemoveSection: () => handleRemoveSection(section),
                onChangeQuestionOrder: handleChangeQuestionOrder,
                onChangeSectionOrder: handleChangeSectionOrder,
              }
              return <AuthMatrixSection {...sectionProps} />
            }
            if (section.type === 'CUSTOM') {
              const sectionProps = {
                ...section,
                key: section.id,
                sIndex,
                isOpen: openSectionIndex === sIndex,
                handleOpen: () => handleSectionOpen(sIndex),
                isTitleEditing: openSectionTitleIndex === sIndex,
                handleSetTitleEditing: setOpenSectionTitleIndex,
                handleUpdateSectionName,
                isViewOnly,
                isMockData,
                control,
                watch,
                templateInPlace,
                onAddQuestion: handleAddCustomQuestion,
                onRemoveQuestion: handleRemoveQuestion,
                onUpdateQuestion: handleUpdateQuestion,
                clearSubmitErrors: () => {
                  setSubmitErrorMsg(undefined)
                  setInvalidQuestionQuery(undefined)
                },
                onRemoveSection: () => handleRemoveSection(section),
                onChangeQuestionOrder: handleChangeQuestionOrder,
                onChangeSectionOrder: handleChangeSectionOrder,
              }
              return <CustomSection {...sectionProps} />
            }
            return null
          })}
        </TemplateViewWrapper>

        <Instructions
          initialVal={templateInPlace?.instructions}
          isOpen={isInstructionsOpen}
          onCancel={handleInstructionsCancel}
          onSave={updatedInstructions => handleInstructionsSave(updatedInstructions)}
        />

        <Modal
          isOpen={showViewOnlyModal}
          title={<>{t('viewOnlyModeMsg')}</>}
          content={<>{t('viewOnlyModeExplained')}</>}
          onClose={() => setShowViewOnlyModal(false)}
          buttons={
            <Button onClick={() => setShowViewOnlyModal(false)} size="small" color="secondary">
              {t('okayBtn')}
            </Button>
          }
        />
        <ConfirmDialog
          isOpen={!!showConfirmCancelDialog}
          confirmText={t('confirmCancelWithChanges')}
          onConfirm={handleCloseWithNoChanges}
          onCancel={() => setShowConfirmCancelDialog(false)}
        />
        <Modal
          isOpen={isMoreInfoOpen}
          hasOverlay
          content={
            <MoreInfoModal>
              <ModalTitle>
                <div />
                <h3>{t('sectionMoreInfoModalTitle')}</h3>
                <CloseIcon onClick={() => setIsMoreInfoOpen(false)}>
                  <FontAwesomeIcon icon="times" />
                </CloseIcon>
              </ModalTitle>
              <h4>{t('sectionMoreInfoMatrixTitle')}</h4>
              <p>{t('sectionMoreInfoMatrixDescription')}</p>
              <h4>{t('sectionMoreInfoCustomTitle')}</h4>
              <p>{t('sectionMoreInfoCustomDescription')}</p>
            </MoreInfoModal>
          }
        />
      </SectionsTableForm>
    )
  }

  return content
}

export default TemplateView
