import React, { useState, useEffect, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { isMockData } from 'utils/brandConfig'
import { isEmpty, map, snakeCase, toUpper, size, values, includes, isNil, filter, find } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs, { Dayjs } from 'dayjs'

import { ReportSchedule, ReportScheduleRequest, ReportScheduleFormData } from 'models/schedule'
import { CompanyReportUser, ScheduleCompany } from 'models/client'
import { mockTemplates } from 'mockData/mockTemplates'
import { calcNextReportDate } from 'utils/calcRecurringReportCadence'

import {
  listSchedules,
  createSchedule,
  // updateSchedule,
  removeSchedule,
  listTemplates,
  CLEAR_ERRORS,
} from 'actions/scheduleActions'
import { listContacts } from 'actions/clientActions'
import ScheduleReportForm from './ScheduleReportForm'

import EmptyView from 'components/EmptyView'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import ErrorView from 'components/ErrorView'
import HorizontalRule from 'components/HorizontalRule'
import ConfirmDialog from 'components/ConfirmDialog'

import { PageContent, PageTitleWithAction, ContentBoxLists, SubTitle, ColumnHeading } from 'components/styles'

import {
  SchedulesTable,
  SchedulesHeader,
  ScheduleItemRow,
  ScheduleToggleIcon,
  ScheduleName,
  ScheduleTemplate,
  ScheduleNextReport,
  ScheduleRecipients,
  ScheduleCadence,
  ScheduleRemoveBtn,
  HiddenScheduleDetails,
  UpdateScheduleForm,
  CreateScheduleFormWrapper,
} from './styles'

const ScheduleView: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const [isNewFormShown, setIsNewFormShown] = useState<boolean>(false)
  const [confirmRemoveSchedule, setConfirmRemoveSchedule] = useState<{ id: string; name: string } | undefined>()
  const [expandedScheduleId, setExpandedScheduleId] = useState<string | undefined>()

  const { schedulesMap, error, loading, isSchedulesUpdated, templatesList } = useSelector(
    (state: any) => state.schedule,
  )
  const { contactsMap, companiesMap, error: clientError, loading: clientLoading } = useSelector(
    (state: any) => state.client,
  )

  useEffect(() => {
    dispatch(listSchedules(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isNil(schedulesMap) && isEmpty(schedulesMap)) {
      setIsNewFormShown(true)
    }
  }, [schedulesMap])

  useEffect(() => {
    if (isSchedulesUpdated) {
      setIsNewFormShown(false)
      clearAllErrors()
      dispatch(listSchedules(auth0Context))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSchedulesUpdated])

  useEffect(() => {
    dispatch(listTemplates(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(listContacts(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clearAllErrors = () => {
    dispatch({ type: CLEAR_ERRORS })
  }

  const templatesListOptions = isMockData ? mockTemplates : templatesList

  const handleToggleRowExpand = (id: string) => {
    clearAllErrors()
    if (id === expandedScheduleId) {
      setExpandedScheduleId(undefined)
    } else {
      setIsNewFormShown(false)
      setExpandedScheduleId(id)
    }
  }

  const handleCreateSchedule = (payload: ReportScheduleRequest) => {
    console.log('handleCreateSchedule', payload)
    dispatch(createSchedule(auth0Context, payload))
  }

  const handleCloseForms = () => {
    setIsNewFormShown(false)
    setExpandedScheduleId(undefined)
    clearAllErrors()
  }

  const handleUpdateSchedule = (payload: ReportScheduleRequest, scheduleId: string) => {
    console.log('handleUpdateSchedule', payload)
    // dispatch(updateSchedule(auth0Context, scheduleId, payload))
  }

  const handleRemoveScheduleConfirmed = (id?: string) => {
    console.log('handleRemoveCompany', id)
    if (!!id) {
      dispatch(removeSchedule(auth0Context, id))
    }
    setConfirmRemoveSchedule(undefined)
  }

  const handleConfirmRemove = (event: MouseEvent<HTMLButtonElement>, scheduleId: string, scheduleName: string) => {
    console.log('handleConfirmRemove', scheduleName)
    event.stopPropagation()
    setConfirmRemoveSchedule({ id: scheduleId, name: scheduleName })
  }

  const renderScheduleItem = (scheduleDetail: ReportSchedule, rowIndex: number) => {
    const isOpen = expandedScheduleId === scheduleDetail.id
    // console.log('scheduleDetail', isOpen, scheduleDetail)
    const rType = toUpper(snakeCase(scheduleDetail.recipients?.type))
    const recipientList =
      rType === 'SPECIFIED_CONTACTS'
        ? filter(scheduleDetail.recipients?.list, contactId => {
            // filter results to count only users that are not removed (i.e. not in the listContacts response)
            return !!find(contactsMap, (u: CompanyReportUser) => u.id === contactId)
          })
        : rType === 'SPECIFIED_COMPANIES'
        ? filter(scheduleDetail.recipients?.list, companyId => {
            // filter results to count only companies that are not removed (i.e. not in the listContacts response)
            return !!find(companiesMap, (u: ScheduleCompany) => u.id === companyId)
          })
        : scheduleDetail.recipients?.list

    const recipientsContent = isEmpty(scheduleDetail.recipients?.list)
      ? t(`recipients_option_${rType}`)
      : size(recipientList) > 1
      ? t(`recipients_option_${rType}_plural`, { count: size(recipientList) })
      : t(`recipients_option_${rType}_singular`, { count: size(recipientList) })

    const cadenceContent = scheduleDetail.recurrence
      ? t(`recurrence_option_${toUpper(snakeCase(scheduleDetail.recurrence?.cadence))}`)
      : t('noRecurrence')

    let nextReportDate: Dayjs = dayjs(scheduleDetail.start)
    if (!isNil(scheduleDetail.last_dispatched) && !isNil(scheduleDetail.recurrence)) {
      const calculatedDate = calcNextReportDate(
        scheduleDetail.last_dispatched,
        scheduleDetail.recurrence?.cadence || 'one_off',
      )
      if (!isNil(calculatedDate)) nextReportDate = calculatedDate
    }

    const isPastReport = nextReportDate.isBefore(dayjs())

    const existingData: ReportScheduleFormData = {
      ...scheduleDetail,
      recipients_type: scheduleDetail.recipients?.type,
      recipients_list: scheduleDetail.recipients?.list,
      recurrence_cadence: scheduleDetail.recurrence?.cadence || 'one_off',
    }

    return (
      <React.Fragment key={`sch-${scheduleDetail.id}`}>
        <ScheduleItemRow onClick={() => handleToggleRowExpand(scheduleDetail.id)} isOpen={isOpen} rowIndex={rowIndex}>
          <ScheduleToggleIcon isOpen={isOpen}>
            <FontAwesomeIcon icon="angle-right" />
          </ScheduleToggleIcon>
          <ScheduleName>{scheduleDetail.name}</ScheduleName>
          <ScheduleTemplate>{scheduleDetail.report_template_name}</ScheduleTemplate>
          <ScheduleNextReport>{isPastReport ? null : nextReportDate.format('DD MMM YYYY')}</ScheduleNextReport>
          <ScheduleRecipients>{recipientsContent}</ScheduleRecipients>
          <ScheduleCadence>{cadenceContent}</ScheduleCadence>
          <ScheduleRemoveBtn>
            <Button
              onClick={e => handleConfirmRemove(e, scheduleDetail.id, scheduleDetail.name)}
              isSimple
              color="error"
            >
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </ScheduleRemoveBtn>
        </ScheduleItemRow>
        <HiddenScheduleDetails isOpen={isOpen}>
          <UpdateScheduleForm>
            {isEmpty(values(companiesMap)) || isEmpty(values(contactsMap)) ? (
              <Spinner />
            ) : (
              <ScheduleReportForm
                onSubmit={(data: ReportScheduleRequest) => handleUpdateSchedule(data, scheduleDetail.id)}
                onCancel={handleCloseForms}
                templatesListOptions={templatesListOptions}
                allUsersList={values(contactsMap)}
                allCompaniesList={values(companiesMap)}
                errorMsg={error['CREATE_SCHEDULE']}
                existingData={existingData}
                nextReportDate={nextReportDate}
                isDisabled
              />
            )}
          </UpdateScheduleForm>
        </HiddenScheduleDetails>
        <HorizontalRule lineStyle="dashed" isHideLast />
      </React.Fragment>
    )
  }

  const errorMsgBlockingNewForm = error?.LIST_SCHEDULES || error?.LIST_TEMPLATES || clientError?.LIST_CONTACTS

  const hasNoContacts =
    (isEmpty(values(companiesMap)) || isEmpty(values(contactsMap))) && !includes(clientLoading, 'LIST_CONTACTS')

  const isInvalidForNewForm = errorMsgBlockingNewForm || hasNoContacts

  const renderContent = () => {
    if (includes(loading, 'LIST_SCHEDULES')) {
      return <Spinner />
    }

    if (errorMsgBlockingNewForm) {
      return <ErrorView errorMsg={t('somethingWentWrong')} serverResponse={t(errorMsgBlockingNewForm)} />
    }

    if (isEmpty(schedulesMap)) {
      if (hasNoContacts) {
        return <EmptyView emptyMsg={t('scheduleReportsWithEmptyContactsMsg')} />
      }
      return <EmptyView />
    }

    return (
      <SchedulesTable>
        <SchedulesHeader>
          <div />
          <ColumnHeading isLeftAligned>{t('scheduleNameCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('scheduleTemplateCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('scheduleNextReportCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('scheduleRecipientsCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('scheduleCadenceCol')}</ColumnHeading>
          <div />
        </SchedulesHeader>
        <HorizontalRule />
        {map(values(schedulesMap), renderScheduleItem)}
      </SchedulesTable>
    )
  }

  return (
    <>
      <PageContent>
        <PageTitleWithAction>
          {t('scheduleViewTitle')}
          {!isNewFormShown && !isInvalidForNewForm ? (
            <Button
              onClick={() => {
                setIsNewFormShown(true)
                setExpandedScheduleId(undefined)
                clearAllErrors()
              }}
              size="small"
              color="success"
            >
              <>
                <FontAwesomeIcon icon="plus" />
                {t('createScheduledReportBtn')}
              </>
            </Button>
          ) : (
            <div />
          )}
        </PageTitleWithAction>
        <ContentBoxLists>
          <CreateScheduleFormWrapper isOpen={isNewFormShown && !isInvalidForNewForm}>
            <SubTitle>{t('createScheduleFormTitle')}</SubTitle>
            {includes(loading, 'LIST_TEMPLATES') || includes(clientLoading, 'LIST_CONTACTS') ? (
              <Spinner />
            ) : (
              <ScheduleReportForm
                onSubmit={handleCreateSchedule}
                onCancel={handleCloseForms}
                templatesListOptions={templatesListOptions}
                allUsersList={values(contactsMap)}
                allCompaniesList={values(companiesMap)}
                errorMsg={error['CREATE_SCHEDULE']}
                isResetNeeded={isSchedulesUpdated}
              />
            )}
          </CreateScheduleFormWrapper>
          {renderContent()}
          <HorizontalRule lineStyle="dashed" isHideLast />
        </ContentBoxLists>
      </PageContent>
      <ConfirmDialog
        isOpen={confirmRemoveSchedule != null}
        confirmText={t('confirmRemoveSchedule', { name: confirmRemoveSchedule?.name })}
        onConfirm={() => handleRemoveScheduleConfirmed(confirmRemoveSchedule?.id)}
        onCancel={() => setConfirmRemoveSchedule(undefined)}
      />
    </>
  )
}

export default ScheduleView
