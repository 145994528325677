import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Checkbox } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil, size, map, reduce, isInteger, nth, last } from 'lodash'
import { v4 as uuid } from 'uuid'

import { AuthMatrixSection as AuthMatrixSectionType, Question, AuthMatrixQuestion, Template } from 'models/template'

import Button from 'components/Button'
import { ColumnHeading, ToggleIcon } from 'components/styles'

import {
  AuthMatrixSectionContainer,
  ToggleEditIcon,
  CountLength,
  SectionName,
  SectionToggleName,
  HiddenContentBox,
  MatrixSectionHeader,
  MatrixQuestionRow,
  CheckboxWrapper,
  DocDescripWrapper,
  RemoveBtnWrapper,
  EditNameWrapper,
  SortOrderWrapper,
  ChangeSortOrderWrapper,
  SortArrow,
  SortOrderValue,
  AddQuestionWrapper,
} from './styles'

interface AuthMatrixSectionProps extends AuthMatrixSectionType {
  sIndex: number
  isOpen: boolean
  handleOpen: () => void
  isTitleEditing: boolean
  handleSetTitleEditing: (index: number | undefined) => void
  handleUpdateSectionName: (e: SyntheticEvent) => void
  isViewOnly: boolean | undefined
  isMockData: boolean
  control: any
  watch: any
  templateInPlace: Template
  onAddQuestion: (sIndex: number, question: AuthMatrixQuestion) => void
  onRemoveQuestion: (questionToRemoveId: string) => void
  onUpdateQuestion: (updatedTemplateInPlace: Template) => void
  clearSubmitErrors: () => void
  onRemoveSection: () => void
  onChangeQuestionOrder: (initialIndex: number, incrementBy: number, sectionIndex: number) => void
  onChangeSectionOrder: (initialIndex: number, incrementBy: number) => void
}

const AuthMatrixSection: React.FC<AuthMatrixSectionProps> = ({
  id,
  name,
  questions,
  sIndex,
  isOpen,
  handleOpen,
  isTitleEditing,
  handleSetTitleEditing,
  handleUpdateSectionName,
  isViewOnly,
  isMockData,
  control,
  watch,
  templateInPlace,
  onAddQuestion,
  onRemoveQuestion,
  onUpdateQuestion,
  clearSubmitErrors,
  onRemoveSection,
  onChangeQuestionOrder,
  onChangeSectionOrder,
}) => {
  const { t } = useTranslation()

  const emptyQuestion = {
    id: uuid(),
    columns: ['', '', '', ''],
    allowNA: false,
    allowDocuments: false,
  }

  const handleAddQuestion = (e: SyntheticEvent) => {
    e.stopPropagation()
    const newQuestion = Object.assign({}, emptyQuestion)
    onAddQuestion(sIndex, {
      ...newQuestion,
      id: uuid(),
    })
  }

  const updateCheckbox = (sIndex: number, qIndex: number, field: string, checked: boolean) => {
    console.log('updateCheckbox()', sIndex, qIndex, field, checked, templateInPlace)

    const isClearDescripField = field === 'allowDocuments' && checked === false
    const updatedTemplateInPlace = { ...templateInPlace }
    updatedTemplateInPlace.sections[sIndex].questions[qIndex] = {
      ...updatedTemplateInPlace.sections[sIndex].questions[qIndex],
      [field]: checked,
      documentDescription: isClearDescripField
        ? ''
        : updatedTemplateInPlace.sections[sIndex].questions[qIndex].documentDescription,
    }
    onUpdateQuestion(updatedTemplateInPlace)
    return checked
  }

  // const updateDocumentationDescription = (sIndex: number, qIndex: number, val: any) => {
  //   console.log('updateDocumentationDescription()', sIndex, qIndex, val, templateInPlace)

  //   const updatedTemplateInPlace = { ...templateInPlace }
  //   updatedTemplateInPlace.sections[sIndex].questions[qIndex] = {
  //     ...updatedTemplateInPlace.sections[sIndex].questions[qIndex],
  //     'documentDescription': val
  //   }
  //   onUpdateQuestion(updatedTemplateInPlace)
  // }

  const handleUpdateQuestion = (sIndex: number, qIndex: number, data: any): void => {
    console.log('handleUpdateQuestion()', sIndex, qIndex, data)

    const updatedTemplateInPlace = { ...templateInPlace }
    updatedTemplateInPlace.sections[sIndex].questions[qIndex] = reduce(
      data,
      (question: Question, val: string | boolean, key: string) => {
        const parts = key.split('-')
        const sectionIndex = parseInt(parts[0], 10)
        const questionIndex = parseInt(parts[1], 10)
        const columnName = parts[2]

        if (sectionIndex !== sIndex || questionIndex !== qIndex) {
          return question
        }

        const columnIndex = parseInt(columnName, 10)
        if (columnName === 'allowNA') {
          question.allowNA = val as boolean
        } else if (columnName === 'allowDocuments') {
          question.allowDocuments = val as boolean
        } else if (columnName === 'documentDescription') {
          question.documentDescription = val as string
        } else if (question.columns && isInteger(columnIndex)) {
          question.columns[columnIndex] = val
        }

        return question
      },
      updatedTemplateInPlace.sections[sIndex].questions[qIndex],
    )
    onUpdateQuestion(updatedTemplateInPlace)
  }

  return (
    <AuthMatrixSectionContainer key={`am${id}`}>
      <SectionName onClick={handleOpen} isViewOnly={isViewOnly}>
        <ToggleIcon isOpen={isOpen}>
          <FontAwesomeIcon icon="angle-right" />
        </ToggleIcon>
        <SortOrderWrapper>
          <SortOrderValue>{`S${sIndex + 1}`}</SortOrderValue>
          {isViewOnly ? (
            <div />
          ) : (
            <ChangeSortOrderWrapper>
              <SortArrow
                onClick={e => {
                  e.stopPropagation()
                  onChangeSectionOrder(sIndex, -1)
                }}
                isDisabled={sIndex < 1}
              />
              <SortArrow
                onClick={e => {
                  e.stopPropagation()
                  onChangeSectionOrder(sIndex, 1)
                }}
                isDisabled={sIndex + 1 >= size(templateInPlace?.sections)}
                isDown
              />
            </ChangeSortOrderWrapper>
          )}
        </SortOrderWrapper>
        <SectionToggleName>
          {isTitleEditing ? (
            <EditNameWrapper onClick={(e: SyntheticEvent) => e.stopPropagation()}>
              <input name={`sectionName-${sIndex}`} type="text" defaultValue={name} onBlur={handleUpdateSectionName} />
              <Button onClick={() => handleSetTitleEditing(undefined)} size="small" color="success">
                {t('submitBtn')}
              </Button>
            </EditNameWrapper>
          ) : (
            <>
              {name}
              {isViewOnly ? null : (
                <ToggleEditIcon
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleSetTitleEditing(sIndex)
                  }}
                >
                  <FontAwesomeIcon icon="pencil-alt" />
                </ToggleEditIcon>
              )}
            </>
          )}
        </SectionToggleName>
        <CountLength>{t('questionsLength', { count: size(questions) })}</CountLength>
        {isViewOnly || !id ? null : (
          <Button onClick={onRemoveSection} color="error" size="small" isSimple>
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        )}
      </SectionName>

      <HiddenContentBox isOpen={isOpen}>
        <MatrixSectionHeader extrasLength={3}>
          <ColumnHeading key={`heading-${sIndex}-order`}>{t('questionOrderHeading')}</ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-actionDecision`}>{t('actionDecisionHeading')}</ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-approvalRequiredWhen`}>
            {t('approvalRequiredWhenHeading')}
          </ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-companyApproval`}>{t('companyApprovalHeading')}</ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-triveApproval`}>
            {isMockData ? 'Fund Approval' : t('triveApprovalHeading')}
          </ColumnHeading>
          <ColumnHeading>{t('allowNAColumnHeading')}</ColumnHeading>
          <ColumnHeading isDoubleCol>{t('docRequiredColumnHeading')}</ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-remove`}></ColumnHeading>
        </MatrixSectionHeader>
        {isNil(questions)
          ? null
          : map(questions as AuthMatrixQuestion[], (quest: Question, qIndex: number) => {
              return (
                <MatrixQuestionRow key={quest.id} extrasLength={size(quest.columns) - 1}>
                  <SortOrderWrapper>
                    <SortOrderValue>{`Q${qIndex + 1}`}</SortOrderValue>
                    {isViewOnly ? (
                      <div />
                    ) : (
                      <ChangeSortOrderWrapper>
                        <SortArrow onClick={() => onChangeQuestionOrder(qIndex, -1, sIndex)} isDisabled={qIndex < 1} />
                        <SortArrow
                          onClick={() => onChangeQuestionOrder(qIndex, 1, sIndex)}
                          isDisabled={qIndex + 1 >= size(questions)}
                          isDown
                        />
                      </ChangeSortOrderWrapper>
                    )}
                  </SortOrderWrapper>
                  <Controller
                    key={`input-${sIndex}-${qIndex}-0`}
                    as={TextField}
                    name={`${sIndex}-${qIndex}-0`}
                    control={control}
                    variant="outlined"
                    multiline
                    onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                    defaultValue={nth(quest.columns, 0)}
                  />

                  <Controller
                    key={`input-${sIndex}-${qIndex}-1`}
                    as={TextField}
                    name={`${sIndex}-${qIndex}-1`}
                    control={control}
                    variant="outlined"
                    multiline
                    onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                    defaultValue={nth(quest.columns, 1)}
                  />

                  <Controller
                    key={`input-${sIndex}-${qIndex}-2`}
                    as={TextField}
                    name={`${sIndex}-${qIndex}-2`}
                    control={control}
                    variant="outlined"
                    multiline
                    onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                    defaultValue={nth(quest.columns, 2)}
                  />

                  <Controller
                    key={`input-${sIndex}-${qIndex}-3`}
                    as={TextField}
                    name={`${sIndex}-${qIndex}-3`}
                    control={control}
                    variant="outlined"
                    multiline
                    onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                    defaultValue={nth(quest.columns, 3)}
                  />

                  <CheckboxWrapper>
                    <Controller
                      as={Checkbox}
                      name={`${sIndex}-${qIndex}-allowNA`}
                      control={control}
                      color="default"
                      onChange={args => updateCheckbox(sIndex, qIndex, 'allowNA', last(args))}
                      defaultValue={quest.allowNA}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <Controller
                      as={Checkbox}
                      name={`${sIndex}-${qIndex}-allowDocuments`}
                      control={control}
                      color="default"
                      onChange={args => updateCheckbox(sIndex, qIndex, 'allowDocuments', last(args))}
                      defaultValue={quest.allowDocuments}
                    />
                  </CheckboxWrapper>
                  <DocDescripWrapper isActive={!!quest.allowDocuments}>
                    <Controller
                      as={TextField}
                      name={`${sIndex}-${qIndex}-documentDescription`}
                      control={control}
                      variant="outlined"
                      multiline
                      onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                      defaultValue={quest.documentDescription}
                    />
                  </DocDescripWrapper>

                  {isViewOnly ? null : (
                    <RemoveBtnWrapper>
                      <Button onClick={() => onRemoveQuestion(quest.id as string)} isSimple size="small" color="error">
                        <FontAwesomeIcon icon="trash-alt" />
                      </Button>
                    </RemoveBtnWrapper>
                  )}
                </MatrixQuestionRow>
              )
            })}
        {isViewOnly ? null : (
          <AddQuestionWrapper>
            <Button onClick={handleAddQuestion} isInverted color="active">
              <>
                <FontAwesomeIcon icon="plus" />
                {t('addQuestionBtn')}
              </>
            </Button>
          </AddQuestionWrapper>
        )}
      </HiddenContentBox>
    </AuthMatrixSectionContainer>
  )
}

export default AuthMatrixSection
