import React from 'react'
import styled, { keyframes } from 'styled-components'
import { text, bg } from 'utils/colors'

interface SpinnerProps {
  isTextSize?: boolean
  isMini?: boolean
  isSmall?: boolean
}

const load8 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const SpinnerElement = styled.div`
  font-size: 100px;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 0 auto;
  top: 0.6em;
  font-size: 100px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.1em solid ${bg.spinner};
  border-right: 0.1em solid ${bg.spinner};
  border-bottom: 0.1em solid ${bg.spinner};
  border-left: 0.1em solid ${text.inactive};
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;
  &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }
`
const TextSizeElement = styled(SpinnerElement)`
  font-size: 1.2rem;
  top: 0;
  border-left: 0.1em solid ${text.contrast};
`
const MiniSpinnerElement = styled(SpinnerElement)`
  font-size: 20px;
  top: 0;
  border-left: 0.1em solid ${text.contrast};
`
const SmallSpinnerElement = styled(SpinnerElement)`
  font-size: 50px;
  top: 0;
`
const SpinnerWrapper = styled.div`
  height: 15em;
`

const Spinner: React.FC<SpinnerProps> = ({ isTextSize, isMini, isSmall }: SpinnerProps) => {
  if (isTextSize) return <TextSizeElement />
  if (isMini) return <MiniSpinnerElement />
  if (isSmall) return <SmallSpinnerElement />
  return (
    <SpinnerWrapper>
      <SpinnerElement />
    </SpinnerWrapper>
  )
}

export default Spinner
