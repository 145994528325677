import React from 'react'
import styled, { css } from 'styled-components'
import { text, bg } from 'utils/colors'

import { ContentBoxTitle, ActionBtns } from 'components/styles'
import { SIDENAV_WIDTH, TITLEBAR_HEIGHT } from 'utils/appUnits'
import { Dialog } from '@material-ui/core'

interface ModalProps {
  isOpen: boolean
  title?: string | React.ReactElement<any>
  content: string | React.ReactElement<any>
  buttons?: React.ReactElement<any>
  onClose?: () => void
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  hasOverlay?: boolean
}

const widthSwitch = (width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  switch (width) {
    case 'xs':
      return '20%'
    case 'sm':
      return '35%'
    case 'md':
      return '50%'
    case 'lg':
      return '65%'
    case 'xl':
      return '80%'
    default:
      return '50%'
  }
}

const StyledTitle = styled(ContentBoxTitle)`
  margin-bottom: 1rem;
`
const InnerDialog = styled.div`
  padding: 2rem 2rem 2rem;
  min-width: 30rem;
  min-height: 10rem;
`
const StyledContent = styled.div`
  margin: 0 1rem;
`
const mainContentPositioning = css`
  position: absolute;
  top: ${TITLEBAR_HEIGHT};
  height: ${`calc(100vh - ${TITLEBAR_HEIGHT})`};
  left: ${SIDENAV_WIDTH};
  width: ${`calc(100vw - ${SIDENAV_WIDTH})`};
`
const AltWrapper = styled.div`
  ${mainContentPositioning}
  z-index: 160;
  display: flex;
  align-items: center;
  justify-content: center;
`
const AltModal = styled.div<{ width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }>`
  width: ${p => widthSwitch(p.width)};
  background: ${bg.contentBox};
  color: ${text.main};
  box-shadow: 0 0.3rem 0.5rem -0.3rem rgba(0, 0, 0, 0.2), 0 1rem 1rem 0.1rem rgba(0, 0, 0, 0.14),
    0 0.3rem 1rem 0.3rem rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
`
const Overlay = styled.div`
  ${mainContentPositioning}
  z-index: 150;
  background: ${bg.main};
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
`

const Modal: React.FC<ModalProps> = ({ isOpen, title, content, buttons, onClose, width, hasOverlay }: ModalProps) => {
  if (hasOverlay && isOpen) {
    return (
      <>
        <AltWrapper>
          <AltModal width={width}>
            <InnerDialog>
              {title != null ? <StyledTitle>{title}</StyledTitle> : null}
              <StyledContent>{content}</StyledContent>
              {buttons != null && <ActionBtns>{buttons}</ActionBtns>}
            </InnerDialog>
          </AltModal>
        </AltWrapper>

        {hasOverlay && <Overlay />}
      </>
    )
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={width}>
      <InnerDialog>
        {title != null ? <StyledTitle>{title}</StyledTitle> : null}
        <StyledContent>{content}</StyledContent>
        {buttons != null && <ActionBtns>{buttons}</ActionBtns>}
      </InnerDialog>
    </Dialog>
  )
}

export default Modal
