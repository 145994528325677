import { Report } from '../models/report'
import { map } from 'lodash'

import {
  LIST_REPORTS_LOADING,
  LIST_REPORTS_SUCCESS,
  LIST_REPORTS_FAILURE,
  FIND_REPORT_LOADING,
  FIND_REPORT_SUCCESS,
  FIND_REPORT_FAILURE,
  UPDATE_EFFECTIVE_DATE_PENDING,
  UPDATE_EFFECTIVE_DATE_SUCCESS,
  UPDATE_EFFECTIVE_DATE_FAILURE,
  SEND_AD_HOC_REPORT_PENDING,
  SEND_AD_HOC_REPORT_SUCCESS,
  SEND_AD_HOC_REPORT_FAILURE,
  CLEAR_AD_HOC_REPORT_STATUS,
  REMOVE_REPORT_PENDING,
  REMOVE_REPORT_SUCCESS,
  REMOVE_REPORT_FAILURE,
  CLEAR_ERRORS,
} from 'actions/reportActions'

interface PendingItem {
  status: string
  error?: string
}
interface ReportState {
  reportDetail: {
    isFetching: boolean
    item: { id: string } | Report
    error?: string
    lastUpdated?: Date
  }

  reportList: {
    items: Report[]
    isFetching: boolean
    page?: number
    perPage?: number
    totalItems?: number
    error?: string
    lastUpdated?: Date
    pendingItems: Record<string, PendingItem>
  }

  adHocReportStatus: {
    isPending: boolean
    error?: string
    isComplete: boolean
  }
}

const initState = {
  reportDetail: {
    isFetching: false,
    item: { id: '' },
  },

  reportList: {
    isFetching: false,
    items: [],
    error: undefined,
    pendingItems: {},
  },

  adHocReportStatus: {
    isPending: false,
    error: undefined,
    isComplete: false,
  },
}

export const reportReducer = (state: ReportState = initState, action: any): ReportState => {
  switch (action.type) {
    case LIST_REPORTS_LOADING:
      return {
        ...state,

        reportDetail: { ...state.reportDetail },
        reportList: {
          ...state.reportList,

          error: undefined,
          isFetching: true,
          pendingItems: {},
        },
      }

    case LIST_REPORTS_SUCCESS:
      return {
        ...state,

        reportList: {
          items: action.payload.items,
          page: action.payload.page,
          perPage: action.payload.perPage,
          totalItems: action.payload.totalItems,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
          pendingItems: {},
        },
      }

    case LIST_REPORTS_FAILURE:
      return {
        ...state,

        reportList: {
          ...state.reportList,

          error: action.payload.errorMsg,
          isFetching: false,
          pendingItems: {},
        },
      }

    case FIND_REPORT_LOADING:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case FIND_REPORT_SUCCESS:
      return {
        ...state,
        reportDetail: {
          item: {
            ...action.payload.report,
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case FIND_REPORT_FAILURE:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case UPDATE_EFFECTIVE_DATE_PENDING:
      return {
        ...state,
        reportList: {
          ...state.reportList,
          error: undefined,
          isFetching: true,
        },
      }
    case UPDATE_EFFECTIVE_DATE_SUCCESS:
      const updatedReportsList = map(state.reportList.items, report => {
        return report.id === action.payload.reportId
          ? {
              ...report,
              effectiveDate: action.payload.effectiveDate,
            }
          : report
      })
      return {
        ...state,
        reportList: {
          ...state.reportList,
          items: updatedReportsList,
          error: undefined,
          isFetching: false,
        },
      }
    case UPDATE_EFFECTIVE_DATE_FAILURE:
      return {
        ...state,
        reportList: {
          ...state.reportList,
          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case SEND_AD_HOC_REPORT_PENDING:
      return {
        ...state,
        adHocReportStatus: {
          ...state.adHocReportStatus,
          isPending: true,
        },
      }
    case SEND_AD_HOC_REPORT_SUCCESS:
      return {
        ...state,
        adHocReportStatus: {
          ...state.adHocReportStatus,
          isPending: false,
          isComplete: true,
        },
      }
    case SEND_AD_HOC_REPORT_FAILURE:
      return {
        ...state,
        adHocReportStatus: {
          ...state.adHocReportStatus,
          isPending: false,
          error: action.payload.errorMsg,
        },
      }
    case CLEAR_AD_HOC_REPORT_STATUS:
      return {
        ...state,
        adHocReportStatus: {
          ...state.adHocReportStatus,
          isPending: false,
          isComplete: false,
          error: undefined,
        },
      }

    case REMOVE_REPORT_PENDING:
      return {
        ...state,
        reportList: {
          ...state.reportList,
          pendingItems: {
            ...state.reportList.pendingItems,
            [`${action.payload.reportId}-${action.payload.userId}`]: {
              status: 'PENDING',
              error: undefined,
            },
          },
        },
      }
    case REMOVE_REPORT_SUCCESS:
      return {
        ...state,
        reportList: {
          ...state.reportList,
          pendingItems: {
            ...state.reportList.pendingItems,
            [`${action.payload.reportId}-${action.payload.userId}`]: {
              status: 'REMOVED',
              error: undefined,
            },
          },
        },
      }
    case REMOVE_REPORT_FAILURE:
      return {
        ...state,
        reportList: {
          ...state.reportList,
          pendingItems: {
            ...state.reportList.pendingItems,
            [`${action.payload.reportId}-${action.payload.userId}`]: {
              status: 'ERROR',
              error: action.payload.errorMsg,
            },
          },
        },
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          error: undefined,
        },
        reportList: {
          ...state.reportList,
          error: undefined,
        },
        adHocReportStatus: {
          ...state.adHocReportStatus,
          error: undefined,
        },
      }

    default:
      return state
  }
}
