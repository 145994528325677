import { ReportSchedule, Template } from 'models/schedule'
import { omit, without, concat } from 'lodash'

import {
  LIST_SCHEDULES_LOADING,
  LIST_SCHEDULES_SUCCESS,
  LIST_SCHEDULES_FAILURE,
  CREATE_SCHEDULE_LOADING,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILURE,
  REMOVE_SCHEDULE_LOADING,
  REMOVE_SCHEDULE_SUCCESS,
  REMOVE_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_LOADING,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_SCHEDULE_FAILURE,
  LIST_TEMPLATES_LOADING,
  LIST_TEMPLATES_SUCCESS,
  LIST_TEMPLATES_FAILURE,
  CLEAR_ERRORS,
} from 'actions/scheduleActions'

interface ScheduleState {
  error: Record<string, string>
  loading: string[]
  isSchedulesUpdated: boolean
  schedulesMap?: Record<string, ReportSchedule>
  templatesList: Template[]
}

const initState = {
  error: {},
  loading: [],
  isSchedulesUpdated: false,
  schedulesMap: undefined,
  templatesList: []
}

export const scheduleReducer = (state = initState, action: any): ScheduleState => {
  switch (action.type) {
    case LIST_SCHEDULES_LOADING:
      return {
        ...state,
        error: omit(state.error, 'LIST_SCHEDULES'),
        loading: concat(state.loading, 'LIST_SCHEDULES'),
        isSchedulesUpdated: false
      }

    case LIST_SCHEDULES_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'LIST_SCHEDULES'),
        loading: without(state.loading, 'LIST_SCHEDULES'),
        schedulesMap: action.payload.schedulesMap
      }

    case LIST_SCHEDULES_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          LIST_SCHEDULES: action.payload.errorMsg
        },
        loading: without(state.loading, 'LIST_SCHEDULES'),
        schedulesMap: {}
      }

    case CREATE_SCHEDULE_LOADING:
      return {
        ...state,
        error: omit(state.error, 'CREATE_SCHEDULE'),
        loading: concat(state.loading, 'CREATE_SCHEDULE')
      }

    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'CREATE_SCHEDULE'),
        loading: without(state.loading, 'CREATE_SCHEDULE'),
        isSchedulesUpdated: true,
      }

    case CREATE_SCHEDULE_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          CREATE_SCHEDULE: action.payload.errorMsg
        },
        loading: without(state.loading, 'CREATE_SCHEDULE')
      }

    case UPDATE_SCHEDULE_LOADING:
      return {
        ...state,
        error: omit(state.error, `UPDATE_SCHEDULE_${action.payload.scheduleId}`),
        loading: concat(state.loading, `UPDATE_SCHEDULE_${action.payload.scheduleId}`)
      }

    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: omit(state.error, `UPDATE_SCHEDULE_${action.payload.scheduleId}`),
        loading: without(state.loading, `UPDATE_SCHEDULE_${action.payload.scheduleId}`),
        isSchedulesUpdated: true,
      }

    case UPDATE_SCHEDULE_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          [`UPDATE_SCHEDULE_${action.payload.scheduleId}`]: action.payload.errorMsg
        },
        loading: without(state.loading, `UPDATE_SCHEDULE_${action.payload.scheduleId}`)
      }

    case REMOVE_SCHEDULE_LOADING:
      return {
        ...state,
        error: omit(state.error, `REMOVE_SCHEDULE_${action.payload.scheduleId}`),
        loading: concat(state.loading, `REMOVE_SCHEDULE_${action.payload.scheduleId}`)
      }

    case REMOVE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: omit(state.error, `REMOVE_SCHEDULE_${action.payload.scheduleId}`),
        loading: without(state.loading, `REMOVE_SCHEDULE_${action.payload.scheduleId}`),
        schedulesMap: omit(state.schedulesMap, action.payload.id),
      }

    case REMOVE_SCHEDULE_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          [`REMOVE_SCHEDULE_${action.payload.scheduleId}`]: action.payload.errorMsg
        },
        loading: without(state.loading, `REMOVE_SCHEDULE_${action.payload.scheduleId}`)
      }

    case LIST_TEMPLATES_LOADING:
      return {
        ...state,
        error: omit(state.error, 'LIST_TEMPLATES'),
        loading: concat(state.loading, 'LIST_TEMPLATES'),
      }

    case LIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'LIST_TEMPLATES'),
        loading: without(state.loading, 'LIST_TEMPLATES'),
        templatesList: action.payload.templatesList
      }

    case LIST_TEMPLATES_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          LIST_TEMPLATES: action.payload.errorMsg
        },
        loading: without(state.loading, 'LIST_TEMPLATES'),
        templatesList: []
      }

    case CLEAR_ERRORS:
      return {
        ...state,
        error: {}
      }



    default:
      return state
    }

  }
