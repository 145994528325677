import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import Demo1LogoImg from 'components/Demo1Logo/ngp.png'

interface Demo1LogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Demo1Logo: React.FC<Demo1LogoProps> = ({ brandName }: Demo1LogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={Demo1LogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default Demo1Logo
