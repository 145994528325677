import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import StonePointLogoImg from './stonepoint.png'

interface StonePointLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const StonePointLogo: React.FC<StonePointLogoProps> = ({ brandName }: StonePointLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={StonePointLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default StonePointLogo
