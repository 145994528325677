import React from 'react'
import { text } from 'utils/colors'

interface ComplyifyLogoProps {
  color?: string
}
const ComplyifyLogo: React.FC<ComplyifyLogoProps> = ({ color }: ComplyifyLogoProps) => {
  const logoColor = color || text.nav

  return (
    <svg id="complyify-logo" data-name="complyify-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.816 30.485">
      <g>
        <path
          fill={logoColor}
          fillRule="evenodd"
          d="M71.845,53.938a11.9,11.9,0,1,0,0,12.124h0l1.6.33a12.879,12.879,0,1,1,0-12.784l-1.6.33ZM72.58,64.6l-4.231-.871-1.387-.285-3.177-.654c-.016.037-.033.074-.05.111v0l-.051.108,0,.005-.053.106v0c-.019.037-.038.073-.057.109h0c-.019.035-.038.07-.057.105l0,0-.057.1,0,0,0,0-.059.1,0,0v0l-.061.1,0,.005-.062.1,0,.007-.066.1h0l-.068.1,0,0-.069.1,0,0-.07.094h0l0,0a6.891,6.891,0,1,1,0-8.337l0,0h0l.07.095,0,0,.069.1,0,0,.068.1h0l.067.1,0,0,.065.1h0l.062.1v0l0,0,.059.1,0,0,0,0,.058.1h0l.058.107h0c.038.072.075.143.111.216l0,.006.051.107v0c.017.037.034.074.05.111l3.177-.654,1.387-.285,4.231-.87,1.587-.327,4.264-.877q-.107-.26-.223-.514h0q-.116-.254-.24-.5h0q-.062-.124-.126-.246h0v0q-.052-.1-.105-.2h0a15.241,15.241,0,1,0,.7,13.081l-4.264-.877L72.58,64.6Zm-5,.581-1.414-.291h0a8.688,8.688,0,1,1,0-9.787h0l1.414-.291a9.556,9.556,0,1,0,0,10.369Z"
          transform="translate(-49.092 -44.757)"
        />
        <path
          fill={logoColor}
          fillRule="evenodd"
          d="M93.653,63.381A5.04,5.04,0,0,1,90.2,64.792a4.487,4.487,0,0,1-4.792-4.736A4.558,4.558,0,0,1,90.2,55.227a4.776,4.776,0,0,1,3.362,1.337l1.393-1.337A6.647,6.647,0,0,0,90.2,53.258a6.507,6.507,0,0,0-6.854,6.817c.019,3.418,2.136,6.668,6.854,6.668a6.815,6.815,0,0,0,4.885-1.931Z"
          transform="translate(-49.092 -44.757)"
        />
        <path
          fill={logoColor}
          fillRule="evenodd"
          d="M102.754,53.239a6.5,6.5,0,0,0-6.687,6.817c0,3.418,2.136,6.705,6.65,6.705s6.649-3.213,6.649-6.724-2.173-6.78-6.612-6.8ZM98.13,60.075c0-2.842,1.616-4.941,4.625-4.941,3.176.056,4.551,2.545,4.551,4.941,0,2.489-1.356,4.774-4.588,4.774C99.5,64.848,98.167,62.378,98.13,60.075Z"
          transform="translate(-49.092 -44.757)"
        />
        <path
          fill={logoColor}
          fillRule="evenodd"
          d="M123.149,66.483h2.08V53.5h-2.415l-4.272,6.083-4.29-6.083h-2.415V66.483h2.08V56.524l4.365,6.139h.446l4.421-6.139Z"
          transform="translate(-49.092 -44.757)"
        />
        <path
          fill={logoColor}
          fillRule="evenodd"
          d="M130.579,55.375l3.938-.012c3.417,0,3.436,5.213,0,5.213h-3.938v-5.2Zm3.938,7.114c6.222-.037,6.2-9.046,0-9.046-1.988,0-4.031.018-6.018.018V66.483h2.08V62.489Z"
          transform="translate(-49.092 -44.757)"
        />
        <polygon
          fill={logoColor}
          fillRule="evenodd"
          points="92.241 8.705 92.241 21.725 101.175 21.725 101.175 19.793 94.321 19.793 94.321 8.705 92.241 8.705"
        />
        <polygon
          fill={logoColor}
          fillRule="evenodd"
          points="102.327 8.705 99.857 8.705 99.857 8.798 104.872 16.385 104.872 21.725 106.952 21.725 106.952 16.385 112.116 8.798 112.116 8.705 109.608 8.705 105.912 14.453 102.327 8.705"
        />
        <rect fill={logoColor} x="113.936" y="8.705" width="2.062" height="13.021" />
        <polygon
          fill={logoColor}
          fillRule="evenodd"
          points="121.347 21.725 121.347 16.654 128.108 16.654 128.108 14.723 121.347 14.723 121.347 10.766 128.461 10.766 128.461 8.742 119.267 8.742 119.267 21.725 121.347 21.725"
        />
        <polygon
          fill={logoColor}
          fillRule="evenodd"
          points="132.027 8.705 129.557 8.705 129.557 8.798 134.572 16.385 134.572 21.725 136.652 21.725 136.652 16.385 141.816 8.798 141.816 8.705 139.309 8.705 135.612 14.453 132.027 8.705"
        />
      </g>
    </svg>
  )
}

export default ComplyifyLogo
