import React, { useState, useEffect } from 'react'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import ErrorView from 'components/ErrorView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sendAdHocReport, CLEAR_AD_HOC_REPORT_STATUS, listReports } from 'actions/reportActions'
import { listCompanies } from 'actions/companyActions'
import { isMockData } from 'utils/brandConfig'
import Form from 'components/Form/GoodForm'
import { map, isEmpty, includes, filter, concat, trim } from 'lodash/fp'

import { ModalTitle } from 'components/styles'
import {
  AdHocReportModalContainer,
  ActionBtns,
  FormSuccessMsg,
  UsersCheckboxes,
  UserSelect,
  UserName,
  UserEmail,
  UserCheck,
  ErrorMsg,
} from './styles'

interface User {
  id: string
  name: string
  email: string
}

interface TemplateOption {
  value: string
  label: string
}

interface AdHocReportModalProps {
  isOpen: boolean
  onClose: () => void
  templatesListOptions: TemplateOption[]
  initialTemplateId: string,
  initialDaysToComplete: string
}

const AdHocReportModal: React.FC<AdHocReportModalProps> = ({
  isOpen,
  onClose,
  templatesListOptions,
  initialTemplateId,
  initialDaysToComplete
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { companyDetail, usersList } = useSelector((state: any) => state.companies)
  const { adHocReportStatus } = useSelector((state: any) => state.reports)
  const [ selectedUserIds, setSelectedUserIds ] = useState<string[]>([])

  useEffect(() => {
    if (usersList && !usersList.fetching && !isEmpty(usersList.items)) {
      const activeUsers = filter((user) => includes(user.status, ["ACTIVE", "PENDING"]), usersList?.items)
      const ids = map((user) => user.id, activeUsers)
      setSelectedUserIds(ids)
    }
  }, [usersList])

  const handleToggleSelectUser = (userId: string) => {
    if (includes(userId, selectedUserIds)) {
      setSelectedUserIds(filter((id) => id !== userId, selectedUserIds))
    } else {
      setSelectedUserIds(concat([userId], selectedUserIds))
    }
  }

  const handleClose = () => {
    const activeUsers = filter((user) => includes(user.status, ["ACTIVE", "PENDING"]), usersList?.items)
    const ids = map((u) => u.id, activeUsers)
    setSelectedUserIds(ids)
    dispatch({ type: CLEAR_AD_HOC_REPORT_STATUS })
    onClose()
    dispatch(listReports(auth0Context, companyDetail?.item.id))
    dispatch(listReports(auth0Context, companyDetail?.item.id))
    dispatch(listCompanies(auth0Context))
  }

  const handleSubmit = ({
    templateId,
    reportName,
    daysToComplete,
  }: {
    templateId: string,
    reportName: string,
    daysToComplete: string,
  }) => {
    dispatch(sendAdHocReport(auth0Context, {
      users: selectedUserIds,
      daysToComplete: parseInt(daysToComplete),
      name: trim(reportName),
      companyId: companyDetail?.item.id,
      templateId,
    }))
  }

  let InnerContent
  if (!isMockData && adHocReportStatus.error) {
    InnerContent = <ErrorView errorMsg={t('somethingWentWrong')} serverResponse={adHocReportStatus.error} />
  } else if (adHocReportStatus.isPending) {
    InnerContent = <Spinner />
  } else if (adHocReportStatus.isComplete) {
    InnerContent = (
      <FormSuccessMsg>{t('adHocReportSentSuccess')}</FormSuccessMsg>
    )
  } else {
    InnerContent = (
      <>
        <UsersCheckboxes>
          {map((user) => {
            if (includes(user.status, ["ACTIVE", "PENDING"])) {
              const isSelected = includes(user.id, selectedUserIds)
              const checkIcon = isSelected
                ? <FontAwesomeIcon icon="check-square" />
                : <FontAwesomeIcon icon="square" />
              return (
                <UserSelect
                  key={`user-${user.id}`}
                  onClick={() => handleToggleSelectUser(user.id)}
                  isSelected={isSelected}
                >
                  <UserCheck isSelected={isSelected}>{checkIcon}</UserCheck>
                  <UserName>{user.name}</UserName>
                  <UserEmail>{user.email}</UserEmail>
                </UserSelect>
              )
            }
          }, usersList?.items)}
          {isEmpty(selectedUserIds) && (
            <ErrorMsg>{t('adHocUserError')}</ErrorMsg>
          )}
        </UsersCheckboxes>
        <Form
          onSubmit={handleSubmit}
          onCancel={handleClose}
          submitBtnLabel='sendAdHocReportBtn'
          isDisabled={isEmpty(selectedUserIds)}
          isPreventCloseOnSubmit
          fields={[
            {
              type: 'select',
              name: 'templateId',
              options: templatesListOptions,
              initialValue: initialTemplateId,
              ref: { required: true },
              errors: { required: t('templateFieldRequired') },
            },
            {
              type: 'text',
              name: 'reportName',
              ref: { required: true, minLength: 2 },
              errors: { required: t('reportNameRequired') },
            },
            {
              type: 'number',
              name: 'daysToComplete',
              initialValue: initialDaysToComplete,
              ref: { required: true, min: 0 },
              errors: {
                required: t('daysToCompleteError'),
                min: t('daysToCompleteError'),
              },
            },
          ]}
        />
      </>
    )
  }

  const ModalContent = (
    <>
      <ModalTitle>
        {t('adHocReportModalTitle')}
      </ModalTitle>
      <AdHocReportModalContainer>
        {InnerContent}
        <ActionBtns>
          {adHocReportStatus.error || adHocReportStatus.isComplete ? (
            <Button onClick={handleClose}>
              {t('closeBtn')}
            </Button>
          ) : null}
        </ActionBtns>
      </AdHocReportModalContainer>
    </>
  )

  return <Modal isOpen={isOpen} content={ModalContent} onClose={handleClose} width="lg" />
}

export default AdHocReportModal
