import React from 'react'
import styled from 'styled-components'
import { map, reduce, nth, size, find, upperCase, isNil, omit, divide, multiply, round, subtract } from 'lodash'
import { text, random } from 'utils/colors'
import sumValuesForPeriod from 'utils/sumValuesForPeriod'

interface SegmentedBarGraphProps {
  id: string
  responses: Record<string, number>
  options?: string[]
  randomOffset: number
  highestYAxisValue?: number
  isVertical?: boolean
  isPercentage?: boolean
}

interface SegmentedBarGraphWrapperType {
  totalAnswers: string
  isVertical?: boolean
  topBlankSpace: boolean | number
}

const SegmentedBarGraphWrapper = styled.div<SegmentedBarGraphWrapperType>`
  display: grid;

  ${p =>
    p.isVertical
      ? `height: 100%;
    grid-template-rows: ${p.topBlankSpace ? `${p.topBlankSpace}fr ` : ''}${p.totalAnswers};
    `
      : `width: 100%;
    grid-template-columns: ${p.totalAnswers};`}
`
const OptionBar = styled.div<{ colorIndex: number; isVertical?: boolean }>`
  color: ${text.contrast};
  background: ${p => nth(random, p.colorIndex)};
  display: grid;
  padding: 0.5em 0;

  ${p => (p.isVertical ? `grid-template-rows: auto auto;` : `grid-template-columns: auto auto;`)}
`
const OptionLabel = styled.div`
  font-weight: 400;
  padding-left: 0.5em;
  justify-self: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`
const OptionValue = styled.div`
  font-weight: 600;
  padding: 0 0.5em;
  justify-self: end;
  align-self: end;
`

const SegmentedBarGraph: React.FC<SegmentedBarGraphProps> = ({
  id,
  responses,
  options,
  randomOffset,
  highestYAxisValue,
  isVertical,
  isPercentage,
}) => {
  const randomColorsLen = size(random)
  const filteredTotals = omit(responses, 'CONFLICTING_ANSWERS')
  const totalValues = reduce(filteredTotals, (sum: number, n: number) => sum + n, 0)

  const topBlankSpace =
    isPercentage || isNil(highestYAxisValue) ? 0 : subtract(highestYAxisValue, sumValuesForPeriod(responses))

  const totalValueSpan = reduce(responses, (sum: number, n: number) => sum + n, 0)
  if (totalValueSpan < 1) return null

  const hasTopBlankSpace = totalValueSpan % 5 !== 0

  const renderOptionBar = (optionLabel: string, i: number) => {
    if (['TOP_BLANK_BLOCK', 'CONFLICTING_ANSWERS'].includes(optionLabel)) {
      return <div key={`seg-${id}-${i}`} />
    }
    const value = find(responses, (val, key) => upperCase(key) === upperCase(optionLabel) && val > 0)
    if (!value) return <div key={`seg-${id}-${i}`} />

    const colorIndex = (i + randomOffset) % randomColorsLen
    const val = isPercentage ? `${round(multiply(divide(value, totalValues), 100))}%` : value.toLocaleString('en-US')

    const isTooSmallForLabel = divide(value, totalValues) < 0.1

    return (
      <OptionBar key={`seg-${id}-${optionLabel}`} isVertical={isVertical} colorIndex={colorIndex}>
        {isVertical || isTooSmallForLabel ? <div /> : <OptionLabel>{optionLabel}</OptionLabel>}
        <OptionValue>{val}</OptionValue>
      </OptionBar>
    )
  }

  const totalAnswers = reduce(
    filteredTotals,
    (acc: string, value: number) => {
      // if (value < 1) return acc
      return `${acc} ${value}fr`
    },
    '',
  )

  // console.log('Segmented Bar Graph:', {
  //   filteredTotals,
  //   randomColorsLen,
  //   responses,
  //   options,
  //   totalAnswers,
  //   totalValueSpan,
  //   topBlankSpace: hasTopBlankSpace && topBlankSpace,
  // })

  const otherValue = find(responses, (val, key) => upperCase(key) === 'OTHER' && val > 0)

  return (
    <SegmentedBarGraphWrapper
      isVertical={isVertical}
      totalAnswers={totalAnswers}
      topBlankSpace={hasTopBlankSpace && topBlankSpace}
    >
      {hasTopBlankSpace && topBlankSpace ? <div /> : null}
      {map(options, renderOptionBar)}
      {otherValue && renderOptionBar('Other', size(options))}
    </SegmentedBarGraphWrapper>
  )
}

export default SegmentedBarGraph
