import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import DemoLogoImg from 'components/DemoLogo/axyq.png'

interface DemoLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const DemoLogo: React.FC<DemoLogoProps> = ({ brandName }: DemoLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={DemoLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default DemoLogo
