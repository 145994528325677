import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  map,
  isEmpty,
  includes,
  head,
  filter,
  add,
  last,
  drop,
  size,
  subtract,
  // values,
  // findIndex,
  // keys,
  // nth,
  // isNil,
  toUpper,
  snakeCase,
  round,
  find,
  isArray,
  indexOf,
} from 'lodash'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { text, statusColors } from 'utils/colors'
import { listMetrics } from 'actions/clientActions'
import { listCompanies } from 'actions/companyActions'
import Form from 'components/Form/GoodForm'

import { useAuth0 } from 'utils/auth0'
// import calcPercent from 'utils/calcPercent'
import { VictoryChart, VictoryPie, VictoryAxis, VictoryStack, VictoryBar, VictoryTooltip, VictoryLabel } from 'victory'
import { isMockData } from 'utils/brandConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Spinner from 'components/Spinner'
import ErrorView from 'components/ErrorView'
import EmptyView from 'components/EmptyView'
// import StatusBadge from 'components/StatusBadge'
import ProgressBar from 'components/ProgressBar'
import Modal from 'components/Modal'
import StatusBadge from 'components/StatusBadge'
import Avatar from 'components/Avatar'

import CountdownTimer from './CountdownTimer'
import mockDashboardData from 'mockData/mockDashboardData'
import { mockCompanies } from 'mockData/mockCompanies'

import { PageContent, MiniTitle } from 'components/styles'
import {
  DashboardContainer,
  DataBox,
  DataBoxTitle,
  DataItemsList,
  DataItem,
  DataItemLabel,
  PieWrapperWrapper,
  // HoverPieCenter,
  HoverText,
  HoverCount,
  PieWrapper,
  PieLegend,
  LegendItem,
  LegendCount,
  LegendBox,
  SmallLegendItem,
  BarWrapper,
  // BarLegend,
  DataItemGroup,
  // HistoryItem,
  SmallPieWrapper,
  PiesList,
  EmptyWrapper,
  DateDueWrapper,
  DashboardEmptyContent,
  CloseIcon,
  DataBoxTopRow,
  FilteredReportsWrapper,
  CompanyIcon,
  CompanyItem,
  CustomLabelWrapper,
  FormWrapper,
} from './styles'

import { CompanyName, CompanyStatus, UsersWrapper } from 'features/portfolio/styles'

dayjs.extend(relativeTime)

const MAX_HISTORY_REPORTS = 4

const initEmptyReport = {
  id: 'initial',
  name: '',
  totalReportsSent: 1,
  dueByDates: [],
  questionStatistics: { COMPLIANT: 0, NON_COMPLIANT: 0, NOT_APPLICABLE: 1 },
  templates: [],
  reportStatusCounts: {
    COMPLIANT: 0,
    NON_COMPLIANT: 1,
    PENDING: 0,
    OVERDUE: 0,
  },
}

export type StatusesType = 'COMPLIANT' | 'NON_COMPLIANT' | 'NOT_APPLICABLE' | 'OVERDUE' | 'PENDING' | 'INACTIVE'
const statusesList = ['COMPLIANT', 'NON_COMPLIANT', 'NOT_APPLICABLE', 'OVERDUE', 'PENDING', 'INACTIVE']
const DashboardPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const auth0Context = useAuth0()
  const { metrics } = useSelector((state: any) => state.client)
  const { companyList } = useSelector((state: any) => state.companies)

  const [clickedMainPieStatus, setClickedMainPieStatus] = useState<StatusesType | undefined>()
  const [hoveredMainPieStatus, setHoveredMainPieStatus] = useState<StatusesType | undefined>()
  const [hoveredAttestationPieStatus, setHoveredAttestationPieStatus] = useState<StatusesType | undefined>()
  const [hoveredReportPieStatus, setHoveredReportPieStatus] = useState<StatusesType | undefined>()
  const [selectedReport, setSelectedReport] = useState<any | undefined>(initEmptyReport)
  const [isInitialLoaded, setIsInitialLoaded] = useState<boolean>(false)
  const [sortedReportsList, setSortedReportsList] = useState(mockDashboardData)
  // console.log('clickedMainPieStatus', clickedMainPieStatus)

  const isSelectedReportMostRecent = indexOf(sortedReportsList, selectedReport) === subtract(size(sortedReportsList), 1)

  useEffect(() => {
    dispatch(listMetrics(auth0Context))
  }, [dispatch, auth0Context])

  useEffect(() => {
    if (!companyList.lastUpdated) dispatch(listCompanies(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStatusFromLabel = (label?: string): StatusesType | undefined => {
    const constantCaseLabel = toUpper(snakeCase(label)) as StatusesType
    if (statusesList.includes(constantCaseLabel)) return constantCaseLabel
    return undefined
  }

  const handleGoToCompany = (companyId: string) => {
    history.push(`/company/${companyId}`)
  }

  const companies = !isMockData ? companyList.items : mockCompanies

  useEffect(() => {
    if (!isEmpty(metrics?.item?.reports)) {
      let sortedReports = metrics.item.reports.sort((a: any, b: any) => {
        return dayjs(head(a.dueByDates)).isAfter(dayjs(head(b.dueByDates)))
      })
      if (size(sortedReports) > MAX_HISTORY_REPORTS) {
        sortedReports = drop(sortedReports, subtract(size(sortedReports), MAX_HISTORY_REPORTS))
      }
      setClickedMainPieStatus(undefined)
      setSelectedReport(last(sortedReports) as any)
      setSortedReportsList(sortedReports)
    }
  }, [metrics])

  useEffect(() => {
    if (!isInitialLoaded && !isEmpty(sortedReportsList)) {
      setIsInitialLoaded(true)
      setClickedMainPieStatus(undefined)
      setSelectedReport(last(sortedReportsList) as any)
    }
  }, [sortedReportsList, isInitialLoaded])

  if (!selectedReport) return null

  if (metrics.error && !isMockData) {
    return <ErrorView errorMsg={t('listMetricsError')} serverResponse={t(metrics.error)} />
  }

  if (metrics.isFetching) {
    return <Spinner />
  }
  const selectedReportData = map(
    selectedReport.reportStatusCounts,
    (c: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
      return {
        count: round(c),
        color: statusColors[key],
        label: t(key),
      }
    },
  )

  const numComplete = selectedReport.reportStatusCounts
    ? add(selectedReport.reportStatusCounts.COMPLIANT, selectedReport.reportStatusCounts.NON_COMPLIANT)
    : 0
  const dueDateString = dayjs(head(selectedReport.dueByDates)).fromNow()

  let stacksValues = {
    OVERDUE: 0,
    PENDING: 0,
    NON_COMPLIANT: 0,
    COMPLIANT: 0,
  }
  const stacksData = map(stacksValues, (val: number, status: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
    const stackData = map(sortedReportsList, rep => {
      const statusCount = rep.reportStatusCounts && rep.reportStatusCounts[status] ? rep.reportStatusCounts[status] : 0
      stacksValues = {
        ...stacksValues,
        [status]: add(stacksValues[status], statusCount),
      }
      return {
        x: rep.quarter,
        y: round(statusCount),
        color: statusColors[status],
        label: `${statusCount} ${t(status)}`,
      }
    })
    return (
      <VictoryBar
        key={`barGraph-${status}`}
        data={stackData}
        labelComponent={<VictoryTooltip />}
        style={{
          data: { fill: ({ datum }) => datum.color },
          labels: {
            fontSize: 14,
            fontFamily: 'Roboto',
            fontWeight: 600,
          },
        }}
      />
    )
  })

  const complianceData = filter(
    map(selectedReport.reportStatusCounts, (c: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
      if (c > 0 && includes(['COMPLIANT', 'NON_COMPLIANT'], key)) {
        return {
          count: round(c),
          label: t(key),
          color: statusColors[key],
        }
      }
    }),
    (i: any) => i != null,
  )

  const attestationData = filter(
    map(selectedReport.questionStatistics, (c: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'NOT_APPLICABLE') => {
      if (c > 0) {
        return {
          count: round(c),
          label: t(key),
          color: statusColors[key],
        }
      }
    }),
    (i: any) => i != null,
  )

  const filteredCompanies = filter(
    companies,
    co => co.status !== 'DISABLED' && co.reportStatus === clickedMainPieStatus,
  )

  const CustomLabel = ({
    x,
    y,
    datum,
    isMain,
    hoveredStatus,
    setHoverStatus,
  }: {
    x?: number
    y?: number
    datum?: { count: number; label: string; color: string }
    isMain?: boolean
    hoveredStatus: StatusesType | undefined
    setHoverStatus: (status: StatusesType | undefined) => void
  }) => {
    return (
      <CustomLabelWrapper>
        <VictoryLabel
          x={x}
          y={y}
          verticalAnchor="middle"
          textAnchor="middle"
          text={datum?.count ? round(datum?.count) : ''}
          events={{
            onClick: () => {
              if (isMain) {
                setClickedMainPieStatus(getStatusFromLabel(datum?.label))
              }
            },
            onMouseOver: () => {
              setHoverStatus(getStatusFromLabel(datum?.label))
            },
          }}
        />
        {!!hoveredStatus && hoveredStatus === toUpper(snakeCase(datum?.label)) && (
          <>
            <circle cx={200} cy={200} r={90} fill={datum?.color} />
            <HoverCount x="200" y="195" fill="white">
              {datum?.count ? round(datum?.count) : ''}
            </HoverCount>
            <HoverText x="200" y="225" fill="white">
              {datum?.label}
            </HoverText>
          </>
        )}
      </CustomLabelWrapper>
    )
  }

  const handleReportChange = (name: string) => {
    const newSelectedReport = find(sortedReportsList, r => r.quarter === name)
    if (newSelectedReport) {
      setClickedMainPieStatus(undefined)
      setSelectedReport(newSelectedReport)
    }
  }

  const invertedReports = isArray(sortedReportsList) ? sortedReportsList.slice(0).reverse() : sortedReportsList
  const reportsListOptions = map(invertedReports, r => ({
    value: r.quarter,
    label: r.quarter,
  }))

  const nextReportSendDate = isMockData ? dayjs().add(15, 'day') : metrics.item.nextSendDate
  return (
    <PageContent>
      <DashboardContainer>
        <DataBox area="main">
          <DataBoxTitle>
            {t('bigChartTitle', { reportName: selectedReport.quarter })}
            <FormWrapper>
              <Form
                onSubmit={() => {
                  // do nothing
                }}
                errorMsg={!isMockData ? t(companyList.error) : undefined}
                fields={[
                  {
                    type: 'select',
                    name: 'templateId',
                    options: reportsListOptions,
                    initialValue: selectedReport?.quarter || '',
                    ref: { required: true },
                    errors: { required: t('templateFieldRequired') },
                    onChange: handleReportChange,
                  },
                ]}
                isNoLabel
                isNoActionBtns
              />
            </FormWrapper>
          </DataBoxTitle>
          <DataItemsList>
            <DataItem>
              <DataItemLabel>{t('responsesDueLabel')}</DataItemLabel>
              {dueDateString}
            </DataItem>
            <DataItem>
              <DataItemLabel>{t('totalCompletionLabel')}</DataItemLabel>
              <DataItemGroup>
                <ProgressBar
                  countTotal={
                    numComplete > selectedReport.totalReportsSent ? numComplete : selectedReport.totalReportsSent
                  }
                  countComplete={numComplete}
                  message={`${numComplete} of ${
                    numComplete > selectedReport.totalReportsSent ? numComplete : selectedReport.totalReportsSent
                  }`}
                  color={text.logo}
                />
              </DataItemGroup>
            </DataItem>
          </DataItemsList>
          <PieWrapperWrapper>
            <PieWrapper>
              <VictoryPie
                data={selectedReportData}
                x="label"
                y="count"
                innerRadius={100}
                padAngle={2}
                labelRadius={125}
                labelComponent={
                  <CustomLabel isMain hoveredStatus={hoveredMainPieStatus} setHoverStatus={setHoveredMainPieStatus} />
                }
                style={{
                  data: {
                    fill: ({ datum }) => datum.color,
                  },
                  labels: {
                    fontSize: 30,
                    fontFamily: 'Roboto',
                    fontWeight: 600,
                    fill: 'white',
                    background: ({ datum }) => datum.color,
                  },
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            target: 'data',
                            mutation: ({ datum }) => setClickedMainPieStatus(getStatusFromLabel(datum?.label)),
                          },
                          {
                            target: 'labels',
                            mutation: ({ datum }) => setClickedMainPieStatus(getStatusFromLabel(datum?.label)),
                          },
                        ]
                      },
                      onMouseOver: () => {
                        return [
                          {
                            target: 'data',
                            mutation: ({ datum }) => setHoveredMainPieStatus(getStatusFromLabel(datum?.label)),
                          },
                          {
                            target: 'labels',
                            mutation: ({ datum }) => setHoveredMainPieStatus(getStatusFromLabel(datum?.label)),
                          },
                        ]
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: 'data',
                            mutation: () => setHoveredMainPieStatus(undefined),
                          },
                          {
                            target: 'labels',
                            mutation: () => setHoveredMainPieStatus(undefined),
                          },
                        ]
                      },
                    },
                  },
                ]}
              />
              <PieLegend>
                <div>
                  {map(
                    selectedReport.reportStatusCounts,
                    (count: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => (
                      <LegendItem key={`legend-${t(key)}`} color={text[key]}>
                        <LegendCount color={text[key]}>{count}</LegendCount>
                        <div>{t(key)}</div>
                      </LegendItem>
                    ),
                  )}
                </div>
              </PieLegend>
            </PieWrapper>
          </PieWrapperWrapper>
        </DataBox>
        <DataBox area="bar">
          <DataBoxTitle>{t('previousReportsChartTitle')}</DataBoxTitle>
          <BarWrapper>
            <VictoryChart domainPadding={50} height={425}>
              <VictoryAxis
                tickValues={map(sortedReportsList, rep => rep.quarter)}
                style={{
                  tickLabels: {
                    fontSize: 14,
                    fontFamily: 'Roboto',
                    fontWeight: 600,
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={x => x}
                style={{
                  tickLabels: {
                    fontSize: 14,
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <VictoryStack>{stacksData}</VictoryStack>
            </VictoryChart>
          </BarWrapper>
        </DataBox>
        <DataBox area="smalls">
          <DataBoxTitle>
            {t('complianceOverviewChartTitle', {
              reportName: selectedReport.quarter,
            })}
          </DataBoxTitle>
          <PiesList>
            <SmallPieWrapper>
              <MiniTitle>{t('reportsBreakdown')}</MiniTitle>
              {isEmpty(complianceData) ? (
                <EmptyWrapper>
                  <EmptyView emptyMsg={t('noReportsCompleteBreakdownMsg')} />
                </EmptyWrapper>
              ) : (
                <VictoryPie
                  data={complianceData}
                  x="label"
                  y="count"
                  innerRadius={100}
                  padAngle={2}
                  labelRadius={125}
                  labelComponent={
                    <CustomLabel hoveredStatus={hoveredReportPieStatus} setHoverStatus={setHoveredReportPieStatus} />
                  }
                  style={{
                    data: {
                      fill: ({ datum }) => datum.color,
                    },
                    labels: {
                      fontSize: 24,
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      fill: 'white',
                      background: ({ datum }) => datum.color,
                    },
                  }}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onMouseOver: () => {
                          return [
                            {
                              target: 'data',
                              mutation: ({ datum }) => setHoveredReportPieStatus(getStatusFromLabel(datum?.label)),
                            },
                            {
                              target: 'labels',
                              mutation: ({ datum }) => setHoveredReportPieStatus(getStatusFromLabel(datum?.label)),
                            },
                          ]
                        },
                        onMouseOut: () => {
                          return [
                            {
                              target: 'data',
                              mutation: () => setHoveredReportPieStatus(undefined),
                            },
                            {
                              target: 'labels',
                              mutation: () => setHoveredReportPieStatus(undefined),
                            },
                          ]
                        },
                      },
                    },
                  ]}
                />
              )}
            </SmallPieWrapper>
            <PieLegend>
              <div>
                <SmallLegendItem color={text.COMPLIANT}>
                  <LegendBox color={text.COMPLIANT} />
                  <div>{t('COMPLIANT')}</div>
                </SmallLegendItem>
                <SmallLegendItem color={text.NON_COMPLIANT}>
                  <LegendBox color={text.NON_COMPLIANT} />
                  <div>{t('NON_COMPLIANT')}</div>
                </SmallLegendItem>
                <SmallLegendItem color={text.NOT_APPLICABLE}>
                  <LegendBox color={text.NOT_APPLICABLE} />
                  <div>{t('NOT_APPLICABLE')}</div>
                </SmallLegendItem>
              </div>
            </PieLegend>
            <SmallPieWrapper>
              <MiniTitle>{t('attestationsBreakdown')}</MiniTitle>
              {isEmpty(attestationData) ? (
                <EmptyWrapper>
                  <EmptyView emptyMsg={t('noReportsCompleteBreakdownMsg')} />
                </EmptyWrapper>
              ) : (
                <VictoryPie
                  data={attestationData}
                  x="label"
                  y="count"
                  innerRadius={100}
                  padAngle={2}
                  labelRadius={125}
                  labelComponent={
                    <CustomLabel
                      hoveredStatus={hoveredAttestationPieStatus}
                      setHoverStatus={setHoveredAttestationPieStatus}
                    />
                  }
                  style={{
                    data: {
                      fill: ({ datum }) => datum.color,
                    },
                    labels: {
                      fontSize: 24,
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      fill: 'white',
                      background: ({ datum }) => datum.color,
                    },
                  }}
                  events={[
                    {
                      target: 'data',
                      eventHandlers: {
                        onMouseOver: () => {
                          return [
                            {
                              target: 'data',
                              mutation: ({ datum }) => setHoveredAttestationPieStatus(getStatusFromLabel(datum?.label)),
                            },
                            {
                              target: 'labels',
                              mutation: ({ datum }) => setHoveredAttestationPieStatus(getStatusFromLabel(datum?.label)),
                            },
                          ]
                        },
                        onMouseOut: () => {
                          return [
                            {
                              target: 'data',
                              mutation: ({ datum }) => setHoveredAttestationPieStatus(undefined),
                            },
                            {
                              target: 'labels',
                              mutation: ({ datum }) => setHoveredAttestationPieStatus(undefined),
                            },
                          ]
                        },
                      },
                    },
                  ]}
                />
              )}
            </SmallPieWrapper>
          </PiesList>
        </DataBox>
        {!!clickedMainPieStatus && !isEmpty(filteredCompanies) && isSelectedReportMostRecent ? (
          <DataBox area="countdown">
            <DataBoxTopRow>
              <DataBoxTitle>
                {t(`${clickedMainPieStatus}_data_box_title`, {
                  reportName: selectedReport.quarter,
                })}
              </DataBoxTitle>
              <CloseIcon onClick={() => setClickedMainPieStatus(undefined)}>
                <FontAwesomeIcon icon="times" />
              </CloseIcon>
            </DataBoxTopRow>
            <FilteredReportsWrapper>
              {map(filteredCompanies, co => (
                <CompanyItem status={clickedMainPieStatus} onClick={() => handleGoToCompany(co.id)}>
                  <CompanyIcon color={statusColors[clickedMainPieStatus]}>
                    <FontAwesomeIcon icon="building" />
                  </CompanyIcon>
                  <CompanyName>{co.name}</CompanyName>
                  <CompanyStatus>
                    {co.reportStatus === 'OVERDUE' ? (
                      <StatusBadge status={co.reportStatus} />
                    ) : (
                      <StatusBadge status={co.reportStatus} />
                    )}
                  </CompanyStatus>
                  <UsersWrapper>
                    {map(co.users, u => (
                      <Avatar
                        key={`contact-${co.id}-${u.id}`}
                        user={u}
                        status={u?.reportStatus}
                        isImageOnly
                        isCircleSmall
                      />
                    ))}
                  </UsersWrapper>
                </CompanyItem>
              ))}
            </FilteredReportsWrapper>
          </DataBox>
        ) : (
          (!isEmpty(metrics?.item?.reports) || isMockData) && (
            <DataBox area="countdown">
              <DataBoxTitle>{t('nextReportChartTitle')}</DataBoxTitle>
              <CountdownTimer date={nextReportSendDate} />
              <DateDueWrapper>{dayjs(nextReportSendDate).format('MMMM D, YYYY - h:mma')}</DateDueWrapper>
            </DataBox>
          )
        )}
      </DashboardContainer>
      <Modal
        isOpen={isEmpty(metrics?.item?.reports) && !isMockData}
        content={
          <DashboardEmptyContent>
            <h1>{t('dashboardEmptyModalTitle')}</h1>
            <p>{t('dashboardEmptyModalMsg')}</p>
            <h3>{t('nextReportChartTitle')}</h3>
            <CountdownTimer date={nextReportSendDate} />
            <DateDueWrapper>{dayjs(nextReportSendDate).format('MMMM D, YYYY - h:mma')}</DateDueWrapper>
          </DashboardEmptyContent>
        }
        hasOverlay={true}
      />
    </PageContent>
  )
}

export default DashboardPage
