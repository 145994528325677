import React from 'react'
import styled from 'styled-components'
import { colors } from 'utils/colors'

interface HorizontalRuleProps {
  size?: string
  lineStyle?: 'solid' | 'dashed'
  color?: string
  isHideLast?: boolean
  isPadded?: boolean
}

const HorizontalRuleElement = styled.hr<HorizontalRuleProps>`
  width: 100%;
  grid-column: 1 / -1;
  display: block;
  overflow: hidden;
  border-style: ${p => p.lineStyle || 'solid'};
  border-width: ${p => p.size || '1px'};
  border-color: ${p => p.color || colors.border};
  margin: ${p => (p.isPadded ? '0.5rem 0' : '0')};
  &:last-child {
    display: ${p => (p.isHideLast ? 'none' : 'block')};
  }
`

const HorizontalRule: React.FC<HorizontalRuleProps> = ({
  size,
  lineStyle,
  color,
  isHideLast,
  isPadded,
}: HorizontalRuleProps) => {
  return (
    <HorizontalRuleElement
      size={size}
      lineStyle={lineStyle}
      color={color}
      isHideLast={isHideLast}
      isPadded={isPadded}
    />
  )
}

export default HorizontalRule
