/**
 * dateDiff
 * Returns a detail object about the difference between two dates
 *
 * When providing custom units, provide them in descending order (eg week,day,hour; not hour,day,week)
 *
 * Example usage:
 * dateDiff(new Date(1995, 10, 18), new Date(2012, 9, 1), 'year', 'month')
 *
 * @param {Date} dateStart - date to compare to
 * @param {Date|string} [dateEnd=new Date()] - second date, can be used as unit param instead
 * @param {...string} [units=Object.keys(dateDiffDef)] - limits the returned object to provided keys
 */

interface Response {
  year?: number
  quarter?: number
  month?: number
  week?: number
  day?: number
  hour?: number
  minute?: number
  second?: number
  millisecond?: number
}
export const dateDiffDef = {
  year: 31536000000,
  quarter: 7776000000,
  month: 2592000000,
  week: 604800000,
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
  millisecond: 1,
}

const dateDiff = (
  dateStart: Date,
  dateEnd: Date | string = new Date(),
  ...units: string[]
): {
  [key: string]: number
} => {
  if (typeof dateEnd === 'string') dateEnd = new Date()

  let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime())

  return (units.length ? units : Object.keys(dateDiffDef)).reduce((res: any, key: string) => {
    const k = key as 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second' | 'millisecond'
    if (!dateDiffDef[k]) throw new Error('Unknown unit in dateDiff: ' + k)

    res[k] = Math.floor(delta / dateDiffDef[k])
    delta -= res[k] * dateDiffDef[k]
    return res
  }, {})
}

export default dateDiff
