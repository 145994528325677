import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { Auth0Context } from 'utils/auth0'
import { Client, ClientUser, Metrics, Invite, ScheduleCompany } from 'models/client'
import { pipe, keyBy, flatMap, find, map } from 'lodash/fp'

interface ClientAction {
  type: string
  payload: {
    errorMsg?: string
    client?: Client
    metrics?: Metrics
    invite?: Invite
    user?: ClientUser
    userId?: string
  }
}

const ctx = 'app/client'
const localhostClientId = '3f5f12f9-3acf-4d54-8ad9-b98353588d2a'
const localhostUrl = 'http://localhost:8080'

export const FIND_CLIENT_LOADING = `${ctx}/FIND_CLIENT_LOADING`
export const FIND_CLIENT_SUCCESS = `${ctx}/FIND_CLIENT_SUCCESS`
export const FIND_CLIENT_FAILURE = `${ctx}/FIND_CLIENT_FAILURE`

export const LIST_METRICS_LOADING = `${ctx}/LIST_METRICS_LOADING`
export const LIST_METRICS_SUCCESS = `${ctx}/LIST_METRICS_SUCCESS`
export const LIST_METRICS_FAILURE = `${ctx}/LIST_METRICS_FAILURE`

export const ADD_USER_LOADING = `${ctx}/ADD_USER_LOADING`
export const ADD_USER_SUCCESS = `${ctx}/ADD_USER_SUCCESS`
export const ADD_USER_FAILURE = `${ctx}/ADD_USER_FAILURE`

export const REMOVE_USER_LOADING = `${ctx}/REMOVE_USER_LOADING`
export const REMOVE_USER_SUCCESS = `${ctx}/REMOVE_USER_SUCCESS`
export const REMOVE_USER_FAILURE = `${ctx}/REMOVE_USER_FAILURE`

export const LIST_CONTACTS_LOADING = `${ctx}/LIST_CONTACTS_LOADING`
export const LIST_CONTACTS_SUCCESS = `${ctx}/LIST_CONTACTS_SUCCESS`
export const LIST_CONTACTS_FAILURE = `${ctx}/LIST_CONTACTS_FAILURE`

export const findClient = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_CLIENT_LOADING })

  const clientId = auth0Context.getClientId()

  const url = `${host}/v1/client/${clientId}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_CLIENT_SUCCESS,
        payload: {
          client: data.client,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: FIND_CLIENT_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const listMetrics = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: LIST_METRICS_LOADING })

  const clientId = auth0Context.getClientId()

  const url = `${host}/v1/client/${clientId}/metrics`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_METRICS_SUCCESS,
        payload: {
          metrics: data,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_METRICS_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const addUser = (auth0Context: Auth0Context, params: { name: string; email: string }) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: ADD_USER_LOADING })

  const clientId = auth0Context.getClientId()

  await auth0Context
    .makeRequest(`${host}/v1/client/${clientId}/user`, 'POST', { ...params })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: {
          invite: data.invite,
        },
      }),
    )
    .catch(err => dispatch({ type: ADD_USER_FAILURE, payload: { errorMsg: err.message } }))
}

export const removeUser = (auth0Context: Auth0Context, userId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: REMOVE_USER_LOADING })

  const clientId = auth0Context.getClientId()

  await auth0Context
    .makeRequest(`${host}/v1/client/${clientId}/user/${userId}`, 'DELETE')
    .then(() =>
      dispatch({
        type: REMOVE_USER_SUCCESS,
        payload: {
          userId,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: REMOVE_USER_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const listContacts = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  const scheduleHost = window.location.host.startsWith('localhost') ? localhostUrl : host

  dispatch({ type: LIST_CONTACTS_LOADING })
  try {
    const clientId = auth0Context.getClientId()
    const scheduleClient = window.location.host.startsWith('localhost') ? localhostClientId : clientId

    const babyyodaUrl = `${host}/v1/client/${clientId}/contacts`
    const babyyodaResponse = await auth0Context.makeRequest(babyyodaUrl, 'GET')
    const babyyodaContacts = await babyyodaResponse.json()

    const scheduleContactsUrl = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/contacts`
    const scheduleContactsResponse = await auth0Context.makeRequest(scheduleContactsUrl, 'GET')
    const scheduleContacts = await scheduleContactsResponse.json()

    const scheduleCompaniesUrl = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/companies`
    const scheduleCompaniesResponse = await auth0Context.makeRequest(scheduleCompaniesUrl, 'GET')
    const scheduleCompanies = await scheduleCompaniesResponse.json()

    dispatch({
      type: LIST_CONTACTS_SUCCESS,
      payload: {
        companiesMap: pipe(
          map((scheduleCo: ScheduleCompany) => {
            const babyyodaCompany = find(byCo => byCo.id === scheduleCo.db_id, babyyodaContacts)
            return {
              ...scheduleCo,
              users: babyyodaCompany?.users,
            }
          }),
          keyBy('id'),
        )(scheduleCompanies),
        contactsMap: pipe(
          flatMap((company: { id: string; users: ClientUser[] }) => {
            return flatMap((user: ClientUser) => {
              const scheduleUser = find(sc => sc.db_id === user.id, scheduleContacts)
              const scheduleCompany = find(co => co.db_id === company.id, scheduleCompanies)
              return {
                ...user,
                dbId: user.id,
                id: scheduleUser?.id,
                uuid: scheduleUser?.id,
                companyId: scheduleCompany?.id,
                companyDbId: company.id,
                companyName: scheduleCompany?.name,
              }
            }, company.users)
          }),
          keyBy('id'),
        )(babyyodaContacts),
      },
    })
  } catch (err) {
    dispatch({
      type: LIST_CONTACTS_FAILURE,
      payload: { errorMsg: err.message },
    })
  }
}
