import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { has, round } from 'lodash'
import { text, bg } from 'utils/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface NumberBadgesProps {
  totals: Record<string, number>
  isPercent?: boolean
}

const NumberBadgesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
`
const BadgeBox = styled.div`
  background: ${bg.secondary};
  color: ${text.main};
  text-align: center;
  border-radius: 1em;
  padding: 1rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const NumberLabel = styled.div`
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0.5em;
  white-space: normal;
  text-overflow: ellipsis;
`
const NumberValue = styled.div`
  font-weight: 600;
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    color: ${text.inactive};
    font-size: 0.5em;
    margin-left: 0.5em;
  }
`

const NumberBadges: React.FC<NumberBadgesProps> = ({ totals, isPercent }: NumberBadgesProps) => {
  const { t } = useTranslation()

  const formatNum = (val: number) => {
    const num = Math.abs(val)
    const MIN_THRESHOLD = 10000
    const MAX_THRESHOLD = 1e12

    if (num < MIN_THRESHOLD) {
      return round(num).toLocaleString()
    }
    if (num > MAX_THRESHOLD) {
      return '!'
    }
    const lookup = [
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var item = lookup
      .slice()
      .reverse()
      .find(item => num >= item.value)
    return item ? (num / item.value).toFixed().replace(rx, '$1') + item.symbol : '0'
  }

  const renderBadge = (value: number, key: string) => {
    return (
      <BadgeBox>
        <NumberLabel>{t(`numberLabel_${key}`)}</NumberLabel>
        <NumberValue>
          {formatNum(value)}
          {isPercent && <FontAwesomeIcon icon="percent" />}
        </NumberValue>
      </BadgeBox>
    )
  }

  return (
    <NumberBadgesWrapper>
      {has(totals, 'minValue') && renderBadge(totals?.minValue, 'minValue')}
      {has(totals, 'median') && renderBadge(totals?.median, 'median')}
      {has(totals, 'maxValue') && renderBadge(totals?.maxValue, 'maxValue')}
    </NumberBadgesWrapper>
  )
}

export default NumberBadges
