import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'
import { keyBy, reduce, map, find } from 'lodash'
import { MetricRequest } from 'models/metrics'

const ctx = 'api/metrics'

export const LIST_METRICS_GROUPS_LOADING = `${ctx}/LIST_METRICS_GROUPS_LOADING`
export const LIST_METRICS_GROUPS_SUCCESS = `${ctx}/LIST_METRICS_GROUPS_SUCCESS`
export const LIST_METRICS_GROUPS_FAILURE = `${ctx}/LIST_METRICS_GROUPS_FAILURE`

export const FETCH_METRICS_GROUP_DETAIL_LOADING = `${ctx}/FETCH_METRICS_GROUP_DETAIL_LOADING`
export const FETCH_METRICS_GROUP_DETAIL_SUCCESS = `${ctx}/FETCH_METRICS_GROUP_DETAIL_SUCCESS`
export const FETCH_METRICS_GROUP_DETAIL_FAILURE = `${ctx}/FETCH_METRICS_GROUP_DETAIL_FAILURE`

export const FETCH_METRICS_GROUP_HISTORY_LOADING = `${ctx}/FETCH_METRICS_GROUP_HISTORY_LOADING`
export const FETCH_METRICS_GROUP_HISTORY_SUCCESS = `${ctx}/FETCH_METRICS_GROUP_HISTORY_SUCCESS`
export const FETCH_METRICS_GROUP_HISTORY_FAILURE = `${ctx}/FETCH_METRICS_GROUP_HISTORY_FAILURE`

export const ADD_METRIC_LOADING = `${ctx}/ADD_METRIC_LOADING`
export const ADD_METRIC_SUCCESS = `${ctx}/ADD_METRIC_SUCCESS`
export const ADD_METRIC_FAILURE = `${ctx}/ADD_METRIC_FAILURE`

export const EDIT_METRIC_LOADING = `${ctx}/EDIT_METRIC_LOADING`
export const EDIT_METRIC_SUCCESS = `${ctx}/EDIT_METRIC_SUCCESS`
export const EDIT_METRIC_FAILURE = `${ctx}/EDIT_METRIC_FAILURE`

export const REMOVE_METRIC_LOADING = `${ctx}/REMOVE_METRIC_LOADING`
export const REMOVE_METRIC_SUCCESS = `${ctx}/REMOVE_METRIC_SUCCESS`
export const REMOVE_METRIC_FAILURE = `${ctx}/REMOVE_METRIC_FAILURE`

export const ADD_METRICS_GROUP_LOADING = `${ctx}/ADD_METRICS_GROUP_LOADING`
export const ADD_METRICS_GROUP_SUCCESS = `${ctx}/ADD_METRICS_GROUP_SUCCESS`
export const ADD_METRICS_GROUP_FAILURE = `${ctx}/ADD_METRICS_GROUP_FAILURE`

export const EDIT_METRICS_GROUP_LOADING = `${ctx}/EDIT_METRICS_GROUP_LOADING`
export const EDIT_METRICS_GROUP_SUCCESS = `${ctx}/EDIT_METRICS_GROUP_SUCCESS`
export const EDIT_METRICS_GROUP_FAILURE = `${ctx}/EDIT_METRICS_GROUP_FAILURE`

export const REMOVE_METRICS_GROUP_LOADING = `${ctx}/REMOVE_METRICS_GROUP_LOADING`
export const REMOVE_METRICS_GROUP_SUCCESS = `${ctx}/REMOVE_METRICS_GROUP_SUCCESS`
export const REMOVE_METRICS_GROUP_FAILURE = `${ctx}/REMOVE_METRICS_GROUP_FAILURE`

export const DOWNLOAD_METRIC_GROUP_EXPORT_LOADING = `${ctx}/DOWNLOAD_METRIC_GROUP_EXPORT_LOADING`
export const DOWNLOAD_METRIC_GROUP_EXPORT_SUCCESS = `${ctx}/DOWNLOAD_METRIC_GROUP_EXPORT_SUCCESS`
export const DOWNLOAD_METRIC_GROUP_EXPORT_FAILURE = `${ctx}/DOWNLOAD_METRIC_GROUP_EXPORT_FAILURE`
export const CLEAR_METRIC_GROUP_EXPORT_STATUS = `${ctx}/CLEAR_METRIC_GROUP_EXPORT_STATUS`

export const CLEAR_METRICS_DETAIL = `${ctx}/CLEAR_METRICS_DETAIL`
export const CLEAR_ERRORS = `${ctx}/CLEAR_ERRORS`

export const listMetricsGroups = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: LIST_METRICS_GROUPS_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/groups`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_METRICS_GROUPS_SUCCESS,
        payload: {
          groupsMap: keyBy(data.groups, 'id'),
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_METRICS_GROUPS_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const fetchMetricsGroupDetail = (auth0Context: Auth0Context, groupId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FETCH_METRICS_GROUP_DETAIL_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/groups/${groupId}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data => {
      const groupMetrics = map(data.metricTotal, metric => {
        const metricGroupDetail = find(data.group.customMetrics, groupMetric => groupMetric.id === metric.id)
        return {
          ...metric,
          created: metricGroupDetail?.created,
        }
      })
      return dispatch({
        type: FETCH_METRICS_GROUP_DETAIL_SUCCESS,
        payload: {
          groupDetail: data.group,
          groupMetrics,
        },
      })
    })
    .catch(err =>
      dispatch({
        type: FETCH_METRICS_GROUP_DETAIL_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const fetchMetricsGroupHistory = (
  auth0Context: Auth0Context,
  groupId: string,
  metricId: string,
  cadence: 'QUARTERLY' | 'YEARLY',
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: FETCH_METRICS_GROUP_HISTORY_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/groups/${groupId}/custom-metrics/${metricId}/history?cadence=${cadence}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data => {
      return dispatch({
        type: FETCH_METRICS_GROUP_HISTORY_SUCCESS,
        payload: {
          historyItemId: `${groupId}-${metricId}`,
          historyCadence: data.cadence,
          historyData: data.history,
        },
      })
    })
    .catch(err =>
      dispatch({
        type: FETCH_METRICS_GROUP_HISTORY_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const addMetric = (auth0Context: Auth0Context, payload: MetricRequest, groupId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: ADD_METRIC_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/custom-metrics`
  await auth0Context
    .makeRequest(url, 'POST', {
      ...payload,
      groupId,
    })
    .then(res =>
      dispatch({
        type: ADD_METRIC_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: ADD_METRIC_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const editMetric = (
  auth0Context: Auth0Context,
  customMetricId: string,
  payload: MetricRequest,
  groupId: string,
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: EDIT_METRIC_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/custom-metrics`
  await auth0Context
    .makeRequest(url, 'PUT', {
      ...payload,
      groupId,
      customMetricId,
    })
    .then(res =>
      dispatch({
        type: EDIT_METRIC_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: EDIT_METRIC_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const removeMetric = (
  auth0Context: Auth0Context,
  payload: {
    customMetricId: string
    groupId: string
  },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: REMOVE_METRIC_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/custom-metrics`
  await auth0Context
    .makeRequest(url, 'DELETE', payload)
    .then(res =>
      dispatch({
        type: REMOVE_METRIC_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: REMOVE_METRIC_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const addMetricsGroup = (
  auth0Context: Auth0Context,
  payload: {
    name: string
    companies: string[]
    groupId?: string
  },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: ADD_METRICS_GROUP_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/groups`

  await auth0Context
    .makeRequest(url, 'POST', payload)
    .then(res =>
      dispatch({
        type: ADD_METRICS_GROUP_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: ADD_METRICS_GROUP_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const editMetricsGroup = (
  auth0Context: Auth0Context,
  payload: {
    name: string
    companies: string[]
  },
  groupId: string,
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: EDIT_METRICS_GROUP_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/groups/${groupId}`

  await auth0Context
    .makeRequest(url, 'PUT', payload)
    .then(res =>
      dispatch({
        type: EDIT_METRICS_GROUP_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: EDIT_METRICS_GROUP_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const removeMetricsGroup = (auth0Context: Auth0Context, groupId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: REMOVE_METRICS_GROUP_LOADING })
  const clientId = auth0Context.getClientId()
  let url = `${host}/v1/client/${clientId}/groups/${groupId}`

  await auth0Context
    .makeRequest(url, 'DELETE')
    .then(res =>
      dispatch({
        type: REMOVE_METRICS_GROUP_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: REMOVE_METRICS_GROUP_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const downloadMetricExport = (
  auth0Context: Auth0Context,
  metricGroupId: string,
  detailType: 'overview' | 'company' | 'user',
  hidden: string[],
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  const clientId = auth0Context.getClientId()
  console.log('dispatch downloadMetricExport', clientId, metricGroupId)
  dispatch({ type: DOWNLOAD_METRIC_GROUP_EXPORT_LOADING })

  const excludedParams = reduce(
    hidden,
    (acc, id) => {
      return `${acc}&hidden=${id}`
    },
    '',
  )

  const url = `${host}/v1/client/${clientId}/groups/${metricGroupId}/pdf?detailType=${detailType}${excludedParams}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(async res => {
      if (!res.ok) {
        throw new Error('DOWNLOAD ERROR')
      }

      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(await res.blob())
      a.download = 'report'
      document.body.appendChild(a)
      a.click()
      a.remove()

      dispatch({
        type: DOWNLOAD_METRIC_GROUP_EXPORT_SUCCESS,
      })
    })
    .catch(err =>
      dispatch({
        type: DOWNLOAD_METRIC_GROUP_EXPORT_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const clearErrors = () => async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
  dispatch({ type: CLEAR_ERRORS })
}
