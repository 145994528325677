import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { map, isEmpty, concat, includes, without, filter, isNil, size, difference } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm, FieldValues } from 'react-hook-form'

import Spinner from 'components/Spinner'
import Button from 'components/Button'
import ErrorView from 'components/ErrorView'
import { ActionBtns } from 'components/styles'

import { isMockData } from 'utils/brandConfig'
import { MetricsGroup } from 'models/metrics'
import { Company } from 'models/company'

import {
  AddEditWrapper,
  FormContainer,
  FieldsWrapper,
  Label,
  SelectInput,
  SelectOption,
  InputField,
  ErrorMsg,
  CompaniesSectionWrapper,
  CompaniesSectionTitleRow,
  OptionsWrapper,
  OptionsLabel,
  ItemOption,
  ItemLabel,
  AllCompaniesLabel,
  DeselectAllLink,
  ActionRow,
  DeleteLink,
  ErrorMsgWrapper,
} from './styles'

interface AddEditGroupFormProps {
  existingGroupDetail?: MetricsGroup
  groupsList: MetricsGroup[]
  companiesList: Company[]
  onSubmit: (data: { name: string; companies: string[]; groupId?: string }, id?: string) => void
  onClose: () => void
  onDelete: (id: string) => void
}

const AddEditGroupForm: React.FC<AddEditGroupFormProps> = ({
  existingGroupDetail,
  groupsList,
  companiesList,
  onSubmit,
  onClose,
  onDelete,
}: AddEditGroupFormProps) => {
  const { t } = useTranslation()
  const { error, loading } = useSelector((state: any) => state.metrics)
  const { handleSubmit, errors, register, triggerValidation, watch, clearError } = useForm({})

  const [isAllCompaniesSelected, setIsAllCompaniesSelected] = useState<boolean>(true)
  const [unselectedCompanies, setUnselectedCompanies] = useState<string[]>([])
  const watchedFields = watch()

  useEffect(() => {
    if (!isNil(existingGroupDetail?.companies)) {
      if (!isEmpty(existingGroupDetail?.companies)) {
        const existingUnselectedCompanies = filter(
          map(companiesList, c => c.id),
          coId => !includes(existingGroupDetail?.companies, coId),
        )
        setIsAllCompaniesSelected(false)
        setUnselectedCompanies(existingUnselectedCompanies)
        return
      }
      setIsAllCompaniesSelected(true)
      setUnselectedCompanies([])
    }
  }, [existingGroupDetail, companiesList])

  const isLastGroupCannotDelete = size(groupsList) < 2

  const groupsListOptions = map(groupsList, g => ({
    value: g.id,
    label: g.name,
  }))
  const allGroupsListOptions = concat(
    {
      value: 'DO_NOT_DUPLICATE',
      label: 'Start with Empty Group',
    },
    groupsListOptions,
  )

  const selectedCompaniesIds = map(
    filter(companiesList, c => !includes(unselectedCompanies, c.id)),
    co => co.id,
  )
  const isFormReady = !isEmpty(watchedFields?.name) && !isEmpty(selectedCompaniesIds)

  const isEditForm = !isNil(existingGroupDetail)

  const handleDeleteGroup = () => {
    if (isEditForm && !!existingGroupDetail?.id && !isLastGroupCannotDelete) {
      onDelete(existingGroupDetail?.id)
    }
  }

  const allCompaniesIds = map(companiesList, 'id')

  const handleDeselectAll = () => {
    console.log('allCompaniesIds', allCompaniesIds)
    setUnselectedCompanies(allCompaniesIds)
  }

  const handleSubmitInternally = (data: FieldValues) => {
    clearError()

    const baseData = {
      name: data.name,
      companies: isAllCompaniesSelected
        ? []
        : map(
            filter(companiesList, c => !includes(unselectedCompanies, c.id)),
            co => co.id,
          ),
    }

    if (isEditForm && !isNil(existingGroupDetail?.id)) {
      return onSubmit(baseData, existingGroupDetail?.id)
    }
    if (data.groupId === 'DO_NOT_DUPLICATE') {
      return onSubmit(baseData, 'NEW_GROUP')
    }
    return onSubmit(
      {
        ...baseData,
        groupId: data.groupId,
      },
      'NEW_GROUP',
    )
  }

  const handleCancel = () => {
    clearError()
    onClose()
  }

  const toggleAllCompanies = () => {
    if (!isAllCompaniesSelected) {
      setUnselectedCompanies([])
    }
    setIsAllCompaniesSelected(!isAllCompaniesSelected)
  }
  const toggleCompany = (companyId: string) => {
    if (isAllCompaniesSelected) {
      return
    }
    if (includes(unselectedCompanies, companyId)) {
      setUnselectedCompanies(without(unselectedCompanies, companyId))
    } else {
      setUnselectedCompanies(concat(unselectedCompanies, companyId))
    }
  }

  const isLoading = includes(loading, 'ADD_METRICS_GROUP') || includes(loading, 'EDIT_METRICS_GROUP')

  if (isLoading) return <Spinner />

  if (isEmpty(companiesList)) {
    return (
      <div>
        <ErrorMsgWrapper>{t('cannotAddMetricGroupWithNoCompaniesMsg')}</ErrorMsgWrapper>
        <ActionBtns>
          <Button onClick={handleCancel} size="small" color="secondary">
            {t('okayBtn')}
          </Button>
        </ActionBtns>
      </div>
    )
  }

  return (
    <AddEditWrapper>
      <FormContainer onSubmit={handleSubmit(handleSubmitInternally)}>
        <FieldsWrapper>
          <Label key="name" htmlFor="name">
            {t('addMetricNameFieldLabel')}
            <InputField
              name="name"
              type="text"
              defaultValue={existingGroupDetail?.name || ''}
              ref={register({ required: true, minLength: 2 })}
              onBlur={() => triggerValidation('name')}
            />

            {errors['name'] && errors['name']?.type && (
              <ErrorMsg>
                {errors[
                  errors['name'].type as 'required' | 'min' | 'max' | 'minLength' | 'maxLength' | 'pattern' | 'validate'
                ] || t(`${'name'}_${errors['name'].type}`)}
              </ErrorMsg>
            )}
          </Label>

          {!isEditForm && (
            <Label key="groupId" htmlFor="groupId">
              {t('addEditGroupToCopyFieldLabel')}
              <SelectInput
                name="groupId"
                ref={register({ required: true })}
                onBlur={() => triggerValidation('groupId')}
              >
                {map(allGroupsListOptions, ({ value, label }) => (
                  <SelectOption key={`option-${value}`} value={value}>
                    {label}
                  </SelectOption>
                ))}
              </SelectInput>

              {errors['groupId'] && errors['groupId']?.type && (
                <ErrorMsg>
                  {errors[
                    errors['groupId'].type as
                      | 'required'
                      | 'min'
                      | 'max'
                      | 'minLength'
                      | 'maxLength'
                      | 'pattern'
                      | 'validate'
                  ] || t(`${'groupId'}_${errors['groupId'].type}`)}
                </ErrorMsg>
              )}
            </Label>
          )}

          <CompaniesSectionWrapper>
            <CompaniesSectionTitleRow>
              <OptionsLabel>{t('addEditGroupCompaniesFieldLabel')}</OptionsLabel>
              <ItemOption onClick={toggleAllCompanies} isSelected={isAllCompaniesSelected}>
                {isAllCompaniesSelected ? <FontAwesomeIcon icon="check-square" /> : <FontAwesomeIcon icon="square" />}
                <AllCompaniesLabel isSelected={isAllCompaniesSelected}>
                  {t('includeAllCompaniesLabel')}
                </AllCompaniesLabel>
              </ItemOption>
            </CompaniesSectionTitleRow>
            <OptionsWrapper>
              {map(companiesList, co => {
                const isSelected = !includes(unselectedCompanies, co.id)
                const Icon = isSelected ? <FontAwesomeIcon icon="check-square" /> : <FontAwesomeIcon icon="square" />

                return (
                  <ItemOption
                    key={`question-option-${co.id}`}
                    onClick={() => toggleCompany(co.id)}
                    isDisabled={isAllCompaniesSelected}
                    isSelected={isSelected}
                  >
                    {Icon}
                    <ItemLabel isDisabled={isAllCompaniesSelected}>{co.name}</ItemLabel>
                  </ItemOption>
                )
              })}
              {!isAllCompaniesSelected && (
                <DeselectAllLink
                  onClick={handleDeselectAll}
                  isInactive={isEmpty(difference(allCompaniesIds, unselectedCompanies))}
                >
                  {t('deselectAll')}
                </DeselectAllLink>
              )}
            </OptionsWrapper>
          </CompaniesSectionWrapper>
        </FieldsWrapper>

        <ActionRow>
          {isEditForm ? (
            <DeleteLink isDeleteInvalid={isLastGroupCannotDelete} onClick={handleDeleteGroup}>
              {isLastGroupCannotDelete ? <p>{t('cannotDeleteLastGroupMsg')}</p> : <p>{t('deleteGroupBtn')}</p>}
            </DeleteLink>
          ) : (
            <div />
          )}

          <ActionBtns isInGrid>
            <Button type="submit" size="small" color="success" isDisabled={!isFormReady}>
              {t('submitBtn')}
            </Button>
            <Button onClick={handleCancel} size="small" color="secondary">
              {t('cancelBtn')}
            </Button>
          </ActionBtns>
        </ActionRow>
        {!isMockData && (error?.ADD_METRICS_GROUP || error?.EDIT_METRICS_GROUP) && (
          <ErrorView serverResponse={error?.ADD_METRICS_GROUP || error?.EDIT_METRICS_GROUP} hideContactMsg />
        )}
      </FormContainer>
    </AddEditWrapper>
  )
}

export default AddEditGroupForm
