import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from 'utils/locales/common.json'
const availableLanguages = ['en']

// the translations
// (tip move them in a JSON file and import them)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    whitelist: availableLanguages,
    keySeparator: false, // we do not use keys in form messages.welcome
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
