import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map, orderBy, sortBy, last, filter, concat, uniqWith, reverse, size } from 'lodash'
import { isMockData } from 'utils/brandConfig'

import { validateEmail } from 'utils/stringMethods'
import { findClient, addUser, removeUser } from 'actions/clientActions'
import { ClientUser } from 'models/client'
import { bg } from 'utils/colors'

import ErrorView from 'components/ErrorView'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import Form from 'components/Form/GoodForm'
import HorizontalRule from 'components/HorizontalRule'
import { ContentBoxTitle, FormWrapper } from 'components/styles'
import { AdminTable, UserButton, InviteMsg } from './styles'
import { mockAdminUsers, mockAdminInvites } from '../../mockData/mockAdminUsers'

const AdminMgmt: React.FC = () => {
  const { t } = useTranslation()
  const [isNewFormShown, setIsNewFormShown] = React.useState<boolean>(false)

  const dispatch = useDispatch()
  const auth0Context = useAuth0()

  const { client } = useSelector((state: any) => state.client)

  React.useEffect(() => {
    dispatch(findClient(auth0Context))
  }, [dispatch, auth0Context])

  const handleSubmitNewUser = ({ name, email }: { name: string; email: string }): void => {
    console.log('handleSubmitNewUser', { name, email })
    dispatch(addUser(auth0Context, { name, email }))
    setIsNewFormShown(false)
  }

  const handleUserAction = (userId: string, expiredUserDetails?: { name: string; email: string }): void => {
    console.log('handleUserAction', userId)
    if (expiredUserDetails) {
      dispatch(addUser(auth0Context, expiredUserDetails))
    } else {
      dispatch(removeUser(auth0Context, userId))
    }
  }

  const renderUsers = (usersList: ClientUser[], userType: string) => {
    const sortedList = sortBy(usersList, (usr: ClientUser) => last(usr.events)?.timestamp)
    const sortExpiredList = orderBy(sortedList, 'status', 'asc')

    return map(sortExpiredList, usr => {
      const expiredUserDetails = usr.status === 'EXPIRED' ? { name: usr.name, email: usr.email } : undefined
      return (
        <React.Fragment key={`adminUser-${usr.id}-${usr.status}`}>
          <Avatar
            user={usr}
            secondaryText={usr.email}
            isInvite={userType === 'INVITE'}
            isExpired={usr.status === 'EXPIRED'}
            isRemoved={userType === 'REMOVED'}
            backgroundColor={bg.contentBox}
          />
          <UserButton>
            {userType !== 'REMOVED' && (
              <Button onClick={() => handleUserAction(usr.id, expiredUserDetails)} isSimple color="error">
                {expiredUserDetails ? <InviteMsg>{t('inviteAgain')}</InviteMsg> : <FontAwesomeIcon icon="trash-alt" />}
              </Button>
            )}
          </UserButton>
          <HorizontalRule lineStyle="dashed" isHideLast />
        </React.Fragment>
      )
    })
  }

  // should not need to show empty view - at least one admin user *should* exist

  const usersList: ClientUser[] = !isMockData ? client.item.users : mockAdminUsers
  const invitesList = !isMockData ? client.item.invites : mockAdminInvites

  const deduplicateUsersList = reverse(
    uniqWith(
      reverse(uniqWith(usersList, (a, b) => a.id === b.id && size(a.events) <= size(b.events))),
      (c, d) => c.id === d.id && size(c.events) <= size(d.events),
    ),
  )

  let AdminUsersList
  if (client.error && !isMockData) {
    AdminUsersList = <ErrorView serverResponse={t(client.error)} errorMsg={t('findClientError')} />
  } else if (client.isFetching) {
    return <Spinner />
  } else {
    AdminUsersList = (
      <AdminTable>
        {renderUsers(
          filter(deduplicateUsersList, (u: ClientUser) => u?.status !== 'REMOVED'),
          'USER',
        )}
        {renderUsers(
          filter(invitesList, (u: ClientUser) => u?.status !== 'REMOVED'),
          'INVITE',
        )}
        {renderUsers(
          filter(concat(deduplicateUsersList, invitesList), (u: ClientUser) => u?.status === 'REMOVED'),
          'REMOVED',
        )}
      </AdminTable>
    )
  }

  return (
    <>
      <ContentBoxTitle>
        {t('adminMgmtTitle', { portfolioName: client.name })}
        {!isNewFormShown && (
          <Button onClick={() => setIsNewFormShown(true)} size="small" color="success">
            {t('addAdminBtn')}
          </Button>
        )}
      </ContentBoxTitle>
      <FormWrapper isOpen={isNewFormShown}>
        <Form
          fields={[
            {
              type: 'text',
              name: 'name',
              initialValue: '',
              ref: { required: true, minLength: 1 },
              errors: {
                required: t('nameFieldRequired'),
                minLength: t('nameFieldRequired'),
              },
            },
            {
              type: 'text',
              name: 'email',
              initialValue: '',
              ref: { required: true, minLength: 1, pattern: validateEmail },
              errors: {
                required: t('emailFieldRequired'),
                minLength: t('emailFieldRequired'),
                pattern: t('emailFieldRequired'),
              },
            },
          ]}
          onSubmit={handleSubmitNewUser}
          onCancel={() => setIsNewFormShown(false)}
        />
      </FormWrapper>
      {AdminUsersList}
    </>
  )
}

export default AdminMgmt
