import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import Demo5LogoImg from 'components/Demo5Logo/fxyq.png'

interface Demo5LogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Demo5Logo: React.FC<Demo5LogoProps> = ({ brandName }: Demo5LogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={Demo5LogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default Demo5Logo
