import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'
import { CLEAR_METRICS_DETAIL } from 'actions/metricsActions'

const ctx = 'api/report'

export const LIST_REPORTS_LOADING = `${ctx}/LIST_REPORTS_LOADING`
export const LIST_REPORTS_SUCCESS = `${ctx}/LIST_REPORTS_SUCCESS`
export const LIST_REPORTS_FAILURE = `${ctx}/LIST_REPORTS_FAILURE`

export const FIND_REPORT_LOADING = `${ctx}/FIND_REPORT_LOADING`
export const FIND_REPORT_SUCCESS = `${ctx}/FIND_REPORT_SUCCESS`
export const FIND_REPORT_FAILURE = `${ctx}/FIND_REPORT_FAILURE`

export const UPDATE_EFFECTIVE_DATE_PENDING = `${ctx}/UPDATE_EFFECTIVE_DATE_PENDING`
export const UPDATE_EFFECTIVE_DATE_SUCCESS = `${ctx}/UPDATE_EFFECTIVE_DATE_SUCCESS`
export const UPDATE_EFFECTIVE_DATE_FAILURE = `${ctx}/UPDATE_EFFECTIVE_DATE_FAILURE`

export const SEND_AD_HOC_REPORT_PENDING = `${ctx}/SEND_AD_HOC_REPORT_PENDING`
export const SEND_AD_HOC_REPORT_SUCCESS = `${ctx}/SEND_AD_HOC_REPORT_SUCCESS`
export const SEND_AD_HOC_REPORT_FAILURE = `${ctx}/SEND_AD_HOC_REPORT_FAILURE`
export const CLEAR_AD_HOC_REPORT_STATUS = `${ctx}/CLEAR_AD_HOC_REPORT_STATUS`

export const REMOVE_REPORT_PENDING = `${ctx}/REMOVE_REPORT_PENDING`
export const REMOVE_REPORT_SUCCESS = `${ctx}/REMOVE_REPORT_SUCCESS`
export const REMOVE_REPORT_FAILURE = `${ctx}/REMOVE_REPORT_FAILURE`

export const CLEAR_ERRORS = `${ctx}/CLEAR_ERRORS`

export const listReports = (
  auth0Context: Auth0Context,
  companyId: string,
  params?: { page?: number; perPage?: number; includeTotal?: boolean },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: LIST_REPORTS_LOADING })

  let url = `${host}/v1/report?companyId=${companyId}`
  if (params?.page) url += `&page=${params?.page}`
  if (params?.perPage) url += `&perPage=${params?.perPage}`
  if (params?.includeTotal) url += `&includeTotal=${params?.includeTotal}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_REPORTS_SUCCESS,
        payload: {
          page: data.page,
          perPage: data.perPage,
          totalItems: data.totalItems,
          items: data.items,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_REPORTS_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const findReport = (auth0Context: Auth0Context, reportId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_REPORT_LOADING })

  const url = `${host}/v1/report/${reportId}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_REPORT_SUCCESS,
        payload: {
          report: data.report,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: FIND_REPORT_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

interface updateEffectiveDatePayload {
  reportId: string
  effectiveDate: string | null
}

export const updateEffectiveDate = (auth0Context: Auth0Context, payload: updateEffectiveDatePayload) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  // const clientId = auth0Context.getClientId()

  dispatch({ type: UPDATE_EFFECTIVE_DATE_PENDING })

  const url = `${host}/v1/report/${payload.reportId}`

  await auth0Context
    .makeRequest(url, 'PUT', { effectiveDate: payload.effectiveDate })
    .then(res => {
      if (res.ok) {
        dispatch({ type: CLEAR_METRICS_DETAIL })
        return dispatch({
          type: UPDATE_EFFECTIVE_DATE_SUCCESS,
          payload,
        })
      }
    })
    .catch(
      err =>
        dispatch({
          type: UPDATE_EFFECTIVE_DATE_FAILURE,
          payload: { errorMsg: err.message },
        }),
      // dispatch({
      //   type: UPDATE_EFFECTIVE_DATE_SUCCESS,
      //   payload,
      // }),
    )
}

interface adHocReportPayload {
  users: string[]
  daysToComplete: number
  name: string
  companyId: string
  templateId: string
}

export const sendAdHocReport = (auth0Context: Auth0Context, payload: adHocReportPayload) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  const clientId = auth0Context.getClientId()

  dispatch({ type: SEND_AD_HOC_REPORT_PENDING })

  const url = `${host}/v1/report/adhoc`

  await auth0Context
    .makeRequest(url, 'POST', { ...payload, clientId })
    .then(res => {
      if (res.ok) {
        dispatch({ type: CLEAR_METRICS_DETAIL })
        return dispatch({
          type: SEND_AD_HOC_REPORT_SUCCESS,
        })
      }
    })
    .catch(err =>
      dispatch({
        type: SEND_AD_HOC_REPORT_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const removeReport = (auth0Context: Auth0Context, reportId: string, userId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({
    type: REMOVE_REPORT_PENDING,
    payload: { reportId, userId },
  })

  const url = `${host}/v1/report/${reportId}/${userId}/remove-user`

  await auth0Context
    .makeRequest(url, 'PUT')
    .then(res => {
      if (res.ok) {
        dispatch({ type: CLEAR_METRICS_DETAIL })
        return dispatch({
          type: REMOVE_REPORT_SUCCESS,
          payload: { reportId, userId },
        })
      }
    })
    .catch(err =>
      dispatch({
        type: REMOVE_REPORT_FAILURE,
        payload: { reportId, userId, errorMsg: err.message },
      }),
    )
}

export const clearErrors = () => async (dispatch: ThunkDispatch<{}, {}, Action>): Promise<void> => {
  dispatch({ type: CLEAR_ERRORS })
}
