import { Metric, MetricsGroup, QuestionMetricHistory } from 'models/metrics'
import { omit, without, concat } from 'lodash'

import {
  LIST_METRICS_GROUPS_LOADING,
  LIST_METRICS_GROUPS_SUCCESS,
  LIST_METRICS_GROUPS_FAILURE,
  FETCH_METRICS_GROUP_DETAIL_LOADING,
  FETCH_METRICS_GROUP_DETAIL_SUCCESS,
  FETCH_METRICS_GROUP_DETAIL_FAILURE,
  FETCH_METRICS_GROUP_HISTORY_LOADING,
  FETCH_METRICS_GROUP_HISTORY_SUCCESS,
  FETCH_METRICS_GROUP_HISTORY_FAILURE,
  ADD_METRIC_LOADING,
  ADD_METRIC_SUCCESS,
  ADD_METRIC_FAILURE,
  EDIT_METRIC_LOADING,
  EDIT_METRIC_SUCCESS,
  EDIT_METRIC_FAILURE,
  REMOVE_METRIC_LOADING,
  REMOVE_METRIC_SUCCESS,
  REMOVE_METRIC_FAILURE,
  ADD_METRICS_GROUP_LOADING,
  ADD_METRICS_GROUP_SUCCESS,
  ADD_METRICS_GROUP_FAILURE,
  EDIT_METRICS_GROUP_LOADING,
  EDIT_METRICS_GROUP_SUCCESS,
  EDIT_METRICS_GROUP_FAILURE,
  REMOVE_METRICS_GROUP_LOADING,
  REMOVE_METRICS_GROUP_SUCCESS,
  REMOVE_METRICS_GROUP_FAILURE,
  DOWNLOAD_METRIC_GROUP_EXPORT_LOADING,
  DOWNLOAD_METRIC_GROUP_EXPORT_SUCCESS,
  DOWNLOAD_METRIC_GROUP_EXPORT_FAILURE,
  CLEAR_METRIC_GROUP_EXPORT_STATUS,
  CLEAR_METRICS_DETAIL,
  CLEAR_ERRORS,
} from 'actions/metricsActions'

interface MetricsState {
  error: Record<string, string>
  loading: string[]
  isMetricsUpdated: boolean
  groupsMap?: Record<string, MetricsGroup>
  groupMetrics?: Metric[]
  companies?: string[]
  groupDetail?: {
    id: string
    name: string
    created: string
    companies: string[]
  }
  historyItemId?: string
  historyData?: QuestionMetricHistory[]
  historyCadence?: 'QUARTERLY' | 'YEARLY'
  exportMetricPdf: {
    isFetching: boolean
    isComplete: boolean
    error?: string
  }
}

const initState = {
  error: {},
  loading: [],
  isMetricsUpdated: false,
  groupsMap: undefined,
  groupMetrics: undefined,
  groupDetail: undefined,
  historyItemId: undefined,
  historyData: undefined,
  historyCadence: undefined,
  exportMetricPdf: {
    isFetching: false,
    isComplete: false,
  },
}

export const metricsReducer = (state = initState, action: any): MetricsState => {
  switch (action.type) {
    case LIST_METRICS_GROUPS_LOADING:
      return {
        ...state,
        error: omit(state.error, 'LIST_METRICS'),
        loading: concat(state.loading, 'LIST_METRICS'),
        isMetricsUpdated: false,
      }

    case LIST_METRICS_GROUPS_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'LIST_METRICS'),
        loading: without(state.loading, 'LIST_METRICS'),
        groupsMap: action.payload.groupsMap,
      }

    case LIST_METRICS_GROUPS_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          LIST_METRICS: action.payload.errorMsg,
        },
        loading: without(state.loading, 'LIST_METRICS'),
        groupsMap: {},
      }

    case FETCH_METRICS_GROUP_DETAIL_LOADING:
      return {
        ...state,
        error: omit(state.error, 'FETCH_METRICS_GROUP_DETAIL'),
        loading: concat(state.loading, 'FETCH_METRICS_GROUP_DETAIL'),
        isMetricsUpdated: false,
      }

    case FETCH_METRICS_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'FETCH_METRICS_GROUP_DETAIL'),
        loading: without(state.loading, 'FETCH_METRICS_GROUP_DETAIL'),
        groupDetail: action.payload.groupDetail,
        groupMetrics: action.payload.groupMetrics,
      }

    case FETCH_METRICS_GROUP_DETAIL_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          FETCH_METRICS_GROUP_DETAIL: action.payload.errorMsg,
        },
        loading: without(state.loading, 'FETCH_METRICS_GROUP_DETAIL'),
        groupMetrics: undefined,
      }

    case FETCH_METRICS_GROUP_HISTORY_LOADING:
      return {
        ...state,
        error: omit(state.error, 'FETCH_METRICS_GROUP_HISTORY'),
        loading: concat(state.loading, 'FETCH_METRICS_GROUP_HISTORY'),
        isMetricsUpdated: false,
      }

    case FETCH_METRICS_GROUP_HISTORY_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'FETCH_METRICS_GROUP_HISTORY'),
        loading: without(state.loading, 'FETCH_METRICS_GROUP_HISTORY'),
        historyItemId: action.payload.historyItemId,
        historyData: action.payload.historyData,
        historyCadence: action.payload.historyCadence,
      }

    case FETCH_METRICS_GROUP_HISTORY_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          FETCH_METRICS_GROUP_HISTORY: action.payload.errorMsg,
        },
        loading: without(state.loading, 'FETCH_METRICS_GROUP_HISTORY'),
        historyItemId: undefined,
        historyData: undefined,
        historyCadence: undefined,
      }

    case ADD_METRIC_LOADING:
      return {
        ...state,
        error: omit(state.error, 'ADD_METRIC'),
        loading: concat(state.loading, 'ADD_METRIC'),
        isMetricsUpdated: false,
      }

    case ADD_METRIC_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'ADD_METRIC'),
        loading: without(state.loading, 'ADD_METRIC'),
        isMetricsUpdated: true,
      }

    case ADD_METRIC_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          ADD_METRIC: action.payload.errorMsg,
        },
        loading: without(state.loading, 'ADD_METRIC'),
      }

    case EDIT_METRIC_LOADING:
      return {
        ...state,
        error: omit(state.error, 'EDIT_METRIC'),
        loading: concat(state.loading, 'EDIT_METRIC'),
        isMetricsUpdated: false,
      }

    case EDIT_METRIC_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'EDIT_METRIC'),
        loading: without(state.loading, 'EDIT_METRIC'),
        isMetricsUpdated: true,
      }

    case EDIT_METRIC_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          EDIT_METRIC: action.payload.errorMsg,
        },
        loading: without(state.loading, 'EDIT_METRIC'),
      }

    case REMOVE_METRIC_LOADING:
      return {
        ...state,
        error: omit(state.error, 'REMOVE_METRIC'),
        loading: concat(state.loading, 'REMOVE_METRIC'),
        isMetricsUpdated: false,
      }

    case REMOVE_METRIC_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'REMOVE_METRIC'),
        loading: without(state.loading, 'REMOVE_METRIC'),
        isMetricsUpdated: true,
      }

    case REMOVE_METRIC_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          REMOVE_METRIC: action.payload.errorMsg,
        },
        loading: without(state.loading, 'REMOVE_METRIC'),
      }

    case ADD_METRICS_GROUP_LOADING:
      return {
        ...state,
        error: omit(state.error, 'ADD_METRICS_GROUP'),
        loading: concat(state.loading, 'ADD_METRICS_GROUP'),
        isMetricsUpdated: false,
      }

    case ADD_METRICS_GROUP_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'ADD_METRICS_GROUP'),
        loading: without(state.loading, 'ADD_METRICS_GROUP'),
        isMetricsUpdated: true,
      }

    case ADD_METRICS_GROUP_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          ADD_METRICS_GROUP: action.payload.errorMsg,
        },
        loading: without(state.loading, 'ADD_METRICS_GROUP'),
      }

    case EDIT_METRICS_GROUP_LOADING:
      return {
        ...state,
        error: omit(state.error, 'EDIT_METRICS_GROUP'),
        loading: concat(state.loading, 'EDIT_METRICS_GROUP'),
        isMetricsUpdated: false,
      }

    case EDIT_METRICS_GROUP_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'EDIT_METRICS_GROUP'),
        loading: without(state.loading, 'EDIT_METRICS_GROUP'),
        isMetricsUpdated: true,
      }

    case EDIT_METRICS_GROUP_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          EDIT_METRICS_GROUP: action.payload.errorMsg,
        },
        loading: without(state.loading, 'EDIT_METRICS_GROUP'),
      }

    case REMOVE_METRICS_GROUP_LOADING:
      return {
        ...state,
        error: omit(state.error, 'REMOVE_METRICS_GROUP'),
        loading: concat(state.loading, 'REMOVE_METRICS_GROUP'),
        isMetricsUpdated: false,
      }

    case REMOVE_METRICS_GROUP_SUCCESS:
      return {
        ...state,
        error: omit(state.error, 'REMOVE_METRICS_GROUP'),
        loading: without(state.loading, 'REMOVE_METRICS_GROUP'),
        isMetricsUpdated: true,
      }

    case REMOVE_METRICS_GROUP_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          REMOVE_METRICS_GROUP: action.payload.errorMsg,
        },
        loading: without(state.loading, 'REMOVE_METRICS_GROUP'),
      }

    case DOWNLOAD_METRIC_GROUP_EXPORT_LOADING:
      return {
        ...state,
        exportMetricPdf: {
          isFetching: true,
          isComplete: false,
          error: undefined,
        },
      }
    case DOWNLOAD_METRIC_GROUP_EXPORT_SUCCESS:
      return {
        ...state,
        exportMetricPdf: {
          isFetching: false,
          isComplete: true,
          error: undefined,
        },
      }
    case DOWNLOAD_METRIC_GROUP_EXPORT_FAILURE:
      return {
        ...state,
        exportMetricPdf: {
          isFetching: false,
          isComplete: true,
          error: action.payload.errorMsg,
        },
      }
    case CLEAR_METRIC_GROUP_EXPORT_STATUS:
      return {
        ...state,
        exportMetricPdf: {
          isFetching: false,
          isComplete: false,
          error: undefined,
        },
      }
    case CLEAR_METRICS_DETAIL:
      return {
        ...state,
        isMetricsUpdated: false,
        groupsMap: undefined,
        groupMetrics: undefined,
        groupDetail: undefined,
        historyItemId: undefined,
        historyData: undefined,
        historyCadence: undefined,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
      }

    default:
      return state
  }
}
