import { combineReducers } from 'redux'

import { clientReducer } from './clientReducer'
import { templateReducer } from './templateReducer'
import { companyReducer } from './companyReducer'
import { reportReducer } from './reportReducer'
import { userReportReducer } from './userReportReducer'
import { userReducer } from './userReducer'
import { scheduleReducer } from './scheduleReducer'
import { metricsReducer } from './metricsReducer'

const rootReducer = combineReducers({
  client: clientReducer,
  templates: templateReducer,
  companies: companyReducer,
  reports: reportReducer,
  userReports: userReportReducer,
  user: userReducer,
  schedule: scheduleReducer,
  metrics: metricsReducer,
})

export default rootReducer
