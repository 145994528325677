import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import CompanyActions from 'actions/companyActions'

import { useTranslation } from 'react-i18next'
import { ReportUser } from 'models/company'
import { map, countBy, isEmpty, find, filter, size, isNil } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validateEmail } from 'utils/stringMethods'
import { isMockData } from 'utils/brandConfig'
import { mockCompanies } from 'mockData/mockCompanies'

import Button from 'components/Button'
import Form from 'components/Form/GoodForm'
import Avatar from 'components/Avatar'
import Spinner from 'components/Spinner'
import EmptyView from 'components/EmptyView'
import HorizontalRule from 'components/HorizontalRule'
import ConfirmDialog from 'components/ConfirmDialog'

import { ContentBoxLists, ColumnHeading, FormWrapper } from 'components/styles'
import {
  UsersTable,
  UserEmail,
  LastUserCell,
  UserStatusWrapper,
  UserStatus,
  UserRemovedStatus,
  UserButton,
  ToggleRemovedVisibility,
} from './styles'

interface UsersListProps {
  companyId: string
}

const UsersList: React.FC<UsersListProps> = ({ companyId }: UsersListProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { usersList, companyDetail } = useSelector((state: any) => state.companies)
  const [isNewFormShown, setIsNewFormShown] = useState<boolean>(false)
  const [isRemovedUsersVisible, setIsRemovedUsersVisible] = useState<boolean>(false)
  const [confirmRemoveUserId, setConfirmRemoveUserId] = useState<string | undefined>()

  const mockCompany = find(mockCompanies, co => co.id === companyId)
  const users = !isMockData ? usersList.items : mockCompany?.users
  const activeUsers = filter(users, u => u.status !== 'REMOVED')
  const removedUsers = filter(users, u => u.status === 'REMOVED')

  useEffect(() => {
    setIsNewFormShown(!!usersList?.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList, companyDetail])

  const handleAddReportUser = (user: { email: string; name: string }): void => {
    console.log('handleAddReportUser', companyId, user)
    dispatch(CompanyActions.addReportUser(auth0Context, companyId, user))
  }

  const handleConfirmRemoveReportUser = (userId: string): void => {
    setConfirmRemoveUserId(userId)
  }

  const handleRemoveReportUser = (userId: string | undefined): void => {
    if (userId) {
      dispatch(CompanyActions.removeReportUser(auth0Context, companyId, userId))
    }
    setConfirmRemoveUserId(undefined)
  }

  const renderUser = (user: ReportUser) => {
    const reportCounts = countBy(user.reportStatuses)
    const statusMsg =
      user.status === 'REMOVED' ? (
        <UserRemovedStatus>{t('removedReportUserMsg')}</UserRemovedStatus>
      ) : reportCounts.PENDING > 0 && reportCounts.OVERDUE > 0 ? (
        <>
          <UserStatus status={'OVERDUE'}>{t('overdueReportsMsg', { count: reportCounts.OVERDUE })}</UserStatus>
          <UserStatus status={'PENDING'}>{t('pendingReportsMsg', { count: reportCounts.PENDING })}</UserStatus>
        </>
      ) : reportCounts.PENDING > 0 ? (
        <UserStatus status={'PENDING'}>{t('pendingReportsMsg', { count: reportCounts.PENDING })}</UserStatus>
      ) : reportCounts.OVERDUE > 0 ? (
        <UserStatus status={'OVERDUE'}>{t('overdueReportsMsg', { count: reportCounts.OVERDUE })}</UserStatus>
      ) : reportCounts.COMPLIANT > 0 || reportCounts.NON_COMPLIANT > 0 || reportCounts.COMPLETE > 0 ? (
        <UserStatus status={'COMPLETE'}>{t('reportsCompleteMsg')}</UserStatus>
      ) : (
        <UserStatus status={'INACTIVE'}>{t('noReportsMsg')}</UserStatus>
      )

    return (
      <React.Fragment key={`reportUser-${user.id}`}>
        <Avatar user={user} isRemoved={user.status === 'REMOVED'} isCircleSmall />
        <UserEmail>{user.email}</UserEmail>
        <LastUserCell>
          <UserStatusWrapper>{statusMsg}</UserStatusWrapper>
          <UserButton>
            {user.status !== 'REMOVED' && (
              <Button onClick={() => handleConfirmRemoveReportUser(user.id)} isSimple color="error">
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            )}
          </UserButton>
        </LastUserCell>
        <HorizontalRule lineStyle="dashed" isHideLast />
      </React.Fragment>
    )
  }

  let content
  if (usersList.isFetching) {
    content = <Spinner />
  } else if (isEmpty(users)) {
    content = (
      <>
        <div></div>
        <EmptyView emptyMsg={t('noUsersMsg')} />
      </>
    )
  } else {
    content = (
      <>
        {map(activeUsers, u => renderUser(u))}
        {size(removedUsers) < 2 ? (
          map(removedUsers, u => renderUser(u))
        ) : (
          <>
            {isRemovedUsersVisible && map(removedUsers, u => renderUser(u))}
            <ToggleRemovedVisibility onClick={() => setIsRemovedUsersVisible(!isRemovedUsersVisible)}>
              {isRemovedUsersVisible ? t('hideRemovedUsers') : t('showRemovedUsers')}
            </ToggleRemovedVisibility>
          </>
        )}
      </>
    )
  }

  return (
    <ContentBoxLists>
      <UsersTable>
        <ColumnHeading isLeftAligned size="lg">
          {t('userNameCol')}
        </ColumnHeading>
        <ColumnHeading isLeftAligned>{t('userEmailCol')}</ColumnHeading>
        <ColumnHeading isLeftAligned isFlex>
          {t('userStatusCol')}
          {companyDetail?.item?.status === 'ENABLED' && !isNewFormShown && (
            <Button onClick={() => setIsNewFormShown(true)} size="small" color="success">
              {t('addUserBtn')}
            </Button>
          )}
        </ColumnHeading>

        <FormWrapper isOpen={isNewFormShown}>
          <Form
            onSubmit={handleAddReportUser}
            onCancel={() => setIsNewFormShown(false)}
            errorMsg={t(usersList.error)}
            fields={[
              {
                type: 'text',
                name: 'name',
                initialValue: '',
                ref: { required: true },
                errors: { required: t('nameFieldRequired') },
              },
              {
                type: 'text',
                name: 'email',
                initialValue: '',
                ref: { required: true, pattern: validateEmail },
                errors: {
                  required: t('emailFieldRequired'),
                  pattern: t('emailFieldRequired'),
                },
              },
            ]}
          />
        </FormWrapper>
        {content}
      </UsersTable>
      <ConfirmDialog
        isOpen={!isNil(confirmRemoveUserId)}
        confirmText={t('confirmRemoveContactMsg')}
        onConfirm={() => handleRemoveReportUser(confirmRemoveUserId)}
        onCancel={() => setConfirmRemoveUserId(undefined)}
      />
    </ContentBoxLists>
  )
}

export default UsersList
