import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import Demo3LogoImg from 'components/Demo3Logo/dxyq.png'

interface Demo3LogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Demo3Logo: React.FC<Demo3LogoProps> = ({ brandName }: Demo3LogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={Demo3LogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default Demo3Logo
