import React, { useState, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Checkbox } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isEmpty, size, map, reduce, isInteger, filter, concat, isNil, has, find, includes, keys } from 'lodash'
import { v4 as uuid } from 'uuid'

import {
  CustomSection as CustomSectionType,
  Question,
  CustomQuestion as CustomQuestionType,
  Template,
  CustomQuestionTypes,
} from 'models/template'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { ColumnHeading, ToggleIcon } from 'components/styles'
import MetricQuestion from 'features/metrics/MetricQuestion'
import { exampleQuestionMetrics } from 'mockData/mockMetrics'
import CustomQuestion from './CustomQuestion'

import {
  CustomSectionContainer,
  ToggleEditIcon,
  CountLength,
  SectionName,
  SectionToggleName,
  HiddenContentBox,
  QuestionActions,
  RemoveBtnWrapper,
  QuestionMoreInfoBtn,
  PreviewQuestionBtn,
  EditNameWrapper,
  NewQuestionBox,
  NewBoxTitleRow,
  NewBoxTitle,
  NewQuestionTypesWrapper,
  HelpMeDecideWrapper,
  NewElementSelectBtn,
  NewElementSelectTitle,
  QuestionTypeDescription,
  QuestionTypeExample,
  QuestionTypeQuery,
  QuestionTypeAnswerString,
  QuestionTypeAnswerBullet,
  QuestionTypeAnswerBreakdown,
  QuestionTypeAnswerUpload,
  SortOrderWrapper,
  ChangeSortOrderWrapper,
  SortArrow,
  SortOrderValue,
  CustomQuestionQueryWrapper,
  CustomQuestionOptions,
  CustomSectionHeader,
  CustomQuestionRow,
  CustomFieldLabel,
  OptionalNote,
  QuestionMessage,
  CustomQuestionAnswerOptionsWrapper,
  CustomQuestionAnswerOption,
  CustomQuestionAddAnswerOption,
  RemoveOptionIcon,
  MoreInfoModal,
  ModalTitle,
  CloseIcon,
  MoreInfoTabsWrapper,
  MoreInfoTab,
  MoreInfoTypeDetail,
  ErrorMsg,
  InvisibleWrapper,
  MetricExampleWrapper,
  PreviewQuestionWrapper,
  AddQuestionWrapper,
} from './styles'

interface CustomSectionProps extends CustomSectionType {
  sIndex: number
  isOpen: boolean
  handleOpen: () => void
  isTitleEditing: boolean
  handleSetTitleEditing: (index: number | undefined) => void
  handleUpdateSectionName: (e: SyntheticEvent) => void
  isViewOnly: boolean | undefined
  isMockData: boolean
  control: any
  watch: any
  templateInPlace: Template
  onAddQuestion: (sIndex: number, question: CustomQuestionType) => void
  onRemoveQuestion: (questionToRemoveId: string) => void
  onUpdateQuestion: (updatedTemplateInPlace: Template) => void
  clearSubmitErrors: () => void
  onRemoveSection: () => void
  onChangeQuestionOrder: (initialIndex: number, incrementBy: number, sectionIndex: number) => void
  onChangeSectionOrder: (initialIndex: number, incrementBy: number) => void
}
type QuestionErrors = Record<string, string>
type SectionErrors = Record<string, QuestionErrors>

const QUESTION_TYPES: CustomQuestionTypes[] = [
  'MULTIPLE_CHOICE',
  'YES_NO',
  'MULTI_SELECT',
  'BREAKDOWN',
  'INTEGER',
  'PERCENTAGE',
  'SHORT_ANSWER',
  'FILE',
]

const emptyQuestion: Record<CustomQuestionTypes, CustomQuestionType> = {
  MULTIPLE_CHOICE: {
    id: uuid(),
    type: 'MULTIPLE_CHOICE',
    query: '',
    options: ['', ''],
    allowNA: false,
    allowComment: false,
  },
  BREAKDOWN: {
    id: uuid(),
    type: 'BREAKDOWN',
    query: '',
    options: ['', ''],
    allowNA: false,
    allowComment: false,
  },
  INTEGER: {
    id: uuid(),
    type: 'INTEGER',
    query: '',
    minValue: 0,
    allowNA: false,
    allowComment: false,
  },
  PERCENTAGE: {
    id: uuid(),
    type: 'PERCENTAGE',
    query: '',
    minValue: 0,
    maxValue: 100,
    allowNA: false,
    allowComment: false,
  },
  SHORT_ANSWER: {
    id: uuid(),
    type: 'SHORT_ANSWER',
    query: '',
    allowNA: false,
  },
  FILE: {
    id: uuid(),
    type: 'FILE',
    query: '',
    allowNA: false,
    allowComment: false,
  },
  MULTI_SELECT: {
    id: uuid(),
    type: 'MULTI_SELECT',
    query: '',
    options: ['', ''],
    allowNA: false,
    allowComment: false,
    allowOther: false,
  },
  YES_NO: {
    id: uuid(),
    type: 'YES_NO',
    query: '',
    options: ['Yes', 'No'],
    allowNA: false,
    allowComment: false,
  },
}

const CustomSection: React.FC<CustomSectionProps> = ({
  id,
  name,
  questions,
  sIndex,
  isOpen,
  handleOpen,
  isTitleEditing,
  handleSetTitleEditing,
  handleUpdateSectionName,
  isViewOnly,
  isMockData,
  control,
  watch,
  templateInPlace,
  onAddQuestion,
  onRemoveQuestion,
  onUpdateQuestion,
  clearSubmitErrors,
  onRemoveSection,
  onChangeQuestionOrder,
  onChangeSectionOrder,
}) => {
  const { t } = useTranslation()
  const [isNewQuestionOpen, setIsNewQuestionOpen] = useState<boolean>(false)
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<CustomQuestionTypes>(QUESTION_TYPES[0])
  const [questionErrors, setQuestionErrors] = useState<SectionErrors>({})
  const [previewContent, setPreviewContent] = useState<CustomQuestionType | undefined>(undefined)

  const handleAddQuestion = (e: SyntheticEvent) => {
    setQuestionErrors({})
    e.stopPropagation()
    setIsNewQuestionOpen(true)
  }

  const handleCancelAddQuestion = () => {
    setQuestionErrors({})
    setIsNewQuestionOpen(false)
  }

  const handleNewQuestionType = (typeId: CustomQuestionTypes) => {
    const emptyQ = find(emptyQuestion, q => q.type === typeId)
    if (emptyQ) {
      const newQuestion = Object.assign({}, emptyQ)
      setQuestionErrors({})
      setIsNewQuestionOpen(false)
      onAddQuestion(sIndex, {
        ...newQuestion,
        id: uuid(),
        type: typeId === 'YES_NO' ? 'MULTIPLE_CHOICE' : typeId,
      })
      console.log('handleNewQuestionType()', typeId)
    }
  }

  const handleAddAnswerOption = (sIndex: number, qIndex: number) => {
    setQuestionErrors({})
    const existingOptions = templateInPlace.sections[sIndex].questions[qIndex].options
    const updatedTemplateInPlace = { ...templateInPlace } // spread operator creates new object so that react re-renders
    updatedTemplateInPlace.sections[sIndex].questions[qIndex].options = existingOptions
      ? concat(existingOptions, '')
      : ['']
    console.log('handleAddAnswerOption()', sIndex, qIndex, updatedTemplateInPlace, existingOptions)
    onUpdateQuestion(updatedTemplateInPlace)
  }

  const handleRemoveAnswerOption = (sIndex: number, qIndex: number, optIndex: number) => {
    setQuestionErrors({})
    const existingOptions = templateInPlace.sections[sIndex].questions[qIndex].options
    if (size(existingOptions) <= 2) {
      return setQuestionErrors({
        ...questionErrors,
        [`s-${sIndex}`]: {
          ...questionErrors[`s-${sIndex}`],
          [`q-${qIndex}`]: 'minAnswerOptionsError',
        },
      })
    }
    const updatedTemplateInPlace = { ...templateInPlace } // spread operator creates new object so that react re-renders
    updatedTemplateInPlace.sections[sIndex].questions[qIndex].options = existingOptions
      ? filter(existingOptions, (opt, i) => i !== optIndex)
      : []
    console.log('handleRemoveAnswerOption()', sIndex, qIndex, optIndex, updatedTemplateInPlace, existingOptions)
    onUpdateQuestion(updatedTemplateInPlace)
  }

  const handleUpdateQuestion = (sIndex: number, qIndex: number, data: any): void => {
    if (!isViewOnly) {
      setQuestionErrors({})
      console.log('handleUpdateQuestion()', sIndex, qIndex, data)
      const updatedTemplateInPlace = { ...templateInPlace } // spread operator creates new object so that react re-renders
      updatedTemplateInPlace.sections[sIndex].questions[qIndex] = reduce(
        data,
        (question: Question, val: string | boolean, key: string) => {
          const parts = key.split('-')
          const sectionIndex = parseInt(parts[0], 10)
          const questionIndex = parseInt(parts[1], 10)
          const optionalParam = parts[2]
          const optionalIndex = parseInt(optionalParam, 10)

          if (sectionIndex === sIndex && questionIndex === qIndex) {
            if (optionalParam === 'query') {
              question.query = val as string
            } else if (optionalParam === 'minValue') {
              question.minValue = isNil(val) ? undefined : parseInt(val as string, 10)
            } else if (optionalParam === 'maxValue') {
              question.maxValue = isNil(val) ? undefined : parseInt(val as string, 10)
            } else if (optionalParam === 'isRequired') {
              question.allowNA = val === false
            } else if (optionalParam === 'allowOther') {
              question.allowOther = val === true
            } else if (optionalParam === 'allowComment') {
              question.allowComment = val === true
            } else if (isInteger(optionalIndex)) {
              question.options = map(question.options, (opt, i) => (i === optionalIndex ? (val as string) : opt))
            }
          }
          return question
        },
        updatedTemplateInPlace.sections[sIndex].questions[qIndex],
      )
      onUpdateQuestion(updatedTemplateInPlace)
    }
  }

  const renderExampleMetric = (questionType: string) => {
    const supportedMetrics = keys(exampleQuestionMetrics)
    if (includes(supportedMetrics, questionType)) {
      return (
        <MetricExampleWrapper>
          <MetricQuestion
            questionMetric={exampleQuestionMetrics[questionType]}
            isDetailView={!includes(['BREAKDOWN'], questionType)}
            randomOffset={1}
            isReadOnly
          />
        </MetricExampleWrapper>
      )
    }
  }

  const renderQuestionTypeBtn = (typeId: CustomQuestionTypes) => {
    let ExampleAnswer = (
      <QuestionTypeAnswerString>
        {t(`${typeId}_exampleAnswer`)}
        {typeId === 'PERCENTAGE' && <FontAwesomeIcon icon="percent" />}
      </QuestionTypeAnswerString>
    )
    if (typeId === 'FILE') {
      ExampleAnswer = (
        <QuestionTypeAnswerUpload>
          <FontAwesomeIcon icon="upload" />
          {t(`${typeId}_exampleAnswer`)}
        </QuestionTypeAnswerUpload>
      )
    }
    if (typeId === 'YES_NO') {
      ExampleAnswer = (
        <>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="dot-circle" />
            {t(`${typeId}_exampleAnswer-1`)}
          </QuestionTypeAnswerBullet>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="circle" />
            {t(`${typeId}_exampleAnswer-2`)}
          </QuestionTypeAnswerBullet>
        </>
      )
    }
    if (typeId === 'MULTIPLE_CHOICE') {
      ExampleAnswer = (
        <>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="circle" />
            {t(`${typeId}_exampleAnswer-1`)}
          </QuestionTypeAnswerBullet>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="circle" />
            {t(`${typeId}_exampleAnswer-2`)}
          </QuestionTypeAnswerBullet>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="dot-circle" />
            {t(`${typeId}_exampleAnswer-3`)}
          </QuestionTypeAnswerBullet>
        </>
      )
    }
    if (typeId === 'MULTI_SELECT') {
      ExampleAnswer = (
        <>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="check-square" />
            {t(`${typeId}_exampleAnswer-1`)}
          </QuestionTypeAnswerBullet>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="check-square" />
            {t(`${typeId}_exampleAnswer-2`)}
          </QuestionTypeAnswerBullet>
          <QuestionTypeAnswerBullet>
            <FontAwesomeIcon icon="square" />
            {t(`${typeId}_exampleAnswer-3`)}
          </QuestionTypeAnswerBullet>
        </>
      )
    }
    if (typeId === 'BREAKDOWN') {
      ExampleAnswer = (
        <>
          <QuestionTypeAnswerBreakdown>
            {t(`${typeId}_exampleCategory-1`)}
            <QuestionTypeAnswerString>{t(`${typeId}_exampleAnswer-1`)}</QuestionTypeAnswerString>
          </QuestionTypeAnswerBreakdown>
          <QuestionTypeAnswerBreakdown>
            {t(`${typeId}_exampleCategory-2`)}
            <QuestionTypeAnswerString>{t(`${typeId}_exampleAnswer-2`)}</QuestionTypeAnswerString>
          </QuestionTypeAnswerBreakdown>
          <QuestionTypeAnswerBreakdown>
            {t(`${typeId}_exampleCategory-3`)}
            <QuestionTypeAnswerString>{t(`${typeId}_exampleAnswer-3`)}</QuestionTypeAnswerString>
          </QuestionTypeAnswerBreakdown>
        </>
      )
    }

    let addIcon: IconProp = 'plus'
    if (typeId === 'YES_NO') addIcon = 'thumbs-up'
    if (typeId === 'MULTIPLE_CHOICE') addIcon = 'dot-circle'
    if (typeId === 'MULTI_SELECT') addIcon = 'check-square'
    if (typeId === 'BREAKDOWN') addIcon = 'chart-pie'
    if (typeId === 'INTEGER') addIcon = 'hashtag'
    if (typeId === 'PERCENTAGE') addIcon = 'percent'
    if (typeId === 'FILE') addIcon = 'file-alt'
    if (typeId === 'SHORT_ANSWER') addIcon = 'align-left'

    return (
      <NewElementSelectBtn key={`quest-type-${typeId}`} onClick={() => handleNewQuestionType(typeId)}>
        <NewElementSelectTitle>
          <FontAwesomeIcon icon={addIcon} />
          {t(typeId)}
        </NewElementSelectTitle>
        <QuestionTypeDescription>{t(`${typeId}_shortDescription`)}</QuestionTypeDescription>
        <QuestionTypeExample>
          <QuestionTypeQuery>{t(`${typeId}_exampleQuestion`)}</QuestionTypeQuery>
          {ExampleAnswer}
        </QuestionTypeExample>
      </NewElementSelectBtn>
    )
  }

  const renderNewQuestionOptions = () => {
    if (!isNewQuestionOpen) {
      return (
        <AddQuestionWrapper isWithNoQuestions={isEmpty(questions)}>
          <Button onClick={handleAddQuestion} isInverted color="active">
            <>
              <FontAwesomeIcon icon="plus" />
              {t('addQuestionBtn')}
            </>
          </Button>
        </AddQuestionWrapper>
      )
    }

    return (
      <NewQuestionBox>
        <NewBoxTitleRow>
          <NewBoxTitle>{t('addCustomQuestionTitle')}</NewBoxTitle>
          <Button onClick={handleCancelAddQuestion} color="secondary" size="large" isSimple>
            <FontAwesomeIcon icon="times" />
          </Button>
        </NewBoxTitleRow>
        <NewQuestionTypesWrapper>{map(QUESTION_TYPES, renderQuestionTypeBtn)}</NewQuestionTypesWrapper>
        <HelpMeDecideWrapper
          onClick={() => {
            setIsMoreInfoOpen(true)
            setActiveTab(QUESTION_TYPES[0])
          }}
        >
          <FontAwesomeIcon icon="question-circle" />
          {t('helpMeDecideLabel')}
        </HelpMeDecideWrapper>
      </NewQuestionBox>
    )
  }

  const renderQuestion = (q: CustomQuestionType, qIndex: number) => {
    const hasMinMaxValues = q.type === 'INTEGER' || q.type === 'PERCENTAGE'
    const hasAnswerOptions = q.type === 'MULTIPLE_CHOICE' || q.type === 'BREAKDOWN' || q.type === 'MULTI_SELECT'
    const hasAllowOther = q.type === 'MULTI_SELECT'
    const hasAllowComment = q.type !== 'SHORT_ANSWER'

    const renderAnswerOption = (opt: string, optIndex: number) => {
      return (
        <CustomQuestionAnswerOption key={`answerOption-${q.id}-${opt || optIndex}`}>
          {isViewOnly ? (
            <div />
          ) : (
            <RemoveOptionIcon onClick={() => handleRemoveAnswerOption(sIndex, qIndex, optIndex)}>
              <FontAwesomeIcon icon="minus-square" />
            </RemoveOptionIcon>
          )}

          <Controller
            key={`input-${sIndex}-${qIndex}-${optIndex}`}
            as={TextField}
            name={`${sIndex}-${qIndex}-${optIndex}`}
            control={control}
            variant="outlined"
            defaultValue={opt}
            onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
            disabled={isViewOnly}
          />
        </CustomQuestionAnswerOption>
      )
    }

    const hasQuestionErr = has(questionErrors, `s-${sIndex}`) && has(questionErrors[`s-${sIndex}`], `q-${qIndex}`)

    const isYesNo =
      q.type === 'MULTIPLE_CHOICE' && size(q.options) === 2 && includes(q.options, 'Yes') && includes(q.options, 'No')
    const questionLabel = isYesNo ? t('YES_NO_label') : t(`${q.type}_label`)

    return (
      <CustomQuestionRow key={`quest-${q.id}`} index={qIndex}>
        <SortOrderWrapper>
          <SortOrderValue>{`Q${qIndex + 1}`}</SortOrderValue>
          {isViewOnly ? (
            <div />
          ) : (
            <ChangeSortOrderWrapper>
              <SortArrow onClick={() => onChangeQuestionOrder(qIndex, -1, sIndex)} isDisabled={qIndex < 1} />
              <SortArrow
                onClick={() => onChangeQuestionOrder(qIndex, 1, sIndex)}
                isDisabled={qIndex + 1 >= size(questions)}
                isDown
              />
            </ChangeSortOrderWrapper>
          )}
        </SortOrderWrapper>
        <CustomQuestionQueryWrapper hasAnswerOptions={hasAnswerOptions}>
          <InvisibleWrapper>
            <Controller
              key={`input-${sIndex}-${qIndex}-type`}
              as={TextField}
              name={`${sIndex}-${qIndex}-type`}
              control={control}
              defaultValue={q.type}
              disabled={isViewOnly}
            />
          </InvisibleWrapper>
          <CustomFieldLabel>{questionLabel}</CustomFieldLabel>
          <Controller
            key={`input-${sIndex}-${qIndex}-query`}
            as={TextField}
            name={`${sIndex}-${qIndex}-query`}
            control={control}
            variant="outlined"
            multiline
            onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
            defaultValue={q.query}
            disabled={isViewOnly}
          />
        </CustomQuestionQueryWrapper>
        {hasAnswerOptions && (
          <CustomQuestionAnswerOptionsWrapper>
            <label>{q.type === 'BREAKDOWN' ? t('answerCategoriesLabel') : t('answerOptionsLabel')}</label>
            {map(q.options, renderAnswerOption)}
            {!isViewOnly && (
              <CustomQuestionAddAnswerOption onClick={() => handleAddAnswerOption(sIndex, qIndex)}>
                <FontAwesomeIcon icon="plus-square" />
                {q.type === 'BREAKDOWN' ? t('addAnswerCategoryLabel') : t('addAnswerOptionLabel')}
              </CustomQuestionAddAnswerOption>
            )}

            {hasQuestionErr && (
              <ErrorMsg>
                {t(questionErrors[`s-${sIndex}`][`q-${qIndex}`], {
                  noun: q.type === 'BREAKDOWN' ? 'categories' : 'options',
                })}
              </ErrorMsg>
            )}
          </CustomQuestionAnswerOptionsWrapper>
        )}

        <CustomQuestionOptions>
          <label>{t('requiredFieldLabel')}</label>
          <Controller
            key={`input-${sIndex}-${qIndex}-isRequired`}
            as={Checkbox}
            name={`${sIndex}-${qIndex}-isRequired`}
            control={control}
            color="default"
            onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
            defaultValue={!q.allowNA}
            disabled={isViewOnly}
          />

          {hasAllowOther && (
            <>
              <label>{t('allowOtherFieldLabel')}</label>
              <Controller
                key={`input-${sIndex}-${qIndex}-allowOther`}
                as={Checkbox}
                name={`${sIndex}-${qIndex}-allowOther`}
                control={control}
                color="default"
                onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                defaultValue={!!q.allowOther}
                disabled={isViewOnly}
              />
            </>
          )}

          {hasAllowComment && (
            <>
              <label>{t('allowCommentFieldLabel')}</label>
              <Controller
                key={`input-${sIndex}-${qIndex}-allowComment`}
                as={Checkbox}
                name={`${sIndex}-${qIndex}-allowComment`}
                control={control}
                color="default"
                onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                defaultValue={!!q.allowComment}
                disabled={isViewOnly}
              />
            </>
          )}

          {q.type === 'BREAKDOWN' && <QuestionMessage>{t('numericalResponsesOnlyMsg')}</QuestionMessage>}

          {hasMinMaxValues && (
            <>
              <label>
                {t('minValueFieldLabel')}
                <FontAwesomeIcon icon="asterisk" />
              </label>
              <Controller
                key={`input-${sIndex}-${qIndex}-minValue`}
                as={TextField}
                name={`${sIndex}-${qIndex}-minValue`}
                control={control}
                variant="outlined"
                onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                defaultValue={q.minValue}
                disabled={isViewOnly}
              />
              <label>
                {t('maxValueFieldLabel')}
                <FontAwesomeIcon icon="asterisk" />
              </label>
              <Controller
                key={`input-${sIndex}-${qIndex}-maxValue`}
                as={TextField}
                name={`${sIndex}-${qIndex}-maxValue`}
                control={control}
                variant="outlined"
                onBlur={() => handleUpdateQuestion(sIndex, qIndex, control.getValues())}
                defaultValue={q.maxValue}
                disabled={isViewOnly}
              />
              <OptionalNote isSmall>
                <FontAwesomeIcon icon="asterisk" />
                {t('optionalAsterisk')}
              </OptionalNote>
            </>
          )}
        </CustomQuestionOptions>
        {isViewOnly ? (
          <div />
        ) : (
          <QuestionActions>
            <RemoveBtnWrapper>
              <Button onClick={() => onRemoveQuestion(q.id as string)} isSimple size="small" color="error">
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </RemoveBtnWrapper>
            <QuestionMoreInfoBtn
              onClick={() => {
                setIsMoreInfoOpen(true)
                setActiveTab(q?.type || QUESTION_TYPES[0])
              }}
            >
              <FontAwesomeIcon icon="question-circle" />
            </QuestionMoreInfoBtn>
            <PreviewQuestionBtn onClick={() => setPreviewContent(q)}>
              <FontAwesomeIcon icon="eye" />
            </PreviewQuestionBtn>
          </QuestionActions>
        )}
      </CustomQuestionRow>
    )
  }

  const renderMoreInfoTab = (typeId: CustomQuestionTypes) => {
    return (
      <MoreInfoTab key={`tab-${typeId}`} onClick={() => setActiveTab(typeId)} isActive={typeId === activeTab}>
        {t(typeId)}
      </MoreInfoTab>
    )
  }

  return (
    <CustomSectionContainer key={`c${id}`} id={id}>
      <SectionName onClick={handleOpen} isViewOnly={isViewOnly}>
        <ToggleIcon isOpen={isOpen}>
          <FontAwesomeIcon icon="angle-right" />
        </ToggleIcon>
        <SortOrderWrapper>
          <SortOrderValue>{`S${sIndex + 1}`}</SortOrderValue>
          {isViewOnly ? (
            <div />
          ) : (
            <ChangeSortOrderWrapper>
              <SortArrow
                onClick={e => {
                  e.stopPropagation()
                  onChangeSectionOrder(sIndex, -1)
                }}
                isDisabled={sIndex < 1}
              />
              <SortArrow
                onClick={e => {
                  e.stopPropagation()
                  onChangeSectionOrder(sIndex, 1)
                }}
                isDisabled={sIndex + 1 >= size(templateInPlace?.sections)}
                isDown
              />
            </ChangeSortOrderWrapper>
          )}
        </SortOrderWrapper>
        <SectionToggleName>
          {isTitleEditing ? (
            <EditNameWrapper onClick={(e: SyntheticEvent) => e.stopPropagation()}>
              <input name={`sectionName-${sIndex}`} type="text" defaultValue={name} onBlur={handleUpdateSectionName} />
              <Button onClick={() => handleSetTitleEditing(undefined)} size="small" color="success">
                {t('submitBtn')}
              </Button>
            </EditNameWrapper>
          ) : (
            <>
              {name}
              {isViewOnly ? null : (
                <ToggleEditIcon
                  onClick={(e: any) => {
                    e.stopPropagation()
                    handleSetTitleEditing(sIndex)
                  }}
                >
                  <FontAwesomeIcon icon="pencil-alt" />
                </ToggleEditIcon>
              )}
            </>
          )}
        </SectionToggleName>
        <CountLength>{t('questionsLength', { count: size(questions) })}</CountLength>

        {isViewOnly || !id ? null : (
          <Button onClick={onRemoveSection} color="error" size="small" isSimple>
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        )}
      </SectionName>

      <HiddenContentBox isOpen={isOpen}>
        <CustomSectionHeader isVisible={!isEmpty(questions)}>
          <ColumnHeading key={`heading-${sIndex}-order`}>{t('questionOrderHeading')}</ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-question`} isLeftAligned>
            {t('customQuestionHeading')}
          </ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-answerOptions`}></ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-options`} isLeftAligned>
            {t('customOptionsHeading')}
          </ColumnHeading>
          <ColumnHeading key={`heading-${sIndex}-remove`}></ColumnHeading>
        </CustomSectionHeader>
        {isNil(questions) ? null : map(questions as CustomQuestionType[], renderQuestion)}
        {isViewOnly ? null : renderNewQuestionOptions()}
      </HiddenContentBox>
      <Modal
        isOpen={isMoreInfoOpen}
        onClose={() => {
          setIsMoreInfoOpen(false)
          setActiveTab(QUESTION_TYPES[0])
        }}
        hasOverlay
        width="xl"
        content={
          <MoreInfoModal>
            <ModalTitle>
              <div />
              <h3>{t('questionTypeHelpModalTitle')}</h3>
              <CloseIcon
                onClick={() => {
                  setIsMoreInfoOpen(false)
                  setActiveTab(QUESTION_TYPES[0])
                }}
              >
                <FontAwesomeIcon icon="times" />
              </CloseIcon>
            </ModalTitle>
            <p>{t('questionTypeHelpModalIntro')}</p>
            <MoreInfoTabsWrapper>{map(QUESTION_TYPES, renderMoreInfoTab)}</MoreInfoTabsWrapper>
            <MoreInfoTypeDetail>
              <p>{t(`${activeTab}_explanation`)}</p>
              <p>{t(`${activeTab}_explanationNote`)}</p>
              <h4>{t('exampleQuestion')}</h4>
              <p>{t(`${activeTab}_contextExample`)}</p>
              <h4>{t('exampleMetrics')}</h4>
              <p>{t(`${activeTab}_metricsDescription`)}</p>
              {renderExampleMetric(activeTab)}
            </MoreInfoTypeDetail>
          </MoreInfoModal>
        }
      />
      <Modal
        isOpen={!isNil(previewContent)}
        onClose={() => setPreviewContent(undefined)}
        hasOverlay
        width="lg"
        content={
          <MoreInfoModal>
            <ModalTitle>
              <div />
              <h3>{t('previewQuestionTitle')}</h3>
              <CloseIcon onClick={() => setPreviewContent(undefined)}>
                <FontAwesomeIcon icon="times" />
              </CloseIcon>
            </ModalTitle>
            <p>{t('previewQuestionIntro')}</p>
            <PreviewQuestionWrapper>
              <CustomQuestion question={previewContent} />
            </PreviewQuestionWrapper>
          </MoreInfoModal>
        }
      />
    </CustomSectionContainer>
  )
}

export default CustomSection
