import React from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../utils/auth0'

interface PrivateRouteOptions {
  component: any
  path: string
  exact?: boolean
}

const PrivateRoute = ({ component, path, exact, ...rest }: PrivateRouteOptions) => {
  const { isInitializing, isAuthenticated, loginWithRedirect } = useAuth0()

  React.useEffect(() => {
    const fn = async () => {
      if (isInitializing || !loginWithRedirect) {
        return
      }

      if (!isAuthenticated) {
        const targetUrl = window.location.pathname
        await loginWithRedirect({
          redirect_uri: '',
          appState: { targetUrl },
        })
      }
    }
    fn()
  }, [isAuthenticated, loginWithRedirect, path, isInitializing])

  return <Route exact={exact} path={path} component={component} {...rest} />
}

export default PrivateRoute
