import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Markdown from 'markdown-to-jsx'
import {
  map,
  size,
  countBy,
  find,
  includes,
  isEmpty,
  forEach,
  head,
  last,
  add,
  divide,
  round,
  clamp,
  findLast,
  isString,
  isNumber,
  toUpper,
  trim,
  filter,
  every,
  max,
  multiply,
  sortBy,
  isObject,
  isNil,
  isArray,
} from 'lodash'
import { UserSection, UserQuestion, Attestation, AuthMatrixAttestation, AnswerType } from 'models/report'
import { User } from 'models/common'
import { isMockData } from 'utils/brandConfig'
import { mockReportDetailResponse, mockReportsResponse } from 'mockData/mockReports'
import { mockTemplates } from 'mockData/mockTemplates'
import { mockCompanies } from 'mockData/mockCompanies'

import { downloadDocument, downloadReportExport } from 'actions/userReportActions'
import { findReport } from 'actions/reportActions'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import StatusBadge from 'components/StatusBadge'
import Avatar from 'components/Avatar'
import EmptyView from 'components/EmptyView'
import ErrorView from 'components/ErrorView'
import Modal from 'components/Modal'

import { ToggleIcon, PageWithBannerTitle, BannerTitle } from 'components/styles'
import {
  ViewReportTitle,
  TemplatePageTitle,
  ReportName,
  SectionsTableContainer,
  TemplateSectionContainer,
  ReportSectionName,
  ToggleName,
  SectionBadges,
  HiddenContentBoxWithAnswerSlider,
  ReportSectionHeader,
  ReportQuestionRow,
  QuestionField,
  QuestionContentSide,
  AnswerContentSide,
  ExpandBar,
  ExpandBarContent,
  AnswerContentWrapper,
  AnswerContent,
  StatusBadgesContainer,
  AvatarsContainer,
  ReportColumnHeading,
  AttestationHeading,
  ScrollBar,
  RowWrapper,
  ExpandedAttestationHeading,
  AttestationRow,
  ExpandedAttestationRow,
  AttestationContent,
  UserStatus,
  AttestationDetailTitle,
  ExplanationWrapper,
  ExplanationContent,
  DocumentsWrapper,
  DocumentItem,
  ShowMore,
  ErrorMsg,
  AnswerWrapper,
  AnswerBreakdownWrapper,
  AnswerMultiselectWrapper,
  OtherOptionAnswer,
  AnswerMultipleChoiceWrapper,
  AnswerIcon,
  AnswerOptionLabel,
  AnswerCategoryLabel,
  AnswerCategoryValue,
  CompanyFilesWrapper,
  DocumentsBtnsWrapper,
  DocumentBtn,
  ConflictingAnswerBadge,
  CommentWrapper,
} from './styles'

interface ReportViewProps {
  match: {
    params: {
      reportId?: string
      companyId?: string
    }
  }
}

const ReportView: React.FC<ReportViewProps> = ({
  match: {
    params: { reportId, companyId },
  },
}: ReportViewProps) => {
  const { t } = useTranslation()
  const [openSection, setOpenSection] = React.useState<number | undefined>(0)
  const [isAttestationExpanded, setIsAttestationExpanded] = React.useState<boolean>(false)
  const [scrolledItems, setScrolledItems] = React.useState<number>(0)
  const [modalExplanation, setModalExplanation] = React.useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const mockCompany = find(mockCompanies, co => co.id === companyId)
  const mockUsers = mockCompany?.users
  const hasExportPdfFunctionality = true // Currently all Clients have this functionality enabled

  const { reportDetail } = useSelector((state: any) => state.reports)
  const { exportPdf } = useSelector((state: any) => state.userReports)

  const selectedReport = isMockData ? findLast(mockReportsResponse, r => r.id === reportId) : reportDetail?.item
  const reportName = selectedReport?.name

  const mockReport = {
    ...mockReportDetailResponse,
    template: {
      id: mockTemplates[0]?.id,
      name: mockTemplates[0]?.name,
    },
  }

  const report = isMockData ? mockReport : reportDetail?.item

  React.useEffect(() => {
    if (reportId) dispatch(findReport(auth0Context, reportId))
  }, [reportId, dispatch, auth0Context])

  const getOtherOption = (attestationObject: Attestation | AnswerType): string | undefined => {
    const otherOption =
      !isNil(attestationObject) &&
      !isString(attestationObject) &&
      !isNumber(attestationObject) &&
      isObject(attestationObject) &&
      'otherOption' in attestationObject &&
      !isNil(attestationObject.otherOption) &&
      !isEmpty(attestationObject.otherOption) &&
      attestationObject.otherOption
    return isString(otherOption) && !isEmpty(otherOption) ? otherOption : undefined
  }

  const getRowHeight = ({ query, columns, attestations, options }: UserQuestion, isCustom: boolean): number => {
    const QUERY_MIN_THRESHOLD = 10
    const QUERY_MAX_THRESHOLD = 1000
    const QUESTION_DIVISOR = 60 // approximate characters per line
    const ATTESTATION_DIVISOR = 25 // approximate characters per line
    const OTHER_OPTION_DIVISOR = 18 // approximate characters per line
    const OPTION_HEIGHT = 4.5 // approximate allowed height per option
    const MIN_OTHER_OPTION_HEIGHT = 5
    const COMMENT_MIN_HEIGHT = 3
    const DOCS_INIT_HEIGHT = 10
    const DOCS_MULTIPLIER = 4

    const mostDocuments = last(sortBy(attestations, att => (isArray(att.documents) ? size(att.documents) : 0)))
    const maxDocsHeight: number = isNumber(size(mostDocuments?.documents))
      ? add(multiply(size(mostDocuments?.documents), DOCS_MULTIPLIER), DOCS_INIT_HEIGHT)
      : DOCS_INIT_HEIGHT

    const questionsArray = isCustom ? [query] : columns
    const maxQuestionHeight = clamp(
      round(divide(size(head(questionsArray)), QUESTION_DIVISOR)),
      QUERY_MIN_THRESHOLD,
      QUERY_MAX_THRESHOLD,
    )
    const longestAttestation = last(
      sortBy(attestations, att => (isString(att.attestation) ? size(att.attestation) : 0)),
    )
    const maxAttestationHeight: number = isString(longestAttestation?.attestation)
      ? divide(size(longestAttestation?.attestation), ATTESTATION_DIVISOR)
      : 0
    const longestComment = last(sortBy(attestations, att => (isString(att.comment) ? size(att.comment) : 0)))
    const maxCommentHeight: number = isString(longestComment?.comment)
      ? add(COMMENT_MIN_HEIGHT, divide(size(longestComment?.comment), ATTESTATION_DIVISOR))
      : 0

    const longestOtherOptionAtt = last(
      sortBy(attestations, att => {
        const otherOption = getOtherOption(att.attestation)
        return isString(otherOption) ? size(otherOption) : 0
      }),
    )
    const longestOtherOption = getOtherOption(longestOtherOptionAtt?.attestation)
    const maxOtherOptionHeight = longestOtherOption
      ? clamp(divide(size(longestOtherOption), OTHER_OPTION_DIVISOR), MIN_OTHER_OPTION_HEIGHT, QUERY_MAX_THRESHOLD)
      : 0
    const maxOptionsHeight = add(multiply(size(options), OPTION_HEIGHT), maxOtherOptionHeight)

    const longestAnswer: number =
      isNil(maxAttestationHeight) || isNil(maxDocsHeight) ? 0 : max([maxAttestationHeight, maxDocsHeight]) || 0

    const attestationHeight = add(longestAnswer, maxCommentHeight)
    const optionsHeight = add(maxOptionsHeight, maxCommentHeight)

    // console.log('Row HEIGHT:', query, {
    //   attestations,
    //   questionsArray,
    //   questionLength: size(head(questionsArray)),
    //   maxQuestionHeight,
    //   longestAttestation,
    //   maxAttestationHeight,
    //   longestOtherOption,
    //   maxOtherOptionHeight,
    //   maxOptionsHeight,
    //   longestComment,
    //   maxCommentHeight,
    //   mostDocuments,
    //   maxDocsHeight,
    //   sorted: sortBy(attestations, att => (isString(att.attestation) ? size(att.attestation) : 0)),
    //   returnedHeight: max([maxQuestionHeight, attestationHeight, optionsHeight]) || 0,
    // })

    return max([maxQuestionHeight, attestationHeight, optionsHeight]) || 0
  }

  const handleSectionOpen = (sectionIndex: number) => {
    if (openSection === sectionIndex) {
      setOpenSection(undefined)
    } else {
      setOpenSection(sectionIndex)
    }
  }

  const handleDownloadReportExport = (reportId: string): void => {
    console.log('handleDownloadReportExport', reportId)
    if (!exportPdf.isFetching) {
      dispatch(downloadReportExport(auth0Context, reportId))
    }
  }

  const handleDownloadDoc = (reportId: string, sectionId: string, questionId: string, documentId: string): void => {
    dispatch(
      downloadDocument(auth0Context, {
        reportId,
        sectionId,
        questionId,
        documentId,
      }),
    )
  }

  const handleDownloadMultipleDocs = (
    reportId: string,
    sectionId: string,
    questionId: string,
    documentsList: any[] | undefined,
  ): void => {
    forEach(documentsList, (document): void => {
      dispatch(
        downloadDocument(auth0Context, {
          reportId,
          sectionId,
          questionId,
          documentId: document?.id,
        }),
      )
    })
  }

  if (reportDetail?.error && !isMockData) {
    return <ErrorView />
  }
  if (reportDetail?.isFetching) {
    return <Spinner />
  }

  if (isEmpty(report)) return <EmptyView emptyMsg={t('noReportsToListMsg')} />

  const renderExplanation = (explanation?: string) => {
    if (isEmpty(explanation)) return null
    return (
      <ExplanationWrapper
        isClickable
        onClick={() => {
          setModalExplanation(explanation)
        }}
      >
        <AttestationDetailTitle>{t('explanationTitle')}</AttestationDetailTitle>
        <ExplanationContent>{explanation}</ExplanationContent>
        <ShowMore>{t('showMore')}</ShowMore>
      </ExplanationWrapper>
    )
  }

  const renderDocuments = (
    reportId: string,
    sectionId: string,
    questionId: string,
    documents?: any[],
    isButton?: boolean,
  ) => {
    if (isEmpty(documents)) return null
    if (isButton) {
      return (
        <DocumentsBtnsWrapper>
          <h4>
            {t('documentsUploadedMsg', {
              length: size(documents),
              noun: size(documents) === 1 ? 'Document' : 'Documents',
            })}
          </h4>
          {map(documents, doc => (
            <React.Fragment key={`doc-${doc.id}`}>
              <FontAwesomeIcon icon="circle" />
              <p>{doc?.name}</p>
            </React.Fragment>
          ))}
          <DocumentBtn onClick={() => handleDownloadMultipleDocs(reportId, sectionId, questionId, documents)}>
            {t('multipleDocsBtnMsg', {
              length: size(documents),
              noun: size(documents) === 1 ? 'Document' : 'Documents',
            })}
          </DocumentBtn>
        </DocumentsBtnsWrapper>
      )
    }
    return (
      <DocumentsWrapper>
        <AttestationDetailTitle>{t('documentsTitle', { count: size(documents) })}</AttestationDetailTitle>
        {size(documents) > 1 ? (
          <DocumentItem onClick={() => handleDownloadMultipleDocs(reportId, sectionId, questionId, documents)}>
            {t('multipleDocs', { length: size(documents) })}
          </DocumentItem>
        ) : (
          map(documents, doc => (
            <DocumentItem
              key={`doc-${doc.id}`}
              onClick={() => handleDownloadDoc(reportId, sectionId, questionId, doc.id)}
            >
              {doc?.name}
            </DocumentItem>
          ))
        )}
      </DocumentsWrapper>
    )
  }

  const renderCustomAnswer = (quest: UserQuestion, isUser?: boolean, userAttestation?: Attestation) => {
    if (!quest.type) return null

    if (!isUser && quest.isConflicting) {
      return <ConflictingAnswerBadge>{t('conflictingAnswersBadge')}</ConflictingAnswerBadge>
    }

    let userAnswer: Attestation | AnswerType = quest.attestation
    let firstAttestation

    if (!isEmpty(quest.attestations)) {
      firstAttestation = head(quest.attestations)
      userAnswer = firstAttestation?.attestation
    }

    if (isUser && userAttestation) {
      userAnswer = userAttestation.attestation
    }

    let answerComponent: React.ReactElement = <div />

    // if (userAttestation) {
    //   console.log('userAttestation', userAttestation)
    // }

    if (
      ['INTEGER', 'PERCENTAGE', 'SHORT_ANSWER'].includes(quest.type) &&
      (isString(userAnswer) || isNumber(userAnswer))
    ) {
      answerComponent = (
        <>
          <p>{userAnswer}</p>
          {quest.type === 'PERCENTAGE' && <FontAwesomeIcon icon="percent" />}
        </>
      )
    }

    if (quest.type === 'MULTI_SELECT') {
      // console.log('MULTI_SELECT', quest, userAnswer, userAttestation)
      const userOtherOption = getOtherOption(userAnswer)
      answerComponent = (
        <AnswerMultiselectWrapper>
          {map(quest.options, opt => {
            const isSelected =
              !isNil(userAnswer) &&
              !isString(userAnswer) &&
              !isNumber(userAnswer) &&
              'selections' in userAnswer &&
              isObject(userAnswer) &&
              isArray(userAnswer.selections) &&
              !isNumber(userAnswer.selections) &&
              includes(userAnswer.selections, opt)
            const iconKey = isSelected ? 'check-square' : 'square'
            return (
              <React.Fragment key={`option-${opt}-${quest.id}`}>
                <AnswerIcon isSelected={isSelected} isSquare>
                  <FontAwesomeIcon icon={iconKey} />
                </AnswerIcon>
                <AnswerOptionLabel isSelected={isSelected}>{opt}</AnswerOptionLabel>
              </React.Fragment>
            )
          })}
          {!!userOtherOption && (
            <React.Fragment>
              <AnswerIcon isSelected={!!userOtherOption} isSquare>
                <FontAwesomeIcon icon={!!userOtherOption ? 'check-square' : 'square'} />
              </AnswerIcon>
              <AnswerOptionLabel isSelected={!!userOtherOption}>Other</AnswerOptionLabel>
              {!!userOtherOption && <OtherOptionAnswer>{userOtherOption}</OtherOptionAnswer>}
            </React.Fragment>
          )}
        </AnswerMultiselectWrapper>
      )
    }

    if (quest.type === 'MULTIPLE_CHOICE') {
      answerComponent = (
        <AnswerMultipleChoiceWrapper>
          {map(quest.options, opt => {
            const isSelected = toUpper(trim(opt)) === toUpper(trim(userAnswer as string))
            const iconKey = isSelected ? 'check-circle' : 'circle'
            return (
              <React.Fragment key={`option-${opt}-${quest.id}`}>
                <AnswerIcon isSelected={isSelected}>
                  <FontAwesomeIcon icon={iconKey} />
                </AnswerIcon>
                <AnswerOptionLabel isSelected={isSelected}>{opt}</AnswerOptionLabel>
              </React.Fragment>
            )
          })}
        </AnswerMultipleChoiceWrapper>
      )
    }

    if (quest.type === 'BREAKDOWN') {
      answerComponent = (
        <AnswerBreakdownWrapper>
          {!isString(userAnswer) && !isNumber(userAnswer)
            ? map(userAnswer, (val, label) => {
                return (
                  <React.Fragment key={`option-${label}-${quest.id}`}>
                    <AnswerCategoryLabel>{label}:</AnswerCategoryLabel>
                    <AnswerCategoryValue>{val}</AnswerCategoryValue>
                  </React.Fragment>
                )
              })
            : null}
        </AnswerBreakdownWrapper>
      )
    }

    if (quest.type === 'FILE' && (userAttestation || !isEmpty(quest.attestations))) {
      if (isUser) {
        if (!reportId || !userAttestation?.sectionId) return
        answerComponent = renderDocuments(
          reportId,
          userAttestation?.sectionId,
          quest.id,
          userAttestation?.documents,
          true,
        ) || <div />
      } else {
        const withDocs = filter(quest.attestations, (a: Attestation) => !isEmpty(a.documents))
        answerComponent = (
          <CompanyFilesWrapper>
            {t('filesCountMsg', { count: size(withDocs), noun: size(withDocs) === 1 ? 'File' : 'Files' })}
          </CompanyFilesWrapper>
        )
      }
    }

    if (
      isUser &&
      (!userAttestation ||
        (isEmpty(userAttestation.attestation) &&
          !isNumber(userAttestation.attestation) &&
          isEmpty(userAttestation.documents)))
    ) {
      answerComponent = (
        <AttestationContent>
          <EmptyView emptyMsg={t('notSubmittedMsg')} />
        </AttestationContent>
      )
    }

    if (quest.isConflicting && !isUser) {
      answerComponent = <StatusBadge status="CONFLICTING_ANSWERS" />
    }

    if (firstAttestation?.attestation === 'NOT_APPLICABLE' && !isUser) {
      answerComponent = <StatusBadge status="NOT_APPLICABLE" />
    }

    return (
      <>
        <AnswerWrapper
          key={`custom-company-answer-${quest.id}`}
          isLarger={['INTEGER', 'PERCENTAGE'].includes(quest.type)}
        >
          {answerComponent}
        </AnswerWrapper>
        {!isNil(userAttestation?.comment) && (
          <CommentWrapper>
            <h6>Comment:</h6>
            {userAttestation?.comment}
          </CommentWrapper>
        )}
      </>
    )
  }

  const renderSection = (section: UserSection, sIndex: number) => {
    const isCustom = section.type === 'CUSTOM'
    const colNamesArray = isCustom ? ['Question'] : section.columnNames

    const completeQuestionsLength = size(
      filter(
        map(
          section.questions,
          q =>
            !isEmpty(q.attestations) &&
            every(q.attestations, a => !isEmpty(a.attestation) || isNumber(a.attestation) || !isEmpty(a.documents)),
        ),
      ),
    )

    return (
      <TemplateSectionContainer key={`section-${sIndex}`}>
        <ReportSectionName onClick={() => handleSectionOpen(sIndex)}>
          <ToggleIcon isOpen={openSection === sIndex}>
            <FontAwesomeIcon icon="angle-right" />
          </ToggleIcon>
          <ToggleName>{section.name}</ToggleName>
          <SectionBadges>
            {isCustom ? (
              <StatusBadge
                size="md"
                status="COMPLETE"
                count={completeQuestionsLength}
                determineIsFaded={() => completeQuestionsLength < 1}
              />
            ) : (
              <>
                <StatusBadge
                  size="md"
                  status="NON_COMPLIANT"
                  count={section.questionStatistics?.NON_COMPLIANT}
                  determineIsFaded={() => section.questionStatistics?.NON_COMPLIANT < 1}
                />
                <StatusBadge
                  size="md"
                  status="NOT_APPLICABLE"
                  count={section.questionStatistics?.NOT_APPLICABLE}
                  determineIsFaded={() => section.questionStatistics?.NOT_APPLICABLE < 1}
                />
              </>
            )}
          </SectionBadges>
        </ReportSectionName>
        <HiddenContentBoxWithAnswerSlider isOpen={openSection === sIndex}>
          <QuestionContentSide isOpen={openSection === sIndex}>
            <ReportSectionHeader extrasLength={size(colNamesArray) - 1}>
              {map(colNamesArray, (colName: string, headingIndex: number) => (
                <ReportColumnHeading key={`heading-${sIndex}-${headingIndex}`} isLeftAligned={headingIndex === 0}>
                  {colName}
                </ReportColumnHeading>
              ))}
            </ReportSectionHeader>
            {map(section.questions, (quest: UserQuestion, qIndex: number) => {
              const questionsArray = isCustom ? [quest.query] : quest.columns
              return (
                <ReportQuestionRow
                  key={`question-${sIndex}-${qIndex}`}
                  extrasLength={size(questionsArray) - 1}
                  isOpen={openSection === sIndex}
                  rowHeight={getRowHeight(quest, isCustom)}
                >
                  {map(questionsArray, (colData: string, colIndex: number) => (
                    <QuestionField key={`field-${sIndex}-${qIndex}-${colIndex}`} isLeftAligned={colIndex === 0}>
                      <Markdown>{colData}</Markdown>
                    </QuestionField>
                  ))}
                </ReportQuestionRow>
              )
            })}
          </QuestionContentSide>
          <AnswerContentSide isExpanded={isAttestationExpanded}>
            <ExpandBar
              onClick={() => setIsAttestationExpanded(!isAttestationExpanded)}
              isExpanded={isAttestationExpanded}
            >
              <FontAwesomeIcon icon="angle-double-left" />
              <ExpandBarContent isExpanded={isAttestationExpanded}>
                {isAttestationExpanded
                  ? t('hideAttestationDetail', {
                      count: size(section?.questions),
                    })
                  : t('showAttestationDetail', {
                      count: size(section?.questions),
                    })}
              </ExpandBarContent>
              <FontAwesomeIcon icon="angle-double-left" />
            </ExpandBar>
            <AnswerContentWrapper>
              <AnswerContent isExpanded={isAttestationExpanded}>
                <RowWrapper>
                  {scrolledItems > 0 ? (
                    <ScrollBar onClick={() => setScrolledItems(scrolledItems - 1)}>
                      <FontAwesomeIcon icon="caret-left" />
                    </ScrollBar>
                  ) : (
                    <div />
                  )}
                  <ExpandedAttestationHeading
                    count={size(isMockData ? mockUsers : report?.users)}
                    scrolled={scrolledItems}
                  >
                    {map(isMockData ? mockUsers : report?.users, (usr: User, i) => {
                      const user = !isMockData
                        ? usr
                        : {
                            ...report?.users[i],
                            name: usr.name,
                            email: usr.email,
                          }
                      const status = includes(['COMPLIANT', 'NON_COMPLIANT', 'COMPLETE'], user.reportStatus)
                        ? 'COMPLETE'
                        : user.reportStatus === 'OVERDUE'
                        ? 'OVERDUE'
                        : 'PENDING'
                      let secondaryText
                      if (includes(['COMPLETE', 'OVERDUE', 'PENDING'], status)) {
                        secondaryText = <UserStatus status={status}>{t(status as string)}</UserStatus>
                      }
                      return (
                        <AttestationContent key={`avatar-${user.id}`}>
                          <Avatar user={user} secondaryText={secondaryText} isCircleSmall />
                        </AttestationContent>
                      )
                    })}
                  </ExpandedAttestationHeading>
                  {scrolledItems < size(isMockData ? mockUsers : report?.users) - 2 ? (
                    <ScrollBar onClick={() => setScrolledItems(scrolledItems + 1)}>
                      <FontAwesomeIcon icon="caret-right" />
                    </ScrollBar>
                  ) : (
                    <div />
                  )}
                </RowWrapper>

                {map(section.questions, (quest: UserQuestion) => {
                  return (
                    <RowWrapper key={`attest-expanded-${quest.id}`}>
                      {scrolledItems > 0 ? (
                        <ScrollBar onClick={() => setScrolledItems(scrolledItems - 1)}>
                          <FontAwesomeIcon icon="caret-left" />
                        </ScrollBar>
                      ) : (
                        <div />
                      )}
                      <ExpandedAttestationRow
                        count={size(isMockData ? mockUsers : report?.users)}
                        scrolled={scrolledItems}
                        rowHeight={getRowHeight(quest, isCustom)}
                      >
                        {map(isMockData ? mockUsers : report?.users, (usr: User, i) => {
                          const user = !isMockData
                            ? usr
                            : {
                                ...report?.users[i],
                                name: usr.name,
                                email: usr.email,
                              }
                          const userAttestation = find(quest.attestations, (att: Attestation) => att.userId === user.id)
                          const customAnswer = renderCustomAnswer(quest, true, userAttestation)

                          if (
                            userAttestation &&
                            includes(['COMPLIANT', 'NON_COMPLIANT', 'NOT_APPLICABLE'], userAttestation?.attestation)
                          ) {
                            return (
                              <AttestationContent key={`attestation-${quest.id}-${user.id}`}>
                                <StatusBadge status={userAttestation.attestation as AuthMatrixAttestation} size="lg" />
                                {renderExplanation(userAttestation.explanation)}
                                {renderDocuments(
                                  report.id,
                                  section.id as string,
                                  quest.id as string,
                                  userAttestation.documents,
                                )}
                              </AttestationContent>
                            )
                          } else if (customAnswer != null) {
                            return (
                              <AttestationContent key={`attestation-${quest.id}-${user.id}`}>
                                {customAnswer}
                              </AttestationContent>
                            )
                          } else {
                            return (
                              <AttestationContent key={`attestation-${quest.id}-${user.id}`}>
                                <EmptyView emptyMsg={t('notSubmittedMsg')} />
                              </AttestationContent>
                            )
                          }
                        })}
                      </ExpandedAttestationRow>
                      {scrolledItems < size(isMockData ? mockUsers : report?.users) - 2 ? (
                        <ScrollBar onClick={() => setScrolledItems(scrolledItems + 1)}>
                          <FontAwesomeIcon icon="caret-right" />
                        </ScrollBar>
                      ) : (
                        <div />
                      )}
                    </RowWrapper>
                  )
                })}
              </AnswerContent>

              <AnswerContent isExpanded={!isAttestationExpanded}>
                <AttestationHeading>{isCustom ? t('answerHeading') : t('attestationHeading')}</AttestationHeading>
                {map(section.questions, (quest: UserQuestion, qIndex: number) => {
                  const statusCounts = countBy(quest.attestations, 'attestation')
                  const companyAnswer = isCustom
                    ? renderCustomAnswer(quest)
                    : map(
                        statusCounts,
                        (count: number, status: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
                          if (count > 0) {
                            return (
                              <React.Fragment key={`count-${quest.id}-${status}`}>
                                <StatusBadge status={status} />
                              </React.Fragment>
                            )
                          }
                        },
                      )

                  return (
                    <AttestationRow key={`attest-${quest.id}`} rowHeight={getRowHeight(quest, isCustom)}>
                      {isEmpty(quest.attestations) ? (
                        <AttestationContent>
                          <EmptyView emptyMsg={t('notSubmittedMsg')} />
                        </AttestationContent>
                      ) : (
                        <AttestationContent>
                          <StatusBadgesContainer>{companyAnswer}</StatusBadgesContainer>
                          <AvatarsContainer>
                            {map(quest.attestations, (att: Attestation, i) => {
                              const userObj =
                                isMockData && mockUsers ? mockUsers[i] : find(section.users, { id: att.userId })
                              if (userObj) {
                                return (
                                  <React.Fragment key={`attest-${att.questionId}-${att.sectionId}-${att.userId}`}>
                                    <Avatar user={userObj} status={att.attestation} isImageOnly isSmall />
                                  </React.Fragment>
                                )
                              }
                            })}
                          </AvatarsContainer>
                        </AttestationContent>
                      )}
                    </AttestationRow>
                  )
                })}
              </AnswerContent>
            </AnswerContentWrapper>
          </AnswerContentSide>
        </HiddenContentBoxWithAnswerSlider>
      </TemplateSectionContainer>
    )
  }

  return (
    <PageWithBannerTitle>
      <BannerTitle>
        <ViewReportTitle hasExportBtn={hasExportPdfFunctionality}>
          <TemplatePageTitle>
            {t('reportNameLabel')}
            <ReportName title={reportName}>{reportName}</ReportName>
          </TemplatePageTitle>
          <TemplatePageTitle>
            {t('reportTemplateLabel')}
            <ReportName title={report?.template?.name}>{report?.template?.name}</ReportName>
          </TemplatePageTitle>
          {exportPdf.error ? (
            <ErrorMsg>
              {t('somethingWentWrong')}
              <br />
              {exportPdf.error}
            </ErrorMsg>
          ) : (
            hasExportPdfFunctionality && (
              <Button onClick={() => handleDownloadReportExport(report.id)} color="active">
                {exportPdf.isFetching ? <Spinner isMini /> : t('exportPdfBtn')}
              </Button>
            )
          )}
          <Button
            to={`/company/${isMockData ? companyId : report?.company?.id}`}
            color="secondary"
            size="large"
            isSimple
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </ViewReportTitle>
      </BannerTitle>

      <SectionsTableContainer>
        {map(report?.sections, (section: UserSection, secIndex: number) => renderSection(section, secIndex))}
      </SectionsTableContainer>
      <Modal
        isOpen={!!modalExplanation}
        onClose={() => setModalExplanation(undefined)}
        title={t('explanationTitle')}
        content={<p>{modalExplanation}</p>}
      />
    </PageWithBannerTitle>
  )
}

export default ReportView
