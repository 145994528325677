import { ClientUser } from 'models/client'
export interface Auth0User extends Omit<IdToken, '__raw'> {
  'https://app_metadata.com/': {
    clientId?: string
    companyId?: string
  }
}

export const mockAuth0Admin: Auth0User = {
  name: 'Joe Smith',
  email: 'jsmith@axyqcapital.com',
  email_verified: true,
  'https://app_metadata.com/': {
    clientId: 'b95cc8aa-4ccb-42f1-97ee-bd88b0d8703f',
    companyId: '6659748d-f381-4b9d-92e5-ec7a4770e9f7',
  },
  nickname: 'jsmith',
  picture: '',
  updated_at: '2021-03-01T19:59:36.405Z',
}

export const mockAdminUsers: ClientUser[] = [
  {
    status: 'ACTIVE',
    id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
    name: 'Joe Smith',
    email: 'jsmith@axyqcapital.com',
  },
  {
    status: 'ACTIVE',
    id: 'ee350101-4eb4-4d57-adc9-278652bd6e2f',
    name: 'Robin Wave',
    email: 'rwave@axyqcapital.com',
  },
]

export const mockAdminInvites: ClientUser[] = [
  {
    status: 'ACTIVE',
    id: '6403789e-6dfe-4083-9225-32da251209a0',
    name: 'Dan Jones',
    email: 'djones@axyqcapital.com',
  },
]
