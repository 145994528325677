const mockDashboardData = [
  {
    id: '5dfea84d-4945-41d7-a39f-cda7e74b809b',
    name: '2019 Q4',
    quarter: '2019 Q4',
    totalReportsSent: 79,
    dueByDates: ['2020-01-01T22:02:05.343Z'],
    questionStatistics: {
      COMPLIANT: 600,
      NON_COMPLIANT: 313,
      NOT_APPLICABLE: 601,
    },
    templates: [
      {
        id: '5e719d503aebf3bb5264d32a',
        name: 'Fund Default',
        numCompanies: 177,
      },
    ],
    reportStatusCounts: {
      COMPLIANT: 8,
      NON_COMPLIANT: 5,
      PENDING: 4,
      OVERDUE: 1,
    },
    reportUserStatusCounts: {
      COMPLIANT: 8,
      NON_COMPLIANT: 5,
      PENDING: 4,
      OVERDUE: 1,
    },
  },
  {
    id: '979ca42b-c205-4e6c-84ed-a5d411d642f5',
    name: '2020 Q1',
    quarter: '2020 Q1',
    totalReportsSent: 123,
    dueByDates: ['2020-04-01T22:02:05.343Z'],
    questionStatistics: {
      COMPLIANT: 354,
      NON_COMPLIANT: 719,
      NOT_APPLICABLE: 836,
    },
    templates: [
      {
        id: '5e719d503aebf3bb5264d32a',
        name: 'Fund Default',
        numCompanies: 123,
      },
    ],
    reportStatusCounts: {
      COMPLIANT: 9,
      NON_COMPLIANT: 3,
      PENDING: 0,
      OVERDUE: 0,
    },
    reportUserStatusCounts: {
      COMPLIANT: 3,
      NON_COMPLIANT: 4,
      PENDING: 0,
      OVERDUE: 0,
    },
  },
  {
    id: '8d84f118-4918-4102-8837-6f17ace9b8b4',
    name: '2020 Q2',
    quarter: '2020 Q2',
    totalReportsSent: 106,
    dueByDates: ['2020-07-01T22:02:05.343Z'],
    questionStatistics: {
      COMPLIANT: 673,
      NON_COMPLIANT: 499,
      NOT_APPLICABLE: 860,
    },
    templates: [
      {
        id: '5e719d503aebf3bb5264d32a',
        name: 'Fund Default',
        numCompanies: 166,
      },
    ],
    reportStatusCounts: {
      COMPLIANT: 13,
      NON_COMPLIANT: 5,
      PENDING: 0,
      OVERDUE: 1,
    },
    reportUserStatusCounts: {
      COMPLIANT: 5,
      NON_COMPLIANT: 2,
      PENDING: 0,
      OVERDUE: 1,
    },
  },
  {
    id: 'cf16cb90-9bb2-4c32-99a4-4dc91fd292f0',
    name: '2020 Q3',
    quarter: '2020 Q3',
    totalReportsSent: 79,
    dueByDates: ['2020-10-01T22:02:05.344Z'],
    questionStatistics: {
      COMPLIANT: 600,
      NON_COMPLIANT: 313,
      NOT_APPLICABLE: 601,
    },
    templates: [
      {
        id: '5e719d503aebf3bb5264d32a',
        name: 'Fund Default',
        numCompanies: 177,
      },
    ],
    reportStatusCounts: {
      COMPLIANT: 13,
      NON_COMPLIANT: 1,
      PENDING: 0,
      OVERDUE: 4,
    },
    reportUserStatusCounts: {
      COMPLIANT: 7,
      NON_COMPLIANT: 1,
      PENDING: 0,
      OVERDUE: 2,
    },
  },
  {
    id: '8866e8ef-70da-4060-b521-1bcb616e7263',
    name: '2020 Q4',
    quarter: '2020 Q4',
    totalReportsSent: 28,
    dueByDates: ['2021-01-01T22:02:05.343Z'],
    questionStatistics: {
      COMPLIANT: 402,
      NON_COMPLIANT: 64,
      NOT_APPLICABLE: 39,
    },
    templates: [
      {
        id: '5e719d503aebf3bb5264d32a',
        name: 'Fund Default',
        numCompanies: 166,
      },
    ],
    reportStatusCounts: {
      COMPLIANT: 18,
      NON_COMPLIANT: 5,
      PENDING: 4,
      OVERDUE: 1,
    },
    reportUserStatusCounts: {
      COMPLIANT: 4,
      NON_COMPLIANT: 3,
      PENDING: 2,
      OVERDUE: 1,
    },
  },
]

export default mockDashboardData
