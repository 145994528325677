import { Metric, QuestionMetric } from 'models/metrics'

export const exampleQuestionMetrics: Record<string, QuestionMetric> = {
  MULTI_SELECT: {
    id: 'ef47bbd8-78b1-4748-b76f-de56e971251a',
    query: 'Choose all that apply',
    type: 'MULTI_SELECT',
    totals: {
      Blue: 4,
      Green: 2,
      Red: 3,
    },
    companyMetrics: [
      {
        id: 'bbc6b7bc-4716-40ac-be23-3e21415eab92',
        name: 'Betterplace',
        attestation: {
          selections: ['Blue', 'Green'],
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '23946c59-b460-4045-b52b-ece2e35e9636',
            name: 'Ella Neal',
            attestation: {
              selections: ['Blue', 'Green'],
            },
            status: 'ACTIVE',
          },
          {
            id: '001f5976-68f8-4859-b49c-5a7511888edc',
            name: 'Sarah Garza',
            attestation: {
              selections: ['Blue', 'Green'],
            },
            status: 'ACTIVE',
          },
          {
            id: '235fc6bb-bbe2-4f0e-b32d-354a9e57cb77',
            name: 'Ernest Cortez',
            attestation: {
              selections: ['Blue', 'Green'],
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: 'd169ca97-6d51-4ebb-9031-1b6b2f415672',
        name: 'Flyant',
        attestation: {
          selections: ['Blue'],
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '4311ee4a-7353-4b60-b138-be47069a4f5b',
            name: 'Charlotte Perez',
            attestation: {
              selections: ['Blue'],
            },

            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '41ff8cbb-3d62-4495-9cfc-792b76c7e1e0',
        name: 'Fusion',
        attestation: {
          selections: ['Blue', 'Red'],
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '89b7f6d0-a5be-412e-8c91-4b66c293c566',
            name: 'James Higgins',
            attestation: {
              selections: ['Blue', 'Red'],
            },
            status: 'ACTIVE',
          },
          {
            id: '0d62d790-f885-4b6a-830e-f19b9fe3191c',
            name: 'Cedric Knight',
            attestation: {
              selections: ['Blue', 'Red'],
            },
            status: 'ACTIVE',
          },
          {
            id: '5691b34c-fed5-401d-a4b4-ab9128ba3a87',
            name: 'Antonio Lopez',
            attestation: {
              selections: ['Blue', 'Red'],
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '010b4786-fafd-486c-a1b9-9aa36ef02c11',
        name: 'Robininvest',
        attestation: {
          selections: ['Blue', 'Green', 'Red'],
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'b6495ae9-e900-4d2c-b5ea-943ad75d398e',
            name: 'Ross Richards',
            attestation: {
              selections: ['Blue', 'Green', 'Red'],
            },
            status: 'ACTIVE',
          },
          {
            id: 'f3019621-9cee-46c5-85c8-07308c010879',
            name: 'Steven Andrews',
            attestation: {
              selections: ['Blue', 'Green', 'Red'],
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '6b2b7aa3-f33a-4a3d-afd7-2404c7087d26',
        name: 'Zipnow',
        attestation: {
          selections: ['Green', 'Red'],
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '7bb0614b-29eb-4275-9e72-9313b08e3b53',
            name: 'Harold Ball',
            attestation: {
              selections: ['Green', 'Red'],
            },
            status: 'ACTIVE',
          },
          {
            id: '9aaa27aa-499a-4c18-8286-dc94352ddba0',
            name: 'Sheila Guerrero',
            attestation: {
              selections: ['Green', 'Red'],
            },
            status: 'ACTIVE',
          },
        ],
      },
    ],
  },
  MULTIPLE_CHOICE: {
    id: '9a6764a2-74ae-46ea-833d-949ef3c07ca3',
    query: 'Does your organization have an ESG Policy.',
    type: 'MULTIPLE_CHOICE',
    totals: {
      Yes: 2,
      No: 1,
      "I Don't Know": 2,
    },
    companyMetrics: [
      {
        id: 'a874fa08-e43b-472a-bc61-9c903f1bcc8d',
        name: 'Betterplace',
        attestation: 'Yes',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '83b5fe3e-3c6c-4216-bc7c-9920bdc8f3d1',
            name: 'Ella Neal',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '4d626166-54ef-4d41-9d5a-1b5b0e9d8b49',
            name: 'Sarah Garza',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: 'ce1cd011-6b91-415b-a414-58ae3e7f6ba3',
            name: 'Ernest Cortez',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '0cbe8534-b80b-47e6-9416-387620770cd0',
        name: 'Flyant',
        attestation: 'No',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '08f83983-bdcb-471e-9287-139b318d65d0',
            name: 'Charlotte Perez',
            attestation: 'No',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '79a98136-5487-455c-872c-77a1a038ce1f',
        name: 'Fusion',
        attestation: "I Don't Know",
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '501b8848-c325-4bef-98cd-2fa1d4c40b35',
            name: 'James Higgins',
            attestation: "I Don't Know",
            status: 'ACTIVE',
          },
          {
            id: '8a5d3168-dead-4342-99d8-09f8d3d1d79f',
            name: 'Cedric Knight',
            attestation: "I Don't Know",
            status: 'ACTIVE',
          },
          {
            id: '9267f7af-b005-4466-9fa9-881c31dae73a',
            name: 'Antonio Lopez',
            attestation: "I Don't Know",
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '5caf4151-7042-4e28-a3ea-f1e1d0f60c06',
        name: 'Robininvest',
        attestation: 'Yes',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'a6cd9f90-eaf8-4c41-b9ad-4c59d87c8095',
            name: 'Ross Richards',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '365efab6-8ecb-43be-b758-fc1328fadf33',
            name: 'Steven Andrews',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '54891d47-2406-4b7e-ae86-7b3cf2c6b42b',
        name: 'Zipnow',
        attestation: "I Don't Know",
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '1dbd65b4-c147-4157-81b5-177e305a1e92',
            name: 'Harold Ball',
            attestation: "I Don't Know",
            status: 'ACTIVE',
          },
          {
            id: 'ab958d6d-dfc4-46b2-97b5-ff60c64eff99',
            name: 'Sheila Guerrero',
            attestation: "I Don't Know",
            status: 'ACTIVE',
          },
        ],
      },
    ],
  },
  YES_NO: {
    id: '9f4d598b-b29b-4439-a6c1-86b62a1f02d9',
    query: 'Does your organization have an ESG Policy.',
    type: 'MULTIPLE_CHOICE',
    totals: {
      Yes: 4,
      No: 1,
    },
    companyMetrics: [
      {
        id: '26acd19c-298b-4997-bf79-8d247638475e',
        name: 'Betterplace',
        attestation: 'Yes',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '4ae85706-a891-41ff-8c68-525221e5c22c',
            name: 'Ella Neal',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '4f86c63e-391a-45cc-bbc5-5e5b20c949f1',
            name: 'Sarah Garza',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '445bb0f5-ba93-4c00-9ef0-9ce3972e2909',
            name: 'Ernest Cortez',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '2b33f27b-6afd-4ede-a24d-ccdb55befb92',
        name: 'Flyant',
        attestation: 'No',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'ec4b1f10-7d41-48b1-ae79-0a3818c0ef70',
            name: 'Charlotte Perez',
            attestation: 'No',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '1c5bc7c4-1b7f-4af8-ad70-6af2d8bd2b35',
        name: 'Fusion',
        attestation: 'Yes',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '0a83fc71-4335-4b39-882a-0c9913047657',
            name: 'James Higgins',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '5848f812-669c-4b72-8c67-b9eceaeb2867',
            name: 'Cedric Knight',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '4538bcd4-2783-47ee-9756-089854effae7',
            name: 'Antonio Lopez',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '65fd0ae1-e9e6-4729-b683-c44e2b7e667f',
        name: 'Robininvest',
        attestation: 'Yes',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '036d3e34-e366-4e3e-90b7-3676d8f028c8',
            name: 'Ross Richards',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: 'd99f1812-a246-4b57-91ee-0b364e136421',
            name: 'Steven Andrews',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '556ec7b3-d195-4a2e-a2a4-7f5a9122623e',
        name: 'Zipnow',
        attestation: 'Yes',
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'fcc70499-36b8-4043-88f8-6d3dc5c739d6',
            name: 'Harold Ball',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
          {
            id: '4c6b2830-96e6-4131-9e42-b18a33e980e5',
            name: 'Sheila Guerrero',
            attestation: 'Yes',
            status: 'ACTIVE',
          },
        ],
      },
    ],
  },
  BREAKDOWN: {
    id: 'f0199a59-0ddf-4e15-afee-6c28d555c24f',
    query: 'What is the gender breakdown of your board of directors?',
    type: 'BREAKDOWN',
    totals: {
      Female: 21,
      Male: 28,
      Other: 2,
    },
    options: ['Female', 'Male', 'Other'],
    companyMetrics: [
      {
        id: '783ff492-7ac3-44bb-8976-9aab5a56e7fc',
        name: 'Betterplace',
        attestation: {
          Female: 6,
          Male: 9,
          Other: 0,
        },
        status: 'ENABLED',
        isConflicting: true,
        users: [
          {
            id: 'c051a76f-9059-4e62-b317-847b803e97a0',
            name: 'Ella Neal',
            attestation: {
              Female: 6,
              Male: 8,
              Other: 1,
            },
            status: 'ACTIVE',
          },
          {
            id: 'c72bd500-ddf8-4329-897c-a002f5eda71b',
            name: 'Sarah Garza',
            attestation: {
              Female: 6,
              Male: 9,
              Other: 0,
            },
            status: 'ACTIVE',
          },
          {
            id: '0199da13-f44e-4774-9360-33d49c5cbe32',
            name: 'Ernest Cortez',
            attestation: {
              Female: 6,
              Male: 9,
              Other: 0,
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '13a8811c-f932-42d1-a371-5f5b11e39edc',
        name: 'Flyant',
        attestation: {
          Female: 2,
          Male: 12,
          Other: 0,
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '63254179-1bdf-42f3-805c-1ce135602189',
            name: 'Charlotte Perez',
            attestation: {
              Female: 2,
              Male: 12,
              Other: 0,
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '4e02683b-107a-4a81-a0fb-4efe7d8da5c4',
        name: 'Fusion',
        attestation: {
          Female: 7,
          Male: 5,
          Other: 1,
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'a9be6037-d8a4-4f8d-b2a9-a7e8adcb8cf7',
            name: 'James Higgins',
            attestation: {
              Female: 7,
              Male: 5,
              Other: 1,
            },
            status: 'ACTIVE',
          },
          {
            id: '72132aa2-1188-449d-b848-a6d46bcbe702',
            name: 'Cedric Knight',
            attestation: {
              Female: 7,
              Male: 5,
              Other: 1,
            },
            status: 'ACTIVE',
          },
          {
            id: 'badfea1c-8901-46b5-af88-44d126279ba2',
            name: 'Antonio Lopez',
            attestation: {
              Female: 7,
              Male: 5,
              Other: 1,
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '1d362967-dc17-4bbe-b7a7-e59bde1ee417',
        name: 'Robininvest',
        attestation: {
          Female: 3,
          Male: 2,
          Other: 4,
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '411ba2a3-9ac8-4544-a1fa-65a4ffff58e1',
            name: 'Ross Richards',
            attestation: {
              Female: 3,
              Male: 2,
              Other: 4,
            },
            status: 'ACTIVE',
          },
          {
            id: '8e9a7256-8301-4947-b31d-e027f790debf',
            name: 'Steven Andrews',
            attestation: {
              Female: 3,
              Male: 2,
              Other: 4,
            },
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '608646e2-fb76-4e09-958c-6febbaad7ac0',
        name: 'Zipnow',
        attestation: {
          Female: 3,
          Male: 1,
          Other: 2,
        },
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '02bdaeb9-bf09-47ff-9457-1348840ac7dd',
            name: 'Harold Ball',
            attestation: {
              Female: 3,
              Male: 1,
              Other: 2,
            },
            status: 'ACTIVE',
          },
          {
            id: 'a05b76b6-bed4-4936-94f7-17d3365f8f88',
            name: 'Sheila Guerrero',
            attestation: {
              Female: 3,
              Male: 1,
              Other: 2,
            },
            status: 'ACTIVE',
          },
        ],
      },
    ],
  },
  INTEGER: {
    id: '272528b8-48e7-41c1-a9f3-f12ba5b98ecc',
    query: 'How many ransomware incidents have you incurred in the past quarter?',
    type: 'INTEGER',
    totals: {
      minValue: 2,
      average: 7,
      maxValue: 16,
    },
    companyMetrics: [
      {
        id: '55c3a224-a59a-4619-a664-362ca9a49f62',
        name: 'Betterplace',
        attestation: 16,
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '2599de0b-ba0f-455e-860b-795cb48dd673',
            name: 'Ella Neal',
            attestation: 16,
            status: 'ACTIVE',
          },
          {
            id: '1900b98e-a361-43f6-8bd1-8e81185494ff',
            name: 'Sarah Garza',
            attestation: 16,
            status: 'ACTIVE',
          },
          {
            id: '3b2e2fbd-f652-426b-b23c-8429850850e6',
            name: 'Ernest Cortez',
            attestation: 16,
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: '0c83a734-df0b-4b54-b51f-5a5b619a993d',
        name: 'Flyant',
        attestation: 2,
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '7bb61253-ddda-4c24-b799-856dc530c972',
            name: 'Charlotte Perez',
            attestation: 2,
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: 'c47a6af8-94a3-4c37-9de0-6ee7cd71deea',
        name: 'Fusion',
        attestation: 4,
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'ba039747-d8d2-42c1-b677-063ae265f09d',
            name: 'James Higgins',
            attestation: 4,
            status: 'ACTIVE',
          },
          {
            id: 'aaa6de22-85a4-44b8-9211-4b7f82c81f1a',
            name: 'Cedric Knight',
            attestation: 4,
            status: 'ACTIVE',
          },
          {
            id: '3c9323a8-c7f9-4a8b-8334-77109dc2bce8',
            name: 'Antonio Lopez',
            attestation: 4,
            status: 'ACTIVE',
          },
        ],
      },
    ],
  },
  PERCENTAGE: {
    id: '10fb2311-2c33-4a44-a8c1-06297ef1d081',
    query: 'What percentage of your workforce is paid hourly?',
    type: 'PERCENTAGE',
    totals: {
      minValue: 17,
      average: 46,
      maxValue: 78,
    },
    companyMetrics: [
      {
        id: 'd5435266-f698-4b29-a3e3-1f669e69b783',
        name: 'Betterplace',
        attestation: 42,
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: 'd94cd0dd-eaf6-47a9-a148-c0fd8f43c7e7',
            name: 'Ella Neal',
            attestation: 42,
            status: 'ACTIVE',
          },
          {
            id: '6156d656-7d2c-4a2a-b4dd-bcb125e98ada',
            name: 'Sarah Garza',
            attestation: 42,
            status: 'ACTIVE',
          },
          {
            id: '13d0ae29-2460-4ffc-b0a9-582a91d1c9b6',
            name: 'Ernest Cortez',
            attestation: 42,
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: 'd10706ea-3257-40ec-bc96-9893635f7500',
        name: 'Flyant',
        attestation: 17,
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '44246544-ab37-43cd-b49b-8fdee8fa0b85',
            name: 'Charlotte Perez',
            attestation: 17,
            status: 'ACTIVE',
          },
        ],
      },
      {
        id: 'c44721ad-3a41-433c-9202-01231ee442e2',
        name: 'Fusion',
        attestation: 78,
        status: 'ENABLED',
        isConflicting: false,
        users: [
          {
            id: '753148e3-426d-4777-9f4f-79e5dd959605',
            name: 'James Higgins',
            attestation: 78,
            status: 'ACTIVE',
          },
          {
            id: '7380e980-7c22-485b-92b0-4dab93711597',
            name: 'Cedric Knight',
            attestation: 78,
            status: 'ACTIVE',
          },
          {
            id: 'bf380d61-3647-437a-9aec-f944b7a03173',
            name: 'Antonio Lopez',
            attestation: 78,
            status: 'ACTIVE',
          },
        ],
      },
    ],
  },
}

export const mockMetrics: Record<string, Metric> = {
  'c90d80cd-0cb2-47e7-a02c-7ceed3930a09': {
    id: '264a18a6-1ed5-454d-b0b6-64d7f45ca149',
    name: 'ESG: Policies and Procedures',
    created: '2021-07-15T14:48:00.000Z',
    template: {
      id: '8785a627-7e24-40d7-90fd-2dcd07783b2f',
      name: 'ESG',
    },
    section: {
      id: 'e3e37a31-0149-43e0-8d14-d8765b805b5e',
      name: 'Policies and Procedures',
    },
    questionMetrics: [
      {
        id: '668af151-e673-4f17-8866-1c93dc51a5cd',
        query:
          'Does your organization have an ESG Policy. Download an example [here](https://dallascounty.civicweb.net/document/674039/Saluting%20Dallas%20Maverick%20Luka%20Dončić.pdf?handle=727D545F96994640BC46C3B3208EEB9F).',
        type: 'MULTIPLE_CHOICE',
        options: ['Yes', 'No', "I Don't Know"],
        totals: {
          Yes: 5,
          No: 6,
          "I Don't Know": 3,
        },
        companyMetrics: [
          {
            id: '7f2c2703-f858-43ab-a407-16b5da93b2c9',
            name: 'Betterplace',
            attestation: 'Yes',
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '34dfcc87-e171-4c95-9dca-99c222b1fbf6',
                name: 'Ella Neal',
                attestation: 'Yes',
                status: 'ACTIVE',
              },
              {
                id: 'd9fe7e1e-0ef4-439b-98ef-f4675289b9a2',
                name: 'Sarah Garza',
                attestation: 'Yes',
                status: 'ACTIVE',
              },
              {
                id: '1faf452d-57ad-4070-837a-e8cf5f01ae7f',
                name: 'Ernest Cortez',
                attestation: 'Yes',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '4423125c-a1e8-434f-9da6-cb677f592e30',
            name: 'Flyant',
            attestation: 'No',
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: 'd544f006-2c4f-49a3-93ff-49f363c5abf5',
                name: 'Charlotte Perez',
                attestation: 'No',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '6ad7ccde-3b3c-442a-aee7-086a1e767a6d',
            name: 'Fusion',
            attestation: 'CONFLICTING_ANSWERS',
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: '817d655d-f6f5-4f71-8a89-d4e6bade53ba',
                name: 'James Higgins',
                attestation: 'Yes',
                status: 'ACTIVE',
              },
              {
                id: '0f55c661-94a0-4d70-8c6e-1ca5d460bf2f',
                name: 'Cedric Knight',
                attestation: 'No',
                status: 'ACTIVE',
              },
              {
                id: '39d21167-363e-41b3-a514-f2b017161dab',
                name: 'Antonio Lopez',
                attestation: 'Yes',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '4e1a5557-d176-4bb5-a815-03ea96f0d366',
            name: 'Robininvest',
            attestation: 'CONFLICTING_ANSWERS',
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: '915c9800-5550-4518-805a-abfce9c9c1af',
                name: 'Ross Richards',
                attestation: 'Yes',
                status: 'ACTIVE',
              },
              {
                id: '15fa0e91-2cc4-45fe-a97b-10b4be4dd857',
                name: 'Steven Andrews',
                attestation: "I Don't Know",
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'c59be6dd-c256-4185-bdbe-2043808a97d9',
            name: 'Zipnow',
            attestation: "I Don't Know",
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '2eea0898-5f13-4c0d-8d1b-47eac60e9421',
                name: 'Harold Ball',
                attestation: "I Don't Know",
                status: 'ACTIVE',
              },
              {
                id: 'a98547e0-87ce-4e11-90ad-67bc76fc769c',
                name: 'Sheila Guerrero',
                attestation: "I Don't Know",
                status: 'ACTIVE',
              },
            ],
          },
        ],
      },
      {
        id: 'c265a2f5-c23e-4a85-a4aa-26571c030aed',
        query: 'What is the gender breakdown of your board of directors?',
        type: 'BREAKDOWN',
        options: ['Female', 'Male', 'Other'],
        totals: {
          Female: 21,
          Male: 28,
          Other: 2,
        },
        companyMetrics: [
          {
            id: '48abc9f2-f0a8-4872-acc4-4181b882c398',
            name: 'Betterplace',
            attestation: {
              Female: 6,
              Male: 9,
              Other: 0,
            },
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: 'df5baaea-cc1e-4962-b4a8-6c326537e02c',
                name: 'Ella Neal',
                attestation: {
                  Female: 6,
                  Male: 8,
                  Other: 1,
                },
                status: 'ACTIVE',
              },
              {
                id: '6e79b105-a3b4-459b-9c71-5a84fe8242ca',
                name: 'Sarah Garza',
                attestation: {
                  Female: 6,
                  Male: 9,
                  Other: 0,
                },
                status: 'ACTIVE',
              },
              {
                id: '82c534e7-8ccc-4e04-a8db-5503600c33ff',
                name: 'Ernest Cortez',
                attestation: {
                  Female: 6,
                  Male: 9,
                  Other: 0,
                },
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '7d072629-74cf-48dc-9a2c-76463594b3a0',
            name: 'Flyant',
            attestation: {
              Female: 2,
              Male: 12,
              Other: 0,
            },
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: 'e1d6132a-b5b2-4eeb-b2c2-f8174716eb3b',
                name: 'Charlotte Perez',
                attestation: {
                  Female: 2,
                  Male: 12,
                  Other: 0,
                },
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'cf010ec1-9bc1-4c70-b4c5-db5e0a2aa2fc',
            name: 'Fusion',
            attestation: {
              Female: 7,
              Male: 5,
              Other: 1,
            },
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '6ed501a1-52a5-4f7a-bd03-03f080cd2a64',
                name: 'James Higgins',
                attestation: {
                  Female: 7,
                  Male: 5,
                  Other: 1,
                },
                status: 'ACTIVE',
              },
              {
                id: '6ab3d7e7-1028-4a49-aeea-9efc26a5c0ea',
                name: 'Cedric Knight',
                attestation: {
                  Female: 7,
                  Male: 5,
                  Other: 1,
                },
                status: 'ACTIVE',
              },
              {
                id: '314bc2c2-23e2-4c71-a051-1b347f8d5a2d',
                name: 'Antonio Lopez',
                attestation: {
                  Female: 7,
                  Male: 5,
                  Other: 1,
                },
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '74ccc523-42a4-4825-bd01-0f5c077bd0c9',
            name: 'Robininvest',
            attestation: {
              Female: 3,
              Male: 2,
              Other: 4,
            },
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '2f582949-770e-4191-b707-6b931672c09f',
                name: 'Ross Richards',
                attestation: {
                  Female: 3,
                  Male: 2,
                  Other: 4,
                },
                status: 'ACTIVE',
              },
              {
                id: '20f38ec9-a5d2-4bee-ac28-10d113306923',
                name: 'Steven Andrews',
                attestation: {
                  Female: 3,
                  Male: 2,
                  Other: 4,
                },
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'f0635aea-02c1-4ae0-a698-3e6d34ac3654',
            name: 'Zipnow',
            attestation: {
              Female: 3,
              Male: 1,
              Other: 2,
            },
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '4f36ea1e-e37a-459c-b2f2-15e353a229ea',
                name: 'Harold Ball',
                attestation: {
                  Female: 3,
                  Male: 1,
                  Other: 2,
                },
                status: 'ACTIVE',
              },
              {
                id: '9c4c5f38-a605-4395-9689-d3987ee64d5f',
                name: 'Sheila Guerrero',
                attestation: {
                  Female: 3,
                  Male: 1,
                  Other: 2,
                },
                status: 'ACTIVE',
              },
            ],
          },
        ],
      },
      {
        id: '7149a56e-18b6-4a10-982b-96e365fb5149',
        query: 'How many ransomware incidents have you incurred in the past quarter?',
        type: 'INTEGER',
        totals: {
          minValue: 0,
          average: 1,
          maxValue: 3,
        },
        companyMetrics: [
          {
            id: 'f5d5e03d-ce8e-40b6-9c34-f733dd37dff5',
            name: 'Betterplace',
            attestation: 16,
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: '999942e8-3708-41c9-93ad-cc98548753e2',
                name: 'Ella Neal',
                attestation: 14,
                status: 'ACTIVE',
              },
              {
                id: '2ed68942-fec0-4440-99b2-41605fb33bfb',
                name: 'Sarah Garza',
                attestation: 17,
                status: 'ACTIVE',
              },
              {
                id: 'c2c0f7bd-3fd4-43ea-98fe-c5ec4e81dac4',
                name: 'Ernest Cortez',
                attestation: 19,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '625c7650-3d79-4096-9cdc-8b83a745a5ed',
            name: 'Flyant',
            attestation: 3,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: 'c2a8f840-2696-4e9c-9149-4867992a715c',
                name: 'Charlotte Perez',
                attestation: 3,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'a433b72f-d75f-41ee-86e6-938a8f026ef8',
            name: 'Fusion',
            attestation: 1,
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: 'c523131f-2d37-4b94-8e14-74220982074a',
                name: 'James Higgins',
                attestation: 1,
                status: 'ACTIVE',
              },
              {
                id: '0f16d435-8d8c-4390-82da-b02057518ead',
                name: 'Cedric Knight',
                attestation: 1,
                status: 'ACTIVE',
              },
              {
                id: 'c51003dd-dc0f-4ef7-bc35-4895a294112d',
                name: 'Antonio Lopez',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '6224fd42-db3d-45ba-b0cb-d9f7434e069f',
            name: 'Robininvest',
            attestation: 0,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: 'e68c3cba-bee2-4c6f-88e2-ee37fbe647ca',
                name: 'Ross Richards',
                attestation: 0,
                status: 'ACTIVE',
              },
              {
                id: '469d8a38-8475-4ccf-8019-bf8efe578492',
                name: 'Steven Andrews',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '1488838d-8b50-4d2f-9879-699f435cc95e',
            name: 'Zipnow',
            attestation: 0,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '8000e507-cabc-478b-b832-09c7ce6fa084',
                name: 'Harold Ball',
                attestation: 0,
                status: 'ACTIVE',
              },
              {
                id: '74090780-0e85-4b07-8591-29daefbae3f5',
                name: 'Sheila Guerrero',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
        ],
      },
    ],
  },

  'c23edbbc-4223-4a7a-928c-dc4a43a518bc': {
    id: '2c706813-0faa-4f3f-9068-cc49f9d97476',
    name: 'Human Resources',
    created: '2021-07-03T14:48:00.000Z',
    template: {
      id: '8246a0ec-33cb-4a7a-b939-31befeaf5234',
      name: 'ESG',
    },
    section: {
      id: '4e18f0f8-ee76-4acd-9748-fd9dd23ea468',
      name: 'Policies and Procedures',
    },
    questionMetrics: [
      {
        id: '6d5deb67-8a78-4e15-a63d-df4409f17389',
        query: 'What percentage of your workforce is paid hourly?',
        type: 'PERCENTAGE',
        totals: {
          minValue: 0,
          average: 12,
          maxValue: 78,
        },
        companyMetrics: [
          {
            id: '0d3e8710-322c-4802-a8e1-daaaa31aaf06',
            name: 'Betterplace',
            attestation: 42,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '5b1e888f-33a3-48f0-b5ea-89d7386f55cc',
                name: 'Ella Neal',
                attestation: 42,
                status: 'ACTIVE',
              },
              {
                id: '7f504ddb-bfdd-4cc7-92fc-598fa0be7dce',
                name: 'Sarah Garza',
                attestation: 42,
                status: 'ACTIVE',
              },
              {
                id: 'cb9f2e6f-382f-40b6-86c9-ac4fcd84cc02',
                name: 'Ernest Cortez',
                attestation: 42,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'd61ef51b-34e9-4f24-95ff-38f01c1b4e45',
            name: 'Flyant',
            attestation: 42,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '3f3516e4-db1a-4f96-8161-da7bcc5f7ba2',
                name: 'Charlotte Perez',
                attestation: 42,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'f76042cf-efd0-46cf-9624-bd9d0747df91',
            name: 'Fusion',
            attestation: 42,
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: 'afc79091-dc96-4171-b5d7-0eff422fa528',
                name: 'James Higgins',
                attestation: 42,
                status: 'ACTIVE',
              },
              {
                id: '309e8c4c-5b84-43c9-bcac-68a2935b8eba',
                name: 'Cedric Knight',
                attestation: 42,
                status: 'ACTIVE',
              },
              {
                id: '72279ff1-f76d-4f6e-9193-ecef4e535427',
                name: 'Antonio Lopez',
                attestation: 42,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '0f90d5ad-bc9a-4197-99b6-6bac9aa26a52',
            name: 'Robininvest',
            attestation: 42,
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: '7da25831-1aec-46ab-b92a-91e3cb504979',
                name: 'Ross Richards',
                attestation: 42,
                status: 'ACTIVE',
              },
              {
                id: '882c2e69-ff46-443b-882e-3d5b0ac9646f',
                name: 'Steven Andrews',
                attestation: 42,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '9b1a4aac-469c-4ff8-8290-c35db5d5a0a4',
            name: 'Zipnow',
            attestation: 42,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '08ba7a66-7e39-457f-9aed-5dd0b437e03a',
                name: 'Harold Ball',
                attestation: 42,
                status: 'ACTIVE',
              },
              {
                id: 'b5373a6b-dc25-441c-8967-e3b08d8c1880',
                name: 'Sheila Guerrero',
                attestation: 42,
                status: 'ACTIVE',
              },
            ],
          },
        ],
      },
      {
        id: '6f4a41d4-be80-4330-8de4-a33d124376ba',
        query: 'When is an employee background check performed?',
        type: 'MULTIPLE_CHOICE',
        totals: {
          'Before Offer': 10,
          'Before First Day': 16,
          'Not Done': 8,
        },
        companyMetrics: [
          {
            id: '3690f0cc-76fc-4a69-a96f-c22da662d618',
            name: 'Betterplace',
            attestation: 'Before Offer',
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: 'f2f16b49-808a-4259-abc4-dbe32a18366c',
                name: 'Ella Neal',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
              {
                id: '57ad9d56-15f7-411f-b3e0-3d502d47d645',
                name: 'Sarah Garza',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
              {
                id: '55b5c974-8bf7-4d4f-8d52-4a037b695330',
                name: 'Ernest Cortez',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '08bf732a-ac7f-4184-be84-fb6dd4068f5e',
            name: 'Flyant',
            attestation: 'Before Offer',
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '5e4e8fa8-0b84-44b6-97af-0c9413f94164',
                name: 'Charlotte Perez',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '204d22c9-bb4e-4767-90c0-bc49ddf9fa20',
            name: 'Fusion',
            attestation: 'Before Offer',
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: 'b67e934b-5aa3-4cb8-a789-23d5b2d5bc3e',
                name: 'James Higgins',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
              {
                id: '094f4aae-146d-4521-9290-5de9498478c7',
                name: 'Cedric Knight',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
              {
                id: '1d458736-2926-4ddb-a031-506361c7292b',
                name: 'Antonio Lopez',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '092f17fc-7fc7-446c-82bb-9964555f8258',
            name: 'Robininvest',
            attestation: 'Before Offer',
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '23627cb4-e86d-45b7-987d-a95b0a7a29ea',
                name: 'Ross Richards',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
              {
                id: 'ab52bee4-72c8-42e7-bce4-93e98cf27137',
                name: 'Steven Andrews',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'e6241ba8-df35-4eec-920f-e94a00d368cb',
            name: 'Zipnow',
            attestation: 'Before Offer',
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '0ad5cb2b-c164-4fa9-b1ff-7105e157e143',
                name: 'Harold Ball',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
              {
                id: '4b6ce954-8c74-44e0-bd72-b4b35133d5d4',
                name: 'Sheila Guerrero',
                attestation: 'Before Offer',
                status: 'ACTIVE',
              },
            ],
          },
        ],
      },
      {
        id: '266da099-dc1f-48a1-888f-17cfeffcc7b1',
        query: 'How many employees are currently on salary in your organization?',
        type: 'INTEGER',
        totals: {
          minValue: 3,
          average: 42,
          maxValue: 144,
        },
        companyMetrics: [
          {
            id: '522477dd-b393-439c-aeed-90f26b9b008a',
            name: 'Betterplace',
            attestation: 0,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: 'e906db00-7cc0-465a-8c57-953f62fc72ba',
                name: 'Ella Neal',
                attestation: 0,
                status: 'ACTIVE',
              },
              {
                id: '7723a101-4b1f-44fc-9da7-45697ffea5f8',
                name: 'Sarah Garza',
                attestation: 0,
                status: 'ACTIVE',
              },
              {
                id: '28416a15-ff0b-4a1c-bdb9-3017ca99fb1b',
                name: 'Ernest Cortez',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: '00af964e-695e-41a0-91f5-c0bad379c151',
            name: 'Flyant',
            attestation: 3,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '964b6eeb-129f-44c9-813a-c235667d08c6',
                name: 'Charlotte Perez',
                attestation: 3,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'b6c6d7b5-5c3e-4747-bc66-afb06fabd726',
            name: 'Fusion',
            attestation: 1,
            status: 'ENABLED',
            isConflicting: true,
            users: [
              {
                id: '1a7f7dcf-b08e-4ec5-b5e1-08f82c23c113',
                name: 'James Higgins',
                attestation: 1,
                status: 'ACTIVE',
              },
              {
                id: '66861bde-13a4-43c8-91e8-4431177908c1',
                name: 'Cedric Knight',
                attestation: 1,
                status: 'ACTIVE',
              },
              {
                id: '65ae2e8d-d364-4cc9-bb37-8e7d309b3dd5',
                name: 'Antonio Lopez',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'b54a4284-678b-4e88-8f68-04577b6dc041',
            name: 'Robininvest',
            attestation: 0,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '81ffe3d7-df93-4623-9b0a-3969c7f2a6b3',
                name: 'Ross Richards',
                attestation: 0,
                status: 'ACTIVE',
              },
              {
                id: 'e7650f40-1b7a-40e1-b492-ebea29262e74',
                name: 'Steven Andrews',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
          {
            id: 'ccb9fdd1-8e89-4a13-9305-bb7b5841305c',
            name: 'Zipnow',
            attestation: 0,
            status: 'ENABLED',
            isConflicting: false,
            users: [
              {
                id: '6e5e1659-9bea-47fa-9b5d-e52a1f3b97f0',
                name: 'Harold Ball',
                attestation: 0,
                status: 'ACTIVE',
              },
              {
                id: '78804c1e-da98-48a5-b710-6d968db2ee7e',
                name: 'Sheila Guerrero',
                attestation: 0,
                status: 'ACTIVE',
              },
            ],
          },
        ],
      },
    ],
  },
}
