import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'

import { Template, Section, AuthMatrixSection, CustomSection } from '../models/template'

interface TemplateAction {
  type: string
  payload: {
    errorMsg?: string

    template?: Template
    templateId?: string

    page?: number
    perPage?: number
    totalItems?: number
    templates?: Template[]

    section?: Section
    sectionId?: string
  }
}

const ctx = 'app/template'

export const LIST_TEMPLATE_OPTIONS_LOADING = `${ctx}/LIST_TEMPLATE_OPTIONS_LOADING`
export const LIST_TEMPLATE_OPTIONS_SUCCESS = `${ctx}/LIST_TEMPLATE_OPTIONS_SUCCESS`
export const LIST_TEMPLATE_OPTIONS_FAILURE = `${ctx}/LIST_TEMPLATE_OPTIONS_FAILURE`

export const LIST_TEMPLATES_LOADING = `${ctx}/LIST_TEMPLATES_LOADING`
export const LIST_TEMPLATES_SUCCESS = `${ctx}/LIST_TEMPLATES_SUCCESS`
export const LIST_TEMPLATES_FAILURE = `${ctx}/LIST_TEMPLATES_FAILURE`

export const FIND_TEMPLATE_LOADING = `${ctx}/FIND_TEMPLATE_LOADING`
export const FIND_TEMPLATE_SUCCESS = `${ctx}/FIND_TEMPLATE_SUCCESS`
export const FIND_TEMPLATE_FAILURE = `${ctx}/FIND_TEMPLATE_FAILURE`

export const DUPLICATE_TEMPLATE_LOADING = `${ctx}/DUPLICATE_TEMPLATE_LOADING`
export const DUPLICATE_TEMPLATE_SUCCESS = `${ctx}/DUPLICATE_TEMPLATE_SUCCESS`
export const DUPLICATE_TEMPLATE_FAILURE = `${ctx}/DUPLICATE_TEMPLATE_FAILURE`

export const ADD_TEMPLATE_LOADING = `${ctx}/ADD_TEMPLATE_LOADING`
export const ADD_TEMPLATE_SUCCESS = `${ctx}/ADD_TEMPLATE_SUCCESS`
export const ADD_TEMPLATE_FAILURE = `${ctx}/ADD_TEMPLATE_FAILURE`

export const UPDATE_TEMPLATE_LOADING = `${ctx}/UPDATE_TEMPLATE_LOADING`
export const UPDATE_TEMPLATE_SUCCESS = `${ctx}/UPDATE_TEMPLATE_SUCCESS`
export const UPDATE_TEMPLATE_FAILURE = `${ctx}/UPDATE_TEMPLATE_FAILURE`

export const REMOVE_TEMPLATE_LOADING = `${ctx}/REMOVE_TEMPLATE_LOADING`
export const REMOVE_TEMPLATE_SUCCESS = `${ctx}/REMOVE_TEMPLATE_SUCCESS`
export const REMOVE_TEMPLATE_FAILURE = `${ctx}/REMOVE_TEMPLATE_FAILURE`

export const CLOSE_TEMPLATE_DETAIL = `${ctx}/CLOSE_TEMPLATE_DETAIL`
export const SET_TEMPLATE_HAS_UNSAVED_CHANGES = `${ctx}/SET_TEMPLATE_HAS_UNSAVED_CHANGES`

export const listTemplateOptions = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: LIST_TEMPLATE_OPTIONS_LOADING })

  const clientId = auth0Context.getClientId()
  const url = `${host}/v1/template?clientId=${clientId}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_TEMPLATE_OPTIONS_SUCCESS,
        payload: {
          items: data.items,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_TEMPLATE_OPTIONS_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const listTemplates = (
  auth0Context: Auth0Context,
  params?: { page?: number; perPage?: number; includeTotal?: boolean },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: LIST_TEMPLATES_LOADING })

  const clientId = auth0Context.getClientId()

  let url = `${host}/v1/template?clientId=${clientId}`
  if (params?.page) url += `&page=${params?.page}`
  if (params?.perPage) url += `&perPage=${params?.perPage}`
  if (params?.includeTotal) url += `&includeTotal=${params?.includeTotal}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_TEMPLATES_SUCCESS,
        payload: {
          page: data.page,
          perPage: data.perPage,
          totalItems: data.totalItems,
          items: data.items,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_TEMPLATES_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const findTemplate = (auth0Context: Auth0Context, templateId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_TEMPLATE_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/template/${templateId}`, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_TEMPLATE_SUCCESS,
        payload: {
          template: data.template,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: FIND_TEMPLATE_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const addTemplate = (
  auth0Context: Auth0Context,
  params: { name: string; instructions?: string; sections: Section[] },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: ADD_TEMPLATE_LOADING })

  const url = `${host}/v1/template`

  await auth0Context
    .makeRequest(url, 'POST', {
      ...params,
      clientId: auth0Context.getClientId(),
    })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: ADD_TEMPLATE_SUCCESS,
        payload: {
          template: data.template,
        },
      }),
    )
    .catch(err => {
      console.log('err', err)
      dispatch({
        type: ADD_TEMPLATE_FAILURE,
        payload: { errorMsg: err.message },
      })
    })
}

export const duplicateTemplate = (auth0Context: Auth0Context, templateId: string, params: { name: string }) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: DUPLICATE_TEMPLATE_LOADING })

  const url = `${host}/v1/template/${templateId}`

  await auth0Context
    .makeRequest(url, 'POST', params)
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: DUPLICATE_TEMPLATE_SUCCESS,
        payload: {
          template: data.template,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: DUPLICATE_TEMPLATE_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const updateTemplate = (
  auth0Context: Auth0Context,
  templateId: string,
  params: { name?: string; instructions?: string; sections?: (AuthMatrixSection | CustomSection)[] },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: UPDATE_TEMPLATE_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/template/${templateId}`, 'PUT', params)
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: UPDATE_TEMPLATE_SUCCESS,
        payload: {
          template: data.template,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: UPDATE_TEMPLATE_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const removeTemplate = (auth0Context: Auth0Context, templateId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: REMOVE_TEMPLATE_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/template/${templateId}`, 'DELETE')
    .then(() =>
      dispatch({
        type: REMOVE_TEMPLATE_SUCCESS,
        payload: {
          templateId,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: REMOVE_TEMPLATE_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const closeTemplateDetail = () => (dispatch: ThunkDispatch<{}, {}, Action>) => {
  dispatch({
    type: CLOSE_TEMPLATE_DETAIL,
  })
}

export const setTemplateHasUnsavedChanges = (hasUnsavedChanges: boolean) => (
  dispatch: ThunkDispatch<{}, {}, Action>,
) => {
  dispatch({
    type: SET_TEMPLATE_HAS_UNSAVED_CHANGES,
    payload: { hasUnsavedChanges },
  })
}
