export interface BrandConfigProps {
  brandId: string
  brandName: string
  partnerId?: string
  colorPrimary: string
  colorDark: string
  brandLogoPath?: string
  partnerLogoPath?: string
  apiHost?: string
}

export const DEMO_WHITELABEL = {
  brandId: 'AXYQ',
  brandName: 'AXYQ Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://localhost:3000',
}

export const DEMO1_CONFIG = {
  brandId: 'BXYQ',
  brandName: 'NGP Energy Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/Demo1Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo1.comply.cloud/demo1',
}

export const DEMO2_CONFIG = {
  brandId: 'CXYQ',
  brandName: 'CXYQ Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/Demo2Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo2.comply.cloud/demo2',
}

export const DEMO3_CONFIG = {
  brandId: 'DXYQ',
  brandName: 'DXYQ Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/Demo3Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo3.comply.cloud/demo3',
}

export const DEMO4_CONFIG = {
  brandId: 'EXYQ',
  brandName: 'EXYQ Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/Demo4Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo4.comply.cloud/demo4',
}

export const DEMO5_CONFIG = {
  brandId: 'FXYQ',
  brandName: 'FXYQ Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/Demo5Logo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://reportify-demo5.comply.cloud/demo5',
}

export const LOCALHOST_BACKEND_CONFIG = {
  brandId: 'LOCAL',
  brandName: 'Local Capital',
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://localhost:3003',
}

export const MOCK_CONFIG = {
  brandId: 'MOCK',
  brandName: 'AXYQ Capital',
  partnerId: undefined,
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: '',
}

export const DEV_CONFIG = {
  brandId: 'DEV',
  brandName: 'Baby Yoda Capital',
  colorPrimary: '#79abd2',
  colorDark: '#0b3f69',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://rfly-dev-admin.comply.cloud/dev',
}

export const STAGING_BACKEND = {
  brandId: 'AXYQ',
  brandName: 'AXYQ Capital',
  partnerId: undefined,
  colorPrimary: '#536379',
  colorDark: '#333',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://stage.rfly.comply.cloud:3003',
}

export const TRIVE_CONFIG = {
  brandId: 'TRIVE',
  brandName: 'Trive Capital',
  colorPrimary: '#39586F',
  colorDark: '#1B2B36',
  brandLogoPath: 'components/TriveLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://trive.comply.cloud/trive',
}

export const EA_CONFIG = {
  brandId: 'EA',
  brandName: 'ACME Equity',
  partnerId: 'EISNERAMPER',
  colorPrimary: '#c29a60',
  colorDark: '#333',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'http://3.142.188.12:3000',
}

export const KAINOS_CONFIG = {
  brandId: 'KAINOS',
  brandName: 'Kainos Capital',
  colorPrimary: '#39586F',
  colorDark: '#1B2B36',
  brandLogoPath: 'components/KainosLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://kainos.comply.cloud',
}

export const NEWSTATE_CONFIG = {
  brandId: 'NEWSTATE',
  brandName: 'New State Capital Partners',
  colorPrimary: '#1f9bde',
  colorDark: '#003764',
  brandLogoPath: 'components/NewStateLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://newstate.comply.cloud/newstate',
}

export const TAILWATER_CONFIG = {
  brandId: 'TAILWATER',
  brandName: 'Tailwater Capital',
  colorPrimary: '#79abd2',
  colorDark: '#0b3f69',
  brandLogoPath: 'components/TailwaterLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://tailwater.comply.cloud/tailwater',
}

export const TRINITYHUNT_CONFIG = {
  brandId: 'TRINITYHUNT',
  brandName: 'Trinity Hunt Partners',
  colorPrimary: '#36617f',
  colorDark: '#013c4d',
  brandLogoPath: 'components/TrinityHuntLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://trinityhunt.comply.cloud/trinityhunt',
}

export const HIG_CONFIG = {
  brandId: 'AXYQ',
  brandName: 'AXYQ Capital',
  colorPrimary: '#36617f',
  colorDark: '#013c4d',
  brandLogoPath: 'components/DemoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://hig.comply.cloud/trinityhunt',
}

export const STONEPOINT_CONFIG = {
  brandId: 'STONEPOINT',
  brandName: 'Stone Point Capital',
  colorPrimary: '#0b4981',
  colorDark: '#1B2B36',
  brandLogoPath: 'components/StonePointLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://stonepoint.comply.cloud/stonepoint',
}

export const ABRY_CONFIG = {
  brandId: 'ABRY',
  brandName: 'Abry Partners, LLC',
  colorPrimary: '#79abd2',
  colorDark: '#0b3f69',
  brandLogoPath: 'components/AbryLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://abry.comply.cloud/abry',
}

export const ALTAMONT_CONFIG = {
  brandId: 'ALTAMONT',
  brandName: 'Altamont Capital Partners',
  colorPrimary: '#79abd2',
  colorDark: '#0b3f69',
  brandLogoPath: 'components/AltamontLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://altamont.comply.cloud/altamont',
}

export const KELSO_CONFIG = {
  brandId: 'KELSO',
  brandName: 'Kelso Private Equity',
  colorPrimary: '#6699cc',
  colorDark: '#003366',
  brandLogoPath: 'components/KelsoLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://kelso.comply.cloud/kelso',
}

export const ACON_CONFIG = {
  brandId: 'ACON',
  brandName: 'Acon Investments',
  colorPrimary: '#79abd2',
  colorDark: '#0b3f69',
  brandLogoPath: 'components/AconLogo',
  partnerLogoPath: 'components/PoweredByComplyify',
  apiHost: 'https://acon.comply.cloud/acon',
}

export const DEFAULT_CONFIG = HIG_CONFIG
export const STAGING_CONFIG = STAGING_BACKEND
export const DEMO_CONFIG = HIG_CONFIG
// export const LOCALHOST_TEST = HIG_CONFIG
export const LOCALHOST_TEST = LOCALHOST_BACKEND_CONFIG

export const getBrandConfig = (): BrandConfigProps => {
  const origin = window?.location?.origin
  if (!origin) return DEFAULT_CONFIG

  if (/report-admin\.comply\.cloud/.test(origin)) {
    return MOCK_CONFIG
  }
  if (/report-admin-demo\.comply\.cloud/.test(origin)) {
    return DEMO_CONFIG
  }
  if (/reportify-admin-stage\.comply\.cloud/.test(origin)) {
    return STAGING_CONFIG
  }
  if (/rfly-dev\.comply\.cloud/.test(origin)) {
    return DEV_CONFIG
  }
  if (/rfly-dev-admin\.comply\.cloud/.test(origin)) {
    return DEV_CONFIG
  }
  if (/localhost/.test(origin)) {
    return LOCALHOST_TEST
  }
  if (/reportify-demo1\.comply\.cloud/.test(origin)) {
    return DEMO1_CONFIG
  }
  if (/reportify-demo2\.comply\.cloud/.test(origin)) {
    return DEMO2_CONFIG
  }
  if (/reportify-demo3\.comply\.cloud/.test(origin)) {
    return DEMO3_CONFIG
  }
  if (/reportify-demo4\.comply\.cloud/.test(origin)) {
    return DEMO4_CONFIG
  }
  if (/reportify-demo5\.comply\.cloud/.test(origin)) {
    return DEMO5_CONFIG
  }
  if (/report-admin\.trivecapital\.com/.test(origin)) {
    return TRIVE_CONFIG
  }
  if (/kainos\.comply\.cloud/.test(origin)) {
    return KAINOS_CONFIG
  }
  if (/newstate\.comply\.cloud/.test(origin)) {
    return NEWSTATE_CONFIG
  }
  if (/tailwater\.comply\.cloud/.test(origin)) {
    return TAILWATER_CONFIG
  }
  if (/trinityhunt\.comply\.cloud/.test(origin)) {
    return TRINITYHUNT_CONFIG
  }
  if (/hig\.comply\.cloud/.test(origin)) {
    return DEMO_CONFIG
  }
  if (/stonepoint\.comply\.cloud/.test(origin)) {
    return STONEPOINT_CONFIG
  }
  if (/abry\.comply\.cloud/.test(origin)) {
    return ABRY_CONFIG
  }
  if (/altamont\.comply\.cloud/.test(origin)) {
    return ALTAMONT_CONFIG
  }
  if (/kelso\.comply\.cloud/.test(origin)) {
    return KELSO_CONFIG
  }
  if (/acon\.comply\.cloud/.test(origin)) {
    return ACON_CONFIG
  }

  return DEFAULT_CONFIG
}

export const brandConfig = getBrandConfig()
console.dir({ brandConfig })

export const isMockData = ['MOCK'].includes(brandConfig?.brandId)

export default brandConfig
