import { UserReport, UserSection } from '../models/report'
import { map, isNil } from 'lodash'

import {
  LIST_REPORTS_LOADING,
  LIST_REPORTS_SUCCESS,
  LIST_REPORTS_FAILURE,
  FIND_REPORT_LOADING,
  FIND_REPORT_SUCCESS,
  FIND_REPORT_FAILURE,
  CREATE_ATTESTATION_LOADING,
  CREATE_ATTESTATION_SUCCESS,
  CREATE_ATTESTATION_FAILURE,
  UPLOAD_DOCUMENT_LOADING,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  REMOVE_DOCUMENT_LOADING,
  REMOVE_DOCUMENT_SUCCESS,
  REMOVE_DOCUMENT_FAILURE,
  DOWNLOAD_DOCUMENT_LOADING,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_FAILURE,
  DOWNLOAD_REPORT_EXPORT_LOADING,
  DOWNLOAD_REPORT_EXPORT_SUCCESS,
  DOWNLOAD_REPORT_EXPORT_FAILURE,
  SIGN_REPORT_LOADING,
  SIGN_REPORT_SUCCESS,
  SIGN_REPORT_FAILURE,
} from 'actions/userReportActions'

interface UserReportState {
  reportDetail: {
    isFetching: boolean
    item: { id: string; sections: UserSection[] } | UserReport

    error?: string
    lastUpdated?: Date
  }

  reportList: {
    items: { sections: UserSection[] }[] | UserReport[]
    isFetching: boolean

    page?: number
    perPage?: number
    totalItems?: number

    error?: string
    lastUpdated?: Date
  }

  exportPdf: {
    isFetching: boolean
    error?: string
  }
}

const initState: UserReportState = {
  reportDetail: {
    isFetching: false,
    item: { id: '', sections: [] },
  },

  reportList: {
    isFetching: false,
    items: [],
  },

  exportPdf: {
    isFetching: false,
  },
}

export const userReportReducer = (state = initState, action: any): UserReportState => {
  switch (action.type) {
    case LIST_REPORTS_LOADING:
      return {
        ...state,

        reportList: {
          ...state.reportList,

          error: undefined,
          isFetching: true,
        },
      }

    case LIST_REPORTS_SUCCESS:
      return {
        ...state,

        reportList: {
          items: action.payload.items,
          page: action.payload.page,
          perPage: action.payload.perPage,
          totalItems: action.payload.totalItems,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case LIST_REPORTS_FAILURE:
      return {
        ...state,

        reportList: {
          ...state.reportList,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case FIND_REPORT_LOADING:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case FIND_REPORT_SUCCESS:
      return {
        ...state,
        reportDetail: {
          item: {
            ...action.payload.report,
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case FIND_REPORT_FAILURE:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case CREATE_ATTESTATION_LOADING:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case CREATE_ATTESTATION_SUCCESS:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,
          item: {
            ...state.reportDetail.item,

            sections: map(state.reportDetail.item.sections, section =>
              section.id === action.payload.sectionId
                ? {
                    ...section,
                    questions: map(section.questions, question =>
                      question.id === action.payload.questionId
                        ? {
                            ...question,

                            attestation: action.payload.attestation,
                          }
                        : question,
                    ),
                  }
                : section,
            ),
          },

          isFetching: false,
          error: undefined,
        },
      }

    case CREATE_ATTESTATION_FAILURE:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.console.errorMsg,
        },
      }

    case SIGN_REPORT_LOADING:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case SIGN_REPORT_SUCCESS:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          item: Object.assign({}, state.reportDetail.item, {
            signed: action.payload.signed,
          }),

          isFetching: false,
          error: undefined,
        },
      }

    case SIGN_REPORT_FAILURE:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case UPLOAD_DOCUMENT_LOADING:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,
          item: {
            ...state.reportDetail.item,

            sections: map(state.reportDetail.item.sections, section =>
              section.id === action.payload.sectionId
                ? {
                    ...section,
                    questions: map(section.questions, question =>
                      question.id === action.payload.questionId
                        ? {
                            ...question,

                            attestation: {
                              ...question.attestation,
                              documents: isNil(question.attestation?.documents)
                                ? []
                                : question.attestation?.documents.concat(action.payload.reportDocument),
                            },
                          }
                        : question,
                    ),
                  }
                : section,
            ),
          },

          isFetching: false,
          error: undefined,
        },
      }

    case UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case DOWNLOAD_DOCUMENT_LOADING:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: undefined,
        },
      }

    case DOWNLOAD_DOCUMENT_FAILURE:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case REMOVE_DOCUMENT_LOADING:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case REMOVE_DOCUMENT_SUCCESS:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          item: {
            ...state.reportDetail.item,

            sections: map(state.reportDetail.item.sections, section =>
              section.id === action.payload.sectionId
                ? {
                    ...section,
                    questions: map(section.questions, question =>
                      question.id === action.payload.questionId
                        ? {
                            ...question,

                            attestation: {
                              ...question.attestation,
                              documents: map(question.attestation.documents, document =>
                                document.id === action.payload.documentId
                                  ? {
                                      ...document,
                                      status: 'REMOVED',
                                    }
                                  : document,
                              ),
                            },
                          }
                        : question,
                    ),
                  }
                : section,
            ),
          },

          isFetching: false,
          error: undefined,
        },
      }

    case REMOVE_DOCUMENT_FAILURE:
      return {
        ...state,

        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    case DOWNLOAD_REPORT_EXPORT_LOADING:
      return {
        ...state,
        exportPdf: {
          ...state.exportPdf,
          isFetching: true,
          error: undefined,
        }
      }

    case DOWNLOAD_REPORT_EXPORT_SUCCESS:
      return {
        ...state,
        exportPdf: {
          ...state.exportPdf,
          isFetching: false,
          error: undefined,
        }
      }

    case DOWNLOAD_REPORT_EXPORT_FAILURE:
      return {
        ...state,
        exportPdf: {
          ...state.exportPdf,
          isFetching: false,
          error: action.payload.errorMsg,
        }
      }

    default:
      return state
  }
}
