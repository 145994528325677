import dayjs, { Dayjs } from 'dayjs'
import { add, isNil, subtract } from 'lodash'

type CadenceType = 'annually' | 'quarterly' | 'monthly' | 'weekly' | 'one_off'
type CadenceString = 'Week' | 'Month' | 'Quarter' | 'Year' | null

interface NextDateInterface {
  nth?: number
  weekday: number
  cadence: CadenceString
  firstDayOfPeriod: Dayjs
  firstDayOfNextPeriod: Dayjs
  optionalMonth?: number
}

const getCadenceString = (period?: CadenceType): CadenceString => {
  switch (period) {
    case 'one_off':
      return null
    case 'weekly':
      return 'Week'
    case 'monthly':
      return 'Month'
    case 'quarterly':
      return 'Quarter'
    case 'annually':
      return 'Year'
    default:
      return null
  }
}

export const calcRecurringCadenceData = (initialDate?: Date, recurringCadence?: CadenceType): NextDateInterface => {
  const selectedDate = dayjs(initialDate)
  const selectedMonth = dayjs(initialDate).month()
  const selectedYear = dayjs(initialDate).year()

  let optionalMonth = undefined

  // if (recurringCadence === 'monthly')
  let firstDayOfPeriod = selectedDate.date(1) // first day of selected month
  let firstDayOfNextPeriod = selectedDate.date(1).month(add(selectedMonth, 1)) // first day of next month

  if (recurringCadence === 'annually') {
    optionalMonth = selectedDate.month()
    firstDayOfPeriod = selectedDate.date(1) // first day of selected month
    firstDayOfNextPeriod = selectedDate.date(1).year(add(selectedYear, 1)) // first day month next year
  }
  if (recurringCadence === 'quarterly') {
    if (selectedMonth < 3) {
      firstDayOfPeriod = selectedDate.date(1).month(0) // first day of Q1 from selected year
    } else if (selectedMonth < 6) {
      firstDayOfPeriod = selectedDate.date(1).month(3) // first day of Q2 from selected year
    } else if (selectedMonth < 9) {
      firstDayOfPeriod = selectedDate.date(1).month(6) // first day of Q3 from selected year
    } else {
      firstDayOfPeriod = selectedDate.date(1).month(9) // first day of Q4 from selected year
    }
    firstDayOfNextPeriod = firstDayOfPeriod.add(3, 'month')
  }

  return {
    nth: recurringCadence === 'weekly' ? undefined : add(dayjs(initialDate).diff(firstDayOfPeriod, 'week'), 1),
    weekday: dayjs(initialDate).day(),
    cadence: getCadenceString(recurringCadence),
    firstDayOfPeriod,
    firstDayOfNextPeriod,
    optionalMonth,
  }
}

export const calcNthDay = (initialDate?: Date, recurringCadence?: CadenceType): number | undefined => {
  const cadenceObj = calcRecurringCadenceData(initialDate, recurringCadence)
  return cadenceObj.nth
}

export const calcNextReportDate = (initialDate?: Date, recurringCadence?: CadenceType): Dayjs | undefined => {
  if (isNil(initialDate)) return undefined

  const { nth, weekday, firstDayOfNextPeriod } = calcRecurringCadenceData(initialDate, recurringCadence)

  const selectedDate = dayjs(initialDate)

  if (recurringCadence === 'weekly') {
    return selectedDate.add(1, 'week')
  }
  if (!nth) return undefined

  const firstDayWeekday = firstDayOfNextPeriod.day()
  let diffDays = subtract(weekday, firstDayWeekday)
  if (diffDays < 0) diffDays += 7

  return firstDayOfNextPeriod.add(diffDays, 'day').add(subtract(nth, 1), 'week')
}
