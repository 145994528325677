import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from 'utils/auth0'
import {
  map,
  isEmpty,
  orderBy,
  isNil,
  find,
  includes,
  size,
  keys,
  values,
  head,
  filter,
  join,
  slice,
  add,
  subtract,
} from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryParam, StringParam } from 'use-query-params'

import {
  listMetricsGroups,
  fetchMetricsGroupDetail,
  fetchMetricsGroupHistory,
  addMetric,
  editMetric,
  removeMetricsGroup,
  removeMetric,
  addMetricsGroup,
  editMetricsGroup,
  clearErrors,
} from 'actions/metricsActions'
import { listCompanies } from 'actions/companyActions'

import { mockMetrics } from 'mockData/mockMetrics'
import { mockCompanies } from 'mockData/mockCompanies'
import { Metric, QuestionMetric } from 'models/metrics'

import Button from 'components/Button'
import ConfirmDialog from 'components/ConfirmDialog'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import Form from 'components/Form/GoodForm'

import MetricQuestion from './MetricQuestion'
import MetricQuestionHistory from './MetricQuestionHistory'
import AddEditMetricForm from './AddEditMetricForm'
import AddEditGroupForm from './AddEditGroupForm'
import ExportMetricPdfForm from './ExportMetricPdfForm'

import { PageContent, PageTitleWithAction, ActionBtns } from 'components/styles'

import {
  MetricsListContainer,
  MetricItemWrapper,
  MetricsItemTitleRow,
  MetricControlsWrapper,
  SectionViewSelectors,
  CadenceFieldWrapper,
  ViewSelectorTab,
  ActionBtnsWrapper,
  RemoveMetricIcon,
  AddEditModalContainer,
  EmptyMetricsGroup,
  EmptyMetricItem,
  MetricsSubTitle,
  QuestionItemWrapper,
  EditMetricDetailsLink,
  MetricErrorMsgWrapper,
} from './styles'

import { isMockData } from 'utils/brandConfig'
// const isMockData = true

interface MetricsViewProps {
  match: {
    params: {
      groupId: string
      metricId?: string
      viewType?: 'history' | 'detail'
    }
  }
}

const SHOW_EDIT_METRIC = true

const MetricsView: React.FC<MetricsViewProps> = ({
  match: {
    params: { groupId, metricId, viewType },
  },
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const history = useHistory()

  const {
    groupsMap,
    groupMetrics,
    groupDetail,
    historyItemId,
    historyCadence,
    isMetricsUpdated,
    exportMetricPdf,
    error,
  } = useSelector((state: any) => state.metrics)
  const { companyList: companiesData } = useSelector((state: any) => state.companies)

  const [editMetricId, setEditMetricId] = useState<string | undefined>(undefined)
  const [editGroupId, setEditGroupId] = useState<string | undefined>(undefined)
  const [confirmRemoveGroupId, setConfirmRemoveGroupId] = useState<string | undefined>(undefined)
  const [confirmRemoveMetricId, setConfirmRemoveMetricId] = useState<string | undefined>(undefined)
  const [isExportPdfModalOpen, setIsExportPdfModalOpen] = useState<boolean>(false)
  const [cadence, setCadence] = useState<'QUARTERLY' | 'YEARLY'>('QUARTERLY')
  const [periodOffset, setPeriodOffset] = React.useState<number>(0)

  const [addGroup, setAddGroup] = useQueryParam('addGroup', StringParam)

  const metrics: Record<string, Metric> = !isMockData ? groupMetrics : mockMetrics
  const sortedMetrics = orderBy(metrics, 'created', 'asc')
  const detailMetric: Metric | undefined = find(sortedMetrics, ({ id }) => id === metricId)
  const isDetailView = !isNil(metricId) && !isNil(detailMetric) && viewType === 'detail'
  const isHistoryView = !isNil(metricId) && !isNil(detailMetric) && viewType === 'history'
  const companiesList = !isMockData ? companiesData?.items : mockCompanies
  const filteredCompaniesList = filter(companiesList, co => co.status !== 'DISABLED')

  const metricToEdit: Metric | undefined = isNil(editMetricId)
    ? undefined
    : find(sortedMetrics, ({ id }) => id === editMetricId)
  const metricToEditDetails =
    isNil(editMetricId) || isNil(groupDetail)
      ? undefined
      : find(groupDetail.customMetrics, ({ id }) => id === editMetricId)

  useEffect(() => {
    if (!isEmpty(groupsMap) && (isNil(groupId) || !includes(keys(groupsMap), groupId))) {
      const firstGroup = head(values(groupsMap))
      if (!isNil(firstGroup)) {
        // console.log('No Metric Group Selected', groupsMap)
        history.push(`/metrics/${firstGroup?.id}`)
        dispatch(fetchMetricsGroupDetail(auth0Context, firstGroup?.id))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, groupsMap])

  useEffect(() => {
    if (!isNil(groupId)) {
      if (groupDetail?.id !== groupId) {
        // console.log('Fetch Metric Group Detail', groupDetail?.id, groupId)
        dispatch(fetchMetricsGroupDetail(auth0Context, groupId))
      }
      // console.log(
      //   'Fetch Metric Group History',
      //   !isNil(metricId) && (historyItemId !== `${groupId}-${metricId}` || historyCadence !== cadence),
      //   {
      //     historyItemId,
      //     groupId,
      //     metricId,
      //     historyCadence,
      //     cadence,
      //   },
      // )
      if (!isNil(metricId) && (historyItemId !== `${groupId}-${metricId}` || historyCadence !== cadence)) {
        dispatch(fetchMetricsGroupHistory(auth0Context, groupId, metricId, cadence))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, metricId, historyItemId, historyCadence, cadence])

  useEffect(() => {
    if (isMetricsUpdated) {
      setEditMetricId(undefined)
      setConfirmRemoveMetricId(undefined)
      dispatch(listMetricsGroups(auth0Context))
      // console.log('Metric Added/Removed/Edited', groupId, metricId, cadence)

      if (!isNil(groupId)) {
        dispatch(fetchMetricsGroupDetail(auth0Context, groupId))
        if (!isNil(metricId)) {
          dispatch(fetchMetricsGroupHistory(auth0Context, groupId, metricId, cadence))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetricsUpdated, groupId])

  useEffect(() => {
    if (!companiesData.lastUpdated) dispatch(listCompanies(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isNil(groupsMap)) {
    return <Spinner />
  }

  const handleGoToDetail = (id: string) => {
    const scrollingEl = document.getElementById('scroll-target')
    if (scrollingEl) scrollingEl.scrollTo({ top: 0, behavior: 'smooth' })
    history.push(`/metrics/${groupId}/${id}/detail`)
  }

  const handleGoToListView = () => {
    const scrollingEl = document.getElementById('scroll-target')
    if (scrollingEl) scrollingEl.scrollTo({ top: 0, behavior: 'smooth' })
    history.push(`/metrics/${groupId}`)
  }

  const handleGoToHistoryView = (id: string) => {
    const scrollingEl = document.getElementById('scroll-target')
    if (scrollingEl) scrollingEl.scrollTo({ top: 0, behavior: 'smooth' })
    history.push(`/metrics/${groupId}/${id}/history`)
  }

  const handleAddEditGroup = (data: { name: string; companies: string[]; groupId?: string }, id?: string) => {
    setEditGroupId(undefined)
    setAddGroup(undefined)
    if (id === 'NEW_GROUP') {
      dispatch(addMetricsGroup(auth0Context, data))
    } else if (!isNil(id)) {
      dispatch(editMetricsGroup(auth0Context, data, id))
    }
  }

  const handleAddEditMetric = (
    data: { name: string; templateId: string; sectionId: string; questions: string[] },
    existingMetricId: string | undefined,
  ) => {
    if (isNil(existingMetricId)) {
      dispatch(addMetric(auth0Context, data, groupId))
    } else {
      dispatch(editMetric(auth0Context, existingMetricId, data, groupId))
    }
  }

  const handleRemoveGroup = (id?: string) => {
    setEditGroupId(undefined)
    setAddGroup(undefined)
    if (!!id) {
      setConfirmRemoveGroupId(id)
    }
  }

  const handleRemoveMetric = (id?: string) => {
    if (!!id) {
      setEditMetricId(undefined)
      setConfirmRemoveMetricId(id)
    }
  }

  const confirmRemoveGroup = () => {
    if (!!confirmRemoveGroupId) {
      dispatch(removeMetricsGroup(auth0Context, confirmRemoveGroupId))
    }
    setConfirmRemoveGroupId(undefined)
    history.push('/metrics')
  }

  const confirmRemoveMetric = () => {
    if (!!confirmRemoveMetricId) {
      dispatch(
        removeMetric(auth0Context, {
          customMetricId: confirmRemoveMetricId,
          groupId,
        }),
      )
    }
    setConfirmRemoveMetricId(undefined)
  }

  const handleCadenceChange = (newCadence: 'QUARTERLY' | 'YEARLY') => {
    console.log('handleCadenceChange', newCadence)
    setCadence(newCadence)
    if (!isNil(metricId)) {
      dispatch(fetchMetricsGroupHistory(auth0Context, groupId, metricId, newCadence))
    }
  }

  const renderMetricItem = (m?: Metric) => {
    if (isNil(m)) return null

    return (
      <MetricItemWrapper key={`metric-item-${m.id}`}>
        <MetricsItemTitleRow>
          <div>
            <h3>{m.name}</h3>
            <p>{`${m.template.name}: ${m.section.name}`}</p>
          </div>

          <MetricControlsWrapper>
            {SHOW_EDIT_METRIC ? (
              <EditMetricDetailsLink onClick={() => setEditMetricId(m.id)}>
                <FontAwesomeIcon icon="pencil-alt" />
              </EditMetricDetailsLink>
            ) : (
              <RemoveMetricIcon onClick={() => handleRemoveMetric(m.id)}>
                <FontAwesomeIcon icon="trash-alt" />
              </RemoveMetricIcon>
            )}
            {!m.noCompletedReports || !isNil(metricId) ? (
              <>
                <SectionViewSelectors>
                  <ViewSelectorTab onClick={() => handleGoToListView()} isSelected={!isDetailView && !isHistoryView}>
                    {t('metricOverviewSelector')}
                  </ViewSelectorTab>
                  <ViewSelectorTab onClick={() => handleGoToDetail(m.id)} isSelected={isDetailView}>
                    {t('metricDetailSelector')}
                  </ViewSelectorTab>
                  <ViewSelectorTab onClick={() => handleGoToHistoryView(m.id)} isSelected={isHistoryView}>
                    {t('metricHistorySelector')}
                  </ViewSelectorTab>
                </SectionViewSelectors>
                {isHistoryView && !m.noCompletedReports && (
                  <CadenceFieldWrapper>
                    <Form
                      onSubmit={handleCadenceChange}
                      isNoActionBtns
                      isNoLabel
                      isFlex
                      isSubmitOnChange
                      fields={[
                        {
                          ref: {},
                          errors: {},
                          type: 'select',
                          name: 'metricHistoryCadence',
                          initialValue: cadence,
                          options: [
                            {
                              value: 'QUARTERLY',
                              label: 'Quarterly',
                            },
                            {
                              value: 'YEARLY',
                              label: 'Annually',
                            },
                          ],
                        },
                      ]}
                    />
                  </CadenceFieldWrapper>
                )}
              </>
            ) : null}
          </MetricControlsWrapper>
        </MetricsItemTitleRow>
        {m.noCompletedReports ? (
          <EmptyMetricItem>{t('noCompleteReportsMsg')}</EmptyMetricItem>
        ) : isHistoryView && (historyItemId !== `${groupId}-${metricId}` || historyCadence !== cadence) ? (
          <Spinner />
        ) : (
          map(m.questionMetrics, (q: QuestionMetric, i: number) => {
            const randomOffset = parseInt(join(slice(q.id, -6), ''), 16) % 10

            if (isHistoryView) {
              return (
                <QuestionItemWrapper key={`quest-${m.id}-${q.id}`} index={i}>
                  <MetricQuestionHistory
                    questionMetric={q}
                    randomOffset={randomOffset}
                    periodOffset={periodOffset}
                    moveForwardPeriodOffset={() => setPeriodOffset(subtract(periodOffset, 1))}
                    moveBackPeriodOffset={() => setPeriodOffset(add(periodOffset, 1))}
                  />
                </QuestionItemWrapper>
              )
            }
            return (
              <QuestionItemWrapper key={`quest-${m.id}-${q.id}`} index={i}>
                <MetricQuestion questionMetric={q} randomOffset={randomOffset} isDetailView={isDetailView} />
              </QuestionItemWrapper>
            )
          })
        )}
      </MetricItemWrapper>
    )
  }

  let viewContent = <Spinner />

  const isSelectedMetricGroup = groupDetail?.id === groupId

  if (isSelectedMetricGroup && isEmpty(sortedMetrics)) {
    viewContent = <EmptyMetricsGroup>{t('emptyMetricsMsg')}</EmptyMetricsGroup>
  }

  if (isSelectedMetricGroup && !isEmpty(sortedMetrics) && !isDetailView && !isHistoryView) {
    viewContent = <MetricsListContainer>{map(sortedMetrics, renderMetricItem)}</MetricsListContainer>
  }
  if (isSelectedMetricGroup && !isEmpty(sortedMetrics) && isDetailView) {
    viewContent = <>{renderMetricItem(detailMetric)}</>
  }

  if (isSelectedMetricGroup && !isEmpty(sortedMetrics) && isHistoryView) {
    viewContent = <>{renderMetricItem(detailMetric)}</>
  }

  const firstCoDetail = find(companiesList, ({ id }) => id === head(groupDetail?.companies))
  const companiesMsg = isEmpty(groupDetail?.companies)
    ? t('allCompaniesMsg')
    : size(groupDetail?.companies) === 1
    ? firstCoDetail?.name
    : t('numCompaniesMsg', { count: size(groupDetail?.companies) })

  const errorModalTile = (): string => {
    if (error.ADD_METRIC) return t('addMetricModalTitle')
    if (error.EDIT_METRIC) return t('editMetricModalTitle')
    if (error.ADD_METRICS_GROUP) return t('addMetricsGroupModalTitle')
    if (error.EDIT_METRICS_GROUP) return t('editMetricsGroupModalTitle')
    return t('genericMetricsErrorTitle')
  }

  const errorModalMsg = (): string => {
    if (error.ADD_METRIC) return t('addMetricErrorMsg')
    if (error.EDIT_METRIC) return t('editMetricErrorMsg')
    if (error.ADD_METRICS_GROUP) return t('addMetricsGroupErrorMsg')
    if (error.EDIT_METRICS_GROUP) return t('editMetricsGroupErrorMsg')
    return t('genericMetricErrorMsg')
  }

  const getErrorMsg = (): string => {
    if (isMockData) return ''
    const errorMsgs = values(error)
    // console.log('ERROR WILL ROBINSON!', {
    //   error,
    //   errorMsgs,
    //   test: isEmpty(errorMsgs),
    // })
    if (isEmpty(errorMsgs)) return ''
    return join(errorMsgs, ', ')
  }

  const selectedMetricGroup = groupsMap && groupsMap[groupId]

  return (
    <PageContent>
      <PageTitleWithAction>
        <div onClick={() => setEditGroupId(groupId)}>
          {t('metricsTitle', { name: selectedMetricGroup?.name })}
          {isSelectedMetricGroup && (
            <MetricsSubTitle>
              <FontAwesomeIcon icon="pencil-alt" />
              <h5>{companiesMsg}</h5>
            </MetricsSubTitle>
          )}
        </div>
        <ActionBtnsWrapper>
          {!isSelectedMetricGroup || isNil(groupId) || isEmpty(metrics) || isExportPdfModalOpen ? (
            <div />
          ) : (
            <Button onClick={() => setIsExportPdfModalOpen(true)} color="active">
              {exportMetricPdf.isFetching ? <Spinner isMini /> : t('exportMetricPdfBtn')}
            </Button>
          )}

          {isSelectedMetricGroup && isNil(editMetricId) ? (
            <Button onClick={() => setEditMetricId('NEW_METRIC')} color="success">
              {t('addMetricBtn')}
            </Button>
          ) : (
            <div />
          )}
        </ActionBtnsWrapper>
      </PageTitleWithAction>
      {viewContent}
      <ConfirmDialog
        isOpen={!isNil(confirmRemoveGroupId)}
        confirmText={
          !!confirmRemoveGroupId && !!groupDetail
            ? t('confirmRemoveNamedGroupMsg', { name: groupDetail.name })
            : t('confirmRemoveGroupMsg')
        }
        onConfirm={confirmRemoveGroup}
        onCancel={() => setConfirmRemoveGroupId(undefined)}
      />
      <ConfirmDialog
        isOpen={!isNil(confirmRemoveMetricId)}
        confirmText={
          !!confirmRemoveMetricId && metrics[confirmRemoveMetricId]
            ? t('confirmRemoveNamedMetricMsg', { name: metrics[confirmRemoveMetricId].name })
            : t('confirmRemoveMetricMsg')
        }
        onConfirm={confirmRemoveMetric}
        onCancel={() => setConfirmRemoveMetricId(undefined)}
      />
      <Modal
        isOpen={!isEmpty(getErrorMsg())}
        width="md"
        content={
          <AddEditModalContainer>
            <h2>{errorModalTile()}</h2>
            <MetricErrorMsgWrapper>
              <p>{errorModalMsg()}</p>
              <p>{getErrorMsg()}</p>
            </MetricErrorMsgWrapper>
            <ActionBtns>
              <Button color="error" size="small" onClick={() => dispatch(clearErrors())}>
                {t('okayBtn')}
              </Button>
            </ActionBtns>
          </AddEditModalContainer>
        }
      />
      <Modal
        isOpen={addGroup === 'true' || !isNil(editGroupId)}
        width="lg"
        content={
          <AddEditModalContainer>
            <h2>{addGroup === 'true' ? t('addMetricsGroupModalTitle') : t('editMetricsGroupModalTitle')}</h2>
            <AddEditGroupForm
              existingGroupDetail={addGroup === 'true' ? undefined : groupDetail}
              groupsList={values(groupsMap)}
              companiesList={filteredCompaniesList}
              onSubmit={handleAddEditGroup}
              onClose={() => {
                setEditGroupId(undefined)
                setAddGroup(undefined)
              }}
              onDelete={handleRemoveGroup}
            />
          </AddEditModalContainer>
        }
      />
      <Modal
        isOpen={!isNil(editMetricId)}
        width="lg"
        content={
          <AddEditModalContainer>
            <h2>{editMetricId === 'NEW_METRIC' ? t('addMetricModalTitle') : t('editMetricModalTitle')}</h2>
            <AddEditMetricForm
              existingMetric={
                isNil(metricToEdit) || isNil(groupDetail)
                  ? undefined
                  : {
                      id: isNil(metricToEdit) ? '' : metricToEdit?.id,
                      name: isNil(metricToEdit) ? '' : metricToEdit?.name,
                      templateId: isNil(metricToEdit) ? '' : metricToEdit?.template.id,
                      sectionId: isNil(metricToEdit) ? '' : metricToEdit?.section.id,
                      selectedQuestionIds: isNil(metricToEditDetails) ? [] : map(metricToEditDetails?.questions, 'id'),
                      selectedCompanyIds: isNil(groupDetail) ? [] : groupDetail?.companies,
                    }
              }
              onRemove={handleRemoveMetric}
              onSubmit={handleAddEditMetric}
              onCancel={() => setEditMetricId(undefined)}
            />
          </AddEditModalContainer>
        }
      />
      <Modal
        isOpen={isExportPdfModalOpen}
        width="lg"
        content={
          <AddEditModalContainer>
            <h2>{t('exportMetricPdfModalTitle')}</h2>
            <ExportMetricPdfForm
              groupId={groupId}
              groupName={groupDetail?.name}
              metrics={sortedMetrics}
              onClose={() => setIsExportPdfModalOpen(false)}
            />
          </AddEditModalContainer>
        }
      />
    </PageContent>
  )
}

export default MetricsView
