import React from 'react'
import styled from 'styled-components'
import PoweredByLogoImg from './poweredBy.png'

interface PoweredByLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  padding: 1rem;
`

const PoweredByLogo: React.FC<PoweredByLogoProps> = () => {
  return (
    <LogoWrapper>
      <StyledLogo src={PoweredByLogoImg} alt="Powered by Complyify" />
    </LogoWrapper>
  )
}

export default PoweredByLogo
