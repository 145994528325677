import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  map,
  join,
  isEmpty,
  includes,
  without,
  concat,
  keys,
  sum,
  values,
  size,
  isNil,
  isString,
  isNumber,
  indexOf,
  has,
  find,
  isObject,
  filter,
  reduce,
  omit,
} from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Markdown from 'markdown-to-jsx'

import { QuestionMetric, AttestationType } from 'models/metrics'
import { random } from 'utils/colors'

import SegmentedBarGraph from './SegmentedBarGraph'
import BreakdownGraph from './BreakdownGraph'
import NumberBadges from './NumberBadges'
import NumbersBarGraph from './NumbersBarGraph'

import {
  MetricQuestionWrapper,
  CompaniesMultiChoiceWrapper,
  CompanyMultiChoiceBox,
  ToggleIcon,
  MultiLabel,
  MultiValue,
  ExpandedCompanyAnswersWrapper,
  UserMultiChoiceBox,
  CompaniesBreakdownWrapper,
  CompanyBreakdownBox,
  CompanyBreakdownLayout,
  BreakdownCompanyInfo,
  MissingResponseWrapper,
  NoDataView,
  EmptyGraph,
} from './styles'

interface MetricQuestionProps {
  questionMetric: QuestionMetric
  randomOffset: number
  isDetailView?: boolean
  isReadOnly?: boolean
}

const MetricQuestion: React.FC<MetricQuestionProps> = ({ questionMetric, randomOffset, isDetailView, isReadOnly }) => {
  const { t } = useTranslation()
  const [expandedCompanyAnswers, setExpandedCompanyAnswers] = useState<string[]>([])

  const toggleCompanyAnswer = (uniqueId: string) => {
    if (includes(expandedCompanyAnswers, uniqueId)) {
      setExpandedCompanyAnswers(without(expandedCompanyAnswers, uniqueId))
    } else {
      setExpandedCompanyAnswers(concat(expandedCompanyAnswers, uniqueId))
    }
  }

  const getColorIndex = (answer: AttestationType, options: any[]): number | undefined => {
    if (!isString(answer)) return undefined

    const randomColorsLen = size(random)
    const index = indexOf(options, answer)

    if (index < 0 || answer === 'CONFLICTING_ANSWERS') return undefined
    return (index + randomOffset) % randomColorsLen
  }

  const getMultiselectAttestationString = (attestation: AttestationType): string | undefined => {
    if (!isObject(attestation)) return undefined
    const selectionsArray = find(attestation, (val, key) => key === 'selections')
    const otherOption = find(attestation, (val, key) => key === 'otherOption')
    return join(filter(concat(selectionsArray, otherOption)), ', ')
  }

  const renderCondensedGraph = () => {
    const sumTotal = sum(values(questionMetric.totals))

    if (['MULTIPLE_CHOICE', 'MULTI_SELECT', 'BREAKDOWN'].includes(questionMetric.type)) {
      if (
        !isEmpty(questionMetric.companyMetrics) &&
        isEmpty(filter(questionMetric.companyMetrics, co => co.attestation !== 'CONFLICTING_ANSWERS'))
      ) {
        return <EmptyGraph>{t('conflictingAnswersMetricMsg')}</EmptyGraph>
      }
      if (sumTotal === 0) {
        return <EmptyGraph>{t('awaitingResponses')}</EmptyGraph>
      }
    }

    if (['MULTIPLE_CHOICE', 'MULTI_SELECT'].includes(questionMetric.type)) {
      const responses = omit(questionMetric.totals, 'CONFLICTING_ANSWERS')
      const totalValueSpan = reduce(
        responses,
        (sum: number, n: number | undefined) => {
          if (isNil(n)) {
            return sum
          }
          return sum + n
        },
        0,
      )
      if (totalValueSpan < 1) return null

      return (
        <SegmentedBarGraph
          id={questionMetric.id}
          responses={responses}
          options={questionMetric.options}
          randomOffset={randomOffset}
        />
      )
    }
    if (questionMetric.type === 'BREAKDOWN') {
      return (
        <BreakdownGraph
          totals={questionMetric.totals}
          options={questionMetric.options}
          randomOffset={randomOffset}
          isPercent
        />
      )
    }
    if (['INTEGER', 'PERCENTAGE'].includes(questionMetric.type)) {
      return <NumberBadges totals={questionMetric.totals} isPercent={questionMetric.type === 'PERCENTAGE'} />
    }
    return null
  }

  const renderExpandedGraphs = () => {
    if (['MULTIPLE_CHOICE', 'MULTI_SELECT'].includes(questionMetric.type)) {
      return (
        <CompaniesMultiChoiceWrapper>
          {map(questionMetric.companyMetrics, coMetric => {
            const uniqueId = `${questionMetric.id}-${coMetric.id}`
            const isExpanded = includes(expandedCompanyAnswers, uniqueId) && !isReadOnly
            const userMetricsList = coMetric.users

            return (
              <CompanyMultiChoiceBox
                key={`company-question-${uniqueId}`}
                isExpanded={isExpanded && !isEmpty(userMetricsList)}
                onClick={() => toggleCompanyAnswer(uniqueId)}
                colorIndex={getColorIndex(coMetric.attestation, keys(questionMetric.totals))}
              >
                {isEmpty(userMetricsList) || isReadOnly ? (
                  <div />
                ) : (
                  <ToggleIcon isExpanded={isExpanded}>
                    <FontAwesomeIcon icon="angle-right" />
                  </ToggleIcon>
                )}
                <MultiLabel>{coMetric.name}</MultiLabel>
                <MultiValue colorIndex={getColorIndex(coMetric.attestation, keys(questionMetric.totals))}>
                  {isEmpty(userMetricsList)
                    ? 'Not Completed'
                    : coMetric.attestation === 'CONFLICTING_ANSWERS' || coMetric.attestation === 'NOT_APPLICABLE'
                    ? t(coMetric.attestation)
                    : isString(coMetric.attestation)
                    ? coMetric.attestation
                    : getMultiselectAttestationString(coMetric.attestation)}
                </MultiValue>
                <ExpandedCompanyAnswersWrapper>
                  {isEmpty(userMetricsList)
                    ? null
                    : map(userMetricsList, uMetric => (
                        <UserMultiChoiceBox
                          key={`multi-${questionMetric.id}-${uMetric.id}`}
                          colorIndex={getColorIndex(uMetric.attestation, keys(questionMetric.totals))}
                        >
                          <MultiLabel isUser>{uMetric.name}</MultiLabel>
                          <MultiValue isContrast>
                            {uMetric.attestation === 'NOT_APPLICABLE'
                              ? t(uMetric.attestation)
                              : isString(uMetric.attestation)
                              ? uMetric.attestation
                              : getMultiselectAttestationString(uMetric.attestation)}
                          </MultiValue>
                        </UserMultiChoiceBox>
                      ))}
                </ExpandedCompanyAnswersWrapper>
              </CompanyMultiChoiceBox>
            )
          })}
        </CompaniesMultiChoiceWrapper>
      )
    }
    if (questionMetric.type === 'BREAKDOWN') {
      return (
        <CompaniesBreakdownWrapper>
          {map(questionMetric.companyMetrics, coMetric => {
            const uniqueId = `${questionMetric.id}-${coMetric.id}`
            const isExpanded = includes(expandedCompanyAnswers, uniqueId) && !isReadOnly
            const companyUsersLength = size(coMetric.users)
            const userMetricsList = coMetric.users
            const isBlank =
              isNil(coMetric.attestation) ||
              isEmpty(coMetric.attestation) ||
              isString(coMetric.attestation) ||
              isNumber(coMetric.attestation)
            return (
              <CompanyBreakdownBox key={`company-question-${uniqueId}`} onClick={() => toggleCompanyAnswer(uniqueId)}>
                <CompanyBreakdownLayout isExpanded>
                  {isBlank ? (
                    <div />
                  ) : (
                    <ToggleIcon isExpanded={isExpanded}>
                      <FontAwesomeIcon icon="angle-right" />
                    </ToggleIcon>
                  )}
                  <BreakdownCompanyInfo isConflicting={coMetric.isConflicting}>
                    <h4>{coMetric.name}</h4>
                    <h5>
                      {companyUsersLength === 1
                        ? t('respondentsSingularLengthMsg')
                        : t('respondentsPluralLengthMsg', { size: companyUsersLength })}
                    </h5>
                    {!isBlank && <h6>{coMetric.isConflicting ? t('CONFLICTING_ANSWERS') : t('CONSISTENT_ANSWERS')}</h6>}
                  </BreakdownCompanyInfo>
                  {isNil(coMetric.attestation) ||
                  isEmpty(coMetric.attestation) ||
                  isString(coMetric.attestation) ||
                  isNumber(coMetric.attestation) ||
                  has(coMetric.attestation, 'selections') ? (
                    <MissingResponseWrapper>{t('missingResponseMsg')}</MissingResponseWrapper>
                  ) : (
                    <BreakdownGraph
                      totals={coMetric.attestation as Record<string, number>}
                      options={questionMetric.options}
                      randomOffset={randomOffset}
                      size="medium"
                    />
                  )}
                </CompanyBreakdownLayout>
                {map(userMetricsList, uMetric => (
                  <CompanyBreakdownLayout
                    key={`breakdown-${questionMetric.id}-${uMetric.id}`}
                    isExpanded={isExpanded}
                    isConflicting={coMetric.isConflicting}
                    isUser
                  >
                    <BreakdownCompanyInfo isUser>
                      <h4>{uMetric.name}</h4>
                    </BreakdownCompanyInfo>
                    {isNil(uMetric.attestation) ||
                    isString(uMetric.attestation) ||
                    isNumber(uMetric.attestation) ||
                    has(uMetric.attestation, 'selections') ? (
                      <MissingResponseWrapper>{t('missingResponseMsg')}</MissingResponseWrapper>
                    ) : (
                      <BreakdownGraph
                        totals={uMetric.attestation as Record<string, number>}
                        options={questionMetric.options}
                        randomOffset={randomOffset}
                        size="small"
                      />
                    )}
                  </CompanyBreakdownLayout>
                ))}
              </CompanyBreakdownBox>
            )
          })}
        </CompaniesBreakdownWrapper>
      )
    }
    if (['INTEGER', 'PERCENTAGE'].includes(questionMetric.type)) {
      return (
        <NumbersBarGraph
          companyMetrics={questionMetric.companyMetrics}
          randomOffset={randomOffset}
          isPercent={questionMetric.type === 'PERCENTAGE'}
          isReadOnly={isReadOnly}
        />
      )
    }
    return null
  }

  return (
    <>
      <MetricQuestionWrapper isDetailView={!!isDetailView}>
        <div>
          <Markdown>{questionMetric.query}</Markdown>
        </div>
        {isEmpty(questionMetric.totals) ? <NoDataView>{t('noDataMsg')}</NoDataView> : renderCondensedGraph()}
      </MetricQuestionWrapper>
      {!isEmpty(questionMetric.totals) && isDetailView && renderExpandedGraphs()}
    </>
  )
}

export default MetricQuestion
