import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'components/Avatar'
import { TitlebarContainer, TitlebarActions, TitleIcon, AvatarWrapper, OptionalAppTitle } from './styles'
import { useAuth0 } from '../utils/auth0'
import { bg } from 'utils/colors'
import { useTranslation } from 'react-i18next'
import brandConfig from 'utils/brandConfig'

interface TitlebarProps {
  toggleModal: () => void
  toggleHelpModal: () => void
}

const Titlebar: React.FC<TitlebarProps> = ({ toggleModal, toggleHelpModal }: TitlebarProps) => {
  const { t } = useTranslation()
  const { user } = useAuth0()

  const handleOpenUserSettingsModal = () => {
    console.log('handleOpenUserSettingsModal()')
    toggleModal()
  }

  const handleOpenHelpModal = () => {
    console.log('handleOpenHelpModal()')
    toggleHelpModal()
  }

  return (
    <TitlebarContainer>
      <TitlebarActions>
        <AvatarWrapper onClick={handleOpenUserSettingsModal}>
          <Avatar user={user || {}} isTitle backgroundColor={bg.titlebar} />
        </AvatarWrapper>
        <TitleIcon onClick={handleOpenUserSettingsModal}>
          <FontAwesomeIcon icon="cog" />
        </TitleIcon>
        <TitleIcon onClick={handleOpenHelpModal}>
          <FontAwesomeIcon icon="question-circle" />
        </TitleIcon>
      </TitlebarActions>
      {brandConfig?.partnerId === 'EISNERAMPER' && <OptionalAppTitle>{t('optionalAppTitle')}</OptionalAppTitle>}
    </TitlebarContainer>
  )
}

export default Titlebar
