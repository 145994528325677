import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'

const ctx = 'api/user'

export const FIND_CHANGE_PASSWORD_URL_LOADING = `${ctx}/FIND_CHANGE_PASSWORD_URL_LOADING`
export const FIND_CHANGE_PASSWORD_URL_SUCCESS = `${ctx}/FIND_CHANGE_PASSWORD_URL_SUCCESS`
export const FIND_CHANGE_PASSWORD_URL_FAILURE = `${ctx}/FIND_CHANGE_PASSWORD_URL_FAILURE`

export const UPDATE_USER_LOADING = `${ctx}/UPDATE_USER_LOADING`
export const UPDATE_USER_SUCCESS = `${ctx}/UPDATE_USER_SUCCESS`
export const UPDATE_USER_FAILURE = `${ctx}/UPDATE_USER_FAILURE`
export const CLOSE_USER_UPDATE_MODAL = `${ctx}/CLOSE_USER_UPDATE_MODAL`

export const findChangePasswordUrl = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_CHANGE_PASSWORD_URL_LOADING })

  const url = `${host}/v1/user/change-password`

  await auth0Context
    .makeRequest(url, 'POST')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_CHANGE_PASSWORD_URL_SUCCESS,
        payload: {
          url: data.url,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: FIND_CHANGE_PASSWORD_URL_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const update = (auth0Context: Auth0Context, params: { name?: string; email?: string }) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: UPDATE_USER_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/user`, 'PUT', params)
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: {
          user: data.user,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}
