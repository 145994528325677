import styled, { css } from 'styled-components'
import { text, bg, random, randomBg, rgb, colors } from 'utils/colors'
import { isNil } from 'lodash'
import { ContentBox } from 'components/styles'
import { SUBNAV_WIDTH, TITLEBAR_HEIGHT } from 'utils/appUnits'

// metrics/MetricsView

export const MetricsViewContent = styled.div`
  display: grid;
  grid-template-columns: ${SUBNAV_WIDTH} auto;
`
export const MetricErrorMsgWrapper = styled.div`
  font-weight: 600;
  color: ${text.error};
  margin-bottom: 2rem;
  & > p {
    margin-bottom: 2rem;
  }
`

// metrics/GroupsList

export const GroupsListContainer = styled.div`
  width: 100%;
  height: calc(100vh - ${TITLEBAR_HEIGHT});
  background: ${bg.inactive};
  position: relative;
`
export const AddGroupButton = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
  background: rgba(${rgb.success}, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${text.contrast};
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  svg {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  &:hover {
    background: rgba(${rgb.success}, 0.9);
  }
`
export const GroupsListContent = styled.div`
  padding-bottom: 5rem;
`
export const GroupItem = styled.div<{ isOdd: boolean; isSelected: boolean; isEditing: boolean }>`
  background: rgba(${rgb.active}, ${p => (p.isEditing ? '0.4' : p.isSelected ? '0.9' : p.isOdd ? '0.2' : '0.3')});
  color: ${p => (p.isSelected ? text.contrast : text.main)};
`
export const GroupName = styled.div<{ isDisabled: boolean }>`
  display: grid;
  grid-template-columns: auto 1.2rem 1.2rem 1.2rem;
  grid-gap: 0.5rem;
  align-items: center;
  cursor: ${p => (p.isDisabled ? 'default' : 'pointer')};
  color: ${p => (p.isDisabled ? '#ccc' : 'inherit')};
  user-select: none;
  padding: 1rem 2rem;
  min-height: 5rem;
  p {
    font-size: 1.2rem;
    font-weight: 600;
  }
  &:hover {
    background: ${p => (p.isDisabled ? 'inherit' : `rgba(${rgb.active}, 0.9)`)};
    color: ${p => (p.isDisabled ? '#ccc' : text.contrast)};
  }
`
export const EditGroupNameForm = styled.form`
  display: grid;
  grid-template-columns: auto 1.2rem 1.2rem;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 1rem 2rem;
  min-height: 5rem;
`
export const EditGroupNameInput = styled.input``
export const groupIconStyle = css`
  opacity: 0.3;
  cursor: pointer;
  user-select: none;
  border: none;
  background: transparent;
  font-size: 1.2rem;
  &:hover {
    opacity: 1;
  }
`
export const EditGroupIcon = styled.div`
  ${groupIconStyle}
  color: ${text.logo};
`
export const DuplicateGroupIcon = styled.div`
  ${groupIconStyle}
  color: ${text.logo};
`
export const RemoveGroupIcon = styled.div`
  ${groupIconStyle}
  color: ${text.error};
`
export const SubmitGroupNameIcon = styled.button`
  ${groupIconStyle}
  color: ${text.success};
`
export const CancelGroupNameEditIcon = styled.div`
  ${groupIconStyle}
  color: ${text.error};
`

// metrics/MetricsGroup

export const MetricsListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: 80rem) {
    /* (80em == 1280px) */
    grid-template-columns: 1fr 1fr;
  }
`
export const MetricItemWrapper = styled(ContentBox)`
  padding: 0.5rem 0 0;
`
export const MetricsItemTitleRow = styled.div`
  padding: 0 1rem 0.5rem;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  align-items: center;
  h3 {
    font-weight: 600;
    margin: 0 0 0.5rem;
  }
  p {
    font-size: 0.8rem;
    color: #999;
  }
`
export const MetricControlsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  & > * {
    margin-left: 1rem;
  }
`
export const SectionViewSelectors = styled.div`
  justify-self: end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${text.active};
  border-radius: 0.7rem;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  @media print {
    visibility: hidden;
  }
`
export const CadenceFieldWrapper = styled.div``
export const ViewSelectorTab = styled.div<{ isSelected: boolean }>`
  background: ${p => (p.isSelected ? text.active : 'transparent')};
  color: ${p => (p.isSelected ? '#fff' : text.active)};
  border-radius: 0.6rem;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: ${p => (p.isSelected ? text.active : bg.active)};
  }
`
export const EditMetricDetailsLink = styled.div`
  cursor: pointer;
  user-select: none;
  svg {
    font-size: 1rem;
    color: ${text.inactive};
    opacity: 0.5;
    &:hover {
      color: ${text.active};
      opacity: 1;
    }
  }
  @media print {
    visibility: hidden;
  }
`
export const RemoveMetricIcon = styled.div`
  color: ${text.error};
  opacity: 0.2;
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
  &:hover {
    opacity: 1;
  }
  @media print {
    visibility: hidden;
  }
`
export const ActionBtnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 10rem);
  grid-gap: 1rem;
  @media print {
    visibility: hidden;
  }
`
export const EditMetricIcon = styled.div`
  color: ${text.logo};
  opacity: 0.2;
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
  &:hover {
    opacity: 1;
  }
`
export const QuestionItemWrapper = styled.div<{ index: number }>`
  background: ${p => (p.index % 2 === 0 ? bg.inactive : 'transparent')};
  padding: 1rem;
  -webkit-column-break-inside: avoid-page;
  page-break-inside: avoid-page;
  break-inside: avoid-page;
`

export const AddEditModalContainer = styled.div`
  h2 {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-align: center;
  }
`
export const EmptyMetricsGroup = styled.div`
  max-width: 42rem;
  margin: 7rem auto 1rem;
  font-size: 1.4rem;
  color: #aaa;
  line-height: 1.4;
  text-align: center;
`
export const EmptyMetricItem = styled.div`
  margin: 5rem auto;
  max-width: 30rem;
  font-size: 1rem;
  line-height: 1.4;
  color: #aaa;
  text-align: center;
  padding: 0 3rem;
`
export const ButtonPlaceholder = styled.div`
  height: 2.75rem;
`
export const MetricsSubTitle = styled.div`
  font-size: 1rem;
  display: flex;
  grid-template-columns: 1rem auto;
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-top: 0.7rem;

  svg {
    color: #aaa;
    margin-right: 0.5rem;
    &:hover {
      color: #999;
    }
  }
  h5 {
    font-size: 1.2rem;
    color: ${text.active};
    border-bottom: 1px solid ${text.active};
  }
`

// AddEditForms

export const AddEditWrapper = styled.div`
  min-width: 40rem;
`
export const ErrorMsgWrapper = styled.div`
  margin-bottom: 2rem;
  max-width: 30rem;
`
export const FormContainer = styled.form`
  margin: 0 auto;
  & > * {
    margin-bottom: 1rem;
  }
`
export const FieldsWrapper = styled.div`
  margin-bottom: 2rem;
`
export const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  width: 100%;
  margin: 0 0 1rem;
`
export const SelectInput = styled.select<{ hasError?: boolean }>`
  display: block;
  font-size: 0.9rem;
  margin-top: 0.1rem;
  padding: 0.5rem 3%;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid ${p => (p.hasError ? text.error : colors.border)};
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 2rem auto, 100%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  padding-right: 2.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const SelectOption = styled.option`
  appearance: none;
`
export const InputField = styled.input<{ hasError?: boolean }>`
  display: block;
  font-size: 0.9rem;
  margin-top: 0.1rem;
  padding: 0.5rem 3%;
  width: 94%;
  border-radius: 0.25rem;
  border: 1px solid ${p => (p.hasError ? text.error : colors.border)};
`
export const ErrorMsg = styled.div`
  color: ${text.error};
  margin-top: 0.1rem;
  height: 1rem;
  font-size: 0.8rem;
`
export const CompaniesSectionWrapper = styled.div``
export const CompaniesSectionTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
export const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 2rem;
  align-items: start;
`
export const DetailOptionsWrapper = styled.div`
  margin: 2rem 0 2rem;
  & > * {
    margin: 0 4rem 1rem;
  }
  & > *:first-child {
    margin: 0 0 2rem;
  }
`
export const OptionsLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0 1rem;
  grid-column: 1 / -1;
`
export const ItemOption = styled.div<{ isSelected?: boolean; isDisabled?: boolean; isRadio?: boolean }>`
  display: grid;
  grid-template-columns: 1.5rem auto;
  grid-gap: 0.3rem 1rem;
  align-items: start;
  cursor: ${p => (p.isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  svg {
    font-size: ${p => (p.isRadio ? '1rem' : '1.5rem')};
    color: ${p => (p.isDisabled ? text.inactive : p.isSelected ? text.active : 'transparent')};
    opacity: ${p => (p.isDisabled ? 0.6 : 1)};
    border: 1px solid ${p => ((!p.isSelected && !p.isDisabled) || p.isRadio ? text.secondary : 'transparent')};
    border-radius: ${p => (p.isRadio ? '2rem' : '0.25rem')};
    height: ${p => (p.isRadio ? '1rem' : p.isSelected ? '1.5rem' : '1.4rem')};
    padding: ${p => (p.isRadio ? '0.2rem' : 0)};
  }
`
export const ItemLabel = styled.div<{ isDisabled?: boolean }>`
  opacity: ${p => (p.isDisabled ? 0.3 : 1)};
  margin-top: 0.4rem;
  a {
    color: ${text.logo};
    text-decoration: underline;
  }
`
export const AllCompaniesLabel = styled.div<{ isSelected?: boolean }>`
  margin-top: 0.4rem;
  font-size: 1.1rem;
  color: ${p => (p.isSelected ? text.active : '#777')};
  font-weight: ${p => (p.isSelected ? 600 : 400)};
`
export const DeselectAllLink = styled.div<{ isInactive: boolean }>`
  font-size: 0.9rem;
  color: ${p => (p.isInactive ? text.inactive : text.active)};
  cursor: ${p => (p.isInactive ? 'default' : 'pointer')};
  user-select: none;
  margin-left: 2.5rem;
  padding: 0.3rem 0;
  &:hover {
    text-decoration: ${p => (p.isInactive ? 'none' : 'underline')};
  }
`
export const InvalidMsg = styled.div`
  font-size: 0.8rem;
  font-weight: 900;
  color: ${text.secondary};
  grid-column: 2 / -1;
  opacity: 0.7;
`
export const CompaniesOptionsLabel = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0 1rem;
  grid-column: 1 / -1;
`
export const CompanyOption = styled.div<{ isSelected?: boolean }>`
  display: grid;
  grid-template-columns: 1.5rem auto;
  grid-gap: 0.3rem 1rem;
  align-items: start;
  cursor: pointer;
  user-select: none;
  svg {
    font-size: 1.5rem;
    color: ${p => (p.isSelected ? text.active : 'transparent')};
    border: 1px solid ${p => (!p.isSelected ? text.secondary : 'transparent')};
    border-radius: 0.25rem;
  }
`
export const CompanyLabel = styled.div``
export const AddMetricInvalid = styled.div`
  padding: 1rem 2rem;
  text-align: center;
  color: #999;
  font-size: 1.1rem;
  line-height: 1.4;
  max-width: 30rem;
  margin: 0 auto;
`
export const AllCompaniesFieldWrapper = styled.div<{ isAllCompanies: boolean }>`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  svg {
    border: 2px solid ${p => (p.isAllCompanies ? text.active : text.inactive)};
    background: ${p => (p.isAllCompanies ? text.active : bg.contentBox)};
    color: ${bg.contentBox};
    border-radius: 5px;
    padding: 0;
    margin: 0;
    line-height: 1;
  }
`
export const ActionRow = styled.div`
  display: grid;
  grid-template-columns: auto 11rem;
  grid-gap: 1rem;
  align-items: center;
`
export const DeleteLink = styled.div<{ isDeleteInvalid?: boolean }>`
  display: flex;
  p {
    color: ${p => (p.isDeleteInvalid ? text.inactive : text.error)};
    cursor: ${p => (p.isDeleteInvalid ? 'auto' : 'pointer')};
    user-select: none;
    padding: 1rem;

    &:hover {
      background: ${p => (p.isDeleteInvalid ? 'transparent' : bg.error)};
      text-decoration: ${p => (p.isDeleteInvalid ? 'none' : 'underline')};
    }
  }
`

// metrics/MetricQuestion.tsx

export const MetricQuestionWrapper = styled.div<{ isDetailView?: boolean }>`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  min-height: 2.5rem;
  ${p => (p.isDetailView ? 'grid-template-columns: 1fr 1fr;' : 'grid-template-rows: auto auto;')}
  & > div:first-child {
    font-size: 1.1em;
    line-height: 1.4;
    font-weight: 900;
    color: ${text.logo};
    a {
      text-decoration: underline;
    }
  }
`
export const CompaniesMultiChoiceWrapper = styled.div`
  margin: 1em 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  grid-gap: 1em;
  align-items: start;
`
export const CompanyMultiChoiceBox = styled.div<{ isExpanded: boolean; colorIndex?: number }>`
  display: grid;
  grid-template-columns: 1em 1fr 1fr;
  grid-gap: 1em 0;
  align-items: start;
  padding: 1em 1em;
  overflow: hidden;
  height: auto;
  max-height: ${p => (p.isExpanded ? '50em' : '1em')};
  transition: all 0.4s linear;

  white-space: ${p => (p.isExpanded ? 'normal' : 'nowrap')};
  overflow: ${p => (p.isExpanded ? 'visible' : 'hidden')};
  text-overflow: ellipsis;

  background: ${p => (isNil(p.colorIndex) ? bg.secondary : randomBg[p.colorIndex])};
  border-radius: 0.5em;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  & > * {
    max-width: 100%;
    white-space: ${p => (p.isExpanded ? 'normal' : 'nowrap')};
    overflow: ${p => (p.isExpanded ? 'visible' : 'hidden')};
    text-overflow: ellipsis;
  }
`
export const ToggleIcon = styled.div<{ isExpanded: boolean }>`
  cursor: pointer;
  user-select: none;
  line-height: 0;
  padding: 0;
  margin: 0;
  width: 1em;
  height: 1em;
  transform: ${p => (p.isExpanded ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.4s linear;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${text.secondary};
`
export const MultiLabel = styled.div<{ isUser?: boolean }>`
  margin-left: ${p => (p.isUser ? '0' : '1em')};
`
export const MultiValue = styled.div<{ colorIndex?: number; isContrast?: boolean }>`
  justify-self: end;
  color: ${p => (p.isContrast ? text.contrast : isNil(p.colorIndex) ? text.inactive : random[p.colorIndex])};
`
export const ExpandedCompanyAnswersWrapper = styled.div`
  grid-column: 1 / -1;
`
export const UserMultiChoiceBox = styled.div<{ colorIndex?: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1rem;
  padding: 0.5em;
  color: ${text.contrast};
  background: ${p => (isNil(p.colorIndex) ? text.inactive : random[p.colorIndex])};
  margin-bottom: 0.5em;
`
export const CompaniesBreakdownWrapper = styled.div`
  margin: 1em 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30em, 1fr));
  grid-gap: 1em;
  align-items: start;
`
export const CompanyBreakdownBox = styled.div`
  border-radius: 0.5em;
  border: 1px solid ${text.secondary};
`
export const CompanyBreakdownLayout = styled.div<{ isExpanded: boolean; isConflicting?: boolean; isUser?: boolean }>`
  display: grid;
  grid-template-columns: 1em 1fr 2fr;
  align-items: center;
  cursor: pointer;
  user-select: none;

  padding: ${p => (p.isExpanded ? '0.5em 1em' : '0 1em')};
  margin-top: ${p => (p.isUser && p.isExpanded ? '1em' : '0')};
  margin-bottom: ${p => (p.isUser && p.isExpanded ? '1em' : '0')};
  margin-left: ${p => (p.isUser ? '1em' : '0')};
  margin-right: ${p => (p.isUser ? '1em' : '0')};

  overflow: hidden;
  height: auto;
  max-height: ${p => (p.isExpanded ? '30em' : '0')};
  transition: all 0.4s linear;
  background: ${p => (!p.isUser ? 'transparent' : p.isConflicting ? bg.errorLight : bg.activeLight)};
`
export const BreakdownCompanyInfo = styled.div<{ isConflicting?: boolean; isUser?: boolean }>`
  grid-column-end: ${p => (p.isUser ? 'span 2' : 'span 1')};
  margin-left: ${p => (p.isUser ? '0' : '1em')};
  h4 {
    font-weight: 600;
    margin-bottom: 0.4em;
  }
  h5 {
    color: ${text.inactive};
    font-size: 0.9em;
    margin-bottom: 0.4em;
  }
  h6 {
    color: ${p => (p.isConflicting ? text.error : text.active)};
    font-size: 0.9em;
    font-weight: ${p => (p.isConflicting ? '600' : '600')};
  }
`
export const MissingResponseWrapper = styled.div`
  color: ${text.inactive};
  text-align: center;
  padding: 1em 3em;
  font-weight: 600;
  min-height: 5em;
  display: flex;
  align-items: center;
`
export const NoDataView = styled.div`
  text-align: center;
  padding: 2em 4em;
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.2;
`
export const EmptyGraph = styled.div`
  text-align: center;
  color: #bbb;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2em 0;
`
export const DownloadErrorMsg = styled.div`
  text-align: center;
  padding: 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  color: red;
`

// MetricHistory.tsx

export const HistoryGridWrapper = styled.div`
  position: relative;
  margin: 2rem 0 3.5rem;
  height: 15rem;
`

export const HistoryGrid = styled.div<{ dataColumns: number }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 7rem repeat(${p => p.dataColumns}, minmax(0, 1fr));
  height: 15rem;
`
export const HistoryGridTable = styled(HistoryGrid)`
  grid-template-rows: repeat(5, 3rem);
  grid-template-columns: 7rem auto;
`
export const HistoryTableRow = styled.div<{ dataColumns: number; index: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: 7rem repeat(${p => p.dataColumns}, minmax(0, 1fr));
  background: ${p => (p.index % 2 === 1 ? `rgba(${rgb.active}, 0.1)` : 'transparent')};
`
export const XAxisLine = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(128, 128, 128, 0.25);
`
export const LabelColumn = styled.div`
  font-size: 0.7rem;
  text-align: right;
  position: relative;
  top: -0.4rem;
  padding-right: 0.5rem;
`
export const PercentSymbol = styled.div`
  display: inline-block;
  font-size: 0.6em;
  margin-left: 2px;
  vertical-align: middle;
  height: 1.3em;
`
export const DataPointColumn = styled.div`
  height: 15rem;
  width: auto;
  min-width: 3rem;
  margin: 0 auto;
`
interface DataPointLabelType {
  isDetailOpen: boolean
  isCompany?: boolean
  isSortColumn?: boolean
  isSortInverted: boolean
}
export const DataPointLabel = styled.div<DataPointLabelType>`
  font-size: 1rem;
  font-weight: 600;
  text-align: ${p => (p.isCompany ? 'right' : 'center')};
  color: ${p => (p.isSortColumn && p.isDetailOpen ? text.active : text.main)};
  padding: 1rem 0;
  margin-top: 0.5rem;
  background: ${p => (p.isDetailOpen ? `rgba(${rgb.active}, 0.2)` : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: ${p => (p.isCompany ? 'flex-end' : 'center')};
  cursor: pointer;
  user-select: none;
  svg {
    margin-right: 0.5rem;
    line-height: 0;
    align-self: center;
    transform: ${p => (p.isSortInverted ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.3s ease-in-out;
  }
`
export const OffsetPeriodActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 7rem;
  width: calc(100% - 7rem);
`
export const OffsetPeriodBtn = styled.div`
  padding: 0.5rem 0 0;
  display: flex;
  align-items: center;
  opacity: 0.6;
  cursor: pointer;
  user-select: none;
  h5 {
    font-size: 0.7rem;
    padding: 0 0.5rem;
    line-height: 1.6;
  }
  svg {
    line-height: 1;
  }
  &:hover {
    opacity: 1;
  }
`
export const ToggleDetailBtn = styled.div<{ isOpen: boolean }>`
  background: rgba(${rgb.active}, 0.2);
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  margin-top: ${p => (p.isOpen ? '0' : '1rem')};
  svg {
    opacity: 0.5;
    line-height: 0;
    align-self: center;
    transform: ${p => (p.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.3s ease-in-out;
  }
`
export const CompanyResponsesTable = styled.div<{ isOpen: boolean }>`
  padding: 0;
  overflow-x: hidden;
  overflow-y: ${p => (p.isOpen ? 'auto' : 'hidden')};
  height: auto;
  max-height: ${p => (p.isOpen ? '20rem' : '0')};
  transition: max-height 0.3s ease-in-out;

  border-left: 3px solid rgba(${rgb.active}, 0.2);
  border-right: 3px solid rgba(${rgb.active}, 0.2);
  -ms-overflow-style: none; /* Edge, Internet Explorer */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`
export const CompanyName = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${text.secondary};
  text-align: right;
  padding: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const AttestationResponse = styled.div<{ isBreakdown?: boolean; isMultiSelect?: boolean }>`
  padding: 0.5rem 0;
  display: ${p => (p.isMultiSelect ? 'block' : 'flex')};
  flex-direction: ${p => (p.isBreakdown ? 'column' : 'row')};
  text-align: ${p => (p.isBreakdown ? 'left' : 'center')};
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const AttestationAnswer = styled.div<{ optionColor?: string; isBreakdown?: boolean }>`
  font-size: 0.8rem;
  font-weight: 600;
  color: ${p => p.optionColor};
  text-align: ${p => (p.isBreakdown ? 'left' : 'center')};
  width: 80%;
  margin: 0 10%;
`
export const InlineAttestationAnswer = styled.span<{ optionColor?: string }>`
  font-size: 0.8rem;
  font-weight: 600;
  color: ${p => p.optionColor};
`
export const LegendWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax();
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  grid-gap: 1rem;
  background: ${bg.active};
  padding: 1rem;
`
export const LegendItem = styled.div`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  align-items: center;
`
export const LegendBox = styled.div<{ optionColor?: string }>`
  background: ${p => p.optionColor};
  width: 1rem;
  height: 1rem;
`
export const LegendLabel = styled.div<{ optionColor?: string }>`
  color: ${p => p.optionColor};
  font-weight: 600;
`
