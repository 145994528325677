import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text } from 'utils/colors'
import { last } from 'lodash'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
// import updateLocale from 'dayjs/plugin/updateLocale'
import { EventStamp } from 'models/common'

interface LatestEventProps {
  events?: EventStamp[]
}

const LatestEventContainer = styled.div`
  font-size: 0.9rem;
  color: ${text.main};
  line-height: 1.2rem;
`
const EventTitle = styled.div`
  font-weight: 600;
`
const EventWhen = styled.div`
  font-weight: 200;
`

dayjs.extend(relativeTime)
// dayjs.extend(updateLocale)
// dayjs.updateLocale('en', {
//   relativeTime: {
//     s: 'just now',
//   },
// })

const LatestEvent: React.FC<LatestEventProps> = ({ events }: LatestEventProps) => {
  const { t } = useTranslation()
  const latest = last(events)
  if (!latest) return null

  const when = dayjs(latest.timestamp)
  return (
    <LatestEventContainer>
      <EventTitle>{t(latest.name)}</EventTitle>
      {latest && when && <EventWhen>{when.fromNow()}</EventWhen>}
    </LatestEventContainer>
  )
}

export default LatestEvent
