import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  isEmpty,
  map,
  size,
  reduce,
  concat,
  values,
  toString,
  forEach,
  max,
  add,
  divide,
  round,
  subtract,
  multiply,
  isNil,
  nth,
  findIndex,
  toInteger,
  reverse,
  sortBy,
  find,
  filter,
  take,
  slice,
  isString,
  toNumber,
  some,
  difference,
} from 'lodash'
import sumValuesForPeriod from 'utils/sumValuesForPeriod'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Markdown from 'markdown-to-jsx'

import { QuestionMetric } from 'models/metrics'

import HistoryGraphBar from './HistoryGraphBar'
import { random } from 'utils/colors'

import {
  MetricQuestionWrapper,
  HistoryGridWrapper,
  HistoryGridTable,
  HistoryGrid,
  HistoryTableRow,
  LabelColumn,
  PercentSymbol,
  DataPointColumn,
  OffsetPeriodActions,
  OffsetPeriodBtn,
  XAxisLine,
  DataPointLabel,
  ToggleDetailBtn,
  CompanyResponsesTable,
  CompanyName,
  AttestationResponse,
  AttestationAnswer,
  InlineAttestationAnswer,
  LegendWrapper,
  LegendItem,
  LegendBox,
  LegendLabel,
} from './styles'

interface MetricQuestionHistoryProps {
  questionMetric: QuestionMetric
  randomOffset: number
  periodOffset: number
  moveForwardPeriodOffset: () => void
  moveBackPeriodOffset: () => void
}

interface historyTableCompany {
  id: string
  companyName: string
  historicalAttestations: any[]
}

const MetricQuestionHistory: React.FC<MetricQuestionHistoryProps> = ({
  questionMetric,
  randomOffset,
  periodOffset,
  moveForwardPeriodOffset,
  moveBackPeriodOffset,
}) => {
  const { t } = useTranslation()
  const { historyData } = useSelector((state: any) => state.metrics)
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false)
  const [sortColumn, setSortColumn] = useState<number | undefined>(undefined)
  const [isSortInverted, setIsSortInverted] = useState<boolean>(false)

  const handleSortChange = (sortIndex?: number) => {
    if (sortIndex === sortColumn) {
      setIsSortInverted(!isSortInverted)
    } else {
      setSortColumn(sortIndex)
      setIsSortInverted(false)
    }
  }

  const questionAllHistoryData = filter(
    map(historyData, hist => {
      const questionHistoryPeriod = find(hist.questionMetrics, qPeriod => qPeriod.id === questionMetric.id)
      if (!isNil(questionHistoryPeriod)) {
        let label = ''
        if (!isNil(hist?.quarter)) {
          label = hist?.quarter?.name
        }
        if (!isNil(hist?.year)) {
          label = hist?.year
        }
        return {
          ...questionHistoryPeriod,
          label,
        }
      }
    }),
  )

  const allCompanies: Record<string, historyTableCompany> = reduce(
    questionAllHistoryData,
    (acc, historyPeriod) => {
      const periodData = reduce(
        historyPeriod.companyMetrics,
        (periodAcc, company) => ({
          ...periodAcc,
          [company.id]: {
            id: company.id,
            companyName: company.name,
            historicalAttestations: [],
          },
        }),
        {},
      )
      return {
        ...acc,
        ...periodData,
      }
    },
    {},
  )

  const MAX_PERIODS_PER_VIEW = 6
  const isEarliestSlice =
    subtract(size(questionAllHistoryData), multiply(MAX_PERIODS_PER_VIEW, periodOffset)) <= MAX_PERIODS_PER_VIEW

  const questionTruncHistoryData = isEarliestSlice
    ? take(questionAllHistoryData, MAX_PERIODS_PER_VIEW)
    : reverse(slice(reverse(questionAllHistoryData), periodOffset, MAX_PERIODS_PER_VIEW))

  // console.log('History Data Truncated', isEarliestSlice, {
  //   questionAllHistoryData,
  //   questionTruncHistoryData,
  //   a: size(questionAllHistoryData),
  //   b: multiply(MAX_PERIODS_PER_VIEW, periodOffset),
  //   c: subtract(size(questionAllHistoryData), multiply(MAX_PERIODS_PER_VIEW, periodOffset)),
  // })

  if (isEmpty(questionTruncHistoryData)) return null

  const numDataPoints = size(questionTruncHistoryData)

  let highestYAxisValue: number | undefined = 0

  if (questionMetric.type === 'INTEGER') {
    forEach(questionAllHistoryData, period => {
      // largest answer given for any company in any period
      highestYAxisValue = max([highestYAxisValue, period.totals.maxValue])
    })
  }
  if (['MULTIPLE_CHOICE', 'MULTI_SELECT'].includes(questionMetric.type)) {
    // largest value of sum of all company answers in a period
    forEach(questionAllHistoryData, period => {
      highestYAxisValue = max([highestYAxisValue, sumValuesForPeriod(period.totals)])
    })
  }
  if (['PERCENTAGE', 'BREAKDOWN'].includes(questionMetric.type)) {
    highestYAxisValue = 100
  }
  if (highestYAxisValue % 5 !== 0) {
    const diffToNextThreshold = subtract(5, highestYAxisValue % 5)
    highestYAxisValue = add(highestYAxisValue, diffToNextThreshold)
  }

  let thresholdGap = divide(highestYAxisValue, 5)

  const roundToLevel = (val: number) => {
    if (isNil(highestYAxisValue)) {
      return val
    }
    if (highestYAxisValue < 10) {
      return round(val, 1)
    }
    if (highestYAxisValue < 5) {
      return round(val, 2)
    }
    if (highestYAxisValue < 1) {
      return round(val, 5)
    }
    return round(val)
  }
  const responseThresholds = [
    roundToLevel(highestYAxisValue),
    roundToLevel(subtract(highestYAxisValue, thresholdGap)),
    roundToLevel(subtract(highestYAxisValue, multiply(thresholdGap, 2))),
    roundToLevel(subtract(highestYAxisValue, multiply(thresholdGap, 3))),
    roundToLevel(subtract(highestYAxisValue, multiply(thresholdGap, 4))),
  ]

  const historyTableData: Record<string, historyTableCompany> = reduce(
    questionTruncHistoryData,
    (acc, historyPeriod) => {
      const periodResponses: Record<string, historyTableCompany> = reduce(
        allCompanies,
        (periodAcc, companyDetail: historyTableCompany, companyId: string) => {
          const companyPeriodResponse = find(historyPeriod.companyMetrics, co => co.id === companyId)
          const previousCompanyResponses = acc[companyId].historicalAttestations
          return {
            ...periodAcc,
            [companyId]: {
              ...companyDetail,
              historicalAttestations: concat(previousCompanyResponses, companyPeriodResponse?.attestation),
            },
          }
        },
        {},
      )
      return {
        ...acc,
        ...periodResponses,
      }
    },
    allCompanies,
  )

  // Sort Company Response Table
  let sortedCompanyResponses = isNil(sortColumn)
    ? values(historyTableData)
    : sortBy(values(historyTableData), companyAttest => {
        let arrayToSort = companyAttest.historicalAttestations
        if (['INTEGER', 'PERCENTAGE'].includes(questionMetric.type)) {
          arrayToSort = map(companyAttest.historicalAttestations, toNumber)
        }
        return nth(arrayToSort, sortColumn)
      })

  if (isSortInverted) {
    sortedCompanyResponses = reverse(sortedCompanyResponses)
  }

  // console.log('TABLE DATA', questionMetric?.type, questionMetric?.query, {
  //   questionMetric,
  //   sortedCompanyResponses,
  //   isSortInverted,
  //   historyTableData,
  //   sortColumn,
  //   questionTruncHistoryData,
  // })

  const isYAxisLabelPercentage = ['BREAKDOWN', 'PERCENTAGE'].includes(questionMetric.type)

  let hasOtherOption = false
  const otherOptionColor = nth(random, add(size(questionMetric.options), randomOffset) % size(random))
  const nonOtherOptions = concat(questionMetric.options, ['CONFLICTING_ANSWERS', 'NOT_APPLICABLE'])

  if (questionMetric.type === 'MULTIPLE_CHOICE') {
    hasOtherOption = some(sortedCompanyResponses, resp => {
      return !isEmpty(filter(difference(resp.historicalAttestations, nonOtherOptions)))
    })
  }
  if (questionMetric.type === 'MULTI_SELECT') {
    hasOtherOption = some(sortedCompanyResponses, resp => {
      return some(resp.historicalAttestations, att => !isNil(att?.otherOption))
    })
  }

  return (
    <>
      <MetricQuestionWrapper
        isDetailView={['MULTIPLE_CHOICE', 'MULTI_SELECT', 'BREAKDOWN'].includes(questionMetric.type)}
      >
        <div>
          <Markdown>{questionMetric.query}</Markdown>
        </div>
        {['MULTIPLE_CHOICE', 'MULTI_SELECT', 'BREAKDOWN'].includes(questionMetric.type) && (
          <LegendWrapper>
            {map(questionMetric.options, (opt, i) => {
              const colorIndex = (i + randomOffset) % size(random)
              return (
                <LegendItem key={`legend-${questionMetric.id}-${opt}`}>
                  <LegendBox optionColor={nth(random, colorIndex)} />
                  <LegendLabel optionColor={nth(random, colorIndex)}>{opt}</LegendLabel>
                </LegendItem>
              )
            })}
            {hasOtherOption && (
              <LegendItem>
                <LegendBox optionColor={otherOptionColor} />
                <LegendLabel optionColor={otherOptionColor}>Other</LegendLabel>
              </LegendItem>
            )}
          </LegendWrapper>
        )}
      </MetricQuestionWrapper>
      <OffsetPeriodActions>
        {isEarliestSlice ? (
          <div />
        ) : (
          <OffsetPeriodBtn onClick={moveBackPeriodOffset}>
            <FontAwesomeIcon icon="angle-double-left" />
            <h5>Show Earlier</h5>
          </OffsetPeriodBtn>
        )}
        {periodOffset <= 0 ? (
          <div />
        ) : (
          <OffsetPeriodBtn onClick={moveForwardPeriodOffset}>
            <h5>Show Later</h5>
            <FontAwesomeIcon icon="angle-double-right" />
          </OffsetPeriodBtn>
        )}
      </OffsetPeriodActions>

      <HistoryGridWrapper>
        <HistoryGridTable dataColumns={numDataPoints}>
          {map(responseThresholds, th => {
            return (
              <React.Fragment key={`grid_line_${questionMetric.id}_${th}`}>
                <LabelColumn>
                  {th.toLocaleString()}
                  {isYAxisLabelPercentage && (
                    <PercentSymbol>
                      <FontAwesomeIcon icon="percent" />
                    </PercentSymbol>
                  )}
                </LabelColumn>
                <XAxisLine />
              </React.Fragment>
            )
          })}
          <LabelColumn>
            0
            {isYAxisLabelPercentage && (
              <PercentSymbol>
                <FontAwesomeIcon icon="percent" />
              </PercentSymbol>
            )}
          </LabelColumn>
          <XAxisLine />
        </HistoryGridTable>
        <HistoryGrid dataColumns={numDataPoints}>
          <div />
          {map(questionTruncHistoryData, hist => {
            return (
              <DataPointColumn key={`hist-graph-${questionMetric.id}-${hist.label}`}>
                <HistoryGraphBar
                  qId={questionMetric.id}
                  qType={questionMetric.type}
                  totals={hist.totals}
                  options={questionMetric.options}
                  randomOffset={randomOffset}
                  highestYAxisValue={highestYAxisValue || 0}
                />
              </DataPointColumn>
            )
          })}
          {isDetailOpen ? (
            <DataPointLabel
              onClick={() => handleSortChange()}
              isDetailOpen={isDetailOpen}
              isSortColumn={isNil(sortColumn)}
              isSortInverted={isSortInverted}
              isCompany
            >
              {isNil(sortColumn) && <FontAwesomeIcon icon="caret-down" />}
              <h2>Company</h2>
            </DataPointLabel>
          ) : (
            <div />
          )}
          {map(questionTruncHistoryData, (hist, index) => {
            const isSortColumn = sortColumn === index
            return (
              <DataPointLabel
                key={`hist-${questionMetric.id}-${hist.id}-${hist.label}`}
                onClick={() => handleSortChange(index)}
                isDetailOpen={isDetailOpen}
                isSortColumn={isSortColumn}
                isSortInverted={isSortInverted}
              >
                {isSortColumn && <FontAwesomeIcon icon="caret-down" />}
                <h2>{hist.label}</h2>
              </DataPointLabel>
            )
          })}
        </HistoryGrid>
      </HistoryGridWrapper>
      <CompanyResponsesTable isOpen={isDetailOpen}>
        {map(sortedCompanyResponses, (companyRow, i) => {
          return (
            <HistoryTableRow key={`company-responses-${companyRow.id}`} dataColumns={numDataPoints} index={i}>
              <CompanyName>{companyRow.companyName}</CompanyName>
              {map(companyRow.historicalAttestations, (att, jIndex) => {
                const getAttestationDetail = (
                  answer: any,
                  countOrIndex?: number | string,
                ): { label: string; color: string | undefined } => {
                  if (answer === 'CONFLICTING_ANSWERS') {
                    return {
                      label: 'Conflicting',
                      color: 'rgba(128, 128, 128, 0.5)',
                    }
                  }
                  if (answer === 'NOT_APPLICABLE') {
                    return {
                      label: 'Not Applicable',
                      color: 'rgba(128, 128, 128, 0.5)',
                    }
                  }
                  const optionIndex = ['INTEGER', 'PERCENTAGE'].includes(questionMetric.type)
                    ? 0
                    : findIndex(questionMetric.options, opt => opt === answer)

                  let optionColor =
                    isNil(optionIndex) || optionIndex === -1
                      ? otherOptionColor
                      : nth(random, add(optionIndex, randomOffset) % size(random))

                  if (questionMetric.type === 'BREAKDOWN') {
                    return {
                      label: `${toString(answer)}: ${countOrIndex}`,
                      color: optionColor,
                    }
                  }
                  if (questionMetric.type === 'MULTI_SELECT') {
                    const isOther = answer === att?.otherOption

                    let isLast = size(att?.selections) === add(toInteger(countOrIndex), 1)
                    if (!isOther && !isNil(att?.otherOption)) {
                      isLast = false
                    }
                    if (isOther) {
                      isLast = true
                    }
                    return {
                      label: `${toString(answer)}${isLast ? '' : ', '}`,
                      color: optionColor,
                    }
                  }

                  if (questionMetric.type === 'INTEGER') {
                    return {
                      label: isNil(answer) ? '' : round(answer).toLocaleString(),
                      color: optionColor,
                    }
                  }

                  return {
                    label: toString(answer),
                    color: optionColor,
                  }
                }

                if (['BREAKDOWN', 'MULTI_SELECT'].includes(questionMetric.type)) {
                  if (isString(att)) {
                    const optionAttestation = getAttestationDetail(att)
                    return (
                      <AttestationResponse key={`company-attestation-${companyRow.id}-${jIndex}`}>
                        <AttestationAnswer
                          key={`company-attestation-${companyRow.id}-${jIndex}`}
                          optionColor={optionAttestation?.color}
                          isBreakdown={questionMetric.type === 'BREAKDOWN'}
                        >
                          {optionAttestation?.label}
                        </AttestationAnswer>
                      </AttestationResponse>
                    )
                  }
                }

                if (questionMetric.type === 'MULTI_SELECT') {
                  const otherAttestationDetail = getAttestationDetail(att?.otherOption)
                  return (
                    <AttestationResponse key={`company-attestation-${companyRow.id}-${jIndex}`} isMultiSelect>
                      {map(att?.selections, (answer, keyOrIndex) => {
                        const optionAttestation = getAttestationDetail(answer, keyOrIndex)
                        return (
                          <InlineAttestationAnswer
                            key={`company-attestation-${companyRow.id}-${jIndex}-${keyOrIndex}`}
                            optionColor={optionAttestation?.color}
                          >
                            {optionAttestation?.label}
                          </InlineAttestationAnswer>
                        )
                      })}
                      {isNil(att?.otherOption) ? null : (
                        <InlineAttestationAnswer optionColor={otherAttestationDetail?.color}>
                          {otherAttestationDetail?.label}
                        </InlineAttestationAnswer>
                      )}
                    </AttestationResponse>
                  )
                }

                if (questionMetric.type === 'BREAKDOWN') {
                  return (
                    <AttestationResponse key={`company-attestation-${companyRow.id}-${jIndex}`} isBreakdown>
                      {map(att, (answer, keyOrIndex) => {
                        const optionAttestation = getAttestationDetail(keyOrIndex, answer)
                        return (
                          <AttestationAnswer
                            key={`company-attestation-${companyRow.id}-${jIndex}-${keyOrIndex}`}
                            optionColor={optionAttestation?.color}
                            isBreakdown={questionMetric.type === 'BREAKDOWN'}
                          >
                            {optionAttestation?.label}
                          </AttestationAnswer>
                        )
                      })}
                    </AttestationResponse>
                  )
                }

                const companyAttestation = getAttestationDetail(att)

                return (
                  <AttestationResponse key={`company-attestation-${companyRow.id}-${jIndex}`}>
                    <AttestationAnswer optionColor={companyAttestation?.color}>
                      {companyAttestation?.label}
                    </AttestationAnswer>
                  </AttestationResponse>
                )
              })}
            </HistoryTableRow>
          )
        })}
      </CompanyResponsesTable>
      <ToggleDetailBtn onClick={() => setIsDetailOpen(!isDetailOpen)} isOpen={isDetailOpen}>
        <FontAwesomeIcon icon="angle-double-down" />
        <div>{isDetailOpen ? t('hideHistoryDetail') : t('showHistoryDetail')}</div>
        <FontAwesomeIcon icon="angle-double-down" />
      </ToggleDetailBtn>
    </>
  )
}

export default MetricQuestionHistory
