import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { listTemplateOptions } from 'actions/templateActions'
import { isMockData } from 'utils/brandConfig'
import { head, map, find } from 'lodash'

import Spinner from 'components/Spinner'
import ReportsList from './ReportsList'
import UsersList from './UsersList'
import ErrorView from 'components/ErrorView'
import Button from 'components/Button'
import AdHocReportModal from 'components/AdHocReportModal'

import { PageContent, PageTitle } from 'components/styles'
import {
  CompanyDetailContent,
  ReportSettingsContainer,
  ReportsListWrapper,
  CompanySettingsWrapper,
  PortfolioSettingsTitle
} from './styles'
import { findCompany } from 'actions/companyActions'
import { mockTemplates } from 'mockData/mockTemplates'
import { mockCompanies } from 'mockData/mockCompanies'

interface CompanyViewProps {
  match: {
    params: {
      companyId: string
    }
  }
}

const CompanyView: React.FC<CompanyViewProps> = ({
  match: {
    params: { companyId },
  },
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const [ isAdHocModalOpen, setIsAdHocModalOpen ] = useState(false)
  const { companyDetail } = useSelector((state: any) => state.companies)
  const { templateOptions } = useSelector((state: any) => state.templates)
  // const { handleSubmit, register, errors, setValue } = useForm()

  React.useEffect(() => {
    dispatch(findCompany(auth0Context, companyId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!templateOptions.lastUpdated) {
      dispatch(listTemplateOptions(auth0Context))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (companyDetail.isFetching) {
    return <Spinner />
  }

  const selectedCompany = isMockData ? find(mockCompanies, co => co.id === companyId) : companyDetail?.item

  const selectedTemplate = isMockData ? head(mockTemplates) : companyDetail?.item.template
  const selectedTemplateId = selectedTemplate?.id
  const templatesListOptions = isMockData
    ? map(mockTemplates, t => ({
        value: t.id,
        label: t.name,
      }))
    : templateOptions?.items

  console.log('templatesListOptions', templatesListOptions)

  const daysToComplete = isMockData ? 90 : companyDetail?.item.daysToComplete

  let content
  if (companyDetail.error && !isMockData) {
    content = <ErrorView serverResponse={companyDetail.error} errorMsg={t('findCompanyError')} />
  } else if (companyDetail.isFetching) {
    content = <Spinner />
  } else {
    content = (
      <CompanyDetailContent>
        <UsersList companyId={companyId} />
        {companyDetail?.item?.status === 'ENABLED' || isMockData ? (
          <ReportSettingsContainer>
            <PortfolioSettingsTitle>{t('adHocReportSubtitle')}</PortfolioSettingsTitle>
            <CompanySettingsWrapper>
              <Button onClick={() => setIsAdHocModalOpen(true)}>{t('sendAdHocReportBtn')}</Button>
            </CompanySettingsWrapper>
          </ReportSettingsContainer>
        ) : null}
        <ReportsListWrapper>
          <ReportsList companyId={companyId} />
        </ReportsListWrapper>
      </CompanyDetailContent>
    )
  }

  return (
    <PageContent>
      <PageTitle>{selectedCompany?.name}</PageTitle>
      {content}
      <AdHocReportModal
        isOpen={isAdHocModalOpen}
        onClose={() => setIsAdHocModalOpen(false)}
        templatesListOptions={templatesListOptions}
        initialTemplateId={selectedTemplateId}
        initialDaysToComplete={daysToComplete}
      />
    </PageContent>
  )
}

export default CompanyView
