import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { size } from 'lodash'
import { validateEmail } from 'utils/stringMethods'
import Form from 'components/Form'

const ExampleContainer = styled.div`
  width: 20rem;
  margin: 1rem auto;
`

const Example: React.FC<{}> = () => {
  const { t } = useTranslation()

  const handleSubmit = (e: any) => {
    // TODO fix type to be specific to event
    e.preventDefault()
    console.log('handleSubmit()')
  }

  // const toggleForm = (e: any) => {
  //   // TODO fix type to be specific to event
  //   e.preventDefault()
  //   console.log('toggleForm()')
  // }

  // const fields = [
  //   {
  //     name: 'name',
  //     label: t('nameLabel'),
  //     type: 'text',
  //     initialValue: '',
  //     validationSchema: 'textRequired'
  //   },
  //   {
  //     name: 'email',
  //     label: t('emailLabel'),
  //     type: 'text',
  //     initialValue: '',
  //     validationSchema: 'emailRequired'
  //   },
  // ]

  const fields = {
    name: '',
    email: '',
  }

  const validate = (values: any) => {
    const errors: any = {}
    if (size(values.name) < 1) {
      errors.name = t('nameFieldRequired')
    }
    if (size(values.email) < 1 || !validateEmail.test(values.email)) {
      errors.email = t('emailFieldRequired')
    }
    return errors
  }

  return (
    <ExampleContainer>
      <Form fields={fields} validate={validate} onSubmit={handleSubmit} />
    </ExampleContainer>
  )
}

export default Example
