import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import Spinner from 'components/Spinner'
import Sidenav from 'app/Sidenav'
import Titlebar from 'app/Titlebar'
import PrivateRoute from 'components/PrivateRoute'

import { useAuth0 } from '../utils/auth0'
import { isUndefined, isNil } from 'lodash'

import DashboardPage from 'features/dashboard/DashboardPage'
import PortfolioPage from 'features/portfolio/PortfolioPage'
import OrgSettingsPage from 'features/orgSettings/OrgSettingsPage'

import TemplateView from 'features/template/TemplateView'
import CompanyView from 'features/portfolio/CompanyView'
import ReportView from 'features/template/ReportView'

import UserReportsList from 'features/reportUser/UserReportsList'
import UserReportView from 'features/reportUser/UserReportView'
import ScheduleView from 'features/schedule/ScheduleView'

import MetricsView from 'features/metrics/MetricsView'

import ComponentLibrary from 'features/tempComponentLibrary/ComponentLibrary'
import SettingsModal from './SettingsModal'
import { AppContainer, MainContent } from './styles'
import HelpModal from './HelpModal'

const App: React.FC = () => {
  const { user, isInitializing, logout } = useAuth0()
  const [isInstructionsOpen, toggleInstructionsOpen] = React.useState<boolean>(false)
  const [isHelpOpen, toggleHelpOpen] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (
      !isNil(user) &&
      !isNil(user['https://app_metadata.com/']) &&
      isNil(user['https://app_metadata.com/'].clientId)
    ) {
      sessionStorage.clear()
      logout()
    }
  }, [user, logout])

  if (isInitializing) return <Spinner />

  sessionStorage.setItem('user', JSON.stringify(user))

  if (!isUndefined(user)) {
    sessionStorage.setItem('app_metadata', JSON.stringify(user['https://app_metadata.com/']))
  }

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppContainer>
          <Sidenav />
          <Titlebar toggleModal={() => toggleInstructionsOpen(true)} toggleHelpModal={() => toggleHelpOpen(true)} />
          <MainContent id="scroll-target">
            <Switch>
              <PrivateRoute exact path="/" component={DashboardPage} />
              <PrivateRoute path="/portfolio" component={PortfolioPage} />
              <PrivateRoute path="/settings" component={OrgSettingsPage} />
              <PrivateRoute path="/components" component={ComponentLibrary} />
              <PrivateRoute path="/template/:templateId?" component={TemplateView} />
              <PrivateRoute path="/company/:companyId?" component={CompanyView} />
              <PrivateRoute path="/metrics/:groupId?/:metricId?/:viewType?" component={MetricsView} />
              <PrivateRoute path="/report/:reportId?/:companyId?" component={ReportView} />
              <PrivateRoute path="/userReportsList" component={UserReportsList} />
              <PrivateRoute path="/userReport/:reportId?" component={UserReportView} />
              <PrivateRoute path="/schedule" component={ScheduleView} />
            </Switch>
          </MainContent>
          <SettingsModal isOpen={isInstructionsOpen} onClose={() => toggleInstructionsOpen(false)} />
          <HelpModal isOpen={isHelpOpen} onClose={() => toggleHelpOpen(false)} />
        </AppContainer>
      </QueryParamProvider>
    </Router>
  )
}

export default App
