import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { map, isEmpty, concat, isString, isNil, includes, without, find, filter, difference } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm, FieldValues } from 'react-hook-form'
import Markdown from 'markdown-to-jsx'

import Spinner from 'components/Spinner'
import Button from 'components/Button'
import ErrorView from 'components/ErrorView'
import { ActionBtns } from 'components/styles'

import { isMockData } from 'utils/brandConfig'
import { mockTemplates } from 'mockData/mockTemplates'
import { listTemplates, findTemplate } from 'actions/templateActions'
import { CLEAR_ERRORS } from 'actions/metricsActions'

import {
  AddEditWrapper,
  ErrorMsgWrapper,
  FormContainer,
  FieldsWrapper,
  Label,
  SelectInput,
  SelectOption,
  InputField,
  ErrorMsg,
  OptionsWrapper,
  OptionsLabel,
  ItemOption,
  ItemLabel,
  InvalidMsg,
  AddMetricInvalid,
  ActionRow,
} from './styles'

interface AddEditMetricFormProps {
  existingMetric?: {
    id: string
    name: string
    templateId: string
    sectionId: string
    selectedQuestionIds: string[]
    selectedCompanyIds: string[]
  }
  onRemove: (id?: string) => void
  onSubmit: (data: any, existingMetricId?: string) => void
  onCancel: () => void
}

const INVALID_TYPES = ['SHORT_ANSWER', 'FILE']

const AddEditMetricForm: React.FC<AddEditMetricFormProps> = ({ existingMetric, onRemove, onSubmit, onCancel }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { error, loading } = useSelector((state: any) => state.metrics)
  const { templateList, templateDetail } = useSelector((state: any) => state.templates)
  const { handleSubmit, errors, register, triggerValidation, watch, clearError } = useForm({})

  const [fetchedTemplateId, setFetchedTemplateId] = useState<string | undefined>(undefined)
  const [unselectedQuestions, setUnselectedQuestions] = useState<string[]>([])
  const [isSelectedChanged, setIsSelectedChanged] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const watchedFields = watch()

  useEffect(() => {
    dispatch(listTemplates(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isString(watchedFields?.templateId) && watchedFields?.templateId !== fetchedTemplateId) {
      setFetchedTemplateId(watchedFields?.templateId)
      dispatch(findTemplate(auth0Context, watchedFields?.templateId))
    }

    if (
      !watchedFields?.templateId &&
      isString(existingMetric?.templateId) &&
      existingMetric?.templateId !== fetchedTemplateId
    ) {
      setFetchedTemplateId(existingMetric?.templateId)
      dispatch(findTemplate(auth0Context, existingMetric?.templateId as string))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, auth0Context, watchedFields])

  useEffect(() => {
    if (isSubmitting && (error?.ADD_METRIC || error?.EDIT_METRIC)) {
      setIsSubmitting(false)
    }
  }, [isSubmitting, error])

  const templates = isMockData ? mockTemplates : templateList?.items

  const templatesListOptions = map(templates, t => ({
    value: t.id,
    label: t.name,
  }))

  const sectionsListOptions = isEmpty(templateDetail?.item?.sections)
    ? []
    : map(templateDetail?.item?.sections, s => ({
        value: s.id,
        label: s.name,
      }))

  const selectedSection = find(templateDetail?.item?.sections, sec => sec.id === watchedFields?.sectionId)

  useEffect(() => {
    if (existingMetric) {
      const validQuestions = map(
        filter(selectedSection?.questions, q => !includes(INVALID_TYPES, q.type) && selectedSection?.type === 'CUSTOM'),
        'id',
      )
      const deselectedQs = difference(validQuestions, existingMetric.selectedQuestionIds)
      setUnselectedQuestions(deselectedQs)
    }
  }, [existingMetric, templateDetail, selectedSection, setUnselectedQuestions])

  useEffect(() => {
    if (existingMetric) {
      const validQuestions = map(
        filter(selectedSection?.questions, q => !includes(INVALID_TYPES, q.type) && selectedSection?.type === 'CUSTOM'),
        'id',
      )
      const deselectedQs = difference(validQuestions, existingMetric.selectedQuestionIds)
      setUnselectedQuestions(deselectedQs)
    }
  }, [existingMetric, templateDetail, selectedSection, setUnselectedQuestions])

  useEffect(() => {
    if (existingMetric) {
      const validQuestions = map(
        filter(selectedSection?.questions, q => !includes(INVALID_TYPES, q.type) && selectedSection?.type === 'CUSTOM'),
        'id',
      )
      const deselectedQs = difference(validQuestions, existingMetric.selectedQuestionIds)
      setUnselectedQuestions(deselectedQs)
    }
  }, [existingMetric, templateDetail, selectedSection, setUnselectedQuestions])

  useEffect(() => {
    if (existingMetric) {
      const validQuestions = map(
        filter(selectedSection?.questions, q => !includes(INVALID_TYPES, q.type) && selectedSection?.type === 'CUSTOM'),
        'id',
      )
      const deselectedQs = difference(validQuestions, existingMetric.selectedQuestionIds)
      setUnselectedQuestions(deselectedQs)
    }
  }, [existingMetric, templateDetail, selectedSection, setUnselectedQuestions])

  const selectedQuestions = filter(
    selectedSection?.questions,
    q => !includes(unselectedQuestions, q.id) && !includes(INVALID_TYPES, q.type) && selectedSection?.type === 'CUSTOM',
  )

  // console.log('Metric Editing', {
  //   watchedSectionId: watchedFields?.sectionId,
  //   existingSectionId: existingMetric?.sectionId,
  //   selectedSection,
  //   selectedQuestions,
  //   unselectedQuestions,
  //   existingMetric,
  //   templateSections: templateDetail?.item?.sections,
  // })

  const isFormReady =
    (watchedFields?.name || existingMetric?.name) &&
    (watchedFields?.templateId || existingMetric?.templateId) &&
    (watchedFields?.sectionId || existingMetric?.sectionId) &&
    !isEmpty(selectedQuestions)

  const handleSubmitInternally = (data: FieldValues) => {
    setIsSubmitting(true)
    clearError()
    dispatch({ type: CLEAR_ERRORS })
    const fullData = {
      name: data.name || existingMetric?.name,
      templateId: data.templateId || existingMetric?.templateId,
      sectionId: data.sectionId || existingMetric?.sectionId,
      questions:
        isNil(existingMetric) || isSelectedChanged
          ? map(selectedQuestions, q => q.id)
          : existingMetric?.selectedQuestionIds,
    }
    onSubmit(fullData, existingMetric?.id)
  }

  const handleCancel = () => {
    clearError()
    dispatch({ type: CLEAR_ERRORS })
    onCancel()
  }

  const handleRemove = () => {
    if (existingMetric) {
      clearError()
      dispatch({ type: CLEAR_ERRORS })
      onRemove(existingMetric.id)
    }
  }

  // TODO clear error messages when modal closes and opens

  const toggleQuestion = (questionId: string) => {
    setIsSelectedChanged(true)
    if (includes(unselectedQuestions, questionId)) {
      setUnselectedQuestions(without(unselectedQuestions, questionId))
    } else {
      setUnselectedQuestions(concat(unselectedQuestions, questionId))
    }
  }

  const isLoading = !templateList || (templateList.isFetching && isEmpty(templateList.items)) || loading?.ADD_METRIC

  if (isLoading || isSubmitting) return <Spinner />

  if (isEmpty(templateList.items)) {
    return (
      <div>
        <ErrorMsgWrapper>{t('cannotAddMetricWithNoTemplatesMsg')}</ErrorMsgWrapper>
        <ActionBtns>
          <Button onClick={handleCancel} size="small" color="secondary">
            {t('okayBtn')}
          </Button>
        </ActionBtns>
      </div>
    )
  }

  const submissionError = error?.ADD_METRIC || error?.EDIT_METRIC

  let questionContent = <div />
  if (selectedSection?.type === 'AUTHORITY_MATRIX') {
    questionContent = <AddMetricInvalid>{t('addMetricAuthMatrixInvalid')}</AddMetricInvalid>
  }
  if (selectedSection?.type === 'CUSTOM') {
    questionContent = (
      <OptionsWrapper>
        <OptionsLabel>{t('addMetricQuestionsFieldLabel')}</OptionsLabel>
        {map(selectedSection.questions, q => {
          const isSelected = !includes(unselectedQuestions, q.id)
          const isInvalid = INVALID_TYPES.includes(q.type)
          const Icon = isInvalid ? (
            <FontAwesomeIcon icon="ban" />
          ) : isSelected ? (
            <FontAwesomeIcon icon="check-square" />
          ) : (
            <FontAwesomeIcon icon="square" />
          )

          if (!q.type || !q.query) return null

          return (
            <ItemOption
              key={`question-option-${q.id}`}
              onClick={() => toggleQuestion(q.id)}
              isDisabled={isInvalid}
              isSelected={isSelected}
            >
              {Icon}
              <ItemLabel isDisabled={isInvalid}>
                <Markdown>{q.query}</Markdown>
              </ItemLabel>
              {isInvalid && (
                <InvalidMsg>
                  {t('invalidQuestionMsg', { type: q.type === 'FILE' ? 'File Type' : 'Short Answer' })}
                </InvalidMsg>
              )}
            </ItemOption>
          )
        })}
      </OptionsWrapper>
    )
  }

  return (
    <AddEditWrapper>
      <FormContainer onSubmit={handleSubmit(handleSubmitInternally)}>
        <FieldsWrapper>
          <Label key="name" htmlFor="name">
            {t('addMetricNameFieldLabel')}
            <InputField
              name="name"
              type="text"
              ref={register({ required: true, minLength: 2 })}
              onBlur={() => triggerValidation('name')}
              defaultValue={existingMetric ? existingMetric.name : ''}
            />

            {errors['name'] && errors['name']?.type && (
              <ErrorMsg>
                {errors[
                  errors['name'].type as 'required' | 'min' | 'max' | 'minLength' | 'maxLength' | 'pattern' | 'validate'
                ] || t(`${'name'}_${errors['name'].type}`)}
              </ErrorMsg>
            )}
          </Label>

          <Label key="templateId" htmlFor="templateId">
            {t('addMetricTemplateFieldLabel')}
            <SelectInput
              name="templateId"
              ref={register({ required: true })}
              onBlur={() => triggerValidation('templateId')}
              defaultValue={existingMetric ? existingMetric.templateId : ''}
            >
              {map(templatesListOptions, ({ value, label }) => (
                <SelectOption key={`option-${value}`} value={value}>
                  {label}
                </SelectOption>
              ))}
            </SelectInput>

            {errors['templateId'] && errors['templateId']?.type && (
              <ErrorMsg>
                {errors[
                  errors['templateId'].type as
                    | 'required'
                    | 'min'
                    | 'max'
                    | 'minLength'
                    | 'maxLength'
                    | 'pattern'
                    | 'validate'
                ] || t(`${'templateId'}_${errors['templateId'].type}`)}
              </ErrorMsg>
            )}
          </Label>

          {(watchedFields?.name || existingMetric?.name) && (watchedFields?.templateId || existingMetric?.templateId) && (
            <>
              {!isNil(templateDetail?.error) ? (
                <ErrorMsg>{t('fetchTemplateDetailErrorMsg')}</ErrorMsg>
              ) : isEmpty(sectionsListOptions) ? (
                <Spinner isMini />
              ) : (
                <Label key="sectionId" htmlFor="sectionId">
                  {t('addMetricSectionFieldLabel')}
                  <SelectInput
                    name="sectionId"
                    ref={register({ required: true })}
                    onBlur={() => triggerValidation('sectionId')}
                    defaultValue={existingMetric ? existingMetric.sectionId : ''}
                  >
                    {map(sectionsListOptions, ({ value, label }) => (
                      <SelectOption key={`option-${value}`} value={value}>
                        {label}
                      </SelectOption>
                    ))}
                  </SelectInput>

                  {errors['sectionId'] && errors['sectionId']?.type && (
                    <ErrorMsg>
                      {errors[
                        errors['sectionId'].type as
                          | 'required'
                          | 'min'
                          | 'max'
                          | 'minLength'
                          | 'maxLength'
                          | 'pattern'
                          | 'validate'
                      ] || t(`${'sectionId'}_${errors['sectionId'].type}`)}
                    </ErrorMsg>
                  )}
                </Label>
              )}
            </>
          )}
          {questionContent}
        </FieldsWrapper>

        <ActionRow>
          {existingMetric?.id ? (
            <Button size="small" color="error" onClick={handleRemove}>
              {t('removeMetricBtn')}
            </Button>
          ) : (
            <div />
          )}
          <ActionBtns isInGrid>
            <Button type="submit" size="small" color="success" isDisabled={!isFormReady}>
              {t('submitBtn')}
            </Button>
            <Button onClick={handleCancel} size="small" color="secondary">
              {t('cancelBtn')}
            </Button>
          </ActionBtns>
        </ActionRow>
        {!isMockData && submissionError && <ErrorView serverResponse={submissionError} hideContactMsg />}
      </FormContainer>
    </AddEditWrapper>
  )
}

export default AddEditMetricForm
