import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import NewStateLogoImg from 'components/NewStateLogo/newstate_white.png'

interface NewStateLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const NewStateLogo: React.FC<NewStateLogoProps> = ({ brandName }: NewStateLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={NewStateLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default NewStateLogo
