import React from 'react'
import styled from 'styled-components'
import { map, values, nth, round, size as arraySize, reduce, divide, sortBy, last, find, upperCase } from 'lodash'
import { random } from 'utils/colors'
import { VictoryPie } from 'victory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface BreakdownGraphProps {
  totals: Record<string, number>
  options?: string[]
  randomOffset: number
  isPercent?: boolean
  size?: 'small' | 'medium' | undefined
}

const BreakdownGraphWrapper = styled.div<{ size?: 'small' | 'medium' | undefined }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${p => (p.size === 'small' ? '6em' : p.size === 'medium' ? '7em' : '12em')} auto;
  align-items: center;
  font-size: ${p => (p.size === 'small' ? '0.8em' : p.size === 'medium' ? '0.9em' : '1em')};
`
const DonutWrapper = styled.div``
const BarGraphWrapper = styled.div`
  display: grid;
  grid-template-columns: 3.5em auto;
  grid-gap: 0em 1em;
  align-items: center;
  & > * {
    height: 1.8em;
  }
`
const Value = styled.div<{ colorIndex: number }>`
  color: ${p => nth(random, p.colorIndex)};
  font-weight: 600;
  font-size: 1.2em;
  padding: 0.3em 0 0;
  line-height: 1;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: center;
  svg {
    font-size: 0.8em;
    margin-left: 0.3em;
    opacity: 0.5;
  }
`
const BarWrapper = styled.div<{ colorIndex: number }>`
  color: ${p => nth(random, p.colorIndex)};
  height: 1.6em;
  position: relative;
  p {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1;
    padding: 0.3em;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const BarElement = styled.div<{ colorIndex: number; relativeWidth: number }>`
  width: ${p => p.relativeWidth}%;
  height: 2em;
  background: ${p => nth(random, p.colorIndex)};
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
`

const BreakdownGraph: React.FC<BreakdownGraphProps> = ({ totals, options, randomOffset, isPercent, size }) => {
  const randomColorsLen = arraySize(random)
  const totalCount = reduce(totals, (sum: number, n: number) => sum + n, 0)
  const largestCount = last(sortBy(values(totals)))

  if (totalCount < 1 || !largestCount || !options) return null

  const donutData = map(options, (optionLabel: string, index: number) => {
    const count = totals[optionLabel] || 0
    const colorIndex = (index + randomOffset) % randomColorsLen

    return {
      count: round(count),
      color: nth(random, colorIndex),
      label: ' ',
    }
  })

  const renderBar = (optionLabel: string, index: number) => {
    const count = find(totals, (val, key) => upperCase(key) === upperCase(optionLabel)) || 0
    const val = isPercent ? round(divide(count, totalCount) * 100) : count
    const colorIndex = (index + randomOffset) % randomColorsLen
    const relativeWidth = round(divide(count, largestCount) * 100)

    return (
      <React.Fragment key={`bar-${optionLabel}`}>
        <Value colorIndex={colorIndex}>
          {isPercent && <FontAwesomeIcon icon="percent" />}
          {val}
        </Value>
        <BarWrapper colorIndex={colorIndex}>
          <p>{optionLabel}</p>
          <BarElement colorIndex={colorIndex} relativeWidth={relativeWidth} />
        </BarWrapper>
      </React.Fragment>
    )
  }

  const rad = size === 'small' ? 55 : size === 'medium' ? 65 : 75

  return (
    <BreakdownGraphWrapper size={size}>
      <DonutWrapper>
        <VictoryPie
          data={donutData}
          x="label"
          y="count"
          innerRadius={rad}
          style={{
            data: {
              fill: ({ datum }) => datum.color,
            },
          }}
        />
      </DonutWrapper>
      <BarGraphWrapper>{map(options, renderBar)}</BarGraphWrapper>
    </BreakdownGraphWrapper>
  )
}

export default BreakdownGraph
