import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalTitle } from 'components/styles'
import { SettingsModalContainer, CloseIcon } from './styles'

interface HelpModalProps {
  isOpen: boolean
  onClose: () => void
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }: HelpModalProps) => {
  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  const ModalContent = (
    <>
      <ModalTitle>
        {t('helpModalTitle')}
        <CloseIcon onClick={handleClose}>
          <FontAwesomeIcon icon="times" />
        </CloseIcon>
      </ModalTitle>

      <SettingsModalContainer>
        Send us an email at support@complyify.com to connect with our support team. A dedicated team member will respond
        within 1 (one) business day.
      </SettingsModalContainer>
    </>
  )

  return <Modal isOpen={isOpen} content={ModalContent} onClose={handleClose} width="lg" />
}

export default HelpModal
