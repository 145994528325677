import { find } from 'lodash'
import { Template } from 'models/template'
import mockGetTemplate from './mockGetTemplate.json'

export const mockTemplatesResponse = [mockGetTemplate.template]

export const mockTemplateDetailResponse = mockGetTemplate.template

export const mockTemplates: Template[] = [
  {
    id: '583236ad-52f2-4fab-bc03-bade14ab59d5',
    name: 'Technology Company Template',
    status: 'CREATED',

    client: {
      id: 'accb6e70-8e69-478b-b8b4-b3eda2395daf',
      name: '',
    },
    sections: [
      {
        id: '851a4953-f04f-4f38-9e4b-0962022c96db',
        name: 'Section Name Here',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Column 1 Name Here', 'Column 2 Name Here'],
        questions: [
          {
            id: 'b197984e-d978-45b3-8cd0-af01b3b81c6b',
            columns: ['Huh?', true],
            allowNA: true,
            allowDocuments: true,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 42,
          NON_COMPLIANT: 42,
          NOT_APPLICABLE: 42,
        },
      },
    ],
    instructions: 'Instructions here',
    isInUse: true,
    isLocked: false,
    numberOfReports: 0,
    events: [
      {
        id: 'dc32c5e0-8748-4577-877d-f19fa45c8a94',
        name: 'Event Name Here',
        timestamp: new Date(2021, 1, 3),
        user: {
          id: '7fd5df07-5266-4ddf-a957-671612eca7e4',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
          picture: '',
        },
      },
    ],
  },
  {
    id: 'fa6a71f9-d54c-463f-bf76-96a3fcfd87c7',
    name: 'Consumer Goods Company Template',
    status: 'CREATED',

    client: {
      id: 'accb6e70-8e69-478b-b8b4-b3eda2395daf',
      name: '',
    },
    sections: [
      {
        id: '851a4953-f04f-4f38-9e4b-0962022c96db',
        name: 'Section Name Here',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Column 1 Name Here', 'Column 2 Name Here'],
        questions: [
          {
            id: 'b197984e-d978-45b3-8cd0-af01b3b81c6b',
            columns: ['Huh?', true],
            allowNA: true,
            allowDocuments: true,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 42,
          NON_COMPLIANT: 42,
          NOT_APPLICABLE: 42,
        },
      },
    ],
    instructions: 'Instructions here',
    isInUse: true,
    isLocked: true,
    numberOfReports: 1,
    events: [
      {
        id: 'dc32c5e0-8748-4577-877d-f19fa45c8a94',
        name: 'Event Name Here',
        timestamp: new Date(2021, 2, 3),
        user: {
          id: '7fd5df07-5266-4ddf-a957-671612eca7e4',
          name: 'Robin Wave',
          email: 'rwave@axyqcapital.com',
          picture: '',
        },
      },
    ],
  },
  {
    id: '52b91c85-d023-4b55-b8de-7fef47f20053',
    name: 'Oil/Gas Template',
    status: 'CREATED',

    client: {
      id: 'accb6e70-8e69-478b-b8b4-b3eda2395daf',
      name: '',
    },
    sections: [
      {
        id: '71e314b5-635a-4134-b0ea-ead02ffad097',
        name: 'Facilities / Projects',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'Section1-Question1',
            columns: ['1. Development or acquisition of new aboveground facility', '', 'CEO/CFO/COO', 'Deal Lead'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section1-Question2',
            columns: [
              '2. Initiation of any international project requiring significant resource allocation',
              '',
              'CEO/CFO/COO',
              'Deal Lead',
            ],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section1-Question3',
            columns: ['3. Completion of FERC Security Checklist', '', 'COO', 'Deal Lead'],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Copy of FERC Security Check List',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section1-Question4',
            columns: [
              '4. Completion of regular physical security onsite assessment since last quarter',
              '',
              'COO',
              'Deal Lead',
            ],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Proof of assessment compliance status',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section1-Question5',
            columns: [
              '5. Completion of passed pipeline inspection at each applicable project site since last quarter',
              '',
              'COO',
              'Deal Lead',
            ],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Proof of passed inspection',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section1-Question6',
            columns: [
              '6. Completion of passed gas installation inspection checklist at applicable project site since last quarter',
              '',
              'COO',
              'Deal Lead',
            ],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Proof of passed inspection',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },
      {
        id: 'f2562a80-cd78-402f-b6a8-941cbcf59257',
        name: 'Market Conditions',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'Section2-Question1',
            columns: [
              '1. Price shift due to significant decrease in market demand for fuels',
              'Decrease in excess of 10% YTD',
              'CEO',
              'Deal Lead',
            ],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section2-Question2',
            columns: ['2. Addition of new distribution partner', '', 'CEO/CFO/COO', 'Deal Team/Fund Sr Mgmt'],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section2-Question3',
            columns: [
              '3. Reduction in existing distribution partner who\'s volume decreased by more than:',
              '25%',
              'CEO/CFO/COO',
              'Deal Team/Fund Sr Mgmt',
            ],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },
      {
        id: 'd3a16f24-a0e8-413e-babe-5db3466850a3',
        name: 'Incident Response',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'Section3-Question1',
            columns: [
              '1. Notification to deal team of any significant insurance claim or lawsuit suit pursuant to employee on-site injury',
              'Claim or Suit >$100,000',
              'CEO',
              'Deal Lead',
            ],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Provide proof of claim or suit',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section3-Question2',
            columns: [
              '2. Proper notification to Deal Team and local agencies of a spill / leak at a well or facility site',
              '',
              'CEO',
              'Deal Lead',
            ],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Proof of notification',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section3-Question3',
            columns: ['3. Update to/ creation of incident response plan', '', 'CEO', 'Deal Lead'],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Copy of new/updated plan',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
          {
            id: 'Section3-Question4',
            columns: [
              '4. Resolution of any Cybersecurity event or impact',
              '',
              'CEO/CFO/COO',
              'Deal Team/Fund Sr Mgmt',
            ],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [
              {
                userId: '2d3385a5-48d6-4939-84f5-d89434755160',
                sectionId: '8652169d-0e4a-4c5a-8889-d89d0704b310',
                questionId: '8b2848ea-8c61-4515-87d0-9b8faa96b545',
                attestation: 'COMPLIANT',
                documents: [],
                explanation: 'Explanation Here',
              },
            ],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },
    ],
    instructions:
      'Instructions: This report serves to assess for operational risks related to compliance with the Natural Gas Act\'s Environmental Report Preparation Guidelines, which serves to  improve  the  overall quality  and  consistency  of  data  analyses  and  formatting  in  the  resource  reports  that comprise  the  Environmental  Report  to  be  filed  under  the  Federal  Energy  Regulatory Commission\'s    (FERC   or   Commission)   regulations   that   implement the National Environmental  Policy  Act  of  1969  (NEPA). \n\nFor each of the listed Actions/Decisions that require Company Approval or Fund Approval, if proper approvals were not made by each of the represented parties, select "Non Compliant". For security check or inspections, answer "Complaint" only when the inspection received a passing grade.',
    isInUse: true,
    isLocked: false,
    numberOfReports: 0,
    events: [
      {
        id: 'dc32c5e0-8748-4577-877d-f19fa45c8a94',
        name: 'TemplateCreated',
        timestamp: new Date(2021, 1, 3),
        user: {
          id: '7fd5df07-5266-4ddf-a957-671612eca7e4',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
          picture: '',
        },
      },
    ],
  },
  {
    id: '41c84097-9aa9-4e16-ae25-28ad266c7c44',
    name: 'ESG / Sustainability Governance Template',
    status: 'CREATED',

    client: {
      id: 'accb6e70-8e69-478b-b8b4-b3eda2395daf',
      name: '',
    },
    sections: [
      {
        id: '45912210-2d74-474d-851d-e8dd41e43cd7',
        name: 'ESG Policies/Procedures',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: '466372aa-fa12-4cf5-9b7e-92022590fcf5',
            columns: ['Does the company have a separate standalone ESG policy (i.e. in addition to an environmental or safety policy) which provides details of your approach and commitments to management of key ESG risks and opportunities? ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of Standalone ESG Policy',
            attestations: [],
          },
          {
            id: 'dc0fafc7-9bc6-456f-852d-c34d63198320',
            columns: ['Has the company developed an ESG improvement programme? If yes, explain how this is implemented.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '160c7467-ade2-42bf-b790-ff7efb5619e1',
            columns: ['Does the company have a separate ESG risk register? If yes, please upload document(s) that contains details of key risks and control measures.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of ESG Risk Register',
            attestations: [],
          },
          {
            id: '6fb7cb45-c666-43c5-9322-1a69f366a4f5',
            columns: ['Please provide details of any additional ESG relevant standards applied and certifications and/or memberships held such as the application of the IFC Environmental, Health and Safety Guidelines; International Labour Standards; G4 Sustainability Reporting Guidelines; members of the Carbon Disclosure Project.', '', '', ''],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Copies Of Additional ESG Relevant Standards/Policies/etc. ',
            attestations: [],
          },
          {
            id: '47a69a6b-ca8c-4a2b-95b0-c1bc7fc49544',
            columns: ['Are ESG processes and systems reviewed/audited either internally or externally on a regular basis and are the results reported back to the Board? If yes, please provide details of the types/frequency of these audits, who they are conducted by and key issues identified in the last audit.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of Last Audit Report',
            attestations: [],
          },
          {
            id: '576214d6-4677-4bb4-a19b-0f135b7461e9',
            columns: ['Has the company conducted an ESG risk assessment of media, stakeholders and other public sources external to the company?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '810d1644-cbf5-43da-8612-adda60ca3315',
        name: 'Environmental',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: '30d9524d-79eb-4e5e-a0e5-6b5b912e4e7c',
            columns: ['Does the company have an environmental policy which sets out clear commitments and targets to improve the company’s environmental footprint? Does this also cover climate change issues that could be relevant to the company? If yes, please provide details and indicate who in the organization signs off the policy e.g. CEO or equivalent.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of Environmental Policy',
            attestations: [],
          },
          {
            id: '0fb3894b-b5a2-454e-a217-2dbe84462424',
            columns: ['Is the environmental policy reviewed/updated at least every 3 years?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '3ece3a1d-ee18-4104-b297-4d0122e2624a',
            columns: ['Does the company do environmental training? if yes, answer "Compliant". ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'f8b5e443-7227-4fe3-98f1-4adcabde914a',
            columns: ['Has the company implemented an environmental management system to manage environmental matters and has this been externally certified e.g. to ISO 14001 or equivalent standard?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'e5b31669-ec63-4a79-9850-41b681351bd0',
            columns: ['Provide details of any significant projects/initiatives that have been implemented to improve environmental performance/standards/compliance at the company; for example: waste/energy projects that have resulted in carbon/consumption reductions as well as cost savings; enhanced communications to improve environmental awareness.', '', '', ''],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '79b50fdf-1029-4fba-92ea-46e9974c024f',
            columns: ['Please confirm that the company is fully compliant with all relevant environmental permits/licenses/ consents and that there have been no regulatory issues/cases of non-compliance/enforcement actions in the period.If "Non-Compliant", please provide detail. ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '504fae02-295a-4197-89b0-fbd993147ea8',
            columns: ['Have any serious incidents/regulatory breaches in the last 3 years. If no incident/breach has occurred, answer "Compliant". If an incident/breach has occurred, answer "Non-Compliant" and provide details of the nature of the incident, what improvements were made/lessons learnt and whether there was any regulatory action (enforcement/prosecution/quantity of fine).', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '466f484c-6101-44a3-b825-671c9b35db74',
            columns: ['Does the company monitor and report its carbon and/or other greenhouse gas (GHG) emissions such as methane, nitrous oxide etc.?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '2181bd97-9a0a-49c4-ad0c-364968460952',
            columns: ['Has the company conducted a climate change risk assessment to ascertain whether its operations could be at risk from current/evolving climate change regulation and/or physical changes brought about by climate change (including increased flooding, drought or other severe weather events), for example: business disruption or damage to assets and production? If yes, answer "Compliant" and provide key outcomes/actions from the assessments. If no, answer "Not-Applicable".', '', '', ''],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '48fb5f62-4479-4325-be9f-ffe5f3ce1dc4',
            columns: ['Is the company considering the use of more environmentally friendly and safer raw materials/ chemicals in the production process? How is this being addressed? Please provide details. ', '', '', ''],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'c5081fe2-83f8-4ac7-86d5-3c2611d6696b',
            columns: ['Is the company aware of any former or current soil/groundwater contamination issues at the site(s) and is there a need to conduct remediation and inform the local regulator? If this question is applicable and there are no issues, answer "Compliant".  If there are any issues, answer "Non-Compliant" and explain. In your response please provide details of the type of contamination and the associated costs for clean up, including status on any remedial plans.', '', '', ''],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '1f423721-8496-4106-b5e8-d9f9f50e156f',
            columns: ['Has an environmental risk and opportunity assessment of the company’s activities been completed? If yes, answer "Compliant" and provide documentation containing the key risk(s)/opportunities, control measure(s) and improvements.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of Environmental Risk And Opportunity Assessment Report',
            attestations: [],
          },
          {
            id: '24fae677-ef48-4acc-8794-0bd3b63f6618',
            columns: ['Has the company conducted a baseline assessment of its carbon/GHG footprint and has it implemented any measures to make any reductions? If yes, answer "Compliant" and please provide details of the emissions and any targets/initiatives implemented.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '5253884a-c061-4d53-b3e8-f20f1caabeba',
            columns: ['Has the company recognized any financial provisions in the accounts to address any environmental liabilities such as contaminated land/environmental restoration and aftercare requirements/removal of asbestos etc.? Please provide further details.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },

        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '6d80f94f-0a0f-40b1-af46-5975a22ffb01',
        name: 'Social',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'f33ac110-4f5b-4f91-9508-0b1f028ea977',
            columns: ['Does the company have a H&S policy which is signed off by the CEO or equivalent?', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of H&S Policy',
            attestations: [],
          },
          {
            id: 'b5fcb95f-7363-4b59-a72e-4fd486cd8702',
            columns: ['Does the company have a H&S risk register and is this reviewed on a regular basis? If yes state how often it is reviewed and by whom.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of H&S Risk Register',
            attestations: [],
          },
          {
            id: '287d6f80-3e5f-48f9-8961-17db951a5198',
            columns: ['Has the company implemented a formalized H&S management system, which sets out clear organizational responsibilities and management arrangements for implementing the H&S policy? Has the management system been certified by a third party?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '178acd30-b0ad-44e0-9b65-2b2a746d3d8b',
            columns: ['Does the company have formal processes for undertaking workplace risk assessments, providing communication and training to employees, and conducting audits? Are these risk assessments reviewed/updated at least every 3 years?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'f8d1beaf-d3d1-47b8-a9ca-3ac3c20f38e2',
            columns: ['Has the company been subject to enforcement action by the regulators for breaches of relevant H&S legislation in the last quarter? If no, answer "Compliant". If yes, answer "Non-compliant" and please provide details of the nature of the incident, enforcement action, fines, remedial actions and lessons learnt.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'b218a7d2-da60-4eda-8fe3-ddfef2bbe274',
            columns: ['Have you had any serious social related complaints/claims/enforcement actions over the last quarter, associated with employees or key stakeholders such as customers or suppliers? If yes, answer "Non-compliant" and please provide details.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'c9619000-dadb-408a-9a7a-0d87769cf291',
            columns: ['Do all employees have a formal contract of employment? If no, answer "Non-compliant". ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '81e510c3-d891-431b-a450-1b2dbfb62e98',
            columns: ['Do you have a policy that supports anti-discrimination? If so, please provide details of your key commitments/control measures.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy Of H&S Risk Register',
            attestations: [],
          },
          {
            id: 'a8b8e4a3-f327-4777-968e-0dbd6da54bba',
            columns: ['Does the company have a policy that supports diversity and equal opportunity? If so, please provide details of your key commitments/control measures.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of Diversity/EOO document',
            attestations: [],
          },
          {
            id: 'fd060f6f-d33e-4293-bca7-6a320ca9be54',
            columns: ['Do you have a documented data security policy? ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of Data Security Policy',
            attestations: [],
          },
          {
            id: '55cd6a30-b6cc-4b5c-871c-f58c19693fdf',
            columns: ['Have there been any breaches in cyber security within the last three years? If no, answer "Compliant". ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'df856033-ca87-4451-8ece-7491c91551e3',
            columns: ['Has the company implemented an IT security management system? Is it certified to the international standard ISO 27001 for information security?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'bb3a45a3-dad3-466e-8a81-8520ab2dd7a9',
            columns: ['Has management conducted a safety culture review across the organization? If yes, answer "Compliant" and provide details of key findings and associated improvement actions identified from the assessment.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'e0d4b2f1-173c-421d-be94-c617da36f06c',
            columns: ['Do you have policies and processes in place to identify, assess and address risks across the company and its supply chain with respect to human rights, labour standards and modern slavery? ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copies of Policies/Procedures related to human rights/labor standards',
            attestations: [],
          },
          {
            id: '9f8984d7-e364-4516-9481-fc2798c0cbd4',
            columns: ['Does the organization comply with ILO standards? Is this stated in associated HR policies etc.?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'c57355fc-3e3a-4b09-b7e3-e7f44ec19010',
            columns: ['Are steps defined, written, and taken to ensure the health and safety of consumers? Upload document containing these written steps. ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Consumer H&S Steps',
            attestations: [],
          },
          {
            id: '3938673c-cd07-476d-be12-b55ccc8efee1',
            columns: ['Are any formal programmes in place to promote company involvement with the community (volunteering, philanthropic activity, etc.). Answer "Compliant" if yes and describe the program and how much is invested in these projects.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },

        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '070ec69d-ad71-47a2-97c6-ceae44f027f3',
        name: 'Governance',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'bb880974-40b2-4123-b786-e6b649bb6917',
            columns: ['Do you have written corporate governance policies? If so, answer "Compliant" and upload these policies. ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of Corporate Governance Policy(s)',
            attestations: [],
          },
          {
            id: 'bc9d4741-3aab-4913-8c1a-2e9ed1ec3e27',
            columns: ['How is the composition of the Board determined? Answer this question "Compliant" and please provide details.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '472eef78-05b2-4993-991a-79a6effb06f0',
            columns: ['How is diversity taken into consideration when appointing members? Answer this question "Compliant" and please provide details.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'e1b04f3d-e63f-48f3-94d0-591f57f4aa99',
            columns: ['Are shareholders consulted in relation to Board composition? If so, answer this question "Compliant" and please provide details. if not, answer "Not-applicable".', '', '', ''],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '6d2acc65-9765-447c-a607-ad88a3624560',
            columns: ['Is a member of the Board assigned responsibility for ESG/sustainability/CSR matters within the company? If yes, answer "Compliant" and state the individual responsible and details of any relevant training.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'c55cf201-f485-4afa-aed7-81a3f774f678',
            columns: ['Is there an independent Chairman of the Board?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '980976cd-62f8-4c19-9800-590461380971',
            columns: ['Are there any women are on the Board? If yes, answer "Compliant" and provide how many women are on the board in the explanation, otherwise, answer "Non-Compliant".  ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '07134f9f-474f-49e5-a9e6-78659c411833',
            columns: ['Is ESG discussed at the Board level? If so, answer "Compliant" and describe how often this takes place and whether this this follow a set agenda/terms of reference. Provide details of the key issues regularly discussed.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'cf6e387a-abd9-4251-97e7-76774b1a0db1',
            columns: ['Is there a remuneration committee in place? Does this committee engage with the Board?', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'ce838d32-35e6-4422-869e-2574b37b6bc0',
            columns: ['Does the organization have written policies/procedures to safeguard against illegal practices (i.e. corruption/fraud detection)? ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of Illegal Practice Policy/Procedures',
            attestations: [],
          },
          {
            id: 'dce89f7d-a257-4ecf-90fc-d24996351f54',
            columns: ['In the last 3 years, have any corporate governance and/or ethical related employee claims/breaches/ enforcement/litigation action relating to issues such as anti-bribery and corruption, cases of unfair labour practices, human rights abuses, and other malpractices taken place? If no, answer "Compliant". If yes, answer "Non-compliant" and provide details of any prosecution (fines, imprisonment) and corrective/improvement action post the event(s).', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '988bc845-8c44-476d-8846-26d3e4b5128b',
            columns: ['Does the company have a code of ethics? Please provide details and confirmation of how this is delivered to staff within the company.', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Code of Ethics Document',
            attestations: [],
          },
          {
            id: '393a9a7d-cfc3-4207-bf7e-4849d1426823',
            columns: ['Does the company have a corporate code of conduct? Please provide details of the company’s corporate code of conduct. How is this delivered to staff within the company?', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Code of Conduct Document',
            attestations: [],
          },
          {
            id: '12879de7-8690-4690-8a23-d0f8b429551a',
            columns: ['Does the company have a written gifts/corporate entertaining policy? if yes, answer "compliant" and describe how this is regulated.  ', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of gifts/corporate entertaining policy',
            attestations: [],
          },
          {
            id: '2f8252fa-4fba-42ba-985a-100ec24ecfd6',
            columns: ['Does the company have an audit committee? If yes, answer "Compliant" and provide details of its composition, Terms of Reference and policies.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'af070397-74cf-47d4-96e7-6da085958821',
            columns: ['Does the company have a whistle-blowing policy? Is this service independently managed, and is it anonymous? Is it available at all times of the year?', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of Whistle Blowing Policy',
            attestations: [],
          },
          {
            id: 'ca6ef539-6b03-4584-b6fd-70ec49fe866c',
            columns: ['Does the company have an anti-bribery or anti-corruption (ABC) Policy? Has this been reviewed/updated in the last 3 years?', '', '', ''],
            allowNA: false,
            allowDocuments: true,
            documentDescription: 'Copy of Anti-bribery or Anti-corruption (ABC) Policy',
            attestations: [],
          },
          {
            id: 'f7524687-b3fa-466b-9323-841d6d10ead4',
            columns: ['Does the company have a process in place to conduct ABC due diligence on new suppliers, contractors etc.? If yes, answer "Compliant" and provide details of the process and how it is implemented.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

    ],
    instructions: '',
    isInUse: true,
    isLocked: true,
    numberOfReports: 42,
    events: [
      {
        id: 'dc32c5e0-8748-4577-877d-f19fa45c8a94',
        name: 'TemplateCreated',
        timestamp: new Date(2021, 1, 3),
        user: {
          id: '7fd5df07-5266-4ddf-a957-671612eca7e4',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
          picture: '',
        },
      },
    ],
  },
  {
    id: '3c262e4e-a716-4845-977d-91498200cd69',
    name: 'Cybersecurity Checklist Template',
    status: 'CREATED',

    client: {
      id: 'accb6e70-8e69-478b-b8b4-b3eda2395daf',
      name: '',
    },
    sections: [
      {
        id: 'a3d25afa-d2bf-40c3-9ccf-798dc9539193',
        name: 'Identify',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'dd289c5f-57e6-43b1-8dfd-de887a9fa6bd',
            columns: ['Organizational Cybersecurity Policy ("CSP") is established and communicated ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '12682c34-bb26-4313-a75d-a810a181490e',
            columns: ['Notification to Deal Team of any updates to Cybersecurity Policy (CSP) in the last quarter. Select "N/A" if no updates were made to the CSP. Answer "Not-Compliant" if no CSP is established.', 'Cybersecurity Policy updated', 'CTO/CISO (or person with aligned responsibilities)', 'Deal Team'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '1e9ba44a-3758-4e2d-bf10-7b7f4b0795d4',
            columns: ['Organizational Access Control Policy is established and adhered to ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'ed371cee-af00-4fd4-90f9-18346a938086',
            columns: ['All IT assets are inventoried ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '2886098e-4778-4479-b35c-74f4da2ac428',
            columns: ['Cybersecurity roles and responsibilities are coordinated and aligned with internal roles and external partners ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '179bfd92-80f3-4509-a853-5594efed0a94',
            columns: ['Risk management processes are established, managed, and agreed to by organizational stakeholders ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '166eab4a-84f8-4525-8ed6-e63a079f994a',
            columns: ['Governance and risk management processes address cybersecurity risks ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'a2c1e069-5a16-4c25-9721-b65852edc1c7',
            columns: ['Notification to Deal Team of any updates to risk management processes in the last quarter. Select "N/A" if no updates were made to risk management processes.   ', 'Risk management processes updated', 'CTO/CISO (or person with aligned responsibilities)', 'Deal Team'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '0e3c6663-0b6b-4925-82c7-b648a924f086',
            columns: ['If a 3rd party security risk assessment was completed within the last quarter: Indicate "Compliant" if assessment received passing score, Indicate "Non-Compliant" if assessment received a less than passing score, Select "N/A" if no security assessment completed in last quarter. ', 'Security assessment completed in last quarter', 'CEO, CTO/CISO (or person with aligned responsibilities)', ''],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Copy of Security Risk Assessment Results',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '9810d8cb-19cb-4b5c-bf1d-7464bbebd3a6',
        name: 'Protect',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: '883a745e-153a-4a1c-a9ee-256de5a7d77b',
            columns: ['Multi-factor authentication (MFA) is established for all external/internal systems ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'dbb5321d-505d-459e-8c45-4ae6467c0ff8',
            columns: ['Completed security awareness training for all employees.  ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '8c7d63f6-2309-475d-8a19-e5dff6e48a6e',
            columns: ['Password Policy is in place and all individuals at the organization adhere to password requirements.  ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '18369985-780a-41b5-8afb-d48ba26e8b54',
            columns: ['Access to internal/external systems revoked for all terminated employees ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '95667e7b-2f0e-4263-a1bc-6357d258091d',
            columns: ['The data on all C-level or key management personnel\'s computers is regularly backed up. Answer "Non-Compliant" if computers of key personnel have not been backed up at least in the last quarter. ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '909d9380-6ac4-49b4-b97a-1d0a58123d65',
            columns: ['Wifi networks are password protected ', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '64e3b1d6-8ab2-454e-9d13-63ac6e9ea3e2',
        name: 'Detect',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'f87815f6-d2e1-412e-ab80-b1c3a428d274',
            columns: ['If an annual vulnerability scan of assets was completed within the last quarter: Indicate "Compliant" if scan received passing score, Indicate "Non-Compliant" if vulnerabilities were discovered and describe vulnerabilities exposed, Select "N/A" if no vulnerability scan completed in last quarter', 'Vulnerability Scan exposes significant vulnerability', 'CTO/CISO (or person with aligned responsibilities)', 'Deal Team'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: 'Copy of Vulnerability Scan',
            attestations: [],
          },
          {
            id: '7319eefe-1e1a-4257-a87b-5ee7137be074',
            columns: ['Endpoint antivirus is installed on all servers and work stations', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'c47c8961-402b-41fb-80c2-2774422b4ac0',
            columns: ['The network is monitored to detect potential cybersecurity events', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: 'a5746ec8-d56f-4b3d-9fb1-c5058ebf628b',
            columns: ['Personnel activity is monitored to detect potential cybersecurity events', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '84bb3c41-42ef-44c2-a978-5ed60d02a73b',
            columns: ['Monitoring for unauthorized personnel, connections, devices, and software is performed', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '7aaaa3cf-8482-4b43-8984-cdd7d0924829',
        name: 'Respond',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'd29cd920-a21f-4417-8fc7-7d049c8c4e31',
            columns: ['A BCDR (Business Continuity / Disaster Recovery) Plan is established and documented', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '5f09c137-21b0-44a6-b798-5a1b14d3f8f6',
            columns: ['Notification to Deal Team of any updates to BCDR Plan in the last quarter. Select "N/A" if no updates were made to BCDR Plan.', 'Updates made to BCDR Plan', 'CTO/CISO (or person with aligned responsibilities)', ''],
            allowNA: true,
            allowDocuments: true,
            documentDescription: 'Copy of Vulnerability Scan',
            attestations: [],
          },
          {
            id: '081eb108-0dc4-4411-9c93-718875bd997b',
            columns: ['Cyber incidents are reported consistent with established criteria and mitigated per a Recovery/Response Plan', 'Occurrence of cyber incident in last quarter', 'CEO, CTO/CISO (or person with aligned responsibilities)', 'Deal Team, Firm Senior Management'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '29e31064-ce12-46cd-aa6e-7e850db89420',
            columns: ['Processes are established to receive, analyze and respond to vulnerabilities disclosed to the organization from internal and external sources (e.g. internal testing, security bulletins, or security researchers)', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '59ca88a9-b5c7-4e43-9258-d4ad2c22f310',
            columns: ['Cyber Liability Policy is in force and communicated with C-level executives and/or board members.', '', '', ''],
            allowNA: false,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },

      {
        id: '8a48dae0-5e50-4fae-8f35-9a6f6939e9c3',
        name: 'Recover',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Fund Approval'],
        questions: [
          {
            id: 'f27441f6-60bf-4a1c-9cab-9477c636f536',
            columns: ['Recovery plan is executed during or after a cybersecurity incident. Select "N/A" if no incident occurred in previous quarter.', 'Cybersecurity event occurred in previous quarter', 'CEO, CTO/CISO (or person with aligned responsibilities)', 'Deal Team, Firm Senior Management'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
          {
            id: '1370605a-ccf2-4b1d-8a1c-bb8811dc2889',
            columns: ['Restoration activities are coordinated with internal and external parties (e.g.  coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors). Select "N/A" if no restoration activities needed in previous quarter.', 'Restoration activities needed in previous quarter', 'CEO, CTO/CISO (or person with aligned responsibilities)', 'Deal Team, Firm Senior Management'],
            allowNA: true,
            allowDocuments: false,
            documentDescription: '',
            attestations: [],
          },
        ],
        users: [
          {
            id: '8cfe1891-a8ab-48a3-b33d-10d270c23863',
            name: 'Joe Smith',
            email: 'jsmith@axyqcapital.com',
          },
        ],
        questionStatistics: {
          COMPLIANT: 0,
          NON_COMPLIANT: 0,
          NOT_APPLICABLE: 0,
        },
      },
    ],
    instructions: 'Instructions: This Cybersecurity Checklist provides definitive information on the prescribed measures used to establish and enforce the cybersecurity program at ACME Business Solutions, LLC (ACME).\n\n Protecting ACME data and the systems that collect, process and maintain this data is of critical importance. Therefore, the security of systems must include controls and safeguards to offset possible threats, as well as controls to ensure the confidentiality, availability and integrity of the data. Commensurate with risk, cybersecurity and privacy measures must be implemented to guard against unauthorized access to, alteration, disclosure or destruction of data and systems. This also includes protection against accidental loss or destruction. The security of systems must include controls and safeguards to offset possible threats, as well as controls to ensure confidentiality, integrity, availability and safety.\n\nThe sections of this check list will be detailed as follows:\n\n- Identify: Develop the organizational understanding to manage cybersecurity risk to systems, assets, data, and capabilities.\n- Protect: Develop and implement the appropriate safeguards to ensure delivery of critical infrastructure services. \n- Detect:  Develop and implement the appropriate activities to identify the occurrence of a cybersecurity event. \n- Respond: Develop and implement the appropriate activities to take action regarding a detected cybersecurity event. \n- Recover: Develop and implement the appropriate activities to maintain plans for resilience and to restore any capabilities or services that were impaired due to a cybersecurity event.',
    isInUse: true,
    isLocked: true,
    numberOfReports: 12,
    events: [
      {
        id: 'dc32c5e0-8748-4577-877d-f19fa45c8a94',
        name: 'TemplateCreated',
        timestamp: new Date(2021, 3, 14),
        user: {
          id: '7fd5df07-5266-4ddf-a957-671612eca7e4',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
          picture: '',
        },
      },
    ],
  },
]

export const getMockTemplateDetail = (id?: string) => {
  if (!id) return mockGetTemplate.template
  const selectedTemplate = find(mockTemplates, t => t.id === id)
  if (!selectedTemplate) return mockGetTemplate.template
  const templatesToKeepTheDetailsFor = [
    '41c84097-9aa9-4e16-ae25-28ad266c7c44', // ESG
    '52b91c85-d023-4b55-b8de-7fef47f20053', // Oil & Gas
    '3c262e4e-a716-4845-977d-91498200cd69', // Cybersecurity
  ]
  if (templatesToKeepTheDetailsFor.includes(selectedTemplate.id)) return selectedTemplate
  return {
    ...mockGetTemplate.template,
    id,
    name: selectedTemplate?.name,
  }
}
