import React from 'react'
import { useTranslation } from 'react-i18next'
import { map, isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useAuth0 } from 'utils/auth0'
import { useSelector, useDispatch } from 'react-redux'
import { isMockData } from 'utils/brandConfig'

import { Template } from 'models/template'
import { listTemplates, duplicateTemplate, removeTemplate } from 'actions/templateActions'
import { mockTemplates } from '../../mockData/mockTemplates'

import Modal from 'components/Modal'
import EmptyView from 'components/EmptyView'
import ErrorView from 'components/ErrorView'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Avatar from 'components/Avatar'
import ConfirmDialog from 'components/ConfirmDialog'
import Form from 'components/Form/GoodForm'
import HorizontalRule from 'components/HorizontalRule'

import { ContentBoxTitle, FormWrapper, ModalTitle, ActionBtns } from 'components/styles'
import {
  TemplatesTable,
  TemplateItemIcon,
  TemplateCellName,
  TemplateCellNumber,
  TemplateCellModified,
  TemplateDuplicateBtn,
  TemplateEditBtn,
  CannotRemoveMsg,
} from './styles'

dayjs.extend(relativeTime)

const TemplateMgmt: React.FC = () => {
  const { t } = useTranslation()
  const [templateIdToDuplicate, setTemplateIdToDuplicate] = React.useState<string>()
  const dispatch = useDispatch()

  const auth0Context = useAuth0()
  const { templateList } = useSelector((state: any) => state.templates)
  const [templateToRemove, setTemplateToRemove] = React.useState<Template | undefined>(undefined)

  React.useEffect(() => {
    dispatch(listTemplates(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!templateList.error) setTemplateIdToDuplicate(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDuplicateTemplate = ({ name }: { name: string }): void => {
    console.log('handleDuplicateTemplate', { templateIdToDuplicate, name })
    dispatch(duplicateTemplate(auth0Context, templateIdToDuplicate as string, { name }))
  }

  const handleRemoveTemplate = (template: Template): void => {
    console.log('handleRemoveTemplate', template.id)

    if (!template) return
    if (template.isLocked) {
      return setTemplateToRemove(template)
    }

    dispatch(removeTemplate(auth0Context, template.id))
    setTemplateToRemove(undefined)
  }

  const cannotRemoveContent = (
    <React.Fragment>
      <ModalTitle>{t('cannotRemoveTemplateTitle')}</ModalTitle>
      <CannotRemoveMsg>
        {t('cannotRemoveMsg', {
          name: templateToRemove?.name,
          count: templateToRemove?.numberOfReports,
        })}
        <ActionBtns>
          <Button onClick={() => setTemplateToRemove(undefined)} size="small" color="secondary">
            {t('okayBtn')}
          </Button>
        </ActionBtns>
      </CannotRemoveMsg>
    </React.Fragment>
  )

  const templates = !isMockData ? templateList.items : mockTemplates

  let content
  if (templateList.error && !isMockData) {
    content = <ErrorView errorMsg={t('listTemplatesError')} serverResponse={t(templateList.error)} />
  } else if (templateList.isFetching) {
    return <Spinner />
  } else if (isEmpty(templates)) {
    content = <EmptyView emptyMsg={t('noTemplatesMsg')} />
  } else {
    content = (
      <>
        {templateList?.removeError ? <ErrorView errorMsg={templateList.removeError} /> : null}

        <TemplatesTable>
          {map(templates, templ => {
            const lastEvent = templ.events[templ.events.length - 1]
            const fuzzyTime = dayjs(lastEvent.timestamp).fromNow()
            return (
              <React.Fragment key={`template-${templ.id}`}>
                <TemplateItemIcon>
                  <FontAwesomeIcon icon="file-alt" />
                </TemplateItemIcon>
                <TemplateCellName>{templ.name}</TemplateCellName>
                <TemplateCellNumber>
                  {templ?.numberOfReports > 0
                    ? t('templateUsed', { count: templ?.numberOfReports })
                    : t('templateNotUsed')}
                </TemplateCellNumber>
                <TemplateCellModified>
                  <Avatar user={lastEvent.user} isImageOnly isSmall />
                  {t('templateModified', { fuzzyTime })}
                </TemplateCellModified>
                <TemplateDuplicateBtn>
                  {!templateIdToDuplicate && (
                    <Button onClick={() => setTemplateIdToDuplicate(templ.id)} size="small" color="active">
                      {t('duplicateTemplateBtn')}
                    </Button>
                  )}
                </TemplateDuplicateBtn>
                <TemplateEditBtn>
                  {!templateIdToDuplicate && (
                    <Button to={`/template/${templ.id}`} size="small" color="secondary">
                      {templ?.isLocked > 0 ? t('viewBtn') : t('editBtn')}
                    </Button>
                  )}
                </TemplateEditBtn>

                {!templateIdToDuplicate && (
                  <Button onClick={() => setTemplateToRemove(templ)} color="error" size="small" isSimple>
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                )}
                <HorizontalRule lineStyle="dashed" isHideLast />
              </React.Fragment>
            )
          })}
        </TemplatesTable>
      </>
    )
  }

  return (
    <>
      <ContentBoxTitle>
        {t('templateMgmtTitle')}
        {!templateIdToDuplicate && (
          <Button to={'/template'} size="small" color="success">
            {t('addTemplateBtn')}
          </Button>
        )}
      </ContentBoxTitle>
      <FormWrapper isOpen={!!templateIdToDuplicate}>
        <Form
          onSubmit={handleDuplicateTemplate}
          onCancel={() => setTemplateIdToDuplicate(undefined)}
          fields={[
            {
              type: 'text',
              name: 'name',
              initialValue: '',
              ref: { required: true, minLength: 1 },
              errors: {
                required: t('templateNameRequired'),
                minLength: t('templateNameRequired'),
              },
            },
          ]}
        />
      </FormWrapper>
      {content}

      <Modal
        isOpen={templateToRemove != null && !!templateToRemove.isLocked}
        content={cannotRemoveContent}
        onClose={() => setTemplateToRemove(undefined)}
        width="sm"
      />

      <ConfirmDialog
        isOpen={templateToRemove != null && !templateToRemove.isLocked}
        confirmText={t('confirmRemoveTemplate')}
        onConfirm={() => handleRemoveTemplate(templateToRemove as Template)}
        onCancel={() => setTemplateToRemove(undefined)}
      />
    </>
  )
}

export default TemplateMgmt
