import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text } from 'utils/colors'
import { MiniTitle } from 'components/styles'
import { capitalize } from 'lodash'

interface ErrorViewProps {
  errorMsg?: string
  serverResponse?: string
  hideContactMsg?: boolean
  isSingleLine?: boolean
}

const ErrorViewContainer = styled(MiniTitle)`
  color: ${text.error};
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-content: center;
  text-transform: capitalize;
`
const SingleLineContainer = styled(MiniTitle)`
  color: ${text.error};
  margin: 0 auto;
  text-transform: capitalize;
`

const ErrorView: React.FC<ErrorViewProps> = ({
  errorMsg,
  serverResponse,
  hideContactMsg,
  isSingleLine
}: ErrorViewProps) => {
  const { t } = useTranslation()

  if (isSingleLine) {
    return (
      <SingleLineContainer>
        {serverResponse
          ? `${errorMsg || t('genericErrorMsg')}: ${capitalize(serverResponse)}`
          : errorMsg || t('genericErrorMsg')
        }
      </SingleLineContainer>
    )
  }

  return (
    <ErrorViewContainer>
      <div>
        {errorMsg || t('genericErrorMsg')}
        <br />
        <br />
        {serverResponse ? (
          <span>{serverResponse}</span>
        ) : null}
        <br />
        <br />
        {hideContactMsg ? '' : t('contactSupportMsg')}
      </div>
    </ErrorViewContainer>
  )
}

export default ErrorView
