import styled, { css } from 'styled-components'
import { text, bg, colors } from 'utils/colors'
import { FormWrapper } from 'components/styles'
import { FormContainer } from 'components/Form/GoodForm'

export const SchedulesTable = styled.div`
  display: grid;
  grid-template-columns: 1rem repeat(2, 2fr) repeat(3, 1fr) 1.5rem;
  grid-gap: 0 0.5rem;
  align-items: center;
  padding: 0;
`
const scheduleRow = css`
  grid-column: 1 / -1;
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;
  align-items: center;
  padding: 1rem;
`
export const SchedulesHeader = styled.div`
  ${scheduleRow}
`
export const ScheduleItemRow = styled.div<{ isOpen: boolean; rowIndex: number }>`
  ${scheduleRow}
  cursor: pointer;
  user-select: none;
  background: ${p => (p.isOpen ? bg.active : p.rowIndex % 2 === 0 ? bg.inactive : bg.contentBox)};
`
export const ScheduleToggleIcon = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  user-select: none;
  line-height: 0;
  align-self: center;
  transform: ${p => (p.isOpen ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;
  padding: 0 0.1rem;
  svg {
    font-size: 1.5rem;
  }
`
export const ScheduleName = styled.div`
  font-weight: 600;
`
export const ScheduleTemplate = styled.div`
  font-weight: 200;
  font-size: 0.9rem;
`
export const ScheduleNextReport = styled.div`
  font-weight: 200;
  font-size: 0.9rem;
`
export const ScheduleRecipients = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
`
export const ScheduleCadence = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
`
export const ScheduleRemoveBtn = styled.div`
  font-size: 1.5rem;
`
export const HiddenScheduleDetails = styled.div<{ isOpen: boolean }>`
  grid-column: 1 / -1;
  padding: 0 1em;
  overflow: hidden;
  height: auto;
  max-height: ${p => (p.isOpen ? '30rem' : '0')};
  transition: max-height 0.3s ease-in-out;
  background: ${p => (p.isOpen ? bg.active : 'transparent')};
`
// export const HiddenScheduleTable = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 0.5rem;
// `
export const UpdateScheduleForm = styled.div``
export const DateInputWrapper = styled.div<{ hasError: boolean }>`
  width: 100%;
  & > * {
    width: 100%;
  }
  input {
    display: block;
    font-size: 0.9rem;
    margin-top: 0.1rem;
    padding: 0.5rem 3%;
    width: 94%;
    border-radius: 0.25rem;
    border: 1px solid ${p => (p.hasError ? text.error : colors.border)};
  }
`
export const ScheduleReportFormContainer = styled(FormContainer)`
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 1rem;
  align-items: stretch;
  grid-template-areas:
    'info date recipients'
    'recur recur recipients'
    'btns btns btns';
`
const fieldsWrapperStyles = css`
  & > * {
    margin-bottom: 1rem;
  }
`
const sectionBlockBorder = css`
  border: 3px solid ${bg.secondary};
  border-radius: 0.5rem;
  padding: 1rem 1rem;
`
export const FormInfoWrapper = styled.div`
  ${fieldsWrapperStyles}
  ${sectionBlockBorder}
  grid-area: info;
`
export const FormDateWrapper = styled.div`
  ${fieldsWrapperStyles}
  ${sectionBlockBorder}
  grid-area: date;
`
export const FormRecurrenceWrapper = styled.div`
  ${fieldsWrapperStyles}
  ${sectionBlockBorder}
  grid-area: recur;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  text-align: center;
  h3 {
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-weight: 600;
    font-size: 1rem;
  }
  & > div {
    margin-bottom: 0;
  }
`
export const FormErrorWrapper = styled.div`
  text-align: right;
  width: auto;
  margin: 0;
`
export const FormErrorMsg = styled.div`
  grid-column: 1 / -1;
  color: ${text.error};
  font-weight: 600;
`
const secondaryScheduleReportFormMsgs = css`
  font-size: 0.8rem;
  color: ${colors.mediumGrey};
  line-height: 1.3;
  text-align: center;
`
export const HourOfDayWrapper = styled.div`
  ${secondaryScheduleReportFormMsgs}
  max-width: 12rem;
  min-height: 3rem;
  margin: 2rem auto 0rem;
`
export const FormRecipientsWrapper = styled.div`
  ${fieldsWrapperStyles}
  ${sectionBlockBorder}
  grid-area: recipients;
  h4 {
    ${secondaryScheduleReportFormMsgs}
  }
`
export const FormBtns = styled.div`
  grid-area: btns;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  button {
    width: 4rem;
    margin-left: 1rem;
  }
`
export const CreateScheduleFormWrapper = styled(FormWrapper)<{ isOpen: boolean }>`
  padding: ${p => (p.isOpen ? '1rem 2rem' : '0 2rem')};
  h2 {
    margin: 0 0 1rem;
  }
`
export const RadioLabel = styled.label`
  display: block;
  font-size: 1rem;
  width: 8rem;
  border: 2px solid fuchsia;
  border-radius: 0.5rem;
`
export const AddBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`
const recipientRowStyle = css`
  display: grid;
  grid-template-columns: 1rem repeat(2, 1fr);
  grid-gap: 0.5rem;
`
export const RecipientContactsRowWrapper = styled.div`
  ${recipientRowStyle}
  font-size: 0.8rem;
  background: ${bg.active};
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
`
export const RecipientCompaniesRowWrapper = styled.div`
  ${recipientRowStyle}
  font-size: 0.8rem;
  background: ${bg.active};
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
`
export const RecipientOptionRow = styled.div<{ isSelected: boolean }>`
  ${recipientRowStyle}
  padding: 0.6rem 1rem;
  background: ${p => (p.isSelected ? bg.active : bg.inactive)};
  color: ${p => (p.isSelected ? text.active : text.main)};
  border: 2px solid ${p => (p.isSelected ? text.active : text.inactive)};
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  user-select: none;
`
export const RecipientName = styled.div`
  font-weight: 600;
`
export const RecipientSecondaryDetail = styled.div`
  font-weight: 200;
`
export const RecipientsWrapper = styled.div`
  max-height: 10rem;
  overflow: auto;
`
export const EmptyRecipientsWrapper = styled.div<{ hasError: boolean }>`
  ${secondaryScheduleReportFormMsgs}
  color: ${p => (p.hasError ? text.error : colors.mediumGrey)};
`
export const AddRecipientsModalWrapper = styled.div`
  padding-bottom: 2rem;
`
export const RecipientsModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SelectedCountMsg = styled.div`
  color: ${text.active};
  text-align: right;
  font-size: 0.9rem;
  font-weight: 600;
`
export const RecipientOptionsWrapper = styled.div`
  margin-bottom: 1rem;
  max-height: 60vh;
  overflow-y: auto;
`
