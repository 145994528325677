import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { SubTitle, ActionBtns } from 'components/styles'

interface ConfirmDialogProps {
  isOpen: boolean
  confirmText: string
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDialogContent = styled.div`
  width: 40rem;
  margin: 0 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    margin: 0 0 2rem;
    line-height: 1.5;
  }
`
const ConfirmDialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem;
`

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  confirmText,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) => {
  const { t } = useTranslation()

  const ModalContent = (
    <ConfirmDialogContent>
      <ConfirmDialogTitle>
        <SubTitle>{t('confirmDialogHeader')}</SubTitle>
      </ConfirmDialogTitle>
      <p>{confirmText}</p>
      <ActionBtns>
        <>
          <Button onClick={onConfirm} color="error" size="small">
            {t('confirmBtn')}
          </Button>

          <Button onClick={onCancel} color="secondary" size="small">
            {t('cancelBtn')}
          </Button>
        </>
      </ActionBtns>
    </ConfirmDialogContent>
  )

  return <Modal isOpen={isOpen} content={ModalContent} onClose={onCancel} width="lg" />
}

export default ConfirmDialog
