import { Company } from 'models/company'

export const mockCompanies: Company[] = [
  {
    id: '63d499f6-506c-4b8d-b347-bc0ed80a4b3e',
    name: 'Coinhood',
    status: 'ENABLED',
    client: {
      id: 'c83a2635-869a-4fec-a21b-1cd4b42c2df4',
      name: 'Client Name Here',
    },
    template: {
      id: '1efa73ad-2c3a-4cc6-acec-288adb5a5f5c',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'PENDING',
    users: [
      {
        id: 'b7de63df-87d5-4b32-9a46-40fdc94a641b',
        name: 'Ella Neal',
        email: 'eneal@coinhood.com',
        status: 'ACTIVE',
        reportStatus: 'PENDING',
        events: [
          {
            id: '3b16c41e-e37c-4b04-abb9-072095187e4a',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '95eda16a-f57b-4f4d-8362-c2a318e053ea',
              name: 'Ella Neal',
              email: 'eneal@coinhood.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: 'dda66f05-5b4e-420a-88bc-4449b9071bba',
        name: 'Sarah Garza',
        email: 'sgarza@coinhood.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: 'aa618f6c-40a4-4429-9b7a-5a2eec218a44',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'b28a4b01-93df-4ccd-b2a1-f05f9aa7bf63',
              name: 'Sarah Garza',
              email: 'sgarza@coinhood.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '3c6fcdcb-24f2-4580-9b2d-04bd041dacf8',
        name: 'CompanyCreated',
        timestamp: new Date(2021, 1, 23),
        user: {
          id: '6e6c3423-b3ed-4617-8ee6-a46a4b391e64',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: 'c4675909-9345-4c14-9c71-554cda9e63ea',
    name: 'Betterplace',
    status: 'ENABLED',
    client: {
      id: 'e61b9cbd-7dd9-4dc2-ac89-334ead8ec26e',
      name: 'Client Name Here',
    },
    template: {
      id: '6ee15fec-606b-40ac-b6b6-44610c957004',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'NON_COMPLIANT',
    users: [
      {
        id: '4a34d154-5005-4b49-824d-1e4f38dbba38',
        name: 'Ernest Cortez',
        email: 'ecortez@betterplace.com',
        status: 'ACTIVE',
        reportStatus: 'NON_COMPLIANT',
        events: [
          {
            id: '5fdec6da-3db2-4d74-82d4-1b66808b6084',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'e76be348-8854-4d1c-96a5-d7e07f6964b7',
              name: 'Ernest Cortez',
              email: 'ecortez@betterplace.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: 'd24bdf6e-3f37-4fa2-8c3d-87690fc0668f',
        name: 'Charlotte Perez',
        email: 'cperez@betterplace.com',
        status: 'ACTIVE',
        reportStatus: 'NON_COMPLIANT',
        events: [
          {
            id: '4d991b4f-1cb4-4821-aef7-a8cc23dfc64c',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'fadb4694-d132-44ea-9f8f-84779439aa36',
              name: 'Charlotte Perez',
              email: 'cperez@betterplace.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: '18307f70-ef78-46cb-b342-f9508aed6e16',
        name: 'James Higgins',
        email: 'jhiggins@betterplace.com',
        status: 'ACTIVE',
        reportStatus: 'NON_COMPLIANT',
        events: [
          {
            id: 'ca981b63-9b19-4792-8f8b-59295c3cd1e1',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '68deceb3-ca8f-4a3d-b128-5194acc819a5',
              name: 'James Higgins',
              email: 'jhiggins@betterplace.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '9fb0e53c-5ba8-4779-8251-5fe0bbd36e58',
        name: 'CompanyUpdated',
        timestamp: new Date(2021, 0, 25),
        user: {
          id: '047c468a-e463-440e-abbe-ea6c9c34a90e',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: 'ea06947e-6260-4e12-80cc-c9b0b52f9189',
    name: 'Zipnow',
    status: 'ENABLED',
    client: {
      id: 'd1ee37a1-3f25-4aa5-85ad-e6c37b269cf9',
      name: 'Client Name Here',
    },
    template: {
      id: '75f4f875-9d66-444b-8f68-ad6dc2fa3e85',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'OVERDUE',
    users: [
      {
        id: '354db9fb-49b3-452b-9102-ecac4938fc1e',
        name: 'Cedric Knight',
        email: 'cknight@zipnow.com',
        status: 'ACTIVE',
        reportStatus: 'OVERDUE',
        events: [
          {
            id: 'ef0ef9f9-9fc1-4208-a13f-f47a5c2a15df',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '1db670fa-f437-4eca-b833-34ceaeabe183',
              name: 'Cedric Knight',
              email: 'cknight@zipnow.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '41ef83c4-22ff-483f-abaf-6b4354c1ca4e',
        name: 'CompanyUpdated',
        timestamp: new Date(2021, 1, 21),
        user: {
          id: '23fed5d4-d9dd-4c30-a1ff-9c9b40609b0b',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: 'a4190b8f-08f9-4e58-b94a-fe8ba93b02b2',
    name: 'Robininvest',
    status: 'ENABLED',
    client: {
      id: '0fe01949-0493-4376-b1aa-1b64e8af8cad',
      name: 'Client Name Here',
    },
    template: {
      id: 'a54d2f1d-2705-4a66-8d5a-db9c2f0da3cb',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'PENDING',
    users: [
      {
        id: '0bba1561-7e3e-44cb-8af4-e2a0265b7314',
        name: 'Antonio Lucas',
        email: 'alucas@robininvest.com',
        status: 'ACTIVE',
        reportStatus: 'PENDING',
        events: [
          {
            id: 'a3ff5231-9114-4b7a-9cfa-355d2e2d591a',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'bc3d796b-f208-4d55-8f86-1e09b880f92e',
              name: 'Antonio Lucas',
              email: 'alucas@robininvest.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: 'df6a63e8-68f0-4251-bd43-c9e19f1b8892',
        name: 'Ross Richards',
        email: 'rrichards@robininvest.com',
        status: 'ACTIVE',
        reportStatus: 'PENDING',
        events: [
          {
            id: '17218e08-f3d4-4d94-b1ba-091ec881368f',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '8dd11a49-d6bf-4bd8-ac6d-d9c314a35d23',
              name: 'Ross Richards',
              email: 'rrichards@robininvest.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '98b01f47-bd85-47db-9043-645da8dfa6e4',
        name: 'CompanyRemoved',
        timestamp: new Date(2021, 1, 19),
        user: {
          id: 'bc9d338c-29a9-4606-9b4b-602d332b95d4',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: '05629366-54a2-4370-b4e3-25d6d72a5d62',
    name: 'Strope',
    status: 'ENABLED',
    client: {
      id: 'bd1fc1be-78a9-4cae-9739-1f5ff6298163',
      name: 'Client Name Here',
    },
    template: {
      id: '74117ba6-a9e7-4863-b9e2-ef2e25f3a690',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'COMPLIANT',
    users: [
      {
        id: 'c6572fde-50a8-4fa9-8ef0-27b57d55b823',
        name: 'Steven Andrews',
        email: 'sandrews@strope.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: 'b03a8841-1e46-4522-b062-94d7097038ab',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '1482a8cf-256d-49e8-b6f4-431993792c03',
              name: 'Steven Andrews',
              email: 'sandrews@strope.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '17b46ce9-1b1a-4660-8447-a2b47f1c32c2',
        name: 'CompanyUpdated',
        timestamp: new Date(2021, 1, 10),
        user: {
          id: 'd1cc42b1-49b9-4247-949f-c5bba998fe1c',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: '2c6a4573-0889-4915-918c-0517c9a4458e',
    name: 'Zocdig',
    status: 'ENABLED',
    client: {
      id: 'eb912f13-378f-4b04-985a-21f963c677d3',
      name: 'Client Name Here',
    },
    template: {
      id: 'b21b3f0a-3cfe-4cf7-be5c-f04122a7f6df',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'COMPLIANT',
    users: [
      {
        id: '9046261b-51b2-480c-be58-4622f32b4c73',
        name: 'Harold Ball',
        email: 'hball@zocdig.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: 'b72da716-3666-449d-95fc-f59a433502ff',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '62b5035c-a391-4249-8624-afb3d5c55c8c',
              name: 'Harold Ball',
              email: 'hball@zocdig.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: '254e166f-b956-4429-80a7-49dd904fc021',
        name: 'Sheila Guerrero',
        email: 'sguerrero@zocdig.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: 'c01b528d-aaf9-46bd-bb79-43f9c5652c39',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'c3bcc2fe-e253-478b-a469-fa9eb1c12239',
              name: 'Sheila Guerrero',
              email: 'sguerrero@zocdig.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: 'e1c78bf0-a14f-4cfa-9f2a-b883f5d89e00',
        name: 'CompanyUserAdded',
        timestamp: new Date(2021, 1, 15),
        user: {
          id: '109cce96-cac2-4be2-aa4d-b0d03e29e234',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: '7f8ded71-251d-47d1-9c5c-a25d85c46466',
    name: 'Divide',
    status: 'ENABLED',
    client: {
      id: '5ae07a3a-040d-4cc8-9cce-6cceaced8c17',
      name: 'Client Name Here',
    },
    template: {
      id: '4354ad9b-c3b6-42f1-930c-bf4ff34dee92',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'NON_COMPLIANT',
    users: [
      {
        id: '4e8eee76-48f8-40b1-b6fa-c63121ed64f5',
        name: 'Frances Garrett',
        email: 'fgarrett@divide.com',
        status: 'ACTIVE',
        reportStatus: 'NON_COMPLIANT',
        events: [
          {
            id: '25531ec3-40f1-4370-b3b9-b8c8379d53ad',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'bef9e72e-24a0-4f6e-b31c-19c6dcb910d4',
              name: 'Frances Garrett',
              email: 'fgarrett@divide.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: 'b3f370e1-a07e-4cb3-b804-5ad7d038b70f',
        name: 'Eileen McBride',
        email: 'emcbride@divide.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: 'cf797fea-0551-41e8-926e-73c493e3e58e',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '7440f7c2-a49a-4c5f-9dff-35f3fce06d9c',
              name: 'Eileen McBride',
              email: 'emcbride@divide.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: '04bb979d-19b7-4006-9c15-5dc4e2f098f1',
        name: 'Elias Hogan',
        email: 'ehogan@divide.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: '2dc39e06-1062-48b9-96ed-99991df361da',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '357f5a4d-c90a-4581-9e28-8aceb3aaeb34',
              name: 'Elias Hogan',
              email: 'ehogan@divide.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '29289576-d0fc-4c20-bcc7-f323717f497d',
        name: 'CompanyCreated',
        timestamp: new Date(2021, 1, 25),
        user: {
          id: '6ba96244-787b-4b7d-b132-6548c8edc9ad',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: 'c2fa83ec-6eb0-4390-af7f-19b03f638118',
    name: 'Fusion',
    status: 'ENABLED',
    client: {
      id: '66f7ca9c-37f2-4316-9b40-9ab0d25c10d5',
      name: 'Client Name Here',
    },
    template: {
      id: 'b582c63a-9d6b-4135-aec3-fc4f7cc2faa6',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'COMPLIANT',
    users: [
      {
        id: '53d9a991-9331-49c6-8546-c7cde7f6cf46',
        name: 'Judy Olsen',
        email: 'jolsen@fusion.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: '7ac5995a-fc0f-42bf-a04d-1ba535f154d1',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: 'd27eed44-1c4b-40fe-81e9-bc4db787fce6',
              name: 'Judy Olsen',
              email: 'jolsen@fusion.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '8e430565-4a84-4f8b-bb87-e997a2bf874e',
        name: 'CompanyUserRemoved',
        timestamp: new Date(2021, 1, 21),
        user: {
          id: 'fdbbec7e-c205-4fde-88a0-7c1437baeddd',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: '3a3ab6f6-a62a-4aee-8b02-061c5856ea2a',
    name: 'Complyify',
    status: 'ENABLED',
    client: {
      id: '1f6e84fc-ab65-4cc1-858c-678356d39629',
      name: 'Client Name Here',
    },
    template: {
      id: 'a89ecfac-ece5-40de-9b9e-485546bc744b',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'COMPLIANT',
    users: [
      {
        id: 'ec449088-0bc8-4313-b2b9-ea14302e4e7e',
        name: 'Jacob Allen',
        email: 'jallen@complyify.com',
        status: 'ACTIVE',
        reportStatus: 'COMPLIANT',
        events: [
          {
            id: '32ffe399-1a0d-426a-ac3d-bcc76b1c7951',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '1880e5af-887c-4cea-a0ed-05d6c72efea2',
              name: 'Jacob Allen',
              email: 'jallen@complyify.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '9ce385f2-b10b-4ca2-9d15-ae3370404247',
        name: 'CompanyUserRegistered',
        timestamp: new Date(2021, 0, 3),
        user: {
          id: '1b49c6dc-c041-4054-83a2-6b98ae563163',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
  {
    id: 'c36cd00d-349f-42d2-b4a9-6eefb34cc5b1',
    name: 'Flyant',
    status: 'ENABLED',
    client: {
      id: '7e8690ef-1d6a-4b66-aa28-0dd82953ffc4',
      name: 'Client Name Here',
    },
    template: {
      id: 'be2ecfda-4d3b-4dda-8600-9455ec81d2ab',
      name: '',
    },
    daysToComplete: 42,
    reportStatus: 'NON_COMPLIANT',
    users: [
      {
        id: '51bee15e-565e-4ad7-86d6-928afd530992',
        name: 'Markl Daniel',
        email: 'mdaniel@flyant.com',
        status: 'ACTIVE',
        reportStatus: 'NON_COMPLIANT',
        events: [
          {
            id: '789a4119-4df3-4768-98c6-095033e51538',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '94c6a4d9-2834-4482-b762-01833730d29f',
              name: 'Markl Daniel',
              email: 'mdaniel@flyant.com',
              picture: '',
            },
          },
        ],
      },
      {
        id: 'bfc51b92-0988-4122-96ee-6cff794f80ed',
        name: 'Wayne McKinney',
        email: 'wmckinney@flyant.com',
        status: 'ACTIVE',
        reportStatus: 'PENDING',
        events: [
          {
            id: '9780948a-50ac-4166-99bd-66f88814fed9',
            name: 'CompanyUserAdded',
            timestamp: new Date(2021, 1, 3),
            user: {
              id: '7bcc6079-3b84-4210-b20c-d15c223234e7',
              name: 'Wayne McKinney',
              email: 'wmckinney@flyant.com',
              picture: '',
            },
          },
        ],
      },
    ],
    events: [
      {
        id: '2d64b84e-d861-4016-aa58-c4492cad86c1',
        name: 'CompanyUpdated',
        timestamp: new Date(2021, 1, 1),
        user: {
          id: '61b74fe2-69c5-41e2-8a18-cc7779a30bd1',
          name: 'Joe Smith',
          email: 'jsmith@axyqcapital.com',
        },
      },
    ],
  },
]
