import React from 'react'
import styled, { css } from 'styled-components'
import { random } from 'utils/colors'
import { nth, size, subtract, isNil, round } from 'lodash'

interface BoxWhiskerGraphProps {
  id: string
  totals: Record<string, number>
  randomOffset: number
  highestYAxisValue: number
  isPercentage?: boolean
}

const BoxWhiskerWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3rem 1fr;
  grid-template-areas: 'blank graph label';
`
const BoxWhiskerLabel = styled.div<{ colorIndex: number }>`
  grid-area: label;
  font-size: 0.8rem;
  font-weight: 600;
  color: ${p => nth(random, p.colorIndex)};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`
const LabelSpacer = styled.div<{ size: number }>`
  flex-grow: ${p => p.size};
`
const BoxWhiskerGraphContainer = styled.div`
  grid-area: graph;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const segmentStyle = css<{ size: number; colorIndex: number }>`
  flex-grow: ${p => p.size};
`
const TopSpace = styled.div`
  ${segmentStyle}
  width: 100%;
  background: transparent;
  border-bottom: 2px solid ${p => nth(random, p.colorIndex)};
`
const FirstQuad = styled.div`
  ${segmentStyle}
  width: 2px;
  background: ${p => nth(random, p.colorIndex)};
`
const SecondQuad = styled.div`
  ${segmentStyle}
  width: 100%;
  background: ${p => nth(random, p.colorIndex)};
  border-bottom: 1px solid #555;
`
const ThirdQuad = styled.div`
  ${segmentStyle}
  width: 100%;
  background: ${p => nth(random, p.colorIndex)};
  border-top: 1px solid #555;
`
const FourthQuad = styled.div`
  ${segmentStyle}
  width: 2px;
  background: ${p => nth(random, p.colorIndex)};
`
const EndSpace = styled.div`
  ${segmentStyle}
  width: 100%;
  background: transparent;
  border-top: 2px solid ${p => nth(random, p.colorIndex)};
`

const BoxWhiskerGraph = ({ id, totals, randomOffset, highestYAxisValue, isPercentage }: BoxWhiskerGraphProps) => {
  const randomColorsLen = size(random)
  const colorIndex = randomOffset % randomColorsLen

  // console.log('BOX & WHISKER', {
  //   // totalRange,
  //   totals,
  //   highestYAxisValue,
  //   topMargin: subtract(highestYAxisValue, totals.maxValue),
  // })

  const medianValue = isNil(totals.median) ? totals.average : totals.median

  return (
    <BoxWhiskerWrapper>
      <BoxWhiskerGraphContainer>
        <TopSpace colorIndex={colorIndex} size={subtract(highestYAxisValue, totals.maxValue)} />
        <FirstQuad colorIndex={colorIndex} size={subtract(totals.maxValue, totals.q3)} />
        <SecondQuad colorIndex={colorIndex} size={subtract(totals.q3, medianValue)} />
        <ThirdQuad colorIndex={colorIndex} size={subtract(medianValue, totals.q1)} />
        <FourthQuad colorIndex={colorIndex} size={subtract(totals.q1, totals.minValue)} />
        <EndSpace colorIndex={colorIndex} size={subtract(totals.minValue, 0)} />
      </BoxWhiskerGraphContainer>
      <BoxWhiskerLabel colorIndex={colorIndex}>
        <LabelSpacer size={subtract(highestYAxisValue, medianValue)} />
        {`${round(medianValue).toLocaleString()}${isPercentage ? '%' : ''}`}
        <LabelSpacer size={medianValue} />
      </BoxWhiskerLabel>
    </BoxWhiskerWrapper>
  )
}

export default BoxWhiskerGraph
