import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import TailwaterLogoImg from './tailwater.png'

interface TailwaterLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: brightness(0) invert(1);
`

const TailwaterLogo: React.FC<TailwaterLogoProps> = ({ brandName }: TailwaterLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={TailwaterLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default TailwaterLogo
