import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  map,
  nth,
  round,
  size,
  divide,
  sortBy,
  last,
  flatMap,
  isNumber,
  isString,
  toNumber,
  isNil,
  isNaN,
  filter,
} from 'lodash'
import { text, bg, random } from 'utils/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CompanyMetric } from 'models/metrics'

import { ToggleIcon } from './styles'

interface NumbersBarGraphProps {
  companyMetrics: CompanyMetric[]
  randomOffset: number
  isPercent?: boolean
  isReadOnly?: boolean
}

const NumbersBarGraphWrapper = styled.div`
  width: 100%;
  font-size: 1em;
  padding: 2em 0 1em;
`
const CompanyBarWrapper = styled.div<{ colorIndex: number; isExpanded?: boolean }>`
  display: grid;
  grid-template-columns: 1em 8em auto;
  grid-gap: ${p => p.isExpanded ? '0.5em 1em' : '0 1em'};
  align-items: center;
  color: ${p => nth(random, p.colorIndex)};
  margin-bottom: 0.5em;
  cursor: pointer;
  user-select: none;
  padding: 0 1em;
  padding: ${p => p.isExpanded ? '0.5em 1em' : '0 1em'};
  margin-bottom: 1em;
  background: ${p => p.isExpanded ? 'rgba(200, 200, 200, 0.2)' : 'transparent'};
  &:hover {
    background: ${bg.active};
  }
`
const UsersWrapper = styled.div<{ isExpanded: boolean }>`
  overflow: hidden;
  height: auto;
  transition: all 0.3s ease-in-out;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
  grid-gap: inherit;
`
const UserBarWrapper = styled.div<{ colorIndex: number; isExpanded: boolean }>`
  font-size: 0.9em;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: inherit;
  grid-gap: inherit;
  align-items: center;
  color: ${p => nth(random, p.colorIndex)};
  overflow: hidden;
  height: auto;
  max-height: ${(p) => p.isExpanded ? '2em' : '0'};
  transition: all 0.3s ease-in-out;
`
const ValueLabel = styled.div<{ isUser?: boolean }>`
  text-align: right;
  font-size: 1.2em;
  font-weight: 600;
  grid-column-end: ${p => p.isUser ? 'span 2' : 'span 1'};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    color: ${text.inactive};
    font-size: 0.6em;
    margin-left: 0.5em;
  }
`
const BarWrapper = styled.div<{ colorIndex: number }>`
  color: ${p => nth(random, p.colorIndex)};
  height: 2em;
  position: relative;
  p {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1;
    padding: 0.3em;
    position: absolute;
    left: 0;
    top: 0;
  }
`
const ConflictingBadge = styled.div`
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1;
  padding: 0.6em;
  position: absolute;
  right: 0;
  top: 0;
  color: ${text.error};
`
const BarElement = styled.div<{ colorIndex: number; relativeWidth: number; isUser?: boolean }>`
  width: ${p => p.relativeWidth}%;
  height: 2em;
  background: ${p => nth(random, p.colorIndex)};
  opacity: ${p => p.isUser ? '0.2' : '0.2'};
  position: absolute;
  left: 0;
  top: 0;
`
export const NotApplicableAnswer = styled.div`
  position: absolute;
  right: 0.6em;
  top: 0.3em;
  color: #999;
  font-weight: 900;
`

const NumbersBarGraph: React.FC<NumbersBarGraphProps> = ({
  companyMetrics,
  randomOffset,
  isPercent,
  isReadOnly,
}: NumbersBarGraphProps) => {
  const { t } = useTranslation()
  const [expandedId, setExpandedId] = useState<string | undefined>()

  const randomColorsLen = size(random)
  const colorIndex = randomOffset % randomColorsLen

  const allAnswers = filter(flatMap(companyMetrics, (coMetric) => {
    const userMetricsList = coMetric.userMetrics || coMetric.users
    return map(userMetricsList, (uMetric) => {
      const parsedValue = isString(uMetric.attestation) ? parseInt(uMetric.attestation) : uMetric.attestation
      return uMetric.attestation === 'NOT_APPLICABLE' || isNaN(parsedValue) ? false : parsedValue
    })
  }))

  const largestAnswer = last(sortBy(allAnswers))

  if (isNil(largestAnswer)) return null

  const toggleExpanded = (id: string) => {
    if (expandedId === id) {
      setExpandedId(undefined)
    } else {
      setExpandedId(id)
    }
  }

  const renderBar = ({ id, name, attestation, userMetrics, users, isConflicting }: CompanyMetric) => {
    let val = attestation
    const isCompanyNA = attestation === 'NOT_APPLICABLE'

    if (isString(attestation)) {
      val = toNumber(attestation)
    }

    if (isCompanyNA) {
      val = 0
    }

    if (!isNumber(val) || !isNumber(largestAnswer)) {
      return null
    }

    const companyRelativeWidth = round(divide(val, largestAnswer) * 100)
    const isExpanded = expandedId === id && !isReadOnly
    const userMetricsList = userMetrics || users

    return (
      <React.Fragment key={`bar-${id}`}>
        <CompanyBarWrapper
          onClick={() => toggleExpanded(id)}
          colorIndex={colorIndex}
          isExpanded={isExpanded}
        >
          {isReadOnly ? <div /> : (
            <ToggleIcon isExpanded={isExpanded}>
              <FontAwesomeIcon icon='angle-right' />
            </ToggleIcon>
          )}
          <ValueLabel>
            {isCompanyNA ? t('notApplicableShortLabel') : val.toLocaleString()}
            {isPercent && !isCompanyNA && <FontAwesomeIcon icon='percent' />}
          </ValueLabel>
          <BarWrapper colorIndex={colorIndex}>
            <p>{name}</p>
            {isConflicting && (
              <ConflictingBadge>{t('numberConflictingMsg')}</ConflictingBadge>
            )}
            <BarElement relativeWidth={companyRelativeWidth} colorIndex={colorIndex} />
          </BarWrapper>
          <UsersWrapper isExpanded={isExpanded}>
            {map(userMetricsList, (uMetric) => {
              const isUserNA = uMetric.attestation === 'NOT_APPLICABLE'
              const userVal = isUserNA ? 0 : toNumber(uMetric.attestation)

              if (!isNumber(userVal) || !isNumber(largestAnswer)) {
                return <div key={`bar-${uMetric}-${id}-${uMetric.id}`} />
              }
              const userRelativeWidth = round(divide(userVal, largestAnswer) * 100)

              return (
                <UserBarWrapper
                  key={`bar-${uMetric}-${id}-${uMetric.id}`}
                  colorIndex={colorIndex}
                  isExpanded={isExpanded}
                >
                  <ValueLabel isUser>
                    {isUserNA ? t('notApplicableShortLabel') : userVal.toLocaleString()}
                    {isPercent && !isUserNA && <FontAwesomeIcon icon='percent' />}
                  </ValueLabel>
                  <BarWrapper colorIndex={colorIndex}>
                    <p>{uMetric.name}</p>
                    {isUserNA
                      ? <NotApplicableAnswer>{t('notApplicableLabel')}</NotApplicableAnswer>
                      : <BarElement relativeWidth={userRelativeWidth} colorIndex={colorIndex} isUser />
                    }
                  </BarWrapper>
                </UserBarWrapper>
              )
            })}
          </UsersWrapper>
        </CompanyBarWrapper>


      </React.Fragment>
    )
  }

  return (
    <NumbersBarGraphWrapper>
      {map(companyMetrics, renderBar)}
    </NumbersBarGraphWrapper>
  )
}

export default NumbersBarGraph
