import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAlignLeft,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faAsterisk,
  faBan,
  faBook,
  faBuilding,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faChartArea,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faCircle,
  faCopy,
  faDotCircle,
  faCog,
  faEye,
  faFileAlt,
  faFire,
  faGhost,
  faHashtag,
  faListOl,
  faListUl,
  faLock,
  faMinusSquare,
  faPaperPlane,
  faPencilAlt,
  faPercent,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPollH,
  faQuestionCircle,
  faSave,
  faSquare,
  faTasks,
  faThList,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'

const initIcons = () => {
  library.add(
    faAlignLeft,
    faAngleDoubleDown,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleRight,
    faAsterisk,
    faBan,
    faBook,
    faBuilding,
    faCalendarAlt,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faChartArea,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faCopy,
    faDotCircle,
    faCog,
    faEye,
    faFileAlt,
    faFire,
    faGhost,
    faHashtag,
    faListOl,
    faListUl,
    faLock,
    faMinusSquare,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPollH,
    faQuestionCircle,
    faSave,
    faSquare,
    faTasks,
    faThList,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faUpload,
  )
}

export default initIcons
