import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'
import { keyBy } from 'lodash'
import { ReportScheduleRequest } from 'models/schedule'

const ctx = 'api/schedule'
const localhostClientId = '3f5f12f9-3acf-4d54-8ad9-b98353588d2a'
const localhostUrl = 'http://localhost:8080'

export const LIST_SCHEDULES_LOADING = `${ctx}/LIST_SCHEDULES_LOADING`
export const LIST_SCHEDULES_SUCCESS = `${ctx}/LIST_SCHEDULES_SUCCESS`
export const LIST_SCHEDULES_FAILURE = `${ctx}/LIST_SCHEDULES_FAILURE`

export const CREATE_SCHEDULE_LOADING = `${ctx}/CREATE_SCHEDULE_LOADING`
export const CREATE_SCHEDULE_SUCCESS = `${ctx}/CREATE_SCHEDULE_SUCCESS`
export const CREATE_SCHEDULE_FAILURE = `${ctx}/CREATE_SCHEDULE_FAILURE`

export const UPDATE_SCHEDULE_LOADING = `${ctx}/UPDATE_SCHEDULE_LOADING`
export const UPDATE_SCHEDULE_SUCCESS = `${ctx}/UPDATE_SCHEDULE_SUCCESS`
export const UPDATE_SCHEDULE_FAILURE = `${ctx}/UPDATE_SCHEDULE_FAILURE`

export const REMOVE_SCHEDULE_LOADING = `${ctx}/REMOVE_SCHEDULE_LOADING`
export const REMOVE_SCHEDULE_SUCCESS = `${ctx}/REMOVE_SCHEDULE_SUCCESS`
export const REMOVE_SCHEDULE_FAILURE = `${ctx}/REMOVE_SCHEDULE_FAILURE`

export const LIST_TEMPLATES_LOADING = `${ctx}/LIST_TEMPLATES_LOADING`
export const LIST_TEMPLATES_SUCCESS = `${ctx}/LIST_TEMPLATES_SUCCESS`
export const LIST_TEMPLATES_FAILURE = `${ctx}/LIST_TEMPLATES_FAILURE`

export const CLEAR_ERRORS = `${ctx}/CLEAR_ERRORS`

export const listSchedules = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: LIST_SCHEDULES_LOADING })
  const scheduleHost = window.location.host.startsWith('localhost') ? localhostUrl : host
  const clientId = auth0Context.getClientId()
  const scheduleClient = window.location.host.startsWith('localhost') ? localhostClientId : clientId

  let url = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/report_schedules`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_SCHEDULES_SUCCESS,
        payload: {
          schedulesMap: keyBy(data, 'id'),
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_SCHEDULES_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const createSchedule = (auth0Context: Auth0Context, payload: ReportScheduleRequest) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: CREATE_SCHEDULE_LOADING })
  const scheduleHost = window.location.host.startsWith('localhost') ? localhostUrl : host
  const clientId = auth0Context.getClientId()
  const scheduleClient = window.location.host.startsWith('localhost') ? localhostClientId : clientId

  let url = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/report_schedules`

  await auth0Context
    .makeRequest(url, 'POST', payload)
    .then(() =>
      dispatch({
        type: CREATE_SCHEDULE_SUCCESS,
      }),
    )
    .catch(err =>
      dispatch({
        type: CREATE_SCHEDULE_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}

export const updateSchedule = (
  auth0Context: Auth0Context,
  scheduleId: string,
  payload: ReportScheduleRequest,
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({
    type: UPDATE_SCHEDULE_LOADING,
    payload: { id: scheduleId },
  })

  const scheduleHost = window.location.host.startsWith('localhost') ? localhostUrl : host
  const clientId = auth0Context.getClientId()
  const scheduleClient = window.location.host.startsWith('localhost') ? localhostClientId : clientId

  let url = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/report_schedules/${scheduleId}`

  await auth0Context
    .makeRequest(url, 'PATCH', payload)
    .then(res => {
      console.log('update successful', res, res.json())
      return dispatch({
        type: UPDATE_SCHEDULE_SUCCESS,
      })
    })
    .catch(err =>
      dispatch({
        type: UPDATE_SCHEDULE_FAILURE,
        payload: {
          errorMsg: err.message,
          id: scheduleId,
        },
      }),
    )
}

export const removeSchedule = (auth0Context: Auth0Context, id: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({
    type: REMOVE_SCHEDULE_LOADING,
    payload: { id },
  })
  const scheduleHost = window.location.host.startsWith('localhost') ? localhostUrl : host
  const clientId = auth0Context.getClientId()
  const scheduleClient = window.location.host.startsWith('localhost') ? localhostClientId : clientId

  let url = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/report_schedules/${id}`

  await auth0Context
    .makeRequest(url, 'DELETE')
    .then(res => {
      console.log('remove successful', res)
      return dispatch({
        type: REMOVE_SCHEDULE_SUCCESS,
        payload: { id },
      })
    })
    .catch(err =>
      dispatch({
        type: REMOVE_SCHEDULE_FAILURE,
        payload: {
          errorMsg: err.message,
          id,
        },
      }),
    )
}

export const listTemplates = (auth0Context: Auth0Context) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: LIST_TEMPLATES_LOADING })
  const scheduleHost = window.location.host.startsWith('localhost') ? localhostUrl : host
  const clientId = auth0Context.getClientId()
  const scheduleClient = window.location.host.startsWith('localhost') ? localhostClientId : clientId

  let url = `${host || scheduleHost}/accounts/${clientId || scheduleClient}/report_templates`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_TEMPLATES_SUCCESS,
        payload: { templatesList: data },
      }),
    )
    .catch(err =>
      dispatch({
        type: LIST_TEMPLATES_FAILURE,
        payload: { errorMsg: err.message },
      }),
    )
}
