import React, { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from 'utils/auth0'
import { map, isEmpty, orderBy, isNil } from 'lodash'
import { isMockData } from 'utils/brandConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { listCompanies, addCompany, removeCompany } from 'actions/companyActions'
import { findClient } from 'actions/clientActions'

import { Company } from 'models/company'
import { mockCompanies } from 'mockData/mockCompanies'

import EmptyView from 'components/EmptyView'
import ErrorView from 'components/ErrorView'
import Button from 'components/Button'
import HorizontalRule from 'components/HorizontalRule'
import Avatar from 'components/Avatar'
import Spinner from 'components/Spinner'
import LatestEvent from 'components/LatestEvent'
import StatusBadge from 'components/StatusBadge'
import Form from 'components/Form/GoodForm'
import ConfirmDialog from 'components/ConfirmDialog'

import { PageContent, PageTitleWithAction, ContentBoxLists, FormWrapper, ColumnHeading } from 'components/styles'
import {
  CompanyTable,
  CompanyIcon,
  CompanyName,
  CompanyStatus,
  UsersWrapper,
  CompanyLatest,
  CompanyHeader,
  CompanyItem,
} from './styles'

const PortfolioPage: React.FC<{}> = () => {
  const { t } = useTranslation()
  const [isNewFormShown, setIsNewFormShown] = useState<boolean>(false)
  const [confirmRemoveCompanyId, setConfirmRemoveCompanyId] = useState<string | undefined>()

  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const history = useHistory()

  const { companyList } = useSelector((state: any) => state.companies)

  useEffect(() => {
    if (!companyList.lastUpdated) dispatch(listCompanies(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsNewFormShown(!!companyList.error && !isMockData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(findClient(auth0Context))
  }, [dispatch, auth0Context])

  const handleAddCompany = ({ name }: { name: string }): void => {
    console.log('handleAddCompany', { name })
    dispatch(
      addCompany(auth0Context, {
        name,
        users: [],
      }),
    )
  }

  const handleConfirmRemoveCompany = (event: MouseEvent<HTMLButtonElement>, companyId: string): void => {
    event.stopPropagation()
    setConfirmRemoveCompanyId(companyId)
  }

  const handleRemoveCompany = (companyId: string | undefined): void => {
    if (companyId) {
      dispatch(removeCompany(auth0Context, companyId))
    }
    setConfirmRemoveCompanyId(undefined)
  }

  const handleGoToDetail = (companyId: string) => {
    history.push(`/company/${companyId}`)
  }

  const companies = !isMockData ? companyList.items : mockCompanies

  const sortedCompanies = orderBy(companies, 'status', 'desc')
  let content
  if (companyList.error && !isMockData) {
    content = <ErrorView errorMsg={t('listCompaniesError')} serverResponse={t(companyList.error)} />
  } else if (companyList.isFetching) {
    content = <Spinner />
  } else if (isEmpty(sortedCompanies)) {
    content = <EmptyView emptyMsg={t('noCompaniesMsg')} />
  } else {
    content = (
      <CompanyTable>
        <CompanyHeader>
          <div />
          <ColumnHeading isLeftAligned>{t('companyNameCol')}</ColumnHeading>
          <ColumnHeading>{t('companyStatusCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('companyUsersCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('companyLatestCol')}</ColumnHeading>
          <ColumnHeading isDoubleCol>
            {!isNewFormShown && (
              <Button onClick={() => setIsNewFormShown(true)} size="small" color="success">
                {t('addCompanyBtn')}
              </Button>
            )}
          </ColumnHeading>
        </CompanyHeader>
        <HorizontalRule isPadded />

        {map(sortedCompanies, (co: Company) => {
          const isCompanyDisabled = co.status === 'DISABLED'
          return (
            <React.Fragment key={`company=${co.id}`}>
              <CompanyItem onClick={() => handleGoToDetail(co.id)}>
                <CompanyIcon>
                  <FontAwesomeIcon icon="building" />
                </CompanyIcon>
                <CompanyName isDisabled={isCompanyDisabled}>{co.name}</CompanyName>
                <CompanyStatus>
                  {isCompanyDisabled ? 'REMOVED' : <StatusBadge status={co.reportStatus} />}
                </CompanyStatus>
                <UsersWrapper>
                  {isCompanyDisabled
                    ? null
                    : map(co.users, u => (
                        <Avatar
                          key={`contact-${co.id}-${u.id}`}
                          user={u}
                          status={u?.reportStatus}
                          isImageOnly
                          isCircleSmall
                        />
                      ))}
                </UsersWrapper>
                <CompanyLatest>{isCompanyDisabled ? null : <LatestEvent events={co.events} />}</CompanyLatest>
                <Button to={`/company/${co.id}`} size="small" color="secondary">
                  {isCompanyDisabled ? t('viewBtn') : t('editBtn')}
                </Button>
                {co.status === 'ENABLED' ? (
                  <Button onClick={e => handleConfirmRemoveCompany(e, co.id)} isSimple color="error">
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                ) : (
                  <div />
                )}
              </CompanyItem>
              <HorizontalRule lineStyle="dashed" isHideLast />
            </React.Fragment>
          )
        })}
      </CompanyTable>
    )
  }

  return (
    <PageContent>
      <PageTitleWithAction>
        {t('portfolioTitle')}
        {isEmpty(sortedCompanies) && !isNewFormShown ? (
          <Button onClick={() => setIsNewFormShown(true)} size="small" color="success">
            {t('addCompanyBtn')}
          </Button>
        ) : (
          <div />
        )}
      </PageTitleWithAction>
      <ContentBoxLists>
        <FormWrapper isOpen={!!isNewFormShown}>
          <Form
            onSubmit={handleAddCompany}
            onCancel={() => setIsNewFormShown(false)}
            errorMsg={!isMockData ? t(companyList.error) : undefined}
            fields={[
              {
                type: 'text',
                name: 'name',
                initialValue: '',
                ref: { required: true, minLength: 1 },
                errors: {
                  required: t('companyNameRequired'),
                  min: t('companyNameRequired'),
                },
              }
            ]}
          />
        </FormWrapper>
        {content}
        <HorizontalRule lineStyle="dashed" isHideLast />
      </ContentBoxLists>
      <ConfirmDialog
        isOpen={!isNil(confirmRemoveCompanyId)}
        confirmText={t('confirmRemoveCompanyMsg')}
        onConfirm={() => handleRemoveCompany(confirmRemoveCompanyId)}
        onCancel={() => setConfirmRemoveCompanyId(undefined)}
      />
    </PageContent>
  )
}

export default PortfolioPage
