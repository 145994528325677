import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import Demo2LogoImg from 'components/Demo2Logo/cxyq.png'

interface Demo2LogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const Demo2Logo: React.FC<Demo2LogoProps> = ({ brandName }: Demo2LogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={Demo2LogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default Demo2Logo
