import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, text } from 'utils/colors'

// import {
//   InputFieldWrapper,
//   StyledLabel,
//   inputStyle,
// } from './styles'

interface InputTextProps {
  name: string
  value: string
  error?: string | boolean
  onChange: (e: any) => void
  onBlur: (e: any) => void
}

interface InputT {
  hasError: boolean
}

const InputTextLabel = styled.label`
  display: block;
  font-size: 0.8rem;
  width: 100%;
`
const InputTextField = styled.input<InputT>`
  display: block;
  font-size: 0.9rem;
  margin-top: 0.1rem;
  padding: 0.5rem 3%;
  width: 94%;
  border-radius: 0.25rem;
  border: 1px solid ${p => (p.hasError ? text.error : colors.border)};
`
const ErrorMsg = styled.div`
  color: ${text.error};
  margin-top: 0.1rem;
  height: 1rem;
  font-size: 0.8rem;
`

const InputText: React.FC<InputTextProps> = ({ name, value, error, onChange, onBlur }: InputTextProps) => {
  const { t } = useTranslation()

  return (
    <InputTextLabel>
      {t(name)}
      <InputTextField type="text" name={name} value={value} hasError={!!error} onChange={onChange} onBlur={onBlur} />
      <ErrorMsg>{error}</ErrorMsg>
    </InputTextLabel>
  )
}

export default InputText
