import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import {
  map,
  isEmpty,
  concat,
  isNil,
  includes,
  without,
  filter,
  flatMap,
  flatMapDeep,
  has,
  values,
  uniq,
  find,
  join,
  isObject,
  isString,
} from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import XLSX from 'xlsx'

import Spinner from 'components/Spinner'
import Button from 'components/Button'
import { ActionBtns } from 'components/styles'

import { downloadMetricExport, CLEAR_METRIC_GROUP_EXPORT_STATUS } from 'actions/metricsActions'
import { Metric } from 'models/metrics'

import {
  AddEditWrapper,
  FieldsWrapper,
  OptionsWrapper,
  OptionsLabel,
  ItemOption,
  ItemLabel,
  ActionRow,
  DetailOptionsWrapper,
  DownloadErrorMsg,
} from './styles'

interface ExportMetricPdfFormProps {
  groupId: string
  groupName?: string
  metrics: Metric[]
  onClose: () => void
}

const ExportMetricPdfForm: React.FC<ExportMetricPdfFormProps> = ({ groupId, groupName, metrics, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { exportMetricPdf } = useSelector((state: any) => state.metrics)

  const [isXlsFormat, setIsXlsFormat] = useState<boolean>(false)
  const [includedMetrics, setIncludedMetrics] = useState<string[]>(map(metrics, m => m.id))
  const [isCompanyDetailIncluded, setIsCompanyDetailIncluded] = useState<boolean>(false)
  const [isUserDetailIncluded, setIsUserDetailIncluded] = useState<boolean>(false)
  const [isXlsLoading, setIsXlsLoading] = useState<boolean>(false)

  const isFormReady = !isEmpty(includedMetrics)

  useEffect(() => {
    if (exportMetricPdf?.isComplete && isNil(exportMetricPdf?.error)) {
      setIsCompanyDetailIncluded(false)
      setIsUserDetailIncluded(false)
      dispatch({ type: CLEAR_METRIC_GROUP_EXPORT_STATUS })
      onClose()
    }
  }, [exportMetricPdf, dispatch, onClose])

  const handleDownloadPdf = () => {
    const detailType = isUserDetailIncluded ? 'user' : isCompanyDetailIncluded ? 'company' : 'overview'
    const hidden = filter(
      map(metrics, m => m.id),
      mId => !includes(includedMetrics, mId),
    )
    if (!exportMetricPdf.isFetching && isFormReady) {
      dispatch(downloadMetricExport(auth0Context, groupId, detailType, hidden))
    }
  }

  const handleDownloadXls = () => {
    setIsXlsLoading(true)
    // console.log('handleDownloadXls()', metrics)
    const allUserResponses = flatMapDeep(metrics, group => {
      return flatMap(group.questionMetrics, ques => {
        const query = ques.query
        return flatMap(ques.companyMetrics, co => {
          const company = co.name
          return flatMap(co.users, user => {
            return {
              id: user.id,
              name: user.name,
              attestation: user.attestation,
              company,
              query,
              questionType: ques.type,
              questionOptions: ques.options,
            }
          })
        })
      })
    })

    let allColumnHeaders = ['Company', 'Contact Full Name']

    const xlsData = allUserResponses.reduce((acc: Record<string, any>, userResp: any) => {
      if (
        userResp.questionType === 'BREAKDOWN' &&
        !isEmpty(userResp.questionOptions) &&
        userResp.attestation !== 'NOT_APPLICABLE'
      ) {
        const optionResponses = userResp.questionOptions.reduce((optionsAcc: Record<string, any>, option: string) => {
          allColumnHeaders = concat(allColumnHeaders, `${userResp.query}: ${option}`)

          const breakdownAnswer = has(userResp.attestation, option) ? userResp.attestation[option] : ''
          return {
            ...optionsAcc,
            [`${userResp.query}: ${option}`]: breakdownAnswer,
          }
        }, {})
        return {
          ...acc,
          [userResp.id]: {
            ...acc[userResp.id],
            ...optionResponses,
            Company: userResp.company,
            'Contact Full Name': userResp.name,
          },
        }
      }

      allColumnHeaders = concat(allColumnHeaders, userResp.query)

      let answer = userResp.attestation

      // if (userResp.questionType === 'PERCENTAGE') {
      //   answer = `${userResp.attestation} %`
      // }

      if (userResp.questionType === 'MULTI_SELECT' && isObject(userResp.attestation)) {
        const selectionsArray = find(userResp.attestation, (val, key) => key === 'selections')
        const otherOption = find(userResp.attestation, (val, key) => key === 'otherOption')
        answer = join(filter(concat(selectionsArray, otherOption)), ', ')
      }

      if (userResp.attestation === 'NOT_APPLICABLE') {
        answer = 'Not Applicable'
      }

      return {
        ...acc,
        [userResp.id]: {
          ...acc[userResp.id],
          Company: userResp.company,
          'Contact Full Name': userResp.name,
          [userResp.query]: answer,
        },
      }
    }, {})

    const uniqColumnHeaders = uniq(allColumnHeaders)

    // console.log('JSON Output to be converted to XLS', values(xlsData), { header: uniqColumnHeaders })

    const ws_name = isString(groupName) ? groupName : 'Metrics'
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(values(xlsData), { header: uniqColumnHeaders })
    XLSX.utils.book_append_sheet(wb, ws, ws_name)
    XLSX.writeFile(wb, 'metrics_export.xlsb')
    setIsXlsLoading(false)
    setIsCompanyDetailIncluded(false)
    setIsUserDetailIncluded(false)
    setIsXlsFormat(false)
    onClose()
  }

  const handleDownloadClick = () => {
    if (isXlsFormat) {
      return handleDownloadXls()
    }
    return handleDownloadPdf()
  }

  const toggleMetric = (metricId: string) => {
    if (includes(includedMetrics, metricId)) {
      setIncludedMetrics(without(includedMetrics, metricId))
    } else {
      setIncludedMetrics(concat(includedMetrics, metricId))
    }
  }

  const toggleCompanyDetail = () => {
    if (isCompanyDetailIncluded) {
      setIsCompanyDetailIncluded(false)
      setIsUserDetailIncluded(false)
    } else {
      setIsCompanyDetailIncluded(true)
    }
  }

  const toggleUserDetail = () => {
    if (isUserDetailIncluded) {
      setIsUserDetailIncluded(false)
    } else {
      setIsUserDetailIncluded(true)
      setIsCompanyDetailIncluded(true)
    }
  }

  if (exportMetricPdf.isFetching && isNil(exportMetricPdf?.error)) {
    return <Spinner />
  }

  if (isXlsLoading) {
    return <Spinner />
  }

  return (
    <AddEditWrapper>
      {isNil(exportMetricPdf?.error) ? (
        <FieldsWrapper>
          <DetailOptionsWrapper>
            <OptionsLabel>{t('exportMetricFormatTitle')}</OptionsLabel>
            <ItemOption onClick={() => setIsXlsFormat(false)} isSelected={!isXlsFormat} isRadio>
              <FontAwesomeIcon icon="circle" />
              <ItemLabel>{t('exportMetricAsPdfLabel')}</ItemLabel>
            </ItemOption>
            <ItemOption onClick={() => setIsXlsFormat(true)} isSelected={isXlsFormat} isRadio>
              <FontAwesomeIcon icon="circle" />
              <ItemLabel>{t('exportMetricAsXlsLabel')}</ItemLabel>
            </ItemOption>
          </DetailOptionsWrapper>

          {!isXlsFormat && (
            <>
              <OptionsWrapper>
                <OptionsLabel>
                  {t('exportMetricsToInclude', { groupName: isNil(groupName) ? 'this Group' : groupName })}
                </OptionsLabel>
                {map(metrics, m => {
                  const isSelected = includes(includedMetrics, m.id)
                  const Icon = isSelected ? <FontAwesomeIcon icon="check-square" /> : <FontAwesomeIcon icon="square" />

                  return (
                    <ItemOption
                      key={`metric-option-${m.id}`}
                      onClick={() => toggleMetric(m.id)}
                      isSelected={isSelected}
                    >
                      {Icon}
                      <ItemLabel>{m.name}</ItemLabel>
                    </ItemOption>
                  )
                })}
              </OptionsWrapper>

              <DetailOptionsWrapper>
                <OptionsLabel>{t('exportMetricModalAdditionalOptions')}</OptionsLabel>
                <ItemOption onClick={toggleCompanyDetail} isSelected={isCompanyDetailIncluded}>
                  {isCompanyDetailIncluded ? (
                    <FontAwesomeIcon icon="check-square" />
                  ) : (
                    <FontAwesomeIcon icon="square" />
                  )}
                  <ItemLabel>{t('exportIncludeCompanyDetailLabel')}</ItemLabel>
                </ItemOption>
                <ItemOption onClick={toggleUserDetail} isSelected={isUserDetailIncluded}>
                  {isCompanyDetailIncluded ? (
                    <FontAwesomeIcon icon="check-square" />
                  ) : (
                    <FontAwesomeIcon icon="square" />
                  )}
                  <ItemLabel>{t('exportIncludeUserDetailLabel')}</ItemLabel>
                </ItemOption>
              </DetailOptionsWrapper>
            </>
          )}
        </FieldsWrapper>
      ) : (
        <DownloadErrorMsg>{exportMetricPdf?.error}</DownloadErrorMsg>
      )}

      <ActionRow>
        <div />
        <ActionBtns isInGrid>
          {isNil(exportMetricPdf?.error) ? (
            <>
              <Button onClick={handleDownloadClick} size="small" color="success" isDisabled={!isFormReady}>
                {t('exportPdfModalBtn')}
              </Button>
              <Button onClick={onClose} size="small" color="secondary">
                {t('cancelBtn')}
              </Button>
            </>
          ) : (
            <Button onClick={onClose} size="small" color="secondary">
              {t('closeBtn')}
            </Button>
          )}
        </ActionBtns>
      </ActionRow>
    </AddEditWrapper>
  )
}

export default ExportMetricPdfForm
