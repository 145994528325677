import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import initIcons from 'utils/icons'
import history from 'utils/history'
import { getHost } from 'api/helpers'
import { Auth0Provider } from 'utils/auth0'

import App from 'app/App'
import authConfigDemo1 from './auth_config_demo1.json'
import authConfigDemo2 from './auth_config_demo2.json'
import authConfigDemo3 from './auth_config_demo3.json'
import authConfigDemo4 from './auth_config_demo4.json'
import authConfigDemo5 from './auth_config_demo5.json'
import authConfigTrive from './auth_config_trive.json'
import authConfigKainos from './auth_config_kainos.json'
import authConfigNewstate from './auth_config_newstate.json'
import authConfigTailwater from './auth_config_tailwater.json'
import authConfigTrinityHunt from './auth_config_trinityhunt.json'
import authConfigHig from './auth_config_hig.json'
import authConfigStonePoint from './auth_config_stonepoint.json'
import authConfigAbry from './auth_config_abry.json'
import authConfigAltamont from './auth_config_altamont.json'
import authConfigKelso from './auth_config_kelso.json'
import authConfigAcon from './auth_config_acon.json'

import rootReducer from './reducers'
import './i18n'
import './reset.css'

const localhostAuthConfig = authConfigTrive

const authConfig = window.location.host.startsWith('reportify-demo1')
  ? authConfigDemo1
  : window.location.host.startsWith('reportify-demo2')
  ? authConfigDemo2
  : window.location.host.startsWith('reportify-demo3')
  ? authConfigDemo3
  : window.location.host.startsWith('reportify-demo4')
  ? authConfigDemo4
  : window.location.host.startsWith('reportify-demo5')
  ? authConfigDemo5
  : window.location.host.startsWith('rfly-dev')
  ? authConfigDemo5
  : window.location.host.startsWith('kainos')
  ? authConfigKainos
  : window.location.host.startsWith('newstate')
  ? authConfigNewstate
  : window.location.host.startsWith('tailwater')
  ? authConfigTailwater
  : window.location.host.startsWith('trinityhunt')
  ? authConfigTrinityHunt
  : window.location.host.startsWith('hig')
  ? authConfigHig
  : window.location.host.startsWith('stonepoint')
  ? authConfigStonePoint
  : window.location.host.startsWith('abry')
  ? authConfigAbry
  : window.location.host.startsWith('altamont')
  ? authConfigAltamont
  : window.location.host.startsWith('kelso')
  ? authConfigKelso
  : window.location.host.startsWith('acon')
  ? authConfigAcon
  : window.location.host.startsWith('localhost')
  ? localhostAuthConfig
  : authConfigTrive

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

const middleware = compose(
  applyMiddleware(thunk.withExtraArgument({ host: getHost() })),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
)

const store = createStore(rootReducer, middleware)

const render = () => {
  ReactDOM.render(
    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={authConfig.audience}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>,
    document.getElementById('root'),
  )
}

initIcons()
render()

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('app/App', render)
// }
