import React from 'react'
import styled from 'styled-components'
import RiskForgeLogoImg from './ea_rf.png'

interface RiskForgeLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  padding: 1rem;
`

const RiskForgeLogo: React.FC<RiskForgeLogoProps> = () => {
  return (
    <LogoWrapper>
      <StyledLogo src={RiskForgeLogoImg} alt="EA Solutions Powered by RiskForge" />
    </LogoWrapper>
  )
}

export default RiskForgeLogo
