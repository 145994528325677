import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { text, bg, lighten, darken, shaded, fadedOpacity } from 'utils/colors'
import { SIDENAV_WIDTH, TITLEBAR_HEIGHT } from 'utils/appUnits'

interface NavItemProps {
  'data-active': boolean
  'data-temp'?: boolean
  'data-parent'?: boolean
  'data-hidden'?: boolean
}

// app/App

export const AppContainer = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  margin: 0;

  grid-template-areas:
    'sidenav titlebar'
    'sidenav main';

  grid-template-columns: ${SIDENAV_WIDTH} 1fr;
  grid-template-rows: ${TITLEBAR_HEIGHT} 1fr;

  @media print {
    height: auto;
    grid-template-areas: 'main';
    grid-template-columns: auto;
    grid-template-rows: auto;
    * {
      transition: none !important;
      -webkit-print-color-adjust: exact;
    }
  }
`
export const MainContent = styled.div`
  grid-area: main;
  background: ${bg.main};
  color: ${text.main};
  overflow-y: auto;
  overflow-x: hidden;
  @media print {
    background: #fff;
  }
`

// app/Titlebar

export const TitlebarContainer = styled.div`
  grid-area: titlebar;
  background: ${bg.titlebar};
  color: ${text.titlebar};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  @media print {
    display: none;
  }
`
export const TitlebarActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 2rem;
`

export const TitleIcon = styled.div`
  font-size: 1.4rem;
  color: ${text.titlebar};
  padding: 0.75rem;

  cursor: pointer;
  user-select: none;
  opacity: ${fadedOpacity};
  &:hover {
    opacity: 1;
  }
`
export const AvatarWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`

export const OptionalAppTitle = styled.div`
  padding-left: 2rem;
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
`

// app/Sidenav

export const SidenavContainer = styled.div`
  grid-area: sidenav;
  background: ${bg.nav};
  color: ${text.nav};
  height: 100vh;
  display: grid;
  grid-template-rows: ${TITLEBAR_HEIGHT} auto 4rem;
  @media print {
    display: none;
  }
`
export const NavWrapper = styled.div`
  overflow-y: auto;
`
const navItemStyled = css<NavItemProps>`
  display: grid;
  grid-template-columns: ${p => (p['data-parent'] ? '1.5rem auto 1.5rem' : '1.5rem auto')};
  grid-gap: 1rem;
  align-items: start;
  font-size: 1.2rem;
  font-weight: 400;
  color: ${text.nav};
  cursor: pointer;
  user-select: none;
  opacity: ${p => (p['data-temp'] ? '0.2' : p['data-active'] ? '1' : '0.7')};
  background: ${p => (p['data-active'] ? darken : 'transparent')};
  overflow: hidden;
  height: auto;
  max-height: ${p => (p['data-hidden'] ? '0' : '8rem')};
  padding: ${p => (p['data-hidden'] ? '0 2rem' : '2rem 2rem')};
  transition: all 0.4s linear;
  line-height: 1.4;

  & > svg {
    margin-top: 0.05rem;
    font-size: 1.5rem;
  }

  &:hover {
    background: ${lighten};
  }
`
export const NavItem = styled(Link)<NavItemProps>`
  ${navItemStyled}
`
export const FakeNavItem = styled.div<NavItemProps>`
  ${navItemStyled}
`
export const SubNavItem = styled(Link)<NavItemProps>`
  ${navItemStyled}

  grid-template-columns: 1rem auto;
  background: ${p => (p['data-active'] ? darken : shaded)};
  padding-left: 4rem;

  & > svg {
    margin-top: 0.3rem;
    font-size: 1rem;
  }
`
export const AddMetricGroupBtn = styled.div<NavItemProps>`
  ${navItemStyled}

  grid-template-columns: 1rem auto;
  background: ${p => (p['data-active'] ? darken : shaded)};
  padding-left: 4rem;
  cursor: pointer;
  user-select: none;

  & > svg {
    margin-top: 0.3rem;
    font-size: 1rem;
  }
`
export const ToggleIcon = styled.div<{ 'data-hidden': boolean }>`
  line-height: 1;
  align-self: center;
  transform: ${p => (p['data-hidden'] ? 'rotate(0)' : 'rotate(90deg)')};
  transition: transform 0.3s ease-in-out;
  & > svg {
    height: 1.5rem;
    width: 1.5rem !important;
  }
`

// SettingsModal

export const SettingsModalContainer = styled.div`
  width: 30rem;
  padding: 2rem;
  & > * {
    margin: 0 0 1rem;
  }
`
export const SettingsBtns = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0 0;
`
export const CloseIcon = styled.div`
  font-size: 1.5rem;
  color: ${text.inactive};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${text.active};
  }
`
export const FormSuccessMsg = styled.div`
  padding: 5rem 2rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: ${text.success};
`
