import styled, { css } from 'styled-components'
import { text, bg, bgOpaque, colors, logo } from 'utils/colors'
import { TITLEBAR_HEIGHT, ATTESTATION_WIDTH, REPORT_HEADER_HEIGHT } from 'utils/appUnits'

import { PageContent, ContentBox, ColumnHeading, MiniTitle, PageWithBannerTitle } from 'components/styles'

// templates/TemplateView

export const TemplateViewWrapper = styled(PageWithBannerTitle)`
  padding-bottom: 8rem;
`

const titleBanner = css`
  padding: 0 1rem;
  background: ${bgOpaque.logo};
  height: ${TITLEBAR_HEIGHT};
  display: grid;
  align-items: center;
  grid-gap: 1rem;
`
export const TemplateNormalTitle = styled.div`
  ${titleBanner}
  grid-template-columns: auto 10rem 10rem 10rem 10rem 2rem;
`
export const TemplateViewOnlyTitle = styled.div<{ hasUnsavedChanges?: boolean }>`
  ${titleBanner}
  grid-template-columns: ${p =>
    p.hasUnsavedChanges ? 'auto 6rem 10rem 10rem 10rem 2rem' : 'auto 6rem 10rem 10rem 2rem'};
`
export const TemplateEditTitle = styled.div`
  ${titleBanner}
  grid-template-columns: auto;
  display: flex;
  align-items: center;
  input {
    font-size: 1.5rem;
    margin: 0 1rem;
    width: 20rem;
  }
`
export const ViewReportTitle = styled.div<{ hasExportBtn: boolean }>`
  ${titleBanner}
  grid-template-columns: ${p => (p.hasExportBtn ? '1fr 1fr 12rem 2rem' : '1fr 1fr 2rem')};
`
export const TemplatePageTitle = styled.div`
  font-weight: 200;
  margin: 0;
  align-items: center;
  overflow: hidden;
  & > * {
    display: block;
  }
  @media (min-width: 90rem) {
    /* (90rem) == 1440px) */
    font-size: 1.5rem;
    display: flex;
  }
`
export const ReportName = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0.5rem 0;
  width: calc(100% - 3rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: 90rem) {
    /* (90rem) == 1440px) */
    margin-left: 0.5rem;
  }
`
export const TemplateName = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 3rem);
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem 0;
  }

  @media (min-width: 90rem) {
    /* (90rem) == 1440px) */
    margin-left: 0.5rem;
  }
`
export const ToggleEditIcon = styled.div`
  margin-left: 1rem;
  font-size: 0.8rem;
  color: ${text.inactive};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${text.active};
  }
`
export const CountLength = styled.div`
  color: ${text.inactive};
  font-weight: 600;
  font-size: 1.1rem;
  justify-self: center;
`
export const ViewOnlyMode = styled.div`
  color: ${text.inactive};
  text-align: center;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-around;
  &:hover {
    color: ${text.active};
  }
`

// template/TemplateForm

export const sectionsTable = css`
  padding: 1rem 2rem 5rem;
  margin: 0 0 1rem;
`
export const SectionsTableContainer = styled.div`
  ${sectionsTable}
`
export const SectionsTableForm = styled.form`
  ${sectionsTable}
  padding: 0;
`
export const TemplateSectionContainer = styled(PageContent)`
  margin-bottom: 1rem;
  padding-top: 0;
  padding-bottom: 0;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`

export const AddQuestionWrapper = styled.div<{ isWithNoQuestions?: boolean }>`
  width: 100%;
  margin-top: ${p => (p.isWithNoQuestions ? '1rem' : '0')};
  padding: 1rem 0 0;
  display: flex;
  justify-content: center;
`
export const AuthMatrixSectionContainer = styled(TemplateSectionContainer)``
export const CustomSectionContainer = styled(TemplateSectionContainer)``
export const SectionName = styled.div<{ isViewOnly?: boolean }>`
  font-size: 1.5rem;
  font-weight: 600;
  display: grid;
  grid-template-columns: ${p => (p.isViewOnly ? '1rem 3rem auto 10rem' : '1rem 3rem auto 10rem 1rem')};
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.6rem 0.4rem;
`

export const SectionToggleName = styled.div`
  display: flex;
  align-items: center;
`

export const ReportSectionName = styled(SectionName)`
  grid-template-columns: 1rem 1fr 1fr;
`
export const ToggleName = styled.div`
  cursor: pointer;
  user-select: none;
`
export const SectionBadges = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: row-reverse;
  & > * {
    margin-left: 1rem;
  }
`
export const HiddenContentBox = styled(ContentBox)<{ isOpen?: boolean }>`
  margin-top: ${p => (p.isOpen ? '1rem' : '0')};
  padding-bottom: ${p => (p.isOpen ? '1rem' : '0')};
  transition: all 0.3s ease-in-out;

  & > * {
    height: auto;
    overflow: hidden;
    padding: ${p => (p.isOpen ? '0.5rem 0' : '0 0')};
    max-height: ${p => (p.isOpen ? 'auto' : '0')};
    transform: ${p => (p.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
    transition: all 0.3s ease-in-out;
    display: ${p => (p.isOpen ? 'flex' : 'none !important')};
  }
`
export const QuestionField = styled.div<{ isLeftAligned?: boolean }>`
  padding: 1rem 0;
  border-radius: 0.5rem;
  justify-self: ${p => (p.isLeftAligned ? 'start' : 'center')};
  a {
    color: ${text.logo};
    text-decoration: underline;
  }
`
export const CheckboxWrapper = styled.div`
  padding-top: 0.45rem;
`
export const DocDescripWrapper = styled.div<{ isActive?: boolean }>`
  visibility: ${p => (p.isActive ? 'visible' : 'hidden')};
`
export const HiddenContentBoxWithAnswerSlider = styled(ContentBox)<{ isOpen?: boolean }>`
  margin-top: ${p => (p.isOpen ? '1rem' : '0')};
  transition: all 0.5s ease-in-out;
  position: relative;
  * {
    padding: ${p => (p.isOpen ? '' : '0')};
    height: ${p => (p.isOpen ? '' : '0')};
    transition: all 0.5s ease-in-out;
  }
`
export const QuestionContentSide = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  & > * {
    height: auto;
    overflow: hidden;
    padding: ${p => (p.isOpen ? '0.5rem 0 1rem' : '0 0')};
    transition: all 0.3s ease-in-out;
  }
`
export const AnswerContentSide = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;

  overflow: hidden;
  width: ${p => (p.isExpanded ? `calc(2 * ${ATTESTATION_WIDTH} + 6rem)` : `calc(${ATTESTATION_WIDTH} + 6rem)`)};
  transition: width 0.5s ease-in-out;

  background: ${bgOpaque.active};
  display: flex;
`
export const ExpandBar = styled.div<{ isExpanded: boolean }>`
  background: ${text.active};
  color: ${text.contrast};
  width: 3rem;
  height: 100%;
  padding: 0;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & > svg {
    font-size: 1.6rem;
    line-height: 0;
    align-self: center;
    transform: ${p => (p.isExpanded ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.5s ease-in-out;
    margin: 1rem 0;
  }
`
export const ExpandBarContent = styled.div<{ isExpanded: boolean }>`
  transform: rotate(270deg);
  transform-origin: 50% 50%;
  justify-self: center;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  text-transform: uppercase;
`
const rowHeights = css`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
const matrixQuestionTableGrid = css<{ extrasLength: number }>`
  display: grid;
  grid-template-columns: 3rem 3fr repeat(${p => p.extrasLength}, 1fr) 3rem 1.5rem 1fr 3rem 1rem;
  grid-gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
`
export const MatrixSectionHeader = styled.div`
  ${rowHeights}
  ${matrixQuestionTableGrid}
  align-items: center;
  height: auto;
`

export const MatrixQuestionRow = styled.div`
  ${rowHeights}
  ${matrixQuestionTableGrid}
  align-items: start;
  &:hover {
    background: ${bg.main};
  }
`
const reportTableGrid = css<{ extrasLength: number }>`
  display: grid;
  grid-template-columns: 3fr repeat(${p => p.extrasLength}, 1fr);
  grid-gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% - ${ATTESTATION_WIDTH} - 8rem);
`
export const ReportSectionHeader = styled.div`
  ${rowHeights}
  ${reportTableGrid}
  align-items: center;
  height: auto;
  min-height: auto;
`

interface ReportQuestionRowTypes {
  isOpen: boolean
  extrasLength: number
  rowHeight: number
}
export const ReportQuestionRow = styled.div<ReportQuestionRowTypes>`
  ${rowHeights}
  ${reportTableGrid}
  align-items: start;
  border-top: 1px solid ${colors.border};
  align-items: center;
  min-height: ${p => (p.isOpen ? p.rowHeight : '0')}rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${bg.main};
  }
`

export const AnswerContentWrapper = styled.div`
  padding: 0 0 1rem;
  width: 100%;
  display: flex;
`
export const AnswerContent = styled.div<{ isExpanded: boolean }>`
  overflow: hidden;
  width: ${p => (p.isExpanded ? '100%' : '0')};
  opacity: ${p => (p.isExpanded ? '1' : '0')};
  transform: ${p => (p.isExpanded ? 'scaleX(1)' : 'scaleX(0)')};
  transition: all 0.3s ease-in-out;

  & > * {
    ${rowHeights}
  }
`
export const StatusBadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin: 0.25rem;
  }
`
export const AvatarsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin: 0.25rem -0.25rem;
  }
`
export const ReportColumnHeading = styled(ColumnHeading)`
  height: ${REPORT_HEADER_HEIGHT};
  display: flex;
  align-items: center;
`
export const AttestationHeading = styled(ColumnHeading)`
  height: ${REPORT_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
`

export const ScrollBar = styled.div`
  color: ${text.active};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  cursor: pointer;
  user-select: none;
  z-index: 200;

  &:hover {
    color: ${text.logo};
  }
`
export const RowWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.25rem;
  grid-template-columns: 1rem calc((2 * ${ATTESTATION_WIDTH})) 1rem;
  border-top: 1px solid ${colors.border};
`
export const ExpandedAttestationHeading = styled.div<{ count: number; scrolled: number }>`
  height: ${REPORT_HEADER_HEIGHT};
  width: calc((${p => p.count} * ${ATTESTATION_WIDTH}));
  display: flex;
  margin-left: calc((-${p => p.scrolled} * ${ATTESTATION_WIDTH}));
  transition: all 0.3s ease-in-out;

  & > * {
    width: ${ATTESTATION_WIDTH};
  }
`
export const AttestationRow = styled.div<{ rowHeight: number }>`
  height: ${p => p.rowHeight}rem;
  border-top: 1px solid ${colors.border};
  padding: 0.5rem 0;
  justify-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ExpandedAttestationRow = styled.div<{ count: number; scrolled: number; rowHeight: number }>`
  height: ${p => p.rowHeight}rem;
  width: 100%;
  display: flex;
  align-content: center;
  width: calc((${p => p.count} * ${ATTESTATION_WIDTH}));
  margin-left: calc((-${p => p.scrolled} * ${ATTESTATION_WIDTH}));
  transition: all 0.3s ease-in-out;
  z-index: 150;
`
export const AttestationContent = styled.div`
  width: ${ATTESTATION_WIDTH};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const UserStatus = styled.div<{ status: 'COMPLETE' | 'PENDING' | 'OVERDUE' }>`
  font-weight: 600;
  color: ${p => text[p.status]};
`
export const AttestationDetailTitle = styled(MiniTitle)`
  font-size: 0.8rem;
  color: ${text.inactive};
  margin: 0 0 0.25rem;
`
export const ExplanationWrapper = styled.div<{ isClickable?: boolean }>`
  margin: 1rem 20% 0;
  width: 60%;
  cursor: pointer;
  user-select: none;
`
export const ExplanationContent = styled.div`
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ShowMore = styled.div`
  color: ${text.active};
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0.25rem;
`
export const DocumentsWrapper = styled.div`
  margin: 1rem 20% 0;
  width: 60%;
`
export const DocumentsBtnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2rem auto;
  grid-gap: 0.5rem;
  align-items: center;
  h4 {
    grid-column: 1 / -1;
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1rem;
  }
  & > p {
    color: ${text.main};
    font-weight: 200;
    font-size: 0.9rem;
  }
  svg {
    justify-self: end;
    font-size: 0.5rem;
    color: ${text.inactive};
  }
`
export const DocumentBtn = styled.div`
  margin-top: 1rem;
  grid-column: 1 / -1;
  background: ${text.active};
  color: ${text.contrast};
  padding: 0.3rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
  user-select: none;
  text-align: center;
`
export const DocumentItem = styled.div<{ onClick: (docId: string) => void }>`
  font-size: 0.8rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: ${text.active};
    text-decoration: underline;
  }
`
export const EditNameWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    font-size: 1.5rem;
    margin: 0 1rem;
    width: 20rem;
  }
  svg {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
`
export const InputValidationError = styled.div`
  color: ${text.error};
  font-size: 0.9rem;
`
export const ErrorMsg = styled(MiniTitle)`
  color: ${text.error};
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
`
const newElementBoxStyles = css`
  border: 2px solid ${text.active};
  margin: 1rem auto;
  padding: 0.25rem 0.5rem 1rem;
`
export const NewSectionBox = styled(PageContent)`
  ${newElementBoxStyles}
`
export const NewBoxTitleRow = styled.div`
  display: grid;
  grid-template-columns: auto 1rem;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
`
export const NewBoxTitle = styled.div`
  color: ${text.active};
  font-size: 1.4rem;
  font-weight: 600;
`
export const NewSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
`
export const NameFieldWrapper = styled.div`
  label {
    margin-right: 1rem;
    font-size: 1.1rem;
    padding: 0.5rem 0;
    color: ${text.inactive};
  }
  input {
    font-size: 1.3rem;
    border: none;
    border-bottom: 1px solid ${text.inactive};
    background: transparent;
    font-weight: 500;
  }
`
export const TypeFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr 1fr 1.5rem;
  grid-gap: 1rem;
  align-items: center;
  label {
    text-align: right;
    font-size: 1.2rem;
    color: ${text.inactive};
  }
`
export const NewQuestionBox = styled.div`
  display: block;
  ${newElementBoxStyles}
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`
export const HelpMeDecideWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: ${text.active};
  opacity: 0.6;
  cursor: pointer;
  user-select: none;
  font-size: 1.2rem;
  &:hover {
    opacity: 1;
  }
  svg {
    font-size: 1.5rem;
    margin-left: 0.6rem;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
`
export const NewQuestionTypesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 1rem;
  margin: 0 0.5rem 1rem;
`
export const NewElementSelectBtn = styled.div`
  border: 3px solid ${text.active};
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
  user-select: none;
  background: transparent;
  &:hover {
    background: ${bg.active};
  }
`
export const NewElementSelectTitle = styled.div`
  color: ${text.active};
  display: grid;
  grid-template-columns: 1.5rem auto;
  align-items: center;
  grid-gap: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  svg {
    display: block;
    font-size: 1.6rem;
  }
`
export const QuestionTypeDescription = styled.div`
  color: ${text.active};
  text-align: center;
  margin-top: 1rem;
`
export const QuestionTypeExample = styled.div`
  color: ${text.deemphasis};
  font-size: 0.9rem;
  font-weight: 400;
  margin: 1rem 3rem 0;
`
export const QuestionTypeQuery = styled.div`
  margin-bottom: 0.5rem;
`
export const QuestionTypeAnswerString = styled.div`
  border-bottom: 1px solid ${text.inactive};
  margin-left: 1rem;
  padding-bottom: 0.1rem;
  display: grid;
  grid-template-columns: auto 1rem;
  grid-gap: 0.5rem;
  svg {
    color: ${text.inactive};
  }
`
export const QuestionTypeAnswerBullet = styled.div`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  margin: 0 1rem 0.25rem;
  svg {
    color: ${text.inactive};
  }
`
export const QuestionTypeAnswerBreakdown = styled.div`
  display: grid;
  grid-template-columns: 3rem auto;
  grid-gap: 1rem;
  margin: 0 1rem 0.25rem;
`
export const QuestionTypeAnswerUpload = styled.div`
  background: ${text.deemphasis};
  border-radius: 0.5rem;
  color: ${text.contrast};
  width: 7rem;
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  svg {
    margin-right: 0.5rem;
  }
`
const customQuestionTableGrid = css`
  display: grid;
  grid-template-columns: 3rem 3fr 2fr 12rem 1rem;
  grid-gap: 2rem;
  padding: 1rem 1rem;
  width: calc(100% - 2rem);
`
export const CustomSectionHeader = styled.div<{ isVisible: boolean }>`
  ${customQuestionTableGrid}
  display: ${p => (p.isVisible ? 'grid' : 'none')};
  align-items: center;
  height: auto;
`
export const CustomQuestionRow = styled.div<{ index: number }>`
  ${customQuestionTableGrid}
  align-items: start;
  background: ${p => (p.index % 2 === 1 ? 'transparent' : bg.inactive)};
`
export const SortOrderWrapper = styled.div`
  align-self: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.25rem;
  align-items: center;
`
export const ChangeSortOrderWrapper = styled.div``
export const SortArrow = styled.div<{ isDown?: boolean; isDisabled: boolean }>`
  cursor: ${p => (p.isDisabled ? 'unset' : 'pointer')};
  user-select: none;
  width: 0;
  height: 0;
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  margin: 0.25rem 0;
  ${p =>
    p.isDown
      ? `border-top: 0.75rem solid ${p.isDisabled ? 'transparent' : '#ccc'};`
      : `border-bottom: 0.75rem solid ${p.isDisabled ? 'transparent' : '#ccc'};`}
  &:hover {
    ${p =>
      p.isDown
        ? `border-top: 0.75rem solid ${p.isDisabled ? 'transparent' : '#aaa'};`
        : `border-bottom: 0.75rem solid ${p.isDisabled ? 'transparent' : '#aaa'};`}
  }
`
export const SortOrderValue = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  color: #bbb;
`
export const CustomQuestionQueryWrapper = styled.div<{ hasAnswerOptions?: boolean }>`
  grid-column: 2 / span ${p => (p.hasAnswerOptions ? 1 : 2)};
  & > * {
    width: 100%;
  }
`
export const CustomQuestionAnswerOptionsWrapper = styled.div`
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`
const answerOptionLayout = css`
  display: grid;
  grid-template-columns: 1rem auto;
  grid-gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  svg {
    font-size: 1rem;
  }
  input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`
export const CustomQuestionAnswerOption = styled.div`
  ${answerOptionLayout}
`
export const CustomQuestionAddAnswerOption = styled.div`
  ${answerOptionLayout}
  color: ${text.active};
  font-weight: 500;
  padding: 0.5rem 0;
  cursor: pointer;
  user-select: none;
`
export const RemoveOptionIcon = styled.div`
  color: ${text.error};
  opacity: 0.5;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 1;
  }
`
export const CustomQuestionOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  label {
    text-align: left;
    svg {
      font-size: 0.4rem;
      color: ${text.inactive};
      vertical-align: super;
      margin-left: 0.1rem;
    }
  }
`
export const CustomFieldLabel = styled.div`
  margin-bottom: 0.5rem;
`
export const OptionalNote = styled.div<{ isSmall?: boolean }>`
  grid-column: 1 / -1;
  color: ${text.inactive};
  text-align: center;
  font-size: ${p => (p.isSmall ? '0.7rem' : '1rem')};
  svg {
    font-size: 0.4rem;
    vertical-align: super;
    margin-right: 0.1rem;
  }
`
export const QuestionMessage = styled.div`
  color: ${text.inactive};
  text-align: center;
  padding: 1rem 0;
  grid-column: 1 / -1;
`
export const MoreInfoModal = styled.div`
  padding-bottom: 3rem;
  p {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
`
export const PreviewQuestionWrapper = styled.div`
  background: rgba(${logo.rgb}, 0.2);
  padding: 1rem;
`
export const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 1.5rem auto 1.5rem;
  align-items: center;
  margin-bottom: 1rem;
  h3 {
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;
  }
`
export const CloseIcon = styled.div`
  color: ${text.inactive};
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
`
export const MoreInfoTabsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: stretch;
`
export const MoreInfoTab = styled.div<{ isActive: boolean }>`
  background: ${p => (p.isActive ? text.active : text.inactive)};
  color: ${text.contrast};
  text-align: center;
  border-radius: 1rem 1rem 0 0;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid #fff;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const MoreInfoTypeDetail = styled.div`
  background: ${bg.active};
  padding: 1.5rem;
`
export const MoreInfoIcon = styled.div`
  color: ${text.active};
  font-size: 1.5rem;
  opacity: 0.4;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 1;
  }
`
export const QuestionActions = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1.5rem);
  grid-gap: 2rem;
  align-items: center;
`
export const RemoveBtnWrapper = styled.div`
  padding-top: 0.9rem;
  text-align: center;
  justify-self: center;
`
export const PreviewQuestionBtn = styled(MoreInfoIcon)`
  font-size: 1.25rem;
  color: ${text.active};
`
export const QuestionMoreInfoBtn = styled(MoreInfoIcon)``
export const InvisibleWrapper = styled.div`
  display: none;
`
export const AnswerWrapper = styled.div<{ isLarger: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    color: ${text.logo};
    font-size: ${p => (p.isLarger ? '1.4rem' : '1.1rem')};
    font-weight: 600;
  }
  & > svg {
    font-size: 0.8rem;
    color: ${text.secondary};
    margin-left: 0.25rem;
  }
`
export const CommentWrapper = styled.div`
  margin: 1rem 1rem 0;
  grid-column: 1 / -1;
  color: #999;
  width: calc(100% - 4rem);
  padding: 1rem;
  background: rgba(128, 128, 128, 0.05);
  h6 {
    font-weight: 500;
    color: #555;
    margin: 0 0 1rem;
  }
`
export const AnswerBreakdownWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
`
export const AnswerCategoryLabel = styled.div`
  color: ${text.logo};
  font-weight: 400;
  font-size: 1.1rem;
  justify-self: end;
  text-align: right;
`
export const AnswerCategoryValue = styled.div`
  color: ${text.logo};
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.25rem 2rem;
  border-bottom: 1px solid ${text.secondary};
  justify-self: stretch;
  text-align: left;
`
export const AnswerMultiselectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.4rem auto;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
`
export const OtherOptionAnswer = styled.div`
  grid-column: 2 / -1;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background: rgba(104, 179, 200, 0.15);
  border-bottom: 1px solid #39586f;
`
export const AnswerMultipleChoiceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.4rem auto;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 0;
`
export const AnswerIcon = styled.div<{ isSelected: boolean; isSquare?: boolean }>`
  font-size: ${p => (p.isSelected ? '1.4rem' : '1rem')};
  color: ${p => (p.isSelected ? text.active : 'transparent')};
  border: ${p => (p.isSelected ? 'none' : `0.15rem solid ${text.inactive}`)};
  border-radius: ${p => (p.isSquare ? 0 : '1rem')};
  justify-self: center;
`
export const AnswerOptionLabel = styled.div<{ isSelected: boolean }>`
  color: ${p => (p.isSelected ? text.logo : text.inactive)};
  font-weight: ${p => (p.isSelected ? 600 : 400)};
  font-size: 1.1rem;
`
export const CompanyFilesWrapper = styled.div`
  color: ${text.inactive};
  font-size: 1.1rem;
  font-weight: 600;
`
export const MetricExampleWrapper = styled.div`
  font-size: 0.9rem;
  background: rgba(255, 255, 255, 0.5);
  margin: 1rem;
  padding: 1rem;
`
export const ConflictingAnswerBadge = styled.div`
  background: #bbb;
  color: #fff;
  border-radius: 0.3em;
  border: none;
  font-weight: 600;
  text-transform: uppercase;
  width: auto;
  padding: 0.5rem 0.5rem;
  text-align: center;
`
