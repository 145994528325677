import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import KainosLogoImg from 'components/KainosLogo/kainos.svg'

interface KainosLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: brightness(0) invert(1);
`

const KainosLogo: React.FC<KainosLogoProps> = ({ brandName }: KainosLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={KainosLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default KainosLogo
