import React from 'react'
import { useTranslation } from 'react-i18next'

import TemplateMgmt from './TemplateMgmt'
import AdminMgmt from './AdminMgmt'

import { PageContent, PageTitle, ContentBoxLists } from 'components/styles'

import { OrgSettingsContent } from './styles'

const OrgSettingsPage: React.FC<{}> = () => {
  const { t } = useTranslation()
  return (
    <PageContent>
      <PageTitle>{t('orgSettingsTitle')}</PageTitle>
      <OrgSettingsContent>
        <ContentBoxLists>
          <TemplateMgmt />
        </ContentBoxLists>
        <ContentBoxLists>
          <AdminMgmt />
        </ContentBoxLists>
      </OrgSettingsContent>
    </PageContent>
  )
}

export default OrgSettingsPage
