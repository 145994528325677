import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import TrinityHuntLogoImg from './trinityhunt.png'

interface TrinityHuntLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  filter: brightness(0) invert(1);
`

const TrinityHuntLogo: React.FC<TrinityHuntLogoProps> = ({ brandName }: TrinityHuntLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={TrinityHuntLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default TrinityHuntLogo
