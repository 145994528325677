import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { text } from 'utils/colors'
import { isNil, map, includes, concat, without, toNumber, isNaN } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CustomQuestion as CustomQuestionType } from 'models/template'

interface CustomQuestionProps {
  question: CustomQuestionType | undefined
}

const CustomQuestionContainer = styled.div`

`
const QueryWrapper = styled.div`

`
const AnswerWrapper = styled.div`
  padding: 1rem 2rem 0;
`
const OptionItem = styled.div`
  display: grid;
  grid-template-columns: 1.5rem auto;
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0.25rem;
`
const IconWrapper = styled.div<{ isSelected: boolean; isRadio?: boolean }>`

  ${p => p.isRadio && css`
    background: ${p.isSelected ? text.active : 'transparent'};
    border: 0.3rem solid ${p.isSelected ? text.inactive : text.inactive};
    border-radius: 1rem;
    height: 0.8rem;
    width: 0.8rem;
  `}

  svg {
    font-size: 1.5rem;
    color: ${p => p.isSelected ? text.active : text.inactive};
  }
`
const LabelWrapper = styled.div<{ isSelected: boolean }>`
  color: ${text.main};
  font-weight: 500;
  display: flex;
  align-items: center;
`
const OtherInput = styled.div`
  margin-left: 1rem;
`
const NumberWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1.2rem;
  grid-gap: 1rem;
  align-items: center;
  svg {
    color: #aaa;
    font-size: 1.2rem;
  }
`
const InputWrapper = styled.div`
  margin: 1rem 2.5rem 0;
  border: none;
  p {
    font-weight: 500;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
  }
`
const inputStyle = css`
  border-radius: 0.25rem;
  border: 1px solid #aaa;
  background: #eee;
  outline: 0 !important;
  &:focus {
    outline: 0 !important;
    border: 1px solid #aaa;
  }
`
const InputField = styled.input`
  ${inputStyle}
  padding: 0.1rem 0.5rem;
`
const TextAreaField = styled.textarea`
  ${inputStyle}
  padding: 0.5rem;
  width: 80%;
  resize: none;
`
const InvalidWrapper = styled.div`
  color: ${text.error};
  font-weight: 500;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  min-height: 1rem;
`
const BreakdownWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem 1rem;
  align-items: center;
  margin-bottom: 1rem;
`
const BreakdownLabel = styled.div`

`

const CustomQuestion: React.FC<CustomQuestionProps> = ({ question }) => {
  const [ selected, setSelected ] = useState<string[]>([])
  const [ isNA, setIsNA ] = useState<boolean>(false)
  const [ isOther, setIsOther ] = useState<boolean>(false)
  const [ otherDescription, setOtherDescription ] = useState<string>('')
  const [ attestation, setAttestation ] = useState<string>('')
  const [ value, setValue ] = useState<number | undefined>()

  if (isNil(question)) return null

  const handleOptionSelect = (option: string): void => {
    if (question.type === 'MULTIPLE_CHOICE') {
      if (includes(selected, option)) {
        setSelected([])
      } else {
        setSelected([option])
      }
    } else {
      if (includes(selected, option)) {
        setSelected(without(selected, option))
      } else {
        setSelected(concat(selected, option))
      }
    }
  }

  let answerContent = null

  if (question.type === 'SHORT_ANSWER') {
    answerContent = (
      <TextAreaField
        rows={5}
        value={attestation}
        onChange={(e) => setAttestation(e.currentTarget.value)}
      />
    )
  }

  if (question.type === 'BREAKDOWN') {
    answerContent = (
      <BreakdownWrapper>
        {map(question.options, opt => {
          return (
            <React.Fragment key={`bd-${question.id}-${opt}`}>
              <BreakdownLabel>{opt}</BreakdownLabel>
              <InputField type='number' min={0} />
            </React.Fragment>
          )
        })}
      </BreakdownWrapper>
    )
  }

  if (includes(['INTEGER', 'PERCENTAGE'], question.type)) {
    const min = question.minValue || 0
    const tooSmall = !isNil(value) && value < min
    const tooBig = !isNil(question.maxValue) && !isNaN(question.maxValue) && !isNil(value) && value > question.maxValue
    const invalidMsg = tooSmall || tooBig
      ? `Invalid response: number must be ${isNil(question.maxValue) || isNaN(question.maxValue) ? `greater than ${min}` : `between ${min} and ${question.maxValue}`}.`
      : ''
    answerContent = (
      <>
        <NumberWrapper>
          <InputField
            type='number'
            value={value}
            onChange={(e) => {
              const numVal = toNumber(e.currentTarget.value)
              if (!isNaN(numVal)) {
                setValue(numVal)
              }
            }}
          />
          {question.type === 'PERCENTAGE' && <FontAwesomeIcon icon='percent' />}
        </NumberWrapper>
        <InvalidWrapper>{invalidMsg}</InvalidWrapper>
      </>
    )
  }

  if (includes(['MULTI_SELECT', 'MULTIPLE_CHOICE'], question.type)) {
    answerContent = (
      <>
        {map(question.options, opt => {
          const isSelected = includes(selected, opt)
          let icon = <div />
          if (question.type === 'MULTI_SELECT') {
            icon = isSelected ? <FontAwesomeIcon icon='check-square' /> : <FontAwesomeIcon icon='square' />
          }
          return (
            <OptionItem onClick={() => handleOptionSelect(opt)}>
              <IconWrapper isSelected={isSelected} isRadio={question.type === 'MULTIPLE_CHOICE'}>{icon}</IconWrapper>
              <LabelWrapper isSelected={isSelected}>{opt}</LabelWrapper>
            </OptionItem>
          )
        })}
        {question.allowOther && (
          <>
            <OptionItem>
              <IconWrapper isSelected={isOther} onClick={() => setIsOther(!isOther)}>
                {isOther ? <FontAwesomeIcon icon='check-square' /> : <FontAwesomeIcon icon='square' />}
              </IconWrapper>
              <LabelWrapper isSelected={isOther}>
                <div onClick={() => setIsOther(!isOther)}>Other</div>
                {isOther && (
                  <OtherInput>
                    <InputField
                      value={otherDescription}
                      onChange={(e) => setOtherDescription(e.target.value)}
                    />
                  </OtherInput>
                )}
              </LabelWrapper>
            </OptionItem>
          </>
        )}
      </>
    )
  }

  return  (
    <CustomQuestionContainer>
      <QueryWrapper>
        {question.query}
      </QueryWrapper>
      <AnswerWrapper>
        {!isNA && answerContent}
        {question.allowNA && (
          <>
            <OptionItem>
              <IconWrapper isSelected={isNA} onClick={() => setIsNA(!isNA)}>
                {isNA ? <FontAwesomeIcon icon='check-square' /> : <FontAwesomeIcon icon='square' />}
              </IconWrapper>
              <LabelWrapper isSelected={isNA}>
                <div onClick={() => setIsNA(!isNA)}>Not Applicable</div>
              </LabelWrapper>
            </OptionItem>
            {isNA && (
              <InputWrapper>
                <p>Explanation for Not Applicable:</p>
                <TextAreaField
                  rows={5}
                />
              </InputWrapper>
            )}
          </>
        )}
        {question.allowComment && !isNA && (
          <InputWrapper>
            <p>Optional Comment:</p>
            <TextAreaField
              rows={5}
            />
          </InputWrapper>
        )}
      </AnswerWrapper>
    </CustomQuestionContainer>
  )
}

export default CustomQuestion
