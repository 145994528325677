import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
// import { text, bg } from 'utils/colors'
import { MiniTitle } from 'components/styles'

interface EmptyViewProps {
  emptyMsg?: string | React.ReactElement<any>
}

const EmptyViewContainer = styled(MiniTitle)`
  opacity: 0.2;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-content: center;
`

const EmptyView: React.FC<EmptyViewProps> = ({ emptyMsg }: EmptyViewProps) => {
  const { t } = useTranslation()

  return <EmptyViewContainer>{emptyMsg || t('genericEmptyMsg')}</EmptyViewContainer>
}

export default EmptyView
