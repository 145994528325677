import { AdminReport } from 'models/report'
import mockListReports from './mockListReports.json'
import mockReportDetail from './mockGetReport.json'
import dayjs from 'dayjs'

export const mockReportsResponse = mockListReports.items

export const mockReportDetailResponse = mockReportDetail.report

export const mockReports: AdminReport[] = [
  {
    questionStatistics: {
      COMPLIANT: 42,
      NON_COMPLIANT: 42,
      NOT_APPLICABLE: 42,
    },
    users: [
      {
        id: 'UUID',
        name: 'Fake User',
        email: 'email@email.com',
        reportStatus: 'COMPLIANT',
        questionStatistics: {
          COMPLIANT: 42,
          NON_COMPLIANT: 42,
          NOT_APPLICABLE: 42,
        },
      },
    ],
    events: [
      {
        id: 'UUID',
        timestamp: new Date(2021, 1, 23),
        user: {
          id: 'UUID',
          name: 'Fake User',
          email: 'email@email.com',
          reportStatus: 'COMPLIANT',
        },
        name: 'ReportCreated',
      },
    ],
    id: 'UUID',
    name: 'Report Name here',
    template: {
      id: 'UUID',
      name: 'Technology Company Template',
    },
    status: 'COMPLIANT',
    dueBy: new Date(2021, 1, 12),
    sentDate: dayjs('20201212').toString(),
    sections: [
      {
        id: 'UUID',
        name: 'Section Name here',
        type: 'AUTHORITY_MATRIX',
        columnNames: ['Column Name Here'],
        questions: [
          {
            id: 'UUID',
            columns: ['Column here'],
            allowNA: true,
            allowDocuments: true,
          },
        ],
      },
    ],
  },
]
