import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import AltamontLogoImg from 'components/AltamontLogo/altamont.png'

interface AltamontLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
  padding: 1rem 2rem;
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const AltamontLogo: React.FC<AltamontLogoProps> = ({ brandName }: AltamontLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={AltamontLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default AltamontLogo
