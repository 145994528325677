import { some, map } from 'lodash'

import { Company, ReportUser } from '../models/company'

import {
  LIST_COMPANIES_LOADING,
  LIST_COMPANIES_SUCCESS,
  LIST_COMPANIES_FAILURE,
  FIND_COMPANY_LOADING,
  FIND_COMPANY_SUCCESS,
  FIND_COMPANY_FAILURE,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  UPDATE_COMPANY_LOADING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  REMOVE_COMPANY_LOADING,
  REMOVE_COMPANY_SUCCESS,
  REMOVE_COMPANY_FAILURE,
  ADD_REPORT_USER_LOADING,
  ADD_REPORT_USER_SUCCESS,
  ADD_REPORT_USER_FAILURE,
  REMOVE_REPORT_USER_LOADING,
  REMOVE_REPORT_USER_SUCCESS,
  REMOVE_REPORT_USER_FAILURE,
} from 'actions/companyActions'

interface CompanyState {
  error: object
  loading: string[]

  companyDetail: {
    isFetching: boolean

    item: { id: string } | Company

    error?: string
    isSaving?: boolean
    updateError?: string
    lastUpdated?: Date
  }

  usersList: {
    items: ReportUser[]
    isFetching: boolean

    error?: string
    lastUpdated?: Date
  }

  companyList: {
    items: Company[]
    isFetching: boolean

    page?: number
    perPage?: number
    totalItems?: number

    error?: string
    lastUpdated?: Date
  }
}

const initState: CompanyState = {
  error: {},
  loading: [],

  companyList: {
    isFetching: false,
    items: [],
  },

  companyDetail: {
    isFetching: false,
    item: { id: '' },
  },

  usersList: {
    isFetching: false,
    items: [],
  },
}

export const companyReducer = (state = initState, action: any): CompanyState => {
  switch (action.type) {
    case LIST_COMPANIES_LOADING:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          error: undefined,
          isFetching: true,
        },
      }

    case LIST_COMPANIES_SUCCESS:
      return {
        ...state,

        companyList: {
          items: action.payload.items,
          page: action.payload.page,
          perPage: action.payload.perPage,
          totalItems: action.payload.totalItems,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case LIST_COMPANIES_FAILURE:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case FIND_COMPANY_LOADING:
      return {
        ...state,

        companyDetail: {
          ...state.companyDetail,

          isFetching: true,
          error: undefined,
          updateError: undefined,
        },

        usersList: {
          ...state.usersList,

          isFetching: true,
          error: undefined,
        },
      }

    case FIND_COMPANY_SUCCESS:
      return {
        ...state,

        companyDetail: {
          item: {
            ...action.payload.company,

            users: [],
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },

        usersList: {
          items: action.payload.company.users,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case FIND_COMPANY_FAILURE:
      return {
        ...state,

        companyDetail: {
          ...state.companyDetail,

          error: action.payload.errorMsg,
          isFetching: false,
        },

        usersList: {
          ...state.usersList,

          isFetching: false,
        },
      }

    case ADD_COMPANY_LOADING:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          error: undefined,
          isFetching: true,
        },
      }

    case ADD_COMPANY_SUCCESS:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          isFetching: false,
          items: state.companyList.items.concat(action.payload.company),
        },
      }

    case ADD_COMPANY_FAILURE:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case UPDATE_COMPANY_LOADING:
      return {
        ...state,

        companyDetail: {
          ...state.companyDetail,

          error: undefined,
          updateError: undefined,
          isSaving: true,
        },
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,

        companyDetail: {
          ...state.companyDetail,

          item: {
            ...action.payload.company,
            users: [],
          },

          isFetching: false,
          isSaving: false,
          lastUpdated: new Date(),
        },

        companyList: {
          ...state.companyList,

          items: map(state.companyList.items, (company: Company) =>
            company.id === action.payload.company.id
              ? {
                  ...company,
                  ...action.payload.company,
                }
              : company,
          ),
        },
      }

    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,

        companyDetail: {
          ...state.companyDetail,

          updateError: action.payload.errorMsg,
          isFetching: false,
          isSaving: false,
        },
      }

    case REMOVE_COMPANY_LOADING:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          error: undefined,
          isFetching: true,
        },
      }

    case REMOVE_COMPANY_SUCCESS:
      return {
        ...state,

        companyDetail:
          state.companyDetail.item.id === action.payload.companyId
            ? {
                ...state.companyDetail,

                item: {
                  ...state.companyDetail.item,

                  status: 'DISABLED',
                },
              }
            : state.companyDetail,

        companyList: {
          ...state.companyList,

          isFetching: false,
          items: map(state.companyList.items, (company: Company) =>
            company.id === action.payload.companyId
              ? {
                  ...company,
                  status: 'DISABLED',
                }
              : company,
          ),
        },
      }

    case REMOVE_COMPANY_FAILURE:
      return {
        ...state,

        companyList: {
          ...state.companyList,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case ADD_REPORT_USER_LOADING:
      return {
        ...state,

        usersList: {
          ...state.usersList,

          error: undefined,
        },
      }

    case ADD_REPORT_USER_SUCCESS:
      return {
        ...state,

        usersList: {
          ...state.usersList,

          items: some(state.usersList.items, { id: action.payload.user.id })
            ? map(state.usersList.items, (user: ReportUser) => {
                if (user.id === action.payload.user.id) {
                  if (user.status === 'REMOVED') {
                    return {
                      ...user,
                      status: 'PENDING',
                      name: action.payload.user.name,
                    }
                  }
                  return { ...user, status: 'ACTIVE' }
                }
                return user
              })
            : state.usersList.items.concat(action.payload.user),
        },
      }

    case ADD_REPORT_USER_FAILURE:
      return {
        ...state,

        usersList: {
          ...state.usersList,

          error: action.payload.errorMsg,
        },
      }

    case REMOVE_REPORT_USER_LOADING:
      return {
        ...state,

        usersList: {
          ...state.usersList,

          error: undefined,
        },
      }

    case REMOVE_REPORT_USER_SUCCESS:
      return {
        ...state,

        usersList: {
          ...state.usersList,

          items: map(state.usersList.items, (user: ReportUser) =>
            user.id === action.payload.userId
              ? {
                  ...user,
                  status: 'REMOVED',
                }
              : user,
          ),
        },
      }

    case REMOVE_REPORT_USER_FAILURE:
      return {
        ...state,

        usersList: {
          ...state.usersList,

          error: action.payload.errorMsg,
        },
      }
    default:
      return state
  }
}
