import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { text, bg } from 'utils/colors'
import { useAuth0 } from 'utils/auth0'
import { listReports } from 'actions/userReportActions'

import ErrorView from 'components/ErrorView'
import EmptyView from 'components/EmptyView'
import Spinner from 'components/Spinner'
import { PageTitle, ContextTitle } from 'components/styles'

const UserReportsListContainer = styled.div`
  background: ${bg.reportUser};
  color: ${text.reportUser};
`

const UserReportsList: React.FC<{}> = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const auth0Context = useAuth0()

  const { reportList } = useSelector((state: any) => state.userReports)

  React.useEffect(() => {
    // id should come from auth0context
    dispatch(listReports(auth0Context, '5e71a0caed9186bd98dda859'))
  }, [dispatch, auth0Context])

  if (reportList.error) {
    return <ErrorView />
  }

  if (reportList.isFetching) {
    return <Spinner />
  }

  if (isEmpty(reportList)) {
    return <EmptyView emptyMsg={t('noReportsToListMsg')} />
  }

  return (
    <UserReportsListContainer>
      <PageTitle>{t('userReportsListTitle')}</PageTitle>
      <ContextTitle>{t('userReportsListExplained')}</ContextTitle>
      {JSON.stringify(reportList.items)}
    </UserReportsListContainer>
  )
}

export default UserReportsList
