import React from 'react'
import { omit } from 'lodash'
import { MetricsQuestionTypes } from 'models/metrics'
import SegmentedBarGraph from './SegmentedBarGraph'
import BoxWhiskerGraph from './BoxWhiskerGraph'

const HistoryGraphBar = ({
  qId,
  qType,
  totals,
  options,
  randomOffset,
  highestYAxisValue,
}: {
  qId: string
  qType: MetricsQuestionTypes
  totals: Record<string, number>
  options?: string[]
  randomOffset: number
  highestYAxisValue: number
}) => {
  if (['MULTIPLE_CHOICE', 'MULTI_SELECT', 'BREAKDOWN'].includes(qType)) {
    let responses = omit(totals, ['CONFLICTING_ANSWERS', 'NOT_APPLICABLE'])

    return (
      <SegmentedBarGraph
        id={qId}
        responses={responses}
        randomOffset={randomOffset}
        options={options}
        highestYAxisValue={highestYAxisValue}
        isVertical
        isPercentage={qType === 'BREAKDOWN'}
      />
    )
  }
  if (['INTEGER', 'PERCENTAGE'].includes(qType)) {
    return (
      <BoxWhiskerGraph
        id={qId}
        totals={totals}
        highestYAxisValue={highestYAxisValue}
        randomOffset={randomOffset}
        isPercentage={qType === 'PERCENTAGE'}
      />
    )
  }
  return null
}

export default HistoryGraphBar
