import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text, rgb } from 'utils/colors'
import { isNil } from 'lodash'

interface StatusBadgeProps {
  status:
    | 'COMPLIANT'
    | 'NON_COMPLIANT'
    | 'PENDING'
    | 'OVERDUE'
    | 'NOT_APPLICABLE'
    | 'REMOVED'
    | 'COMPLETE'
    | 'CONFLICTING_ANSWERS'
  size?: 'lg' | 'md'
  count?: number
  determineIsFaded?: () => boolean
  isInverted?: boolean
  isMultiline?: boolean
  prefix?: string
}

const StatusBadgeContainer = styled.div<StatusBadgeProps>`
  font-size: ${p => (p.size === 'lg' ? '1.2em' : '0.9em')};
  display: inline-block;
  background: ${p => (p.isInverted ? `rgba(${rgb[p.status]}, 0.1)` : text[p.status])};
  color: ${p => (p.isInverted ? text[p.status] : text.contrast)};
  border: ${p => (p.isInverted ? `2px solid ${text[p.status]}` : 'none')};
  font-weight: 600;
  width: ${p => (p.size === 'lg' ? '9em' : 'auto')};
  padding: ${p => (['lg', 'md'].includes(p.size) ? '0.5rem 0.5rem' : '0.25em 0.5em')};
  border-radius: 0.3em;
  text-align: center;
  opacity: ${p => (!isNil(p.determineIsFaded) && p.determineIsFaded() ? 0.5 : 1)};
`
const CountWrapper = styled.div<{ isMultiline?: boolean; size?: 'lg' | 'md' }>`
  display: ${p => (p.isMultiline ? 'block' : 'inline-block')};
  font-size: ${p => (p.isMultiline ? '1.6em' : p.size === 'md' ? '1.2em' : '1em')};
  margin-right: ${p => (p.isMultiline ? '0' : '0.4em')};
`

const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  size,
  count,
  determineIsFaded,
  isInverted,
  isMultiline,
  prefix,
}: StatusBadgeProps) => {
  const { t } = useTranslation()
  if (!status) return null
  return (
    <StatusBadgeContainer
      status={status}
      size={size}
      count={count}
      determineIsFaded={determineIsFaded}
      isInverted={isInverted}
    >
      {count != null && (
        <CountWrapper isMultiline={isMultiline} size={size}>
          {count}
        </CountWrapper>
      )}
      {prefix ? t(`${status}_prefixed`, { prefix }) : t(status)}
    </StatusBadgeContainer>
  )
}

export default StatusBadge
