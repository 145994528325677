import React from 'react'
import styled from 'styled-components'
import { darken } from 'utils/colors'
import TriveLogoImg from 'components/TriveLogo/trive.png'

interface TriveLogoProps {
  brandName?: string
}

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${darken};
`
export const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const TriveLogo: React.FC<TriveLogoProps> = ({ brandName }: TriveLogoProps) => {
  return (
    <LogoWrapper>
      <StyledLogo src={TriveLogoImg} alt={brandName} />
    </LogoWrapper>
  )
}

export default TriveLogo
