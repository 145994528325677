import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map, startsWith, values, head, isNil, isEmpty } from 'lodash'
import { useQueryParam, StringParam } from 'use-query-params'

import { listMetricsGroups } from 'actions/metricsActions'
import { setTemplateHasUnsavedChanges } from 'actions/templateActions'
import { MetricsGroup } from 'models/metrics'
import brandConfig, { BrandConfigProps } from 'utils/brandConfig'
import PoweredBy from 'components/PoweredBy'
import ConfirmDialog from 'components/ConfirmDialog'

import DemoLogo from 'components/DemoLogo'
import Demo1Logo from 'components/Demo1Logo'
import Demo2Logo from 'components/Demo2Logo'
import Demo3Logo from 'components/Demo3Logo'
import Demo4Logo from 'components/Demo4Logo'
import Demo5Logo from 'components/Demo5Logo'
import TriveLogo from 'components/TriveLogo'
import KainosLogo from 'components/KainosLogo'
import NewStateLogo from 'components/NewStateLogo'
import TailwaterLogo from 'components/TailwaterLogo'
import TrinityHuntLogo from 'components/TrinityHuntLogo'
import StonePointLogo from 'components/StonePointLogo'
import AbryLogo from 'components/AbryLogo'
import AltamontLogo from 'components/AltamontLogo'
import KelsoLogo from 'components/KelsoLogo'
import AconLogo from 'components/AconLogo'

import { SidenavContainer, NavWrapper, NavItem, FakeNavItem, SubNavItem, AddMetricGroupBtn } from './styles'

interface SidenavProps {
  location?: any
}

const primaryNavItems = [
  {
    label: 'portfolioNav',
    path: '/portfolio',
    icon: <FontAwesomeIcon icon="building" />,
  },
  {
    label: 'metricsNav',
    path: '/metrics',
    icon: <FontAwesomeIcon icon="poll-h" />,
  },
  {
    label: 'dashboardNav',
    path: '/',
    icon: <FontAwesomeIcon icon="chart-pie" />,
  },
  {
    label: 'scheduleNav',
    path: '/schedule',
    icon: <FontAwesomeIcon icon="calendar-alt" />,
  },
  {
    label: 'orgSettingsNav',
    path: '/settings',
    icon: <FontAwesomeIcon icon="cog" />,
  },
]

const Sidenav: React.FC<SidenavProps> = ({ location }: SidenavProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const history = useHistory()

  const { groupsMap } = useSelector((state: any) => state.metrics)
  const { hasUnsavedChanges } = useSelector((state: any) => state.templates)

  const [activePath, setActivePath] = useState(location.pathname)
  const [intendedNavigationPath, setIntendedNavigationPath] = useState<any | undefined>()

  const [addGroup, setAddGroup] = useQueryParam('addGroup', StringParam)

  useEffect(() => {
    if (!groupsMap) {
      dispatch(listMetricsGroups(auth0Context))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsMap])

  useEffect(() => {
    setActivePath(location.pathname)
  }, [location.pathname])

  const handleNavClick = (intendedPath: string) => {
    console.log('Where they wanted to go: ', intendedPath)
    setIntendedNavigationPath(intendedPath)
  }

  const handleContinueNavigation = () => {
    if (!isEmpty(intendedNavigationPath)) {
      dispatch(setTemplateHasUnsavedChanges(false))
      const pathname = intendedNavigationPath
      setIntendedNavigationPath(undefined)
      history.push(pathname)
    }
  }

  const handleAddMetricGroup = () => {
    const firstGroup = head(values(groupsMap))
    if (startsWith(activePath, '/metrics') && addGroup !== 'true') {
      setAddGroup('true')
    } else if (!isNil(firstGroup)) {
      history.push(`/metrics/${firstGroup?.id}/?addGroup=true`)
    } else {
      history.push('/metrics?addGroup=true')
    }
  }

  const renderBrandLogo = (brandConfig: BrandConfigProps): JSX.Element => {
    switch (brandConfig?.brandId) {
      case 'AXYQ':
        return <DemoLogo brandName={brandConfig?.brandName} />

      case 'BXYQ':
        return <Demo1Logo brandName={brandConfig?.brandName} />

      case 'CXYQ':
        return <Demo2Logo brandName={brandConfig?.brandName} />

      case 'DXYQ':
        return <Demo3Logo brandName={brandConfig?.brandName} />

      case 'EXYQ':
        return <Demo4Logo brandName={brandConfig?.brandName} />

      case 'FXYQ':
        return <Demo5Logo brandName={brandConfig?.brandName} />

      case 'TRIVE':
        return <TriveLogo brandName={brandConfig?.brandName} />

      case 'KAINOS':
        return <KainosLogo brandName={brandConfig?.brandName} />

      case 'NEWSTATE':
        return <NewStateLogo brandName={brandConfig?.brandName} />

      case 'TAILWATER':
        return <TailwaterLogo brandName={brandConfig?.brandName} />

      case 'TRINITYHUNT':
        return <TrinityHuntLogo brandName={brandConfig?.brandName} />

      case 'STONEPOINT':
        return <StonePointLogo brandName={brandConfig?.brandName} />

      case 'ABRY':
        return <AbryLogo brandName={brandConfig?.brandName} />

      case 'ALTAMONT':
        return <AltamontLogo brandName={brandConfig?.brandName} />

      case 'KELSO':
        return <KelsoLogo brandName={brandConfig?.brandName} />

      case 'ACON':
        return <AconLogo brandName={brandConfig?.brandName} />

      default:
        return <DemoLogo brandName={brandConfig?.brandName} />
    }
  }

  const renderNavItem = (navItem: { label: string; path: string; icon: React.FC }) => {
    // console.log('PATH', activePath)
    const isParent = navItem.label === 'metricsNav'

    let subItems = null
    if (isParent) {
      subItems = (
        <>
          {map(groupsMap, (group: MetricsGroup) => {
            const subPath = `${navItem.path}/${group.id}`
            return (
              <SubNavItem
                key={`subnav-${subPath}`}
                to={subPath}
                data-active={subPath === activePath}
                data-hidden={!startsWith(activePath, '/metrics')}
              >
                <FontAwesomeIcon icon="circle" />
                {group.name}
              </SubNavItem>
            )
          })}
          <AddMetricGroupBtn
            data-active={false}
            data-hidden={!startsWith(activePath, '/metrics')}
            onClick={handleAddMetricGroup}
          >
            <FontAwesomeIcon icon="plus-circle" />
            {t('addMetricGroupNavBtn')}
          </AddMetricGroupBtn>
        </>
      )
    }

    return (
      <React.Fragment key={`nav-${navItem.path}`}>
        {hasUnsavedChanges ? (
          <FakeNavItem
            onClick={() => handleNavClick(navItem.path)}
            data-active={navItem.path === activePath}
            data-parent={isParent}
          >
            {navItem.icon}
            {t(navItem.label)}
          </FakeNavItem>
        ) : (
          <NavItem to={navItem.path} data-active={navItem.path === activePath} data-parent={isParent}>
            {navItem.icon}
            {t(navItem.label)}
          </NavItem>
        )}
        {subItems}
      </React.Fragment>
    )
  }

  return (
    <SidenavContainer>
      {renderBrandLogo(brandConfig)}
      <NavWrapper>{map(primaryNavItems, renderNavItem)}</NavWrapper>
      <PoweredBy />
      <ConfirmDialog
        isOpen={!isNil(intendedNavigationPath)}
        confirmText={t('confirmCancelWithChanges')}
        onConfirm={handleContinueNavigation}
        onCancel={() => setIntendedNavigationPath(undefined)}
      />
    </SidenavContainer>
  )
}

export default withRouter(Sidenav)
