import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text, bg } from 'utils/colors'
import dateDiff from 'utils/dateDiff'
import { map } from 'lodash'

interface CountdownTimerProps {
  date?: string
}

const CountdownTimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const UnitItem = styled.div`
  margin: 0 0.5rem;
`
const NumberContent = styled.div`
  background: ${bg.contrast};
  color: ${text.contrast};
  font-size: 3rem;
  padding: 0.5rem;
  width: 4rem;
  text-align: center;
  border-radius: 0.5rem;
`
const UnitContent = styled.div`
  margin: 1rem 0 0;
  text-align: center;
`

const CountdownTimer: React.FC<CountdownTimerProps> = ({ date }: CountdownTimerProps) => {
  const { t } = useTranslation()
  if (date == null) return null
  const nextDateObj = dateDiff(new Date(), new Date(date), 'month', 'day', 'hour')

  return (
    <CountdownTimerContainer>
      {map(nextDateObj, (unit: number, key: string) => {
        return (
          <UnitItem key={`countdown-unit-${key}`}>
            <NumberContent>{unit}</NumberContent>
            <UnitContent>{t(key, { count: unit })}</UnitContent>
          </UnitItem>
        )
      })}
    </CountdownTimerContainer>
  )
}

export default CountdownTimer
