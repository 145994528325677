import brandConfig from 'utils/brandConfig'

export const getHost = (): string => {
  if (!brandConfig || !brandConfig?.apiHost) {
    return 'https://babyyoda-staging.comply.cloud'
  }

  return brandConfig?.apiHost
}

export const getClientId = (): string => {
  const appMetadata = JSON.parse(sessionStorage.getItem('app_metadata') as string) || {}
  return appMetadata.clientId
}

export const getCompanyId = (): string => {
  const appMetadata = JSON.parse(sessionStorage.getItem('app_metadata') as string) || {}
  return appMetadata.companyId
}
