import styled from 'styled-components'
import { text, rgb } from 'utils/colors'
import { ContentBox } from 'components/styles'
import { StatusesType } from './DashboardPage'

// DashboardPage

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  grid-template-areas:
    'main'
    'countdown'
    'smalls'
    'bar';
  @media (min-width: 80rem) {
    /* (80em == 1280px) */
    grid-template-columns: 3fr 1fr 3fr;
    grid-template-areas:
      'main main bar'
      'countdown smalls smalls';
  }
`
export const DataBox = styled(ContentBox)<{ area?: string }>`
  padding: 1rem 1rem 0;
  overflow: hidden;
  grid-area: ${p => p.area};
`
export const DataBoxTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`
export const DataItemsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`
export const DataItem = styled.div`
  padding: 1rem;
  background: rgba(${rgb.logo}, 0.04);
  border-radius: 0.5rem;
  font-weight: 600;
  color: ${text.active};
  text-align: center;
`
export const DataItemLabel = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${text.secondary};
  margin: 0 0 0.5rem;
`
export const PieWrapperWrapper = styled.div`
  position: relative;
  min-height: 23rem;
`
export const HoverPieCenter = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 1rem;
  display: grid;
  grid-template-columns: auto 12rem;
  align-items: center;
  grid-gap: 0.5rem;
  & > * {
    margin: 0 auto;
    max-width: 23rem;
  }
`
export const HoverText = styled.text`
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  text-anchor: middle;
`
export const HoverCount = styled.text`
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  text-anchor: middle;
`
export const PieWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 1rem;
  display: grid;
  grid-template-columns: auto 12rem;
  align-items: center;
  grid-gap: 0.5rem;
  .VictoryContainer {
    margin: 0 auto;
    max-width: 23rem;
  }
`
export const PieLegend = styled.div`
  & > * {
    height: auto;
    background: rgba(${rgb.logo}, 0.04);
    padding: 1rem;
    border-radius: 0.5rem;
  }
`
export const LegendItem = styled.div<{ color?: string }>`
  color: ${p => p.color || '#555'};
  font-size: 1rem;
  display: grid;
  grid-template-columns: 2rem auto;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`
export const SmallLegendItem = styled(LegendItem)`
  grid-template-columns: 1rem auto;
  font-size: 0.9rem;
`
export const LegendCount = styled.div`
  font-weight: 900;
  text-align: right;
`
export const LegendBox = styled.div<{ color?: string }>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.3rem;
  background: ${p => p.color || '#555'};
`
export const SmallPieWrapper = styled.div`
  width: 40%;
  text-align: center;
`
export const PiesList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const BarWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 1rem auto 0;
`
export const BarLegend = styled.div`
  display: flex;
  margin: 0;
  padding-bottom: 0.5rem;
`
export const DataItemGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  & > * {
    margin: 0.5rem;
  }
`
export const HistoryItem = styled.div`
  margin: 0 0.5rem;
`
export const EmptyWrapper = styled.div`
  margin: 5rem 0 0;
`
export const DateDueWrapper = styled.div`
  font-size: 1.4rem;
  color: ${text.inactive};
  font-style: italic;
  font-weight: 600;
  text-align: center;
  margin: 3rem auto 1rem;
`
export const DashboardEmptyContent = styled.div`
  h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 2rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 0 0 2rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 2rem;
  }
`
export const CloseIcon = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;
`
export const DataBoxTopRow = styled.div`
  display: grid;
  grid-template-columns: auto 1.5rem;
  grid-gap: 0.5rem;
`
export const FilteredReportsWrapper = styled.div``
export const CompanyItem = styled.div<{ status: StatusesType }>`
  display: grid;
  grid-template-columns: 1rem 1fr 10rem 10rem;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0 0.5rem;
  background: rgba(${p => rgb[p.status]}, 0.1);
  margin-bottom: 0.5rem;
  cursor: pointer;
  user-select: none;
`
export const CompanyIcon = styled.div<{ color?: string }>`
  font-size: 1rem;
  color: ${p => p.color};
`
export const CustomLabelWrapper = styled.g`
  tspan {
    fill: #fff !important;
    font-size: 1.5rem !important;
    font-weight: 600;
    font-family: 'Roboto' !important;
  }
`
export const FormWrapper = styled.div`
  form {
    display: flex;
    justify-content: flex-end;
    width: 10rem;
  }
`
